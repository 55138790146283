import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DataManipulationService} from '../../../services/data-manipulation.service';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit {
  team: any;
  teamSelected: any;
  showStatements = false;
  teams: any[] | any;
  private categories: any;
  questionsToShow: any[] = [];
  questions: { [name: string]: any; } = {};
  gotStatements: boolean = false;
  selectCircle = 'showAll'
  selectSquare = 'showAll'

  createStatementCategory: string = '';
  createNew = {}
  createNewFor = {
    showAll: {},
    Performance: {},
    Motivation: {},
    Satisfaction: {},
    Stress: {}
  };

  createStatementForm = new FormGroup({
    statementTitle: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    // statementDescription: new FormControl(null, [
    //   Validators.required,
    //   Validators.minLength(1)]),
    statementCategory: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
    statementSign: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
  });

  showEdit = false;
  editStatement: any;
  editStatementIndex: any;
  editStatementCategory: any;
  prioritySubscription: Subscription;
  userPriorities: any;
  currentPlan: { title: string; } | undefined;


  constructor(public data: DataManipulationService, public apiwrapper: ApiwrapperService,
              private cdRef: ChangeDetectorRef) {

    this.prioritySubscription = this.data.userPriorities
      .subscribe((value: any) => {
        if (value.role) {
          this.userPriorities = value;
        }
      });

    if (localStorage.getItem('upgrading')) {
      localStorage.removeItem('upgrading');
    }
  }

  ngOnInit() {
    localStorage.setItem('headerText', 'Pollling');
    localStorage.removeItem('currentTeam');
    // @ts-ignore
    this.currentPlan = JSON.parse(localStorage.getItem('workspacePlan'));
    this.showStatements = false;
    this.apiwrapper.getTeamsFromWorkspace().subscribe(
      res => {
        const teams = res;
        this.data.hideLoading();
        if (teams.length === 0) {
          // this.modal.showCreateTeamModal();
          // @ts-ignore
          document.getElementById('closeTeam').click();
        } else if (teams.length === 1) {
          this.teams = teams;
          this.team = teams[0];
          this.getCategoryIndicators();
        } else {
          this.teams = teams;
          // @ts-ignore
          document.getElementById('teamSelect').click();
          this.cdRef.detectChanges();
        }
      }
    );
  }


  // get the statements categories and the indicators
  getCategoryIndicators() {
    this.data.showLoading();
    this.apiwrapper.getQuestionsSuperCategories().subscribe(
      res => {
        this.data.hideLoading();
        this.categories = res;
        // @ts-ignore
        document.getElementById('closeTeamSelector').click();
        // @ts-ignore
        document.getElementById('closeTeamSelector2').click();
        this.getStatements();
        this.getCategoriesForCreate();
      },
      err => {
        console.log(err);
      }
    );
  }

  private getStatements() {
    this.gotStatements = false;
    this.questions['showAll'] = [];
    this.apiwrapper.getTeamQuestionsList(this.team.uuid, 1).subscribe(
      res => {
      }, error => {
        console.log(error);
      }
    )

    // @ts-ignore
    this.categories[0].indicators.forEach((categ) => {
      this.apiwrapper.getQuestionsByIndicator(this.team.uuid, categ.uuid).subscribe(
        res => {
          res.questions.forEach((el: { edit: boolean; }) => {
            // el.indicatorsCategories = el.category.title;
            el.edit = false;
          });
          const frozen = [];
          for (let i = 0; i < res.questions.length; i++) {
            if (res.questions[i].question.status === 'frozen') {
              frozen.push(res.questions[i]);
              res.questions.splice(i, 1);
              i--;
            }
          }
          this.questions[categ.title] = res.questions.concat(frozen);
          this.questions['showAll'] = this.questions['showAll'].concat(res.questions.concat(frozen));
          this.cdRef.detectChanges();
          this.gotStatements = true;
        }
      );
    });
  }

  private getCategoriesForCreate() {
    this.categories.forEach((el: { indicators: any[]; title: string | number; }) => {
      el.indicators.forEach((indicator) => {
        // @ts-ignore
        this.createNewFor[indicator.title][el.title] = indicator.uuid;
      });
    });
  }

  activeStatements(statementName: string | number) {
    let active = 0;
    this.questions[statementName].forEach((el: { question: { status: string; }; }) => {
      if (el.question.status === 'active' || el.question.status === 'frozen') {
        active++;
      }
    });
    return active;
  }

  questionToggled(question: any, category: string | number, index: string | number) {
    let status: string;
    if (question.question.status === 'inactive') {
      question.question.status = 'active';
      status = 'active';
    } else {
      question.question.status = 'inactive';
      status = 'inactive';
    }
    this.data.showLoading();
    this.apiwrapper.patchTeamQuestionToggle({
      status,
      title: question.question.title
    }, question.question.uuid, this.team.uuid).subscribe(
      res => {
        this.questions[category][index].question.status = status;
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error updating the statement status.');
      });
  }

  saveQuestion() {
    const statement = this.editStatement;
    const index = this.editStatementIndex;
    const category = this.editStatementCategory;
    const body = {
      title: statement.question.title,
      sign: statement.question.sign,
      status: statement.question.status,
    };

    // change save to false
    this.data.showLoading();
    this.apiwrapper.patchTeamQuestion(body, statement.question.uuid, this.team.uuid).subscribe(
      res => {
        this.closeEdit();
        this.data.hideLoading();
        this.questions[category][index].question = res;
        this.questions[category][index].edit = false;
        // this.data.showAlertSuccess('Statement updated!', '');
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error changing the statement details.');
      }
    );
  }

  showCreate(indicator: any) {
    // display create modal
    this.createStatementCategory = indicator;
    // @ts-ignore
    document.getElementById('createStatementButton').click();
  }

  createStatement(event: any) {
    this.apiwrapper.postTeamQuestions(this.team.uuid, {
      indicatorUuid: this.createStatementForm.value.statementCategory,
      title: this.createStatementForm.value.statementTitle,
      sign: this.createStatementForm.value.statementSign,
      isSystem: 'no'
    }).subscribe(
      res => {
        this.createStatementForm.reset();
        // @ts-ignore
        document.getElementById('closeCreateStatement').click();
        // this.data.showAlertSuccess('Statement created!', '');
        this.getStatements();
      }, err => {
        // @ts-ignore
        document.getElementById('closeCreateStatement').click();
        this.data.showAlertError('There was an error creating the statement.');
      });
  }

  closeEdit() {
    this.showEdit = false;
    // this.editStatement = {};
    // this.editStatementIndex = 0;
    // this.editStatementCategory = '';
  }

  editingStatement(statement: any, index: any, category: any) {
    this.showEdit = true;
    this.editStatement = statement;
    this.editStatementIndex = index;
    this.editStatementCategory = category;
  }

  deleteStatement(uuid: string) {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'You won\'t be able to revert this!',
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#05D287',
    //   cancelButtonColor: '#F3454C',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //   if (result.value === true) {
    //     this.apiwrapper.deleteQuestions(uuid).subscribe(
    //       res => {
    //         this.showEdit = false;
    //         this.getStatements();
    //         // this.data.showAlertSuccess('Statement deleted!', '');
    //       }, err => {
    //         this.data.showAlertError('There was an error deleting the statements.', '');
    //       }
    //     );
    //   }
    // });
  }

  requirePayment() {
    // return this.currentPlan.requirePayment !== 'no';
    // @ts-ignore
    return this.currentPlan.title.toLowerCase() === 'pro';
  }

  setSelector(select: string) {
    this.selectCircle = select;

    if (select === 'showAll') {
      // add all statements
    }
  }
}
