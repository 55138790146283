import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-onboarding-categories',
  templateUrl: './onboarding-categories.component.html',
  styleUrls: ['./onboarding-categories.component.scss']
})
export class OnboardingCategoriesComponent implements OnInit {

  @Input() reportingCategories: any[] = [];
  
  @Input() set checkinStatements(value: any) {
    this.categoriesStatements = value;
    this.setStatementTab();
    this.statementResponses = [];
    this.getCategoryStatementScoreColor();
  }

  @Input() set stateResponses(value: any | undefined) {
    this.statementResponses = [];
    if (value !== undefined) {
      if (value.type === 'scaled') {
        this.getQuestionDistributionValues(value.responses);
      } else {
        this.statementResponses = value.responses._embedded.responses;
        this.statementResponseCount = value.responses._total_items;
        this.statementResponsePage = value.responses._page_count;
      }
    }
  }
  
  @Output() checkinStatementEvent = new EventEmitter<any>();
  @Output() statementResponsesEvent = new EventEmitter<any>();
  
  categoriesStatements: any[] = [];
  statementResponses: any[] = [];
  showSelectedTabState: any[] = [];

  scoreColorInterval: {color: string, min:number, max:number}[] = [
    {color: 'red', min: 0, max: 39},
    {color: 'orange', min: 40, max: 59},
    {color: 'yellow', min: 60, max: 79},
    {color: 'green', min: 80, max: 100},
  ];
  
  previousShowSelectedTabCategIndex: any;
  showSelectedTabCateg: any;
  previousShowSelectedTabStateIndex: any;
  responseDistribution: any;
  showedOpenQsResponse: any;

  hasCategoryStatements: boolean = false;
  showResponseModal: boolean = false;

  statementResponseCount: number = 0;
  statementResponsePage: number = 0;
  
  
  constructor() { }
  
  ngOnInit(): void {
    console.log(this.reportingCategories);
    this.getCategoryScoreColor();
    this.showSelectedTabCateg = new Array(this.reportingCategories.length).fill(false);
  }

  getCategoryScoreColor() {
    let checkinScore = 0;
    this.reportingCategories.forEach((checkin: any) => {
      checkinScore = checkin.collectionScore;
      this.scoreColorInterval.forEach((scoreInterval: any) => {
        if (checkinScore >= scoreInterval.min && checkinScore <= scoreInterval.max) {
          checkin.color = scoreInterval.color;
        }
      });
    });
  }

  setStatementTab() {
    this.hasCategoryStatements = true;
    this.showSelectedTabState = new Array(this.categoriesStatements.length).fill(false);
  }

  getCategoryStatementScoreColor() {
    let statementScore = 0;
    this.categoriesStatements.forEach((statement: any) => {
      if (statement.eligibleForScore) {
        statementScore = statement.score;
        this.scoreColorInterval.forEach((scoreInterval: any) => {
          if (statementScore >= scoreInterval.min && statementScore <= scoreInterval.max) {
            statement.color = scoreInterval.color;
          }
        });
      } else {
        statement.color = 'default';
      }
    });
  }

  getQuestionDistributionValues(value: any) {
    if (value) {
      value.onInsight = true;
      value.eligibleForResult = true;
      this.responseDistribution = new Observable(subcriber => {
        subcriber.next(value);
      });
    }
  }

  openResponsePreviewModal(response: any) {
    this.showedOpenQsResponse = response;
    this.showResponseModal = true;
    const openModal = document.getElementById('actionModalButton');
    openModal?.click();
  }

  changePage(page: any, statementType: any, statementUuid: any) {
    this.statementResponses = [];
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: [], page: page.page + 1, limit: 6});
  }

  emitStatementResponses(statementType: any, statementUuid: any, index: number) {
    if (this.previousShowSelectedTabStateIndex === undefined) {
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    } else {
      this.showSelectedTabState[this.previousShowSelectedTabStateIndex] = false;
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    }
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: [], page: 1, limit: 6});
  }

  emitCategoryStatements(categoryUuid: any, index: number) {
    this.categoriesStatements = [];
    if (this.previousShowSelectedTabCategIndex === undefined) {
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    } else {
      this.showSelectedTabCateg[this.previousShowSelectedTabCategIndex] = false;
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    }
    this.hasCategoryStatements = false;
    this.checkinStatementEvent.emit(categoryUuid);
  }

  clearStatements() {
    this.statementResponses = [];
  }

}
