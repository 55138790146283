<div class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
  <div
    class="d-flex flex-column justify-content-start invite-to-workspace">
    <h3 class="" *ngIf="data.onboardingStep === 3 ">{{'start_guide_step1'| translate}}</h3>
    <h3 class="" *ngIf="data.onboardingStep === 6">{{'invite_people'|translate}}</h3>
    <nav>
      <div class="nav nav-pills mt-3" id="pills-tab" role="tablist">
        <button class="nav-link active left" id="inviteViaEmail"
                data-bs-toggle="tab" data-bs-target="#inviteViaEmailContent"
                type="button" role="tab" aria-controls="inviteViaEmailContent"
                aria-selected="true"><i class="bi bi-envelope-fill me-2"></i>{{'step31_invite_email' | translate}}
        </button>
        <button class="nav-link ms-3 right" id="inviteViaLink"
                data-bs-toggle="tab" data-bs-target="#inviteViaLinkContent"
                type="button" role="tab" aria-controls="inviteViaLinkContent"
                aria-selected="false"><i class="bi bi-link me-2"></i>{{'step31_invite_link' | translate}}
        </button>
      </div>
    </nav>
    <div class="tab-content card mt-2 invite-to-workspace-tabs" id="pills-tabContent">
      <div class="tab-pane card-body fade show active invite-via-email-content" id="inviteViaEmailContent"
           role="tabpanel" aria-labelledby="inviteViaEmail">
        <p>{{'step31_subtitle' | translate}}</p>
        <div style="display: grid">
          <!--              <input type="email" class="form-control mb-2 invited-emails" (keyup.Space)= "addEmail()">-->
          <mat-form-field class="example-chip-list" appearance="fill"
                          style="background-color: transparent !important;">
            <mat-chip-list #chipList aria-label="Fruit selection">
              <mat-chip *ngFor="let email of membersInvited" #chip="matChip">
                {{email.user.email}}
              </mat-chip>
            </mat-chip-list>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let email of inviteEmails" #chip="matChip"
                        (selectionChange)="selectedEmail($event, email)" (removed)="removeEmail(email)"
                        (click)="chip.toggleSelected()">
                {{email}}
                <button matChipRemove>
                  <!--                      <mat-icon style="color: #ce0000">cancel</mat-icon>-->
                  <img src="../../../assets/svg/team-delete-button-blue.svg" alt="">
                </button>
              </mat-chip>
              <input [formControl]="teamEmails" placeholder="{{'step31_placeholder'| translate}}" id="inviteEmail"
                     [matChipInputFor]="chipList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     [matChipInputAddOnBlur]="addOnBlur"
                     (matChipInputTokenEnd)="addEmail($event)"
                     (focusout)="addEmail($event)">
            </mat-chip-list>
          </mat-form-field>
          <textarea rows="15" class="form-control invited-emails-message" type="text"
                    [(ngModel)]="workspaceInvitationBody.body" [readonly]="!previewMessageButton"></textarea>
          <button (click)="previewMessageOnEmailInvitation()"
                  class="btn btn-primary preview-message-button">{{(previewMessageButton) ? ('step31_save'| translate) : ('step31_adjust_invitation'|translate) }}
          </button>
          <span class="tip"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step31_infobox'| translate}}</span>
          <div class="mt-4" style="cursor: pointer; display: flex">
            <input class="form-check-input" type="checkbox" value="" id="checkbox"
                   [(ngModel)]="emailPermissionCheck">
            <p class="form-check-label ms-2 p-top"
               (click)="emailPermissionCheck = !emailPermissionCheck ">
               {{'step31_permission' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="tab-pane card-body fade invite-via-link" id="inviteViaLinkContent" role="tabpanel"
           aria-labelledby="inviteViaLink">
        <form [formGroup]="inviteViaLink">
          <label class="mt-5 mb-1">{{'step31_copy_link_invitation'| translate}}</label>
          <div class="input-group" style="margin-bottom: 1rem">
            <textarea id="invitationLinkWithMessage" readonly formControlName="invitationLinkWithMessage" cols="40"
                      class="form-control invitation-link-with-message" [ngModel]="data.inviteMessage + '\n' +data.workspaceInvitation" rows="6">
            </textarea>
            <div class="input-group-append">
              <button class="btn copy-to-clipboard" type="button" style="z-index: 100" (click)="data.copyInvitationLink(false)">{{'step31_button_copy'| translate}}</button>
            </div>
          </div>
          <label class="mb-1">{{'step31_copy_link' | translate}}</label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <i class="bi bi-link-45deg fs-3 input-group-text"></i>
            </div>
            <input id="invitationLink" formControlName="invitationLink" type="text"
                   [ngModel]="data.workspaceInvitation"
                   class="form-control invitation-link" readonly>
            <div class="input-group-append">
              <button class="btn copy-to-clipboard" style="z-index: 100" type="button" (click)="data.copyInvitationLink(true)">{{'step31_button_copy'| translate}}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div>
  <button class="btn primary-btn" [disabled]="!emailPermissionCheck"
          style="float: right; margin: 2rem 4rem 1rem 0"
          (click)="inviteMembers()">
    Invite
  </button>
</div>
