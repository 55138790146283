import {Component, OnInit} from '@angular/core';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {DataManipulationService} from '../../../services/data-manipulation.service';


@Component({
  selector: 'app-methods',
  templateUrl: './methods.component.html',
  styleUrls: ['./methods.component.scss']
})
export class MethodsComponent implements OnInit {
  gettingMethods = false;
  methods = [];
  createMethodForm = new FormGroup({
    methodTitle: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    methodDescription: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
  });
  gotMethods = false;
  public showEdit = false;

  // @ts-ignore
  editMethod: { title?: any; tags?: any; description: any; status: any; uuid: any; teamUuid?: any; term?: undefined; tag?: undefined; };
  method = {
    term: undefined,
    tag: undefined,
    description: undefined,
    status: undefined,
    uuid: undefined
  };
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  newTags = [];
  editMethodIndex = 0;

  constructor(private apiwrapper: ApiwrapperService, private data: DataManipulationService) {
  }

  ngOnInit() {
    this.getMethods();
  }


  add(event: MatChipInputEvent, addTo: { tag: string; }[]): void {
    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      addTo.push({tag: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(removeFrom: any[], i: any): void {
    const index = removeFrom.indexOf(i);
    if (index >= 0) {
      removeFrom.splice(index, 1);
    }
  }

  getMethods() {
    this.data.showLoading();
    this.gotMethods = false;
    // teamUuid needed
    this.apiwrapper.getMethods(false, 'teamUuid').subscribe(
      res => {
        this.data.hideLoading();
        this.gotMethods = true;
        res.forEach((el: { edit: boolean; }) => {
          el.edit = false;
        });
        this.methods = res;
      }, err => {
        this.data.hideLoading();
        this.gotMethods = true;
      }
    );
  }

  createMethod() {
    this.data.showLoading();
    // if (this.createMethodForm.valid && this.newTags.length > 0) {
    if (this.createMethodForm.valid) {
      const body = {
        title: this.createMethodForm.value.methodTitle,
        tags: this.parsTags(this.newTags),
        description: this.createMethodForm.value.methodDescription,
      };
      // TODO ADD TEAM UUID
      const teamUuid = 'foo';
      this.apiwrapper.postMethod(body, teamUuid).subscribe(
        res => {
          this.data.hideLoading();
          this.getMethods();
          this.createMethodForm.reset();
          // this.createMethodForm.value.methidTitle.setValue('');
          // @ts-ignore
          document.getElementById('closeCreateMethod').click();
          // this.data.showAlertSuccess('Method created successfully', '');
        }, err => {
          this.data.hideLoading();
          this.data.showAlertError('There was an error creating the method.');
        });
    }
  }

  methodToggled(method: any, i: number) {
    status = method.status;
    if (status === 'active') {
      status = 'inactive';
    } else {
      status = 'active';
    }
    // @ts-ignore
    this.apiwrapper.patchActivateMethod(status, this.methods[i].uuid).subscribe(
      res => {
        this.getMethods();
        // @ts-ignore
        this.methods[i].status = status;
      }, err => {
        console.log(err);
      }
    );
  }

  activeMethods() {
    let active = 0;
    this.methods.forEach((el) => {
      // @ts-ignore
      if (el.status === 'active') {
        active++;
      }
    });
    return active;
  }

  saveMethod(i: number, method: any) {
    // TODO CHECK TEAM UUID
    this.apiwrapper.patchMethod({term: method.term, tag: method.tag, description: method.description},
      method.uuid, method.teamUuid).subscribe(
      res => {
        // @ts-ignore
        this.methods[i].edit = false;
        // this.data.showAlertSuccess('Method update successfully', '');
      }, err => {
        this.data.showAlertError('There was an error updating the method.');
      });
  }

  showCreatModal() {
    // @ts-ignore
    document.getElementById('createMethodButton').click();
  }

  closeEdit() {
    this.getMethods();
    this.showEdit = false;
    this.editMethod = this.method;
  }

  editingMethod(method: any, i: number) {
    this.editMethodIndex = i;
    this.editMethod = method;
    this.showEdit = true;
  }

  parsTags(tags: any[]) {
    const pars: any[] = [];
    tags.forEach((el) => {
      pars.push(el.tag);
    });
    return pars;
  }

  updateEditedMethod() {
    // TODO CHECK ID TEAM UUID
    this.apiwrapper.patchMethod({
      title: this.editMethod.title, tags: this.parsTags(this.editMethod.tags), description: this.editMethod.description,
      status: this.editMethod.status
    }, this.editMethod.uuid, this.editMethod.teamUuid).subscribe(
      res => {
        this.getMethods();
        // @ts-ignore
        this.methods[this.editMethodIndex] = res;
        // @ts-ignore
        this.methods[this.editMethodIndex].edit = false;
        // this.data.showAlertSuccess('Method update successfully', '');
        this.showEdit = false;
        this.editMethod = this.method;
      }, err => {
        this.data.showAlertError('There was an error updating the method.');
      });
  }

  deleteMethod(uuid: string) {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'You won\'t be able to revert this!',
    //   type: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#05D287',
    //   cancelButtonColor: '#F3454C',
    //   confirmButtonText: 'Yes, delete it!'
    // }).then((result) => {
    //   if (result.value === true) {
    //     // todo teamUuid
    //     const teamUuid = '';
    //     this.apiwrapper.deleteMethod(uuid, teamUuid).subscribe(
    //       res => {
    //         this.showEdit = false;
    //         this.getMethods();
    //         // this.data.showAlertSuccess('Method deleted!', '');
    //       }, err => {
    //         this.data.showAlertError('There was an error deleting the method.', '');
    //       }
    //     );
    //   }
    // });

  }

  isMVP() {
    return true;
    // return localStorage.getItem('WORKSPACE_UUID') === 'a57c2fa0-5c07-11ea-bbef-391a5da26eac';
  }
}
