<div *ngIf="gotCreator && !data.showDemo">
  <div *ngIf="onboardingCreator">
    <div class="onboarding" *ngIf="!onboardingDone" #onboardingCard>
      <mat-card class="card-onboarding" *ngIf="showOnboarding">
        <mat-card-header class="onboarding-header">
          <div
            [ngStyle]="onboardingStep === 1 ? {'height': '72px'} :(onboardingStep === 2 ? {'height': '10vh'} : {'height' : '110px'})"
            [ngClass]="onboardingStep != 1 && onboardingStep != 2 ? 'justify-content-between'
              : onboardingStep === 2 ? 'justify-content-center setup-team' : 'justify-content-center'"
            class="d-flex align-items-center onboarding-window-header">
            <img *ngIf="onboardingStep === 1" class="logo" src="../../../assets/images/successteam-icon.svg" alt="" (click)="onboardingStep=2">
            <img *ngIf="onboardingStep === 2" class="logo-text" src="../../../assets/images/successteam-logo-black.svg"  (click)="onboardingStep=3"
                 alt="">
            <img *ngIf="onboardingStep != 1 && onboardingStep != 2"
                 class="ms-2 left-image"
                 src="../../../assets/images/left.png" alt=""
                 (click)="onboardingStep=9">
            <div *ngIf="onboardingStep != 1 && onboardingStep != 2" class="step-container">
              <div class="progress-container">
                <div
                  [ngStyle]="onboardingStep === 3 || onboardingStep === 6 ? {'width' : '23%'} : onboardingStep >= 8 ? {'width' : '86%'} : {'width' : '62%'}"
                  class="progress" id="progress"></div>
                <div class="circle-step">
                  <div class="circle active"><i class="bi bi-check-lg text-white fs-3"></i></div>
                  <span *ngIf="hasMultipleTeams" style="width: 12rem; text-align: center;">{{'step31_top_text2' | translate}}</span>
                  <span *ngIf="!hasMultipleTeams">{{'step31_top_text1' | translate}}</span>
                </div>
                <div class="circle-step">
                  <div [ngClass]="onboardingStep != 3 && onboardingStep != 6 ? 'active' : ''" class="circle"><i
                    *ngIf="onboardingStep != 3 && onboardingStep != 6" class="bi bi-check-lg text-white fs-3"></i></div>
                  <span *ngIf="hasMultipleTeams">{{('step2_create_teams' | translate)}}</span>
                  <span *ngIf="!hasMultipleTeams" style="width: 12rem; text-align: center;">{{'step31_top_text2' | translate}}</span>
                </div>
                <div class="circle-step">
                  <div [ngClass]="onboardingStep >= 8 ? 'active' : ''" class="circle"><i *ngIf="onboardingStep >= 8"
                                                                                         class="bi bi-check-lg text-white fs-3"></i>
                  </div>
                  <span *ngIf="hasMultipleTeams">{{'team_feedback_button'| translate}}</span>
                  <span *ngIf="!hasMultipleTeams">{{'team_feedback_button'| translate}}</span>
                </div>
              </div>
            </div>
            <img *ngIf="onboardingStep != 1 && onboardingStep != 2"
                 class="me-2 right-image"
                 src="../../../assets/images/right.png" alt="">
          </div>
        </mat-card-header>
        <mat-card-content *ngIf="onboardingStep === 1" class="card-content onboarding-step-1">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <div class="d-flex flex-column justify-content-center align-items-center w-100">
              <h1 class="text-center">{{'step1_title'| translate}}</h1>
              <h5 class="text-center">{{'step1_subtitle' | translate}}</h5>
              <div class="workspace-form-container">
                <form [formGroup]="createWorkspace">
                  <div class="row input-container">
                    <div class="col-md-8">
                      <div>
                      <label class="form-label">{{'step1_workspace_name'| translate}}</label>
                      <input type="text" class="form-control" formControlName="workspaceName" id="workspaceName"
                             placeholder="{{'step1_workspace_name_placeholder'|translate}}">
                      </div>
                      <div style="padding: 2rem 0 1rem">
                        <!-- <label class="form-label">{{'step1_language'| translate}} <span tooltipStyleClass="tooltip-style" pTooltip="{{'step1_language_yellow_question_mark'|translate}}" style="padding-left: .5rem"><i class="bi bi-question-circle-fill me-1 text-warning"></i></span></label> -->
                        <div class="default-language">
                          <label class="form-label">{{'step1_language'|translate}}</label>
                          <div class="w-dl-questionmark-container">
                            <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                            <div class="workspace-dl-tooltip">{{'step1_language_yellow_question_mark'|translate}}</div>
                          </div>
                        </div>
                        <div style="display: flex;border: 1px #E8E8E8 solid; border-radius: 7px; padding: 1rem;">
                          <ngx-flag-picker
                            class="country"
                            style="font-size: 2rem; border-radius: 5px"
                            [showFlags]=true
                            [showLabels]=false
                            [showArrow]=true
                            [selectedCountryCode]="selectedCountryCode"
                            [countryCodes]="countryCodes"
                            (changedCountryCode)="changeLanguage($event)">
                          </ngx-flag-picker>
                          <p style="font-size: 1.6rem; padding: .2rem 1rem; margin: 0">{{languageDisplayed}}</p>
                      </div>
                      </div>
                    </div>
                    <div class="col-md-4 upload-logo">
                      <ngx-dropzone (change)="onImageAdd($event)" [multiple]="false">
                        <ngx-dropzone-label>{{'step1_logo' | translate}}</ngx-dropzone-label>
                        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files"
                                                    [file]="f">
                        </ngx-dropzone-image-preview>
                      </ngx-dropzone>
                    </div>

                  </div>
                  <div class="input-container">
                    <label class="form-label">{{'step1_industry'|translate}}</label>
                    <ngx-autocomplete style="border-radius: 7px; color: black"
                                      [data]="workspaceIndustries"
                                      [searchOnProperty]="searchIndustry"
                                      (selected)="selectedWorkspaceIndustry($event)"
                                      [itemTemplate]="userTemplate"
                                      [notFoundTemplate]="notFoundTemplate">
                    </ngx-autocomplete>
                    <ng-template #userTemplate let-user>
                      <div style="display: flex">
                        <a [innerHTML]="user.name"></a>
                      </div>
                    </ng-template>
                    <ng-template #notFoundTemplate let-notFound>
                      <p>{{'step1_no_industry'|translate}}</p>
                    </ng-template>
                  </div>

                </form>
                <div *ngIf="showWorkspaceSizeSelector" class="input-container">
                  <label class="form-label">{{'step1_people'|translate}}</label>
                  <mat-select class="form-select" placeholder="e.g 1-10" [(ngModel)]="workspaceSize">
                    <mat-option *ngFor="let size of workspaceSizes" [value]="size.uuid">
                      <span *ngIf="size.max !== null">{{size!.min}} - {{size!.max}}</span>
                      <span *ngIf="size.max === null">{{size!.min}}+</span></mat-option>
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="onboardingStep === 2" class="card-content onboarding-step-2">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <div
              class="d-flex flex-column justify-content-center align-items-center w-100">
              <!--          todo-->
              <h5 class="text-center">
                {{'step2_title'|translate}}{{user.detail.displayName | getDisplayName: user.detail.firstname: user.detail.lastname: user.email}}
                !</h5>
              <!--              <h1 class="text-center" (click)="setStep(1)">How would you like to start?</h1>-->
              <h1 class="text-center">{{'step2_subtitle'| translate}}</h1>
              <div class="d-flex flex-row flex-wrap justify-content-center w-100">
                <!--multiple teams-->
                <ng-template #card1>
                  <div class="mt-4 ms-2 me-2 card card-hover teams-card" (click)="teamsSelect(true)">
                    <div class="card-header teams-card-header p-3">
                      <h4 class="text-center">{{'step2_choice1_title'| translate}}</h4>
                      <div class="d-flex flex-row">
                        <img class="woman-curly" src="../../../assets/images/woman_curly.png" alt="woman_curly">
                        <img class="bearded-man" src="../../../assets/images/bearded_man.png" alt="bearded_man">
                        <img class="teenage-girl" src="../../../assets/images/teenage_girl.png" alt="teenage_girl">
                        <img class="senior-man" src="../../../assets/images/senior_man.png" alt="senior_man">
                      </div>
                      <img class="mt-2" src="../../../assets/images/group_143.png" alt="">
                      <img class="polygon-arrow-white" src="../../../assets/images/polygon_white.png" alt="polygon">
                    </div>
                    <div class="d-flex flex-column justify-content-start card-body">
                      <p class="text-white teams-card-text">{{'step2_choice1_description'| translate}}</p>
                    </div>
                  </div>
                </ng-template>

                <!--one team-->
                <ng-template #card2>
                  <div class="mt-4 ms-2 me-2 card card-hover teams-card card-flip" [@cardFlip]="state">
                    <div class="face back" (click)="cardClicked()">
                      <div *ngIf="state === 'default'">
                        <div class="card-header teams-card-header p-3">
                          <h4 class="text-center">{{'step2_choice2_title'| translate}}</h4>
                          <img class="mt-4 ms-3" src="../../../assets/images/group_142.png" alt="">
                          <img class="polygon-arrow-white" src="../../../assets/images/polygon_white.png" alt="polygon">
                        </div>
                        <div class="d-flex flex-column justify-content-start card-body">
                          <p class="text-white teams-card-text">{{'step2_choice2_description'|translate}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="face front">
                      <div *ngIf="state === 'flipped'" class="card-header teams-card-header">
                        <h4 class="text-center" style="font-size: 1.6rem; margin-top: 1.5rem">{{'step2_choice2_how'| translate}}</h4>
                          <div class="single-team-dropdown" [ngClass]="showSingleTeamDropdown ? 'dropdown-br':''">
                            <p-dropdown [(ngModel)]="singleTeamType" [options]="teamTypes" (onShow)="showSingleTeamDropdown = !showSingleTeamDropdown" (onHide)="showSingleTeamDropdown = !showSingleTeamDropdown"
                              dropdownIcon="bi bi-chevron-down" optionLabel="name" placeholder="{{'step2_choice2_how_placeholder'|translate}}" class="team-type-dropdown">
                              <ng-template let-selectedType pTemplate="selectedItem">
                                <div class="sort-item sort-item-value">
                                  <div class="sort-icon-container">
                                    <img class="sort-icon" src="../../../assets/svg/team-{{selectedType.icon}}.svg">
                                  </div>
                                  <span [style]="selectedType.type === 'from a physical workplace' ?
                                  {'font-size': '1.5rem', 'text-overflow': 'ellipsis', 'overflow':'hidden', 'max-width':'13rem'}:{'font-size': '1.5rem'}">{{selectedType.name}}</span>
                                </div>
                              </ng-template>
                              <ng-template let-teamType pTemplate="item">
                                <div class="d-flex align-items-center">
                                  <img class="team-type-icon" src="../../../assets/svg/team-{{teamType.icon}}.svg">
                                  <span style="margin-left: .8rem; font-size: 1.5rem;max-width: 13rem;white-space: initial;">{{teamType.name}}</span>
                                </div>
                              </ng-template>
                            </p-dropdown>
                          </div>
                        <div style="text-align: center">
                          <button class="btn btn-blue px-5 py-2" style="margin-top: 10px;width: auto"
                                  (click)="teamsSelect(false)">
                            {{'step2_choice2_confirm'|translate}}
                          </button>
                        </div>
                      </div>
                      <button class="btn-text" style="border: none" (click)="cardClicked()">
                        {{'step41_back'|translate}}
                      </button>
                    </div>

                  </div>
                </ng-template>

                <ng-container [ngTemplateOutlet]="firstCard" class="card"></ng-container>
                <ng-container [ngTemplateOutlet]="secondCard" class="card"></ng-container>
                <!--demo.json-->
                <div class="mt-4 ms-2 me-2 card card-hover demo-environment-card"
                     (click)="demoTeam()">
                  <div class="card-header demo-environment-header p-3">
                    <h4 class="text-center text-white">{{'step2_choice3_title'|translate}}</h4>
                    <img class="demo-environment-img" src="../../../assets/images/startup.png" alt="demo">
                    <img class="polygon-arrow-blue" src="../../../assets/images/polygon_dark_blue.png" alt="polygon">
                  </div>
                  <div class="d-flex flex-column justify-content-start card-body">
                    <p class="teams-card-text">{{'step2_choice3_description'|translate}}</p>
                  </div>
                </div>
              </div>
              <span class="tip" (click)="setStep(2)"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step2_note'|translate}}</span>
            </div>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="onboardingStep === 3 || onboardingStep === 6" class="card-content onboarding-step-3">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <div
              class="d-flex flex-column justify-content-start invite-to-workspace">
              <h3 class="" *ngIf="onboardingStep === 3 ">{{'start_guide_step1'| translate}}</h3>
              <h3 class="" *ngIf="onboardingStep === 6">{{'invite_people'|translate}}</h3>
              <nav>
                <div class="nav nav-pills mt-3" id="pills-tab" role="tablist">
                  <button class="nav-link active left" id="inviteViaEmail"
                          data-bs-toggle="tab" data-bs-target="#inviteViaEmailContent"
                          type="button" role="tab" aria-controls="inviteViaEmailContent"
                          aria-selected="true"><i class="bi bi-envelope-fill me-2"></i>
                    {{'step31_invite_email'| translate}}
                  </button>
                  <button class="nav-link ms-3 right" id="inviteViaLink"
                          data-bs-toggle="tab" data-bs-target="#inviteViaLinkContent"
                          type="button" role="tab" aria-controls="inviteViaLinkContent"
                          aria-selected="false"><i class="bi bi-link me-2"></i>
                    {{'step31_invite_link'| translate}}
                  </button>
                </div>
              </nav>
              <div class="tab-content card mt-2 invite-to-workspace-tabs" id="pills-tabContent">
                <div class="tab-pane card-body fade show active invite-via-email-content" id="inviteViaEmailContent"
                     role="tabpanel" aria-labelledby="inviteViaEmail">
                  <p>{{'step31_subtitle'| translate}}</p>
                  <div style="display: grid">
                    <!--              <input type="email" class="form-control mb-2 invited-emails" (keyup.Space)= "addEmail()">-->
                    <mat-form-field class="example-chip-list" appearance="fill"
                                    style="background-color: transparent !important;">
                      <mat-chip-list #chipList aria-label="Fruit selection">
                        <mat-chip *ngFor="let email of membersInvited" #chip="matChip">
                          {{email.user.email}}
                        </mat-chip>
                      </mat-chip-list>
                      <mat-chip-list #chipList>
                        <mat-chip *ngFor="let email of inviteEmails; let i = index" #chip="matChip"
                                  (selectionChange)="selectedEmail($event, email)" (removed)="removeEmail(email)"
                                  (click)="chip.selectViaInteraction()">
                          {{email}}
                          <button matChipRemove>
                            <!--                      <mat-icon style="color: #ce0000">cancel</mat-icon>-->
                            <img src="../../../assets/svg/team-delete-button.svg" alt="">
                          </button>
                        </mat-chip>
                        <input [formControl]="teamEmails" placeholder="{{'step31_placeholder'| translate}}" id="inviteEmail"
                               [matChipInputFor]="chipList"
                               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                               [matChipInputAddOnBlur]="addOnBlur"
                               (matChipInputTokenEnd)="addEmail($event)"
                               (focusout)="addEmail($event)">
                      </mat-chip-list>
                    </mat-form-field>
                    <span class="tip mb-4" style="padding: 9px 7.5px;"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step31_infobox'| translate}}</span>
                    <textarea rows="15" class="form-control invited-emails-message" type="text"
                              [(ngModel)]="workspaceInvitationBody.body" [readonly]="!previewMessageButton"></textarea>
                    <button (click)="previewMessageOnEmailInvitation()"
                            class="btn btn-primary preview-message-button">{{(previewMessageButton) ? ('step31_save'| translate) : ('step31_adjust_invitation'|translate) }}
                    </button>
                    <div class="mt-5" style="cursor: pointer; display: flex"
                         [ngClass]="(noPermission && !emailPermissionCheck)? 'error-pulse' : ''">
                      <input class="form-check-input" type="checkbox" value="" id="checkbox" style="margin-top: 5px;"
                             [(ngModel)]="emailPermissionCheck">
                      <p class="form-check-label ms-2 p-top" id="permissionInv"
                         (click)="emailPermissionCheck = !emailPermissionCheck ">
                        {{'step31_permission' | translate}}</p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane card-body fade invite-via-link" id="inviteViaLinkContent" role="tabpanel"
                     aria-labelledby="inviteViaLink">
                  <form [formGroup]="inviteViaLink">
                    <label class="mt-5 mb-1">{{'step31_copy_link_invitation'| translate}}</label>
                    <div class="input-group" style="margin-bottom: 1rem">
            <textarea id="invitationLinkWithMessage" readonly formControlName="invitationLinkWithMessage"
                      class="form-control invitation-link-with-message"
                      [ngModel]="data.inviteMessage + '\n' +data.workspaceInvitation">
            </textarea>
                      <div class="input-group-append">
                        <button class="btn copy-to-clipboard" type="button" (click)="data.copyInvitationLink(false)">
                          {{'step31_button_copy'| translate}}
                        </button>
                      </div>
                    </div>
                    <label class="mb-1">{{'step31_copy_link' | translate}}</label>
                    <div class="input-group mb-2">
                      <div class="input-group-prepend">
                        <i class="bi bi-link-45deg fs-3 input-group-text"></i>
                      </div>
                      <input id="invitationLink" formControlName="invitationLink" type="text"
                             [ngModel]="data.workspaceInvitation"
                             class="form-control invitation-link" readonly>
                      <div class="input-group-append">
                        <button class="btn copy-to-clipboard" type="button" (click)="data.copyInvitationLink(true)">{{'step31_button_copy'| translate}}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!--          <div class="d-flex justify-content-center mt-5">-->
              <!--            <button class="btn btn-gray px-5 py-2" (click)="previousStep()">Back</button>-->
              <!--            <button class="btn btn-primary px-5 py-2" (click)="inviteByEmail()"-->
              <!--                    [disabled]="inviteEmails.length > 0 && !emailPermissionCheck">Next-->
              <!--            </button>-->
              <!--          </div>-->
            </div>
          </div>
        </mat-card-content>

        <!--CREATE TEAMS-->
        <mat-card-content *ngIf="onboardingStep === 4" class="card-content onboarding-step-4">
          <div
            class="card-body p-0 onboarding-window-content">
            <div class="create-new-team-container">
              <h3 style="margin: 0 0 2rem 4rem;">{{'step41_title'| translate}}</h3>
              <!--          <h3 class="" (click)="setStep(6)">Create a new team</h3>-->
              <div class="create-new-team">
                <div class="create-new-team-header">
                  <div class="input-container">
                    <label>{{'step41_subtitle'| translate}}</label>
                    <div style="display: flex">
                      <input id="teamName" type="text" placeholder="{{'step41_placeholder'| translate}}"
                             class="form-control team-name"
                             (keyup.enter)="addTeam()"
                             [formControl]="teamName" autofocus>
                      <!-- <img style="cursor: pointer; padding: 0 20px" (click)="addTeam()" src="./assets/svg/add.svg"
                           alt="add"> -->
                      <button class="add-button" (click)="addTeam()"> <img style="cursor: pointer" src="./assets/svg/add.svg"
                            alt="add"> <p>{{'createteam_create_button' | translate}}</p></button>
                    </div>
                  </div>
                  <div class="tip" [ngStyle]="(workspaceTeams.length === 0) ? {'margin-bottom' : '20px' } : {}">
                    <span><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step41_tip' | translate}}</span>
                  </div>
                  <!-- <span class="tip"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step41_tip'|translate}}</span> -->
                </div>

                <div class="teams-list" *ngIf="workspaceTeams.length > 0">
                  <div class="teams-list-main-container">
                    <div class="teams-list-container-header">
                      <h4>{{'step41_my_teams'|translate}}</h4>
                    </div>
                    <div class="teams-list-table-container">
                      <div class="teams-list-table-header">
                        <p class="m-0 column-one">{{'step41_name'| translate}}</p>
                        <p class="m-0 column-two">{{'step41_type'| translate}}</p>
                        <p class="m-0 column-three">{{'step41_action'|translate}}</p>
                      </div>
                      <div class="teams-list-table-body">
                        <div *ngFor="let team of workspaceTeams;let i = index; let last = last" class="team-table-row" [ngClass]="showTeamTypeDropdown[i] ? 'dropdown-br':''">
                          <div class="d-flex flex-row justify-content-between align-items-center team-row">
                            <h6 style="max-width: 20rem; width: 100%;" class="team-name">{{team.name}}</h6>
                              <p-dropdown
                                *ngIf="!team.created"
                                [(ngModel)]="teamType[i]" [options]="teamTypes" 
                                (onChange)="setTeamType(teamType[i], team)" 
                                (onShow)="showTeamTypeDropdown[i] = !showTeamTypeDropdown[i]" 
                                (onHide)="showTeamTypeDropdown[i] = !showTeamTypeDropdown[i]"
                                [appendTo]="onboardingCard"
                                dropdownIcon="bi bi-chevron-down" optionLabel="name" placeholder="{{'createteam_type_placeholder'|translate}}" class="team-type-dropdown column-two">
                                <ng-template pTemplate="selectedItem">
                                  <div class="sort-item sort-item-value">
                                    <div class="sort-icon-container">
                                      <img class="sort-icon" src="../../../assets/svg/team-{{teamType[i].icon}}.svg">
                                    </div>
                                    <span style="font-size: 1.3rem;overflow: hidden;text-overflow: ellipsis;">{{teamType[i].name}}</span>
                                  </div>
                                </ng-template>
                                <ng-template let-teamType pTemplate="item">
                                  <div class="d-flex align-items-center">
                                    <img class="team-type-icon" src="../../../assets/svg/team-{{teamType.icon}}.svg">
                                    <span style="margin-left: .8rem;font-size: 1.3rem; text-wrap: wrap;">{{teamType.name}}</span>
                                  </div>
                                </ng-template>
                              </p-dropdown>
                              <p class="column-two" *ngIf="team.created">{{teamTypes[i].type | titlecase}}</p>
                              <img *ngIf="!team.created" class="delete-button"
                                    src="../../../assets/svg/team-delete-button.svg"
                                    (click)="deleteTeam(i)" alt="">
                              <img *ngIf="team.created" class="delete-button"
                                    src="../../../assets/svg/check-green.svg" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>

        <!--ORGANIZE TEAM-->
        <mat-card-content *ngIf="onboardingStep === 5 || onboardingStep === 7" class="card-content onboarding-step-5">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <div class="w-100">
              <div class="row">
                <div class="col-md-5" *ngIf="onboardingStep === 5">
                  <mat-card class="team-card no-shadow team-card-small">
                    <mat-card-header style="background-color: #fafbfd; display: block">
                      <mat-card-title>{{'step51_members'| translate}}</mat-card-title>
                      <!--                  <mat-card-subtitle>SEARCH</mat-card-subtitle>-->
                      <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <div>
                          <input class="form-check-input" type="checkbox" [(ngModel)]="checkedAllMembers"
                                 style="margin-right: 5px;" id="allMembers"
                                 (change)="checkAllMembers()"> <label style="margin: 0;">{{'step51_select'| translate}}</label>
                        </div>
                        <div class="search-input-container">
                          <span class="align-items-center p-input-icon-left">
                             <i class="bi bi-search search-icon" *ngIf="!searchingApi"></i>
                            <div class="spinner-border text-primary" role="status"  style="position:absolute;margin: 15px" *ngIf="searchingApi"></div>
                            <input class="search-or-invite-input" type="text" pInputText [(ngModel)]="searchValue"
                                   placeholder="{{'step51_search' | translate}}" (ngModelChange)="this.searching.next($event)" style="font-size: 1.4rem; font-weight: 300; font-family: 'Poppins', sans-serif"
                                   (keyup.enter)="(emptyResult && searchValue !== '') ? inviteEmail() :''">
                           </span>
                           <div *ngIf="emptyResult && searchValue !== ''" class="invite-new-email" (click)="inviteEmail()">
                            <i class="bi bi-send send-icon"></i>
                            <b>{{'organizeteam_invite' | translate}}</b>
                            <span>{{searchValue}}</span>
                           </div>
                        </div>
                      </div>
                    </mat-card-header>
                    <mat-card-content style="background-color: white; overflow: auto" (scroll)="onScroll($event)">
                      <div
                        [ngStyle]="{'height': 'calc('+innerHeight+'px - 460px)'}"
                        cdkDropList
                        id="workspace-members"
                        #membersList="cdkDropList"
                        [cdkDropListData]="members"
                        [cdkDropListConnectedTo]="[teamLeadersList, teamMembersList]"
                        class="example-list"
                        (cdkDropListDropped)="drop($event)">
                        <!--                    <div class="example-box" *ngFor="let item of members" cdkDrag>{{item}}</div>-->
                        <div *ngFor="let item of members; let i = index" cdkDrag class="workspace-member"
                             [ngStyle]="item.teams.length > 0 ? {'background-color':'#EFF4FB'}:{'background-color':'#F9FAFD'}">
                          <input class="form-check-input" type="checkbox" value="{{item.checked}}"
                                 [(ngModel)]="item.checked">
                          <img class="user-picture avatar" style="width: 30px"
                               *ngIf="user && (user.avatar || user.detail.displayName)"
                               src="{{user.avatar ? data.baseUrl + '/uploads/user/'+ user.uuid + '/' + user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                               data-holder-rendered="true" alt="">
                          <!--                      <span *ngFor="let team of item.teams" class="member-team-circle p-2 bd-highlight"-->
                          <!--                            [matTooltip]="team.name">-->
                          <!--                          {{team.name.toString() | uppercase | slice :0:1}}</span>-->
                          <p
                            class="bd-highlight workspace-member-name">{{item.user.detail.displayName | getDisplayName: item.user.detail.firstname: item.user.detail.lastname: item.user.email}}
                            <span
                              [ngClass]="(item.teams.length > 0) ? 'assign-member' : 'unassign-member'"
                              class="ms-auto bd-highlight">{{(item.teams.length > 0) ? ('step51_member_status2'| translate) : ('step51_member_status1'| translate)}}</span>
                        </div>
                      </div>
                      <div style="margin: auto; width: max-content;">
                        <div class="spinner-border text-primary" role="status"  style="position: absolute" *ngIf="loadMembers"></div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="" *ngIf="onboardingStep === 7" [ngClass]="onboardingStep === 7 ? 'col-md-3' : ''"></div>
                <div class="" [ngClass]="onboardingStep === 7 ? 'col-md-6' : 'col-md-7'">
                  <mat-card class="team-card no-shadow">
                    <!--IF SINGLE TEAM-->
                    <mat-card-header *ngIf="onboardingStep === 7">
                      <!--                      <mat-card-title>{{user.detail.displayName | getDisplayName: user.detail.firstname: user.detail.lastname: user.email}}</mat-card-title>-->
                      <mat-card-title style="font-size: 2.4rem">{{'does_this_title'|translate}}</mat-card-title>
                      <mat-card-subtitle>{{'does_this_subtitle'|translate}}</mat-card-subtitle>
                    </mat-card-header>

                    <!--IF MULTIPLE TEAMS-->
                    <mat-card-header *ngIf="onboardingStep === 5" style="display: inline">
                      <mat-card-title class="step-5-card-title">
                        {{(workspaceTeams.length > 1) ? 'Teams' : 'Team ' + selectedTeam?.name}}
                        <span *ngIf="teamMembers.length > 0 && teamMembers.length < 4" class="tip members-warning"
                              style="margin: 0"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step51_note'|translate}}</span>
                      </mat-card-title>
                    </mat-card-header>

                    <mat-card-content style="background-color: white">
                      <div class="organize-teams-carousel">
                        <p-carousel [value]="workspaceTeams" [numVisible]="3" *ngIf="workspaceTeams.length > 1"
                                    [numScroll]="3" [circular]="true">
                          <ng-template let-team pTemplate="item" let-i="index">
                            <div class="product-item" [ngStyle]="team === selectedTeam ? {'margin':'8px 0'}:{}"
                                 (click)="openTeamTab(team)">
                              <!--check badge-->
                              <span class="p-badge-info badge-check" *ngIf="team.members >= 4  && team.leaders > 0">
                                    <img src="../../../assets/svg/check.svg" alt=""></span>
                              <!--triangle badge-->
                              <span class="p-badge-info badge-warning leader-pulse"
                                    *ngIf="(team.members > 0 && team.members < 4) || (team.members > 0 && team.leaders === 0)">
                                    <img src="../../../assets/svg/triangle-alert.svg" alt=""
                                         tooltipStyleClass="tooltip-style"
                                         pTooltip="{{'step41_tip'| translate}}"></span>

                              <div [ngClass]="team === selectedTeam ? 'teams-buttons-clicked' : 'teams-buttons' "
                                   style="text-align: center">
                                <div class="teams-buttons-text" style="justify-content: space-between">
                                    <span
                                      style="background-color: #ffffff;color: black; border-radius: 50px; margin: 2px 0; padding: 4px 12px;">{{team.name | uppercase | slice :0:1}}</span>
                                  <p class="align-items-center justify-content-center"
                                     style="padding-left: 1rem">{{team.name}}</p>
                                  <img *ngIf="team?.questionCollections?.length > 0"
                                       pTooltip="{{team.questionCollections[team.questionCollections.length-1].title}}"
                                       tooltipPosition="bottom"
                                       tooltipStyleClass="tooltip-style" class="team-type-icon"
                                       src="../../../assets/svg/team-{{team.icon | lowercase}}.svg">
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-carousel>
                      </div>

                      <mat-card class="team-card"
                                [ngClass]="teamLeaders.length === 0 && onboardingStep === 5 ? 'leader-pulse' : 'no-shadow'">
                        <mat-card-header class="leader-header">
                          <mat-card-title><img style="padding-right: 10px" src="../../../assets/svg/crown.svg"
                                               alt="woman_curly">{{'does_this_leaders' |  translate}}
                          </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="leader-card">
                          <div
                            cdkDropList
                            id="leaders"
                            #teamLeadersList="cdkDropList"
                            [cdkDropListData]="teamLeaders"
                            [cdkDropListConnectedTo]="[teamMembersList]"
                            class="example-list"
                            (cdkDropListDropped)="drop($event)">

                            <div *ngFor="let item of teamLeaders; let i = index" cdkDrag
                                 class="team-leader justify-content-between">
                              <div class="d-flex align-items-center">
                                <img class="user-picture" alt=""
                                     *ngIf="item.user && (item.user.avatar || item.user.detail.displayName)"
                                     src="{{item.user.avatar ? data.baseUrl + '/uploads/user/'+ item.user.uuid + '/' + item.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                                     data-holder-rendered="true">
                                <p
                                  class="bd-highlight">&nbsp; {{item.user.detail.displayName | getDisplayName: item.user.detail.firstname: item.user.detail.lastname: item.user.email}}</p>

                              </div>
                              <img *ngIf="onboardingStep === 5" class="delete-button"
                                   src="../../../assets/svg/team-delete-button.svg"
                                   style="cursor: pointer; padding: 0 10px"
                                   (click)="deleteTeamLeader(i)" alt="">
                            </div>
                            <div *ngIf="onboardingStep != 7" class="team-leader-add">
                              {{'step51_leaders_text'| translate}}
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>

                      <mat-card class="team-card"
                                [ngClass]="teamMembers.length === 0 &&  onboardingStep === 5 ? 'member-pulse' : 'no-shadow'">
                        <mat-card-header class="member-header">
                          <mat-card-title><img style="padding-right: 10px" src="../../../assets/svg/user.svg"
                                               alt="woman_curly">
                            {{'step51_team_members'| translate}}
                          </mat-card-title>
                        </mat-card-header>
                        <mat-card-content class="member-card">
                          <div
                            cdkDropList
                            id="members"
                            #teamMembersList="cdkDropList"
                            [cdkDropListData]="teamMembers"
                            [cdkDropListConnectedTo]="[teamLeadersList]"
                            class="example-list"
                            (cdkDropListDropped)="drop($event)">

                            <div *ngFor="let item of teamMembers; let i = index" cdkDrag
                                 class="team-member justify-content-between">
                              <div class="d-flex align-items-center">
                                <img class="user-picture" alt=""
                                     *ngIf="item.user && (item.user.avatar || item.user.detail.displayName)"
                                     src="{{item.user.avatar ? data.baseUrl + '/uploads/user/'+ item.user.uuid + '/' + item.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                                     data-holder-rendered="true">
                                <p
                                  class="bd-highlight">&nbsp; {{item.user.detail.displayName | getDisplayName: item.user.detail.firstname: item.user.detail.lastname: item.user.email}}</p>
                              </div>
                              <img *ngIf="onboardingStep===5" class="delete-button"
                                   src="../../../assets/svg/team-delete-button.svg"
                                   style="cursor: pointer; padding: 0 10px"
                                   (click)="deleteTeamMember(i)" alt="">
                            </div>

                            <!--                      <div class="example-box" *ngFor="let item of teamMembers" cdkDrag>{{item}}</div>-->
                            <div class="team-member-add" *ngIf="teamMembers.length === 0">
                              {{'step51_team_members_text'| translate}}
                            </div>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
              <!--      </ng-container>-->
            </div>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="onboardingStep === 8" class="card-content">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <div class="d-flex flex-column align-items-center pt-4">
              <h1
                class="mt-5">{{workspaceMembers.length > 1 ? ('step61_title'|translate) : ('step61_title1'|translate)}}</h1>
              <span class="tip" style="margin: 2rem" *ngIf="workspaceMembers.length <= 1">
                <i class="bi bi-question-circle-fill me-1 text-warning"></i>
                {{'step8_subtitle'| translate}}
              </span>
              <div class="card demo-video-card">
                <div class="card-body">
                  <div class="embed-responsive embed-responsive-21by9">
                    <iframe class="card-video"
                            src="https://dev.api.successteam.dk/videos/This%20is%20how%20it%20works!.mp4"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>

        <mat-card-content *ngIf="onboardingStep === 9" class="card-content">
          <div
            class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
            <!--Feedback collector setup-->
            <div style="width: 80%;" class="d-flex flex-column justify-content-center align-items-center pt-4">
              <h1 class="mb-5">{{'step71_title'| translate}}</h1>
              <div *ngIf="teamsValid.length > 1" class="feedback-process-in-teams">
                <h5>
                  {{'step71_subtitle' | translate}}: {{getValidTeams()}}</h5>
                <div class="w-dl-questionmark-container">
                  <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                  <div class="workspace-dl-tooltip fl-tooltip" [innerHTML]="('step71_skip_feedback_tip'|translate)"></div>
                </div>
              </div>
<!--              <div class="d-flex justify-content-center align-items-center flex" style="flex-wrap: wrap;">-->
<!--                <div class="card feedback-loop-card">-->
<!--                  <div class="card-body text-center">-->
<!--                    <p class="fl-card-description">{{'step71_step1_title'|translate}}</p>-->
<!--                    <div *ngFor="let frequency of frequencyKeys; index as i" [ngClass]="i === 0 ? 'mt-4' : 'mt-2'"-->
<!--                         class="d-flex flex-row align-items-center p-4 feedback-frequency">-->
<!--                      &lt;!&ndash;                <input class="form-check-input feedback-check" type="radio">&ndash;&gt;-->
<!--                      <p-radioButton [value]="frequency.title" [(ngModel)]="frequencySelected"-->
<!--                                     (onClick)="changeSlides()"></p-radioButton>-->
<!--                      <span class="ms-4 text-white" style="font-size: 1.8rem">{{frequency.title.replace('_', ' ')}}-->
<!--                        <img *ngIf="frequency.title !== 'weekly' && needPayment" src="../../../assets/images/crown.png"-->
<!--                             style="width: 24px"-->
<!--                             [matTooltip]="'step71_step1_paid'|translate" alt="">-->
<!--                  </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card ms-3 me-3 feedback-loop-card">-->
<!--                  <div class="card-body text-center">-->
<!--                    <p class=" fl-card-description">{{'Start asking for feedback right away or schedule a date using the calendar.'|translate}}</p>-->
<!--                    <div class="feedback-option-margin d-flex flex-row align-items-center p-4 feedback-frequency">-->
<!--                      <p-radioButton [value]="'TODAY'" [(ngModel)]="startCollectorDate"></p-radioButton>-->
<!--                      <span class="ms-4 text-white" style="font-size: 1.8rem">{{'step71_step3_option1' | translate}}</span>-->
<!--                    </div>-->

<!--                    <div class="mt-2 d-flex flex-row align-items-center p-4 feedback-frequency">-->
<!--                      <p-radioButton [value]="'PIKED'" [(ngModel)]="startCollectorDate"></p-radioButton>-->
<!--                      <span class="ms-4 text-white" style="font-size: 1.8rem">{{'step71_step3_option2'| translate}}</span>-->
<!--                      <p-calendar [(ngModel)]="pikedDate" [minDate]="minDateValue" dateFormat="dd.mm.yy"-->
<!--                                  style="margin-left: 18px" (click)="pickDate()"></p-calendar>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card feedback-loop-card">-->
<!--                  <div class="card-body text-center">-->
<!--                    <p class="mt-5 fl-card-description">{{'How many feedback statements should your team answer every time?'|translate}}</p>-->

<!--                    <div id="carouselExampleControls" data-bs-interval="false" class="carousel slide carousel-statement"-->
<!--                         data-bs-ride="carousel">-->
<!--                      <div class="carousel-inner">-->
<!--                        <div class="carousel-inner">-->
<!--                          <div *ngFor="let item of pollingFrequency[frequencySelected]; let i = index"-->
<!--                               class="carousel-item"-->
<!--                               [ngClass]="i===0?'active':''">-->
<!--                            <div class="p-3 carousel-item-statement">-->
<!--                              <div-->
<!--                                class="d-flex flex-column justify-content-center align-items-center pt-2 pb-2 statement-container">-->
<!--                                <span class="fs-5" style="font-size: 1.8rem">{{item.value}} questions</span>-->
<!--                                <p class="fs-6 m-0">{{item.completionTime}}</p>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <button class="carousel-control-prev" (click)="statementSlideOnb(false)" type="button"-->
<!--                              data-bs-target="#carouselExampleControls" data-bs-slide="prev">-->
<!--                <span class="sr-only">-->
<!--                  <svg xmlns="http://www.w3.org/2000/svg"-->
<!--                       aria-hidden="true" role="img" class="iconify iconify&#45;&#45;mdi" width="32"-->
<!--                       height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path-->
<!--                    fill="#2551bf"-->
<!--                    d="M22 12a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-6.6 4.6L10.8 12l4.6-4.6L14 6l-6 6l6 6l1.4-1.4Z"></path></svg>-->
<!--                </span>-->
<!--                      </button>-->
<!--                      <button class="carousel-control-next" (click)="statementSlideOnb(true)" type="button"-->
<!--                              data-bs-target="#carouselExampleControls" data-bs-slide="next">-->
<!--                <span class="sr-only">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg"-->
<!--                         aria-hidden="true" role="img" class="iconify iconify&#45;&#45;mdi" width="32"-->
<!--                         height="32" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path-->
<!--                      fill="#2551bf"-->
<!--                      d="M22 12a10 10 0 0 1-10 10A10 10 0 0 1 2 12A10 10 0 0 1 12 2a10 10 0 0 1 10 10m-12 6l6-6l-6-6l-1.4 1.4l4.6 4.6l-4.6 4.6L10 18Z"></path></svg>-->
<!--                  </span>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              <!--Feedback collector setup-->
              <div class="d-flex flex-column justify-content-center align-items-center pt-4 pb-4" style="background-color: #f5f7fc; width: 100%" *ngIf="gotFrequency">
                <div class="d-flex justify-content-center align-items-center flex" style="width: 100%;">
                  <div class="card ms-3 me-3 feedback-loop-card">
                    <div class="card-body text-center">
                      <p class="fl-card-description" style="margin-bottom: 40px;max-width: 20rem;">{{'step71_step1_title'| translate}}</p>
                      <div class="frequency-container">
                        <div *ngFor="let frequency of frequencyKeys; index as i; let first = first; let last = last;"
                             [style]="first ? {'margin-right':'1.6rem'} : last ? {'margin-left':'1.6rem'} : {}"
                             [ngClass]="selectedFrequency === frequency.title ? 'feedback-frequency-active' : ''"
                             class="feedback-frequency">
                          <p-radioButton [value]="frequency.title" [(ngModel)]="frequencySelected"
                                         (onClick)="changeSlides()"></p-radioButton>
                          <span class="mt-3" style="font-size: 1.8rem; font-weight: 500;">{{('step71_step1_'+frequency.title) | translate}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card ms-3 me-3 feedback-loop-card">
                    <div class="card-body text-center">
                      <p class="fl-card-description" style="max-width: 32rem;">{{'step71_step2_title'| translate}}</p>
                      <div class="statements-number-slider">
                        <ngx-slider  [(value)]="sliderValue" [options]="options" (valueChange)="getSuggestions()"></ngx-slider>
                        <div class="d-flex flex-column">
                          <h2 style="margin-top: -2rem;">{{sliderValue}} {{'statements'|translate}}</h2>
                          <span *ngIf="sliderValue === recomandedStatements" style="font-size: 1.4rem;">({{'recommended'|translate}})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card ms-3 me-3 feedback-loop-card" *ngIf="!data.editCollector">
                    <div class="card-body text-center">
                      <app-feedback-questions-demo *ngIf="showFeedbackDemo"></app-feedback-questions-demo>
                      <p style="max-width: 35.6rem;"
                         class=" fl-card-description">{{data.pauseCollector ? 'Restart asking your team for feedback right away or pick a date from the calendar.' : ('step71_step3_title' | translate)}}</p>
                      <div [ngClass]="startCollectorDate === 'TODAY' ? 'feedback-frequency-date-active':''" class="feedback-option-margin d-flex flex-row align-items-center p-4 feedback-frequency-date">
                        <p-radioButton [value]="'TODAY'" [(ngModel)]="startCollectorDate"></p-radioButton>
                        <span class="ms-4"
                              style="font-size: 1.8rem">{{data.pauseCollector ? 'Restart now' : ('step71_step3_option1' | translate)}}</span>
                      </div>

                      <div [ngClass]="startCollectorDate === 'PIKED' ? 'feedback-frequency-date-active':''" class="mt-3 d-flex flex-row align-items-center p-4 feedback-frequency-date">
                        <p-radioButton [value]="'PIKED'" [(ngModel)]="startCollectorDate"></p-radioButton>
                        <span class="ms-4"
                              style="margin-right: 20px; font-size: 1.8rem">{{data.pauseCollector ? ('restart_at' | translate) : ('pick_date' | translate)}}</span>
                        <p-calendar class="calendar-collector" [(ngModel)]="pikedDate" [minDate]="minDateValue" dateFormat="dd.mm.yy"
                                    (click)="pickDate()"></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex" style="width: 100%;margin-top: 4rem;">
                  <div class="card ms-3 me-3 team-insights-container">
                    <div class="card-body text-center">
                      <p class=" fl-card-description">{{'get_report'|translate}}</p>
                      <div class="team-insights-frequency-container">
                        <div *ngFor="let frequency of frequencyKeys; index as i; let first = first; let last = last;"
                             [style]="first ? {'margin-right':'1.6rem'} : last ? {'margin-left':'1.6rem'} : {}"
                             [ngClass]="checkDisable(frequency.title) ? 'ts-feedback-frequency-active' : ''"
                             class="ts-feedback-frequency">
                          <p-radioButton [value]="frequency.title" [(ngModel)]="tsFrequencySelected" [disabled]="checkDisable(frequency.title)"
                                         (onClick)="changeTsFrequency()"></p-radioButton>
                          <span class="ms-3" style="font-size: 1.8rem; font-weight: 500;">{{('step71_step1_'+frequency.title) | translate}}
              </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>


        <mat-card-footer class="card-footer">

          <!--Footer for invite members-->
          <div *ngIf="onboardingStep === 1" class="d-flex footer-buttons footer-step-1">
            <button class="btn primary-btn" [disabled]="!createWorkspace.valid" (click)="updateWorkspace()">
              {{'next_button'| translate}}
            </button>
          </div>
          <!--Footer for invite members-->
          <div *ngIf="onboardingStep === 2" class="d-flex footer-buttons">
            <!--        <button class="btn btn-gray" (click)="previousStep()">Back</button>-->
            <button class="btn btn-hallow" (click)="finishOnboarding()">{{'step2_skip' | translate}}</button>
          </div>

          <div *ngIf="onboardingStep === 3 || onboardingStep === 6" class="d-flex footer-buttons float-buttons">
            <!--        <button class="btn btn-gray" (click)="previousStep()">Back</button>-->
            <button class="btn primary-btn" (click)="inviteByEmail()">{{'next_button'| translate}}
            </button>
          </div>

          <div *ngIf="onboardingStep === 4" class="d-flex footer-buttons">
            <button class="btn btn-gray" (click)="previousStep()">{{'step41_back'| translate}}</button>
            <div class="d-flex">
              <button class="btn btn-hallow" (click)="finishOnboarding()">{{'step41_later'| translate}}</button>
              <button class="btn primary-btn" [disabled]="workspaceTeams.length === 0"
                      (click)="createTeams()">
                {{'next_button'| translate}}
              </button>
            </div>
          </div>

          <div *ngIf="onboardingStep === 5" class="d-flex onboarding-step-5 footer-buttons">
            <button class="btn btn-gray" (click)="previousStep()">{{'step41_back'| translate}}</button>
            <button class="btn btn-text" [ngStyle]="nextTeamActive ? {'margin-right':'-22px'}:{}" (click)="finishOnboarding()">{{'does_this_button2'| translate}}</button>
            <div style="display: inherit">
              <button class="btn btn-text" *ngIf="workspaceTeams.indexOf(selectedTeam)>0"
                (click)="selectTeam(false)">{{'organizeteam_previous'| translate}}
              </button>
              <button class="btn btn-hallow"
                      *ngIf="workspaceTeams.indexOf(selectedTeam) !== workspaceTeams.length-1 "
                      (click)="addMembers(false)">
                {{'next_team_button' | translate}}
              </button>
              <!--        <button class="btn primary-btn px-5 py-2" (click)="addMembers(true)">Done</button>-->
              <button class="btn primary-btn" (click)="doneMembers()">{{'done_button'| translate}}</button>
            </div>
          </div>


          <div *ngIf="onboardingStep === 7" class="d-flex footer-buttons">
            <!--           style="padding:25px 80px">-->
            <button class="btn btn-gray px-5 py-2" (click)="previousStep()">{{'does_this_button1'| translate}}</button>
            <button class="btn btn-text px-5 py-2" (click)="finishOnboarding()">{{'does_this_button2'| translate}}</button>
            <div [matTooltip]="'Team must have at least 1 leader'" [matTooltipDisabled]="teamLeaders.length !== 0">
              <button class="btn btn-blue px-5 py-2" [disabled]="teamLeaders.length === 0"
                      (click)="confirmSingleTeam()">{{'does_this_button3'| translate}}
              </button>
            </div>
          </div>

          <div *ngIf="onboardingStep === 8" class="d-flex footer-buttons">
            <!--        style="padding:25px 80px">-->
            <button class="btn btn-text px-5 py-2" (click)="finishOnboarding()">{{'step61_button1'| translate}}</button>
            <div style="display: flex">
              <button class="btn btn-hallow" (click)="openFeedbackQuestionsModal()">
                {{'step61_button2'| translate}}
                <img class="btn-logo" src="../../../assets/images/successteam-icon.svg" alt="">
              </button>
              <button class="btn primary-btn"
                  [ngStyle]="(workspaceMembers.length > 1) ? {'padding':'0 3rem'}:{}"
                      (click)="(workspaceMembers.length > 1)  ?setStep(9): finishOnboarding()">{{(workspaceMembers.length > 1) ? ('step61_button3'|translate) : ('got_it_button'| translate)}}
                <i class="bi bi-arrow-right"></i></button>
            </div>
          </div>

          <div *ngIf="onboardingStep === 9" class="d-flex footer-buttons">
            <button class="btn btn-gray px-5 py-2" (click)="previousStep()">{{'step41_back' | translate}}</button>
            <button class="btn btn-gray px-5 py-2" (click)="finishOnboarding()">{{'step41_later'| translate}}</button>
            <!--        <button class="btn btn-blue px-5 py-2" (click)="onboardingDone = !onboardingDone">Ready</button>-->
            <button class="btn btn-blue px-5 py-2" (click)="doneSettingLoop()" style="width: 180px">{{'ready_button'| translate}}</button>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>

    <app-feedback-loop-congrats *ngIf="onboardingDone"></app-feedback-loop-congrats>
    <!--Paid Feature Modal -->
    <app-paid-feature (closePaidFeatureModal)="paidFeatureModalClose($event)"
                      *ngIf="paidFeatureModalOpen"></app-paid-feature>
    <!--Paid Feature Modal -->

    <!-- Feedback Questions Modal -->
    <app-feedback-questions-demo (closeFeedbackModal)="feedbackQuestionModalClose($event)"
                                 *ngIf="tryFeedbackQuestions"></app-feedback-questions-demo>
    <!-- Feedback Questions Modal -->

    <!-- Button trigger modal -->
    <button id="modalTeamSetupButton" type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#modalTeamSetup" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modalTeamSetup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-header" style="display: block; border: none">
            <img src="assets/svg/top-modal.svg" style="float: right; width: 70px;" alt="">
            <button id="closeTeamSetupModal" type="button" class="close" data-dismiss="modal" aria-label="Close" hidden>
            </button>
          </div>
          <div class="modal-body" style="text-align: center">
            <h3 class="modal-title" id="exampleModalLabel">{{'teams_not_ready'| translate}}</h3>
            <br>
            <h4 style="margin: 30px 0">{{'teams_ready'|translate}}</h4>
            <h5 *ngFor="let team of teamsValid">{{team.name}}</h5>
          </div>
          <div class="modal-footer justify-content-between" style="border: none">
            <button type="button" class="btn btn-gray" data-dismiss="modal" style="float: left">{{'close_button'|translate}}</button>
            <button type="button" class="btn btn-blue" style="width: 200px; float: right" (click)="acceptTeams()">{{'step31_button'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Button trigger modal -->
    <button id="modalSingleTeamSetupButton" type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#modalSingleTeamSetup" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modalSingleTeamSetup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-header" style="display: block; border: none">
            <img src="assets/svg/top-modal.svg" style="float: right; width: 70px;" alt="">
            <button id="closeTeamSetupModal" type="button" class="close" data-dismiss="modal" aria-label="Close" hidden>
            </button>
          </div>
          <div class="modal-body" style="text-align: center; margin: 80px 0">
            <h5>{{'not_enough_title'| translate}}</h5>
            <br>
            <h4 style="font-size: 2.2rem; padding: 1rem 2rem">{{'not_enough_subtitle' | translate}}</h4>
          </div>
          <div class="modal-footer justify-content-between" style="border: none; padding: 2rem 8svw;">
            <button type="button" class="btn btn-gray" data-dismiss="modal" style="float: left" (click)="goToInvite()">
              {{'not_enough_button1'|translate}}
            </button>
            <button type="button" class="btn btn-blue" data-dismiss="modal" style="width: 200px; float: right"
                    (click)="finishSingleTeam()">
              {{'not_enough__button2'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Button valid teams -->
    <button id="modalMultipleTeamSetupButton" type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#modalMultipleTeamSetup" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modalMultipleTeamSetup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-header" style="display: block; border: none">
            <img src="assets/svg/close-button.svg" style="float: right" alt="" data-dismiss="modal" aria-label="Close">
            <button id="closeMultipleTeamSetupButton" type="button" class="close" data-dismiss="modal" aria-label="Close" hidden>
            </button>
          </div>
          <div class="modal-body" style="text-align: center; margin: 80px 0">
            <i style="color: #FFC828; font-size: 4rem" class="bi bi-exclamation-triangle"></i>
            <h1 id="exampleModalLabel">{{'step51_modal_title'|translate}}</h1>
            <div style="font-size: 1.8rem; margin-top: 2rem">
              <p *ngIf="teamsValid.length === 0">{{'step51_modal_subtitle'|translate}}</p>
              <p *ngIf="teamsValid.length > 0">{{'step51_modal_subtitle2'|translate}}</p>
            </div>
          </div>
          <div class="modal-footer justify-content-between" style="border: none">
            <button id="closeModalMultipleTeamSetup" type="button" class="btn btn-gray" data-dismiss="modal" style="float: left">{{'step51_modal_button1'|translate}}</button>
            <button type="button" class="btn btn-blue" style="width: 200px; float: right"
                    (click)="doneAssigningMembers()">{{'step51_modal_button2'|translate}}
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- Button valid teams -->
    <button id="modalEditInvite" type="button" class="btn btn-primary" data-toggle="modal"
            data-target="#modalInvitationSetup" hidden>
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modalInvitationSetup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="border-radius: 25px">
          <div class="modal-header" style="display: flex; border: none; margin-left: 1rem">
            <h2 id="exampleModalLabel">{{'step31_adjust_invitation'| translate}}</h2>
            <img src="assets/svg/close-button.svg" style="float: right" alt="" data-dismiss="modal" aria-label="Close">
            <button id="closeInviteModal" type="button" class="close" data-dismiss="modal" aria-label="Close" hidden>
            </button>
          </div>
          <div class="modal-body" style="text-align: center; margin: 80px 0">
            <div>
              <textarea rows="15" class="form-control invited-emails-message" type="text"
                        [(ngModel)]="workspace.activeInvitationBody.body"></textarea>
            </div>
          </div>
          <div class="modal-footer justify-content-between" style="border: none">
            <button type="button" class="btn btn-gray" data-dismiss="modal" style="float: left">{{'close_button'|translate}}</button>
            <button type="button" class="btn btn-blue" style="width: 200px; float: right"
                    (click)="previewMessageOnEmailInvitation()">{{'step31_save'| translate}}
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="!onboardingCreator">
    <div class="d-flex flex-column align-items-center email-confirmation-container">
      <div class="d-flex flex-row align-items-center succesteam" style="cursor: pointer" [routerLink]="'/login'">
        <img class="logo" src="../../../assets/images/logo.png" alt="succesteam_logo">
        <h4 class="text-white fw-bold" style="font-size: 2.6rem">successteam</h4>
      </div>
      <img class="email-sent-logo" src="../../../assets/images/onboarding_member_woarning.svg" alt="email_sent">
      <h2 class="mt-3 text-white fw-bold" style="font-size: 4.5rem">{{'member_wo_not_done'| translate}}</h2>
      <span class="mt-1 mb-auto fw-normal text-white email-confirmation-message"
            style="font-size: 1.8rem;margin-top: 1.5rem">{{'member_wo_not_done_message1'|translate}} {{user.detail.displayName | getDisplayName: user.detail.firstname: user.detail.lastname: user.email}}
        {{'member_wo_not_done_message2'| translate}}</span>
    </div>
  </div>

</div>
<div *ngIf="data.showDemo" class="demo-container">
  <app-teams></app-teams>
  <button class="primary-btn demo-button" (click)="demoTeam()"><i class="bi bi-arrow-left ms-2"></i>{{'member_wo_resume_button'|translate}}
  </button>
</div>
