import {Injectable} from '@angular/core';
import {DataManipulationService} from './data-manipulation.service';
import {ApiwrapperService} from './apiwrapper.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChallengesService {
  myAssignActions = [];
  myProblemsTasks = [];
  myInProgressTasks = [];
  myCompletedTasks = [];
  myArchivedTasks = [];
  //@ts-ignore
  teamUuid;

  allChallenges = [];
  newChallenges = [];
  oldChallenges = [];
  displayChallenges = [];
  gotNewChallengesNumber = 0;
  displayNew = false;
  team: any;

  newActionIndicator = '';
  methods = [];
  newActionIndicatorUuid: any;

  createActionForm = new FormGroup({
    actionTitle: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    actionDescription: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });
  newStatementsActions: any = {};

  newActionColorUuid: any;
  newActionStatementUuid: any;
  newActionCreated = [];
  private newActionSubscription = new BehaviorSubject(false);
  newActionSub = this.newActionSubscription.asObservable();
  private newAssignmentSubscription = new BehaviorSubject(false);
  newAssignmentSub = this.newAssignmentSubscription.asObservable();

  private actionAssignmentSubscription = new BehaviorSubject(false);
  actionAssignmentSub = this.actionAssignmentSubscription.asObservable();
  createAction = true;
  teamAdmins = [];
  showCreateTeamSpinner = false;
  assignationAction: any;
  secondStep = false;
  addMe = false;
  assignMe = false;
  addOnlyMe = false;
  adminMe: any;

  step1 = false;
  step2 = false;
  actionPage = false;
  actionMemberAdded: any;
  actionTaskAdded: any;
  sort: string = 'all';
  private teamTasksType: any;
  showActions: boolean =false;
  private teamTasks: any[] =[];
  private teamTasksPages: number = 0;

  constructor(public api: ApiwrapperService, public data: DataManipulationService) {
  }

  getTeamTasks(teamUuid: any, type: any, page: any, indicator: string) {
    this.teamUuid = teamUuid;
    this.data.showLoading();
    this.teamTasksType = type;
    this.showActions = false;
    if (page === 1) {
      this.teamTasks = [];
      this.teamTasksPages = 1;
    }
    this.api.getTeamTasks(teamUuid, type, page,  indicator).subscribe(
      // tslint:disable-next-line:no-shadowed-variable
      res => {
        res._embedded['my-tasks'].forEach((el: { checked: boolean; status: string; }) => {
          el.checked = el.status === 'done';
          // el.taskId = el.uuid +
        });
        this.teamTasks = this.teamTasks.concat(res._embedded['my-tasks']);
        // @ts-ignore
        this.getMyActions(this.teamTasks, this.teamUuid);
        if (res._page_count > 1 && this.teamTasksPages < res._page_count) {
          this.teamTasksPages++;
          this.getTeamTasks(teamUuid, type, this.teamTasksPages, indicator);
        }
        this.data.hideLoading();
        this.showActions = true;
      },
      err => {

      }
    );
  }


  //@ts-ignore
  getMyActions(teamTasks, teamUuid) {
    this.teamUuid = teamUuid;
    if (!this.data.showDemo){
      this.getArchivedTasks(teamUuid);
    }
    const actions = [];
    this.myProblemsTasks = [];
    this.myCompletedTasks = [];
    this.myInProgressTasks = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < teamTasks.length; i++) {
      if (teamTasks[i].indicator === null){
        teamTasks[i].indicator = {title: 'all'}
      }

      if (teamTasks[i].status === 'to_do') {
        actions.push(teamTasks[i]);

        // @ts-ignore
        this.myProblemsTasks.push({teamTask: teamTasks[i]});
      } else if (teamTasks[i].status === 'done') {
        // @ts-ignore
        this.myCompletedTasks.push({teamTask: teamTasks[i]});
      } else if (teamTasks[i].status === 'in_progress') {
        actions.push(teamTasks[i]);
        // @ts-ignore
        this.myInProgressTasks.push({teamTask: teamTasks[i]});
      }
    }
    // return actions;
    // @ts-ignore
    this.myAssignActions = actions;
    // this.data.myProblemsTasks = actions;
  }

  //@ts-ignore
  patchAction(task, status, teamPage) {
    // if status 'in_progress' -> remove from myActions and add to inProgressTasks
    // if status 'done' -> remove from inProgressTasks and add to doneTasks

    this.data.showLoading();
    this.api.patchTask(task.uuid, status, false, this.teamUuid, '').subscribe(
      res => {
        if (task.uuid === this.data.showMyActionsClicked) {
          this.data.showMyActionsClicked = '';
        }
        if (status === 'in_progress') {
          this.inProgressTask(task);
          // this.data.rateAction(task);
          this.data.showAlert('Great. Your task is set to in progress.');
          if (teamPage) {
            this.myAssignActions.forEach((el, index) => {
              // @ts-ignore
              if (el.uuid === task.uuid) {
                // @ts-ignore
                this.myAssignActions[index].status = 'in_progress';
              }
            });
          }
        } else if (status === 'to_do') {
          this.data.showAlert('Action was reactivated');
          if (!teamPage) {
            this.myArchivedTasks.forEach((el, index) => {
              // @ts-ignore
              if (el.teamTask.uuid === task.uuid) {
                this.myProblemsTasks.push(el);
                this.myArchivedTasks.splice(index, 1);
              }
            });
          }
        } else if (status === 'archived') {
          this.data.showAlert('Task archived successfully.');
          if (teamPage) {
            this.myAssignActions.forEach((el, index) => {
              // @ts-ignore
              if (el.uuid === task.uuid) {
                // @ts-ignore
                this.myArchivedTasks.push({teamTask: el});
                this.myAssignActions.splice(index, 1);
              }
            });
          } else {
            this.myProblemsTasks.forEach((el, index) => {
              // @ts-ignore
              if (el.teamTask.uuid === task.uuid) {
                this.myArchivedTasks.push(el);
                this.myProblemsTasks.splice(index, 1);
              }
            });
          }
        } else if (status === 'done' && task.indicator) {
          this.completedTask(task);
          this.data.rateAction(task);
          if (teamPage) {
            this.myAssignActions.forEach((el, index) => {
              // @ts-ignore
              if (el.uuid === task.uuid) {
                // remove from my assign
                this.myAssignActions.splice(index, 1);
              }
            });
          }
        }
        this.data.hideLoading();

      }, err => {
        this.data.hideLoading();
        this.data.showAlertError(err.error.error.messages[0]);
      }
    );
  }

  private inProgressTask(task: any) {
    this.myProblemsTasks.forEach((el, index) => {
      // @ts-ignore
      if (el.teamTask.uuid === task.uuid) {
        this.myInProgressTasks.push(el);
        this.myProblemsTasks.splice(index, 1);
      }
    });
  }

  private completedTask(task: any) {
    this.myInProgressTasks.forEach((el, index) => {
      // @ts-ignore
      if (el.teamTask.uuid === task.uuid) {
        this.myCompletedTasks.push(el);
        this.myInProgressTasks.splice(index, 1);
      }
    });
  }

  //@ts-ignore
  private getArchivedTasks(teamUuid) {
    this.myArchivedTasks = [];
    this.api.getTeamArchivedTasks(teamUuid).subscribe(
      res => {
        res.forEach((el: any) => {
          // @ts-ignore
          this.myArchivedTasks.push({teamTask: el});
        });
      }
    );
  }

  //@ts-ignore
  setChallenges(challenges, team) {
    this.team = team;
    this.gotNewChallengesNumber = 0;
    this.newChallenges = [];
    this.oldChallenges = [];
    //@ts-ignore
    challenges.forEach((el, index) => {
      if (el.status === 'active') {
        this.gotNewChallengesNumber = this.gotNewChallengesNumber + 1;
        el.hasBeenSeen = false;
        // @ts-ignore
        this.newChallenges.push(el);
      } else if (el.status === 'seen') {
        el.hasBeenSeen = true;
        // @ts-ignore
        this.oldChallenges.push(el);
      }
    });
    this.allChallenges = [];
    this.allChallenges = this.newChallenges.concat(this.oldChallenges);
  }

  //@ts-ignore
  goToImprove(newChallenges) {
    this.data.getTeamsUsers(false);
    this.displayNew = newChallenges;
    this.displayChallenges = [];

    // check if there are .hasBeenSeen in new challenges and remove it
    // mark as seen in allChallenges
    // tslint:disable-next-line:prefer-for-of

    if (newChallenges) {
      this.displayChallenges = this.newChallenges;
    } else {
      this.displayChallenges = this.allChallenges;
    }
    this.data.showTeamActions = true;
  }

  //@ts-ignore
  seenChallenge(challenge) {
    // check if there are seen in newChallenges and remove it
    if (this.displayNew) {
      this.newChallenges.forEach(el => {
        // @ts-ignore
        if (el.uuid === challenge.uuid) {
          //@ts-ignore
          el.hasBeenSeen = true;
        }
      });
    } else {
      this.allChallenges.forEach(el => {
        //@ts-ignore
        if (el.uuid === challenge.uuid) {
          //@ts-ignore
          el.hasBeenSeen = true;
        }
      });
    }

    // this.data.teamProblems.forEach( el => {
    //   if (el.uuid === challenge.uuid){
    //     el.hasBeenSeen = true;
    //     el.status = 'seen';
    //   }
    // });
  }

  //@ts-ignore
  setStep(step) {
    this.step1 = false;
    this.step2 = false;

    if (step === 1) {
      this.step1 = true;
      this.step2 = false;
    } else if (step === 2) {
      this.step1 = false;
      this.step2 = true;
    }
  }

  assignAction() {
    this.data.showLoading();
    let assignees = 0;
    let assigneeSent = 0;
    // go to step 2
    this.teamAdmins.forEach((el) => {
      // @ts-ignore
      if (el.checked) {
        assignees = assignees + 1;
        let body = {
          parentUuid: this.assignationAction.uuid,
          //@ts-ignore
          assignee: el.user.uuid
        };
        if (this.actionPage) {
          body = {
            // @ts-ignore
            teamUuid: this.teamUuid,
            parentUuid: this.assignationAction.teamTask.uuid,
            indicatorUuid: this.assignationAction.teamTask.indicator.uuid,
            title: this.assignationAction.teamTask.title,
            description: this.assignationAction.teamTask.description,
            colorUuid: this.assignationAction.teamTask.color.uuid,
            type: this.assignationAction.teamTask.type,
            dictionaries: this.assignationAction.teamTask.dictionaries,
            //@ts-ignore
            assignee: el.user.uuid
          };
        }
        this.api.createTeamTask(body, this.teamUuid).subscribe(
          res => {
            assigneeSent = assigneeSent + 1;
            if (assignees === assigneeSent) {
              this.data.hideLoading();
              this.data.showAlert('Action assigned successfully');
              if (this.teamAdmins.length > 1) {
                // @ts-ignore
                document.getElementById('closeAddNewTaskModal').click();
              }
              localStorage.removeItem('TAG');
              this.createActionForm.reset();
            }
            // if me add to the team tasks
            // @ts-ignore
            if (el.me === true) {
              // @ts-ignore
              this.myAssignActions.unshift(res);
              // @ts-ignore
              this.myProblemsTasks.unshift({teamTask: res});
            }
            if (this.actionPage) {
              this.actionMemberAdded = el;
              this.actionTaskAdded = res;
              this.actionAssignmentSubscription.next(true);
            }
          }, err => {
            this.data.hideLoading();
            this.data.showAlertError('Error in assigning action');
            console.log(err);
          }
        );
      }
    });
  }


  //@ts-ignore
  onMentionSelect(selection) {
    if (localStorage.getItem('TAG')) {
      // @ts-ignore
      const allTags = JSON.parse(localStorage.getItem('TAG'));
      allTags.push(selection.uuid);
      localStorage.setItem('TAG', JSON.stringify(allTags));
    } else {
      const tag = [];
      tag.push(selection.uuid);
      localStorage.setItem('TAG', JSON.stringify(tag));
    }
    return '@' + selection.title;
  }

  //@ts-ignore
  setAdmins(teamAdmins) {
    this.teamAdmins = [];
    this.addMe = false;
    this.addOnlyMe = false;
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('userDetails'));
    //@ts-ignore
    teamAdmins.forEach((el, index) => {
      // check if user in team to add button add me
      if (user.uuid === el.user.uuid) {
        this.addMe = true;
        el.me = true;
        this.adminMe = el;
        // teamAdmins.splice(teamAdmins, index + 1);
        // teamAdmins.unshift(this.adminMe);
      } else {
        el.me = false;
      }
      el.checked = false;
    });
    if (this.addMe && teamAdmins.length === 1) {
      // dont show add others there are no others
      this.addOnlyMe = true;
    }
    if (teamAdmins.length === 1) {
      teamAdmins[0].checked = true;
    }
    this.teamAdmins = teamAdmins;
  }

  refreshAdmins() {
    this.teamAdmins.forEach((el) => {
      //@ts-ignore
      el.checked = false;
    });
  }

  addActionForm (id: string) {
    this.newStatementsActions[id] = {actionTitle:'', titleError: false, actionDescription:'', descriptionError: false};
  }

  addNewAction(action: any) {
    // @ts-ignore
    this.myProblemsTasks.unshift({teamTask : action})
  }
}
