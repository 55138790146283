import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {Subscription} from "rxjs";
import {ModalsComponent} from "../modals/modals.component";
import {AuthGuard} from "../../helpers/auth.guard";
import {AuthenticationService} from "../../services/authentication.service";
import {Router, Event, NavigationStart} from "@angular/router";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  gotTeams: boolean = false;
  teams: any = [];
  showTeamsOverview: boolean = true;
  private prioritySubscription: Subscription;
  public myTeams: any[] = [];
  workspacePlanFree: boolean = false;
  notMember: boolean = false;
  memberPrio: any = {};
  isTeamDropdownOpen = false;
  isAddDropdownOpen = false;
  isSettingsDropdownOpen = false;
  showActionButton: boolean = false;
  showFeedbackTemplatesButton: boolean = false;
  currentTeamUuid: any;
  onCurrentTeamPage: boolean = false;

  constructor(private apiwrapper: ApiwrapperService, public data: DataManipulationService, public modal: ModalsComponent,
              public auth: AuthenticationService, private cdRef: ChangeDetectorRef, private router: Router) {
    let interv = setInterval(() => {
      if (document.getElementById('header-toggle') !== null && document.getElementById('navbar') !== null) {
        this.showMenu('header-toggle', 'navbar');
        clearInterval(interv);
        this.link();
      }
    }, 500);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.onCurrentTeamPage = event.url.includes('/team') ? true : false;
      }
    });
    if(localStorage.getItem('currentTeam')) {
      this.onCurrentTeamPage = true;
      this.currentTeamUuid = localStorage.getItem('currentTeam');
    }
    this.prioritySubscription = this.data.userPriorities
      .subscribe((value: any) => {
        // this.gotPriority = true;
        this.data.userRole = value;
        this.memberPrio = value;
        this.setTeams();
      });

    if (this.data.getWorkspacePlan().requirePayment === 'no') {
      this.workspacePlanFree = true;
    }
  }


  ngOnInit(): void {
    // check if user is members
    this.setTeams();
  }

  ngOnDestroy() {
    if (!localStorage.getItem('ONBOARDING')) {
      this.prioritySubscription.unsubscribe();
    }
  }

  showMenu(headerToggle: string, navbarId: string) {
    // Validate that variables exist
    if (headerToggle && navbarId) {
      if (document.getElementById(headerToggle) !== null) {
        // @ts-ignore
        document.getElementById(headerToggle).addEventListener('click', () => {
          // We add the show-menu class to the div tag with the nav__menu class
          // @ts-ignore
          document.getElementById(navbarId).classList.toggle('show-menu')
          // change icon
          // @ts-ignore
          document.getElementById(headerToggle).classList.toggle('bx-x')
        })
      }
    }
  }

  closeMenu() {
    //@ts-ignore
    document.getElementById('navbar').classList.toggle('show-menu');
  }

  colorLink() {
    document.querySelectorAll('.nav__link').forEach(l => l.classList.remove('active'))
    // l.classList.add('active')
  }

  link() {
    /*==================== LINK ACTIVE ====================*/
    document.querySelectorAll('.nav__link').forEach(l => {
      l.addEventListener('click', this.colorLink)
    })
  }

  setTeams() {
    this.showActionButton = false;

    this.memberPrio = {...this.data.userRole};
    if (this.memberPrio.role.workspacePriority > 20) {
      this.notMember = true;
    } else if (this.memberPrio.role.workspacePriority <= 20) {
      this.notMember = false;
    }
    // set teams in menu
    this.teams = this.data.userTeams();
    // set menu actions button if user is team-leader
    if (this.teams.length > 0) {
      this.teams.forEach((el: any) => {
        if (el.myRole !== null) {
          if (el.myRole.teamPriority > 10) {
            this.showActionButton = true;
            this.showFeedbackTemplatesButton = true;
          }
        }
      })
    }
  }

  setCurrentTeam(teamUuid : any) {
    this.currentTeamUuid = teamUuid;
  }

  logout() {
    this.auth.logout();
    location.reload();
  }

  retrieveMenuItems() {
    this.isTeamDropdownOpen ? this.isTeamDropdownOpen = !this.isTeamDropdownOpen : this.isTeamDropdownOpen;
    this.isAddDropdownOpen ? this.isAddDropdownOpen = !this.isAddDropdownOpen : this.isAddDropdownOpen;
    this.isSettingsDropdownOpen ? this.isSettingsDropdownOpen = !this.isSettingsDropdownOpen : this.isSettingsDropdownOpen;
  }

  goToBilling() {
    this.data.lastRouteBeforeBilling = this.router.url;
  }
}
