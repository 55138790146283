<div class="d-flex flex-column align-items-center justify-content-center card-body p-5 onboarding-window-content">
  <div class="d-flex flex-column justify-content-start">
    <!--          <h3 class="" (click)="setStep(6)">Create a new team</h3>-->
    <div class="create-new-team">
      <div class="create-new-team-header">
        <div class="input-container">
          <label>{{'createteam_subtitle' | translate}}</label>
          <div style="display: flex">
            <input id="teamName" type="text" placeholder="{{'createteam_name' | translate}}" class="form-control team-name"
                   (keyup.enter)="addTeam()"
                   [formControl]="teamName">
           <button class="add-button" (click)="addTeam()"> <img style="cursor: pointer" src="./assets/svg/add.svg"
                 alt="add"> <p>{{'createteam_create_button' | translate}}</p></button>
          </div>
        </div>
        <div class="tip" [ngStyle]="(workspaceTeams.length === 0) ? {'margin-bottom' : '20px' } : {}">
          <span><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'createteam_tip' | translate}}</span>
        </div>
      </div>

      <div class="teams-list" *ngIf="workspaceTeams.length > 0">
        <mat-card>
          <mat-card-header>
            <h4>{{'createteam_myteams'|translate}}</h4>
          </mat-card-header>
          <mat-card-content style="padding: 0;">
            <div class="d-flex flex-row justify-content-between align-items-end team-column-category">
              <p class="fw-normal m-0 column-one">{{'createteam_name' | translate}}</p>
              <p class="fw-normal m-0 column-two">{{'createteam_type' | translate}}</p>
              <p class="fw-normal m-0 column-three">{{'createteam_action' | translate}}</p>
            </div>
            <div class="create-teams">
              <div *ngFor="let team of workspaceTeams; let i = index" style="margin-top: 2rem" class="team-team">
                <div class="d-flex flex-row justify-content-between align-items-center team-row">
                  <h6 class="team-name column-one">{{team.name}}</h6>
                  <!--                  <mat-select *ngIf="!team.created" class="form-control asign-team-leader-input"-->
                  <!--                              [ngStyle]="{'border': team.error ? '1px red solid' :'','border-radius': team.error ? '50px' : '' }"-->
                  <!--                              [ngClass]="team.error ? 'error-pulse':''"-->
                  <!--                              placeholder="How do they work?"-->
                  <!--                              [(value)]="team.type">-->
                  <!--                    <mat-option *ngFor="let type of teamTypes" [value]="type.uuid">-->
                  <!--                      <img class="team-image-selector"-->
                  <!--                           src="../../../assets/svg/team-remote.svg"-->
                  <!--                           alt="succesteam_logo">-->
                  <!--                      {{type.title}}-->
                  <!--                    </mat-option>-->
                  <!--                  </mat-select>-->
                  <p-dropdown class="team-type-dropdown column-two" [options]="teamTypes" [(ngModel)]="team.type" dropdownIcon="bi bi-caret-down-fill" optionLabel="title" optionValue="uuid"
                  [ngStyle]="{'border': team.error ? '1px red solid' :'','border-radius': team.error ? '50px' : '' }"
                              placeholder="{{'createteam_type_placeholder' | translate}}"></p-dropdown>

                  <p *ngIf="team.created">Team work type</p>

                  <img *ngIf="!team.created" class="delete-button column-three"
                       src="../../../assets/svg/team-delete-button.svg"
                       style="cursor: pointer; padding: 0 10px; height: 20px"
                       (click)="deleteTeam(i)" alt="">
                  <img *ngIf="team.created" class="delete-button column-three" src="../../../assets/svg/check-green.svg"
                       style="cursor: pointer; padding: 0 10px" alt="">
                  <!--                <i class="bi bi-trash-fill ms-3" style="color: #A9AEB3; cursor: pointer" (click)="deleteTeam(i)"></i>-->
                </div>
              </div>
              <div class="d-flex flex-row align-items-center mt-3" *ngIf="workspaceTeams.length === 0 ">
                <div [ngStyle]="{'background-color': '#b0b0b0'}"
                     class="d-flex justify-content-between align-items-center team-row">
                  <h6 class="ms-4 me-5">{{'createteam_name' | translate}}</h6>
                  <mat-select disabled class="form-control asign-team-leader-input"
                              placeholder="Assign team type ">
                  </mat-select>
                  <img class="delete-button" src="../../../assets/svg/team-delete-button.svg"
                       style="padding: 0 10px" alt="">
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<div>
  <button class="btn primary-btn" pButton pRipple
          style="float: right; margin: 3rem"
          (click)="createTeam()">
          {{'createteam_button' | translate}}
  </button>
</div>
