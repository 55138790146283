import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-loop-congrats',
  templateUrl: './feedback-loop-congrats.component.html',
  styleUrls: ['./feedback-loop-congrats.component.scss']
})
export class FeedbackLoopCongratsComponent implements OnInit {
  public feedback: any;
  public hasMultiple: boolean = false;
  public loopError: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // @ts-ignore
    this.feedback = JSON.parse(localStorage.getItem('loop'));
    if (this.feedback.error) {
      this.loopError = true;
    }
    if (this.feedback.multipe === undefined || this.feedback.multiple === false){
      this.hasMultiple = false;
    } else if (this.feedback.multiple === true) {
      this.hasMultiple = true;
    }
  }
}
