<div #mainContainer class="main-container">
    <div class="schedule-header">
        <div class="title-container">
            <h2 class="schedule-header-title">{{'custom_poll_onboarding_temp_schedule_title' | translate}}</h2>
        </div>
        <div class="schedule-main-body">
            <div class="schedule-selected-categories-container">
                <h2 style="font-size: 1.8rem;">{{'custom_poll_onboarding_temp_schedule_selected_checkins' | translate}}</h2>
                <div class="selected-category" *ngFor="let check of checkinsCategories">
                    <input [ngClass]="check.isMandatory ? 'mandatory':''" class="selected-category-check" type="checkbox"
                                [checked]="true" 
                                [disabled]="true">
                    <h2 style="font-size: 1.8rem;margin-bottom: 0;">{{check.title}}</h2>
                    <img *ngIf="check.isMandatory" src="../../../../../assets/svg/lock.svg">
                </div>
            </div>
            <div class="schedule-dates-container">
                <h2 class="date-title start">{{'custom_poll_onboarding_temp_schedule_assign_start_date' | translate}}</h2>
                <div class="start-date">
                    <img class="calendar-icon" src="../../../../../assets/images/calendar.png">
                    <p-calendar class="calendar-picker" [(ngModel)]="startDate" (ngModelChange)="changeStartDate($event)" [minDate]="minStartDateValue" dateFormat="M.dd.yy"></p-calendar>
                </div>
                <h2 class="date-title end">{{'custom_poll_onboarding_temp_schedule_select_end_date' | translate}}</h2>
                <div class="end-date">
                    <div class="end-date-radio-1-container" *ngFor="let radio of radioButtons">
                        <p-radioButton id="radio-1" [value]="radio" [(ngModel)]="selectedRadioValue"></p-radioButton>
                        <label [ngClass]="selectedRadioValue.id !== radio.id ? 'not-selected':''" for="radio-1">{{radio.label}}</label>
                    </div>
                    <div class="start-date">
                        <img class="calendar-icon" [src]="selectedRadioValue.id !== 'radio2' ? '../../../../../assets/svg/calendar-gray.svg':'../../../../../assets/images/calendar.png'">
                        <p-calendar class="calendar-picker" [ngClass]="selectedRadioValue.id !== 'radio2' ? 'not-selected':''" 
                            [(ngModel)]="endDate" 
                            (ngModelChange)="changeEndDate($event)" 
                            [minDate]="minEndDateValue"
                            [disabled]="selectedRadioValue.id !== 'radio2'"
                            [baseZIndex]="1001" 
                            dateFormat="M.dd.yy"></p-calendar>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="selected-category-footer">
        <button class="selected-category-back-btn" (click)="goBack()" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button class="selected-category-next-btn" 
            pButton type="button" 
            label="{{'custom_poll_breadcrumb_t2' | translate}}"
            (click)="nextStep()"></button>
    </div>
</div>