<div class="row teams-overview-header">
  <!--  <a class="nav-link" style="text-align: right; cursor:pointer; font-size: 14px" (click)="auth.logout()">LOG OUT</a>-->
  <h2 (click)="open()">{{'teams_overview'| translate}}</h2>
  <i class="bi bi-person-add"></i>
  <div class="teams-sorting">
    <div class="sort-containers">
      <!--SORT BY INDICATORS-->
      <p-dropdown (onChange)="sortByIndicator()" [options]="sortingByIndicators"
                  [(ngModel)]="sortByIndicators" dropdownIcon="bi bi-caret-down-fill"
                  optionLabel="name">
        <ng-template pTemplate="selectedItem">
          <div style="padding: 1.2rem 1rem 1rem;" class="sort-item sort-item-value">
            <div class="sort-icon-container">
              <img class="sort-icon" src="../../../assets/icons/{{sortByIndicators?.icon}}.svg">
            </div>
            <div>{{sortByIndicators?.name}}</div>
          </div>
        </ng-template>
        <ng-template let-sort pTemplate="item">
          <div class="sort-item">
            <div class="sort-icon-container">
              <img class="sort-icon" src="../../../assets/icons/{{sort.icon}}.svg">
            </div>
            <div>{{sort.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>

      <!--SORT BY TEAM TYPE-->
      <p-dropdown (onChange)="sortTeamsByType()" [options]="sortingByTeamType"
                  [(ngModel)]="sortByTeamType" dropdownIcon="bi bi-caret-down-fill"
                  optionLabel="name">
        <ng-template pTemplate="selectedItem">
          <div style="padding: 1.2rem 1rem 1rem;" class="sort-item sort-item-value">
            <div class="sort-icon-container">
              <img class="sort-icon" src="../../../assets/svg/team-{{sortByTeamType?.icon}}.svg">
            </div>
            <div>{{sortByTeamType?.name}}</div>
          </div>
        </ng-template>
        <ng-template let-sort pTemplate="item">
          <div class="sort-item">
            <div class="sort-icon-container">
              <img class="sort-icon" src="../../../assets/svg/team-{{sort?.icon}}.svg">
            </div>
            <div>{{sort.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="sort-containers">
      <!--SORT BEST OR WORST-->
      <p-dropdown (onChange)="sortTeams()" [options]="sortingByPerformance"
                  [(ngModel)]="sortByPerformance" dropdownIcon="bi bi-caret-down-fill"
                  optionLabel="name">
        <!--               optionLabel="name" style="margin-right: 1.6rem;">-->
        <ng-template pTemplate="selectedItem">
          <div class="sort-item sort-item-value">
            <div>{{sortByPerformance?.name}}</div>
          </div>
        </ng-template>
        <ng-template let-sort pTemplate="item">
          <div class="sort-item">
            <div>{{sort.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>

      <!--      <p-dropdown (onChange)="sortTeams()" [options]="sortingByDate" [(ngModel)]="sortByDate"-->
      <!--                  dropdownIcon="bi bi-caret-down-fill"-->
      <!--                  optionLabel="name">-->
      <!--        <ng-template pTemplate="selectedItem">-->
      <!--          <div class="sort-item sort-item-value">-->
      <!--            <div>{{sortByDate?.name}}</div>-->
      <!--          </div>-->
      <!--        </ng-template>-->
      <!--        <ng-template let-sort pTemplate="item">-->
      <!--          <div class="sort-item">-->
      <!--            <div>{{sort.name}}</div>-->
      <!--          </div>-->
      <!--        </ng-template>-->
      <!--      </p-dropdown>-->
    </div>
  </div>
</div>
<div *ngIf="showTeams" class="row teams-overview-body justify-content-center">
  <div class="col-md-auto team-cards" *ngFor="let team of teams; index as i">
    <p-card class="team-card" *ngIf="sortByTeamType.type === 'all' || sortByTeamType.type === team.typeIcon">
      <ng-template pTemplate="header">
        <div class="team-card-header">
          <span class="team-name-header">{{team['name']}}</span>
          <img *ngIf="team.type"  pTooltip="{{team.type | titlecase}}" tooltipPosition="bottom"
             tooltipStyleClass="tooltip-style" class="team-type-icon" src="../../../assets/svg/team-{{team.typeIcon}}.svg">
        </div>
      </ng-template>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div class="team-leader-card-content">

              <span class="p-overlay-badge">
                <p-skeleton *ngIf="!team.leaders" shape="circle" size="6.5rem" styleClass="p-mr-2"></p-skeleton>
                <img *ngIf="team.leaders" class="team-leader-avatar"
                     src="{{team.leaders[0].user.avatar ? data.baseUrl + '/uploads/user/'+ team.leaders[0].user.uuid + '/' + team.leaders[0].user.avatar.name : '../../../assets/svg/user-icon.svg'}}"
                     alt="avatar">
                <span class="p-badge avatar-badge"><img class="avatar-crown-badge"
                                                        src="../../../assets/images/crown.png"
                                                        alt=""></span>
              </span>
          <div class="team-leader-name-container">
            <p-skeleton styleClass="p-mb-2" borderRadius="16px" *ngIf="!team.leaders"></p-skeleton>
            <span class="team-leader-name"
                  *ngIf="team.leaders && team.leaders.length > 0">{{(team.leaders[0].user.detail['displayName'] | getDisplayName: team.leaders[0].user.detail['firstname']: team.leaders[0].user.detail['lastname']: team.leaders[0].user['email'])}}</span>
            <span [style]="!team.leaders ? 'color:#BFBFBF':''" class="team-leader-rank">{{'team_overview_team_leader' | translate}}</span>
            <span *ngIf="team.leaders && team.leaders.length > 1"
              class="team-leader-counter">+ {{team.leaders.length - 1}} {{'team_overview_team_more' | translate}}</span>
          </div>

          <!-- <button pButton pRipple type="button" label="notify" class="team-leader-notify p-button-outlined"
          *ngIf="team.teamHaveActivePolling === 'yes' && data.userRole.role.workspacePriority > 10" (click)="notifyTeam(team.uuid)" ><i class="bi bi-bell notify-bell-icon"></i></button> -->
        </div>

        <span class="team-total-members" pTooltip="{{'team_overview_team_members' | translate}}" tooltipPosition="bottom"
                tooltipStyleClass="tooltip-style">(<i class="bi bi-person-fill" style="color: #2551BF; font-size: 1.4rem"></i>{{team['totalMembers']}})</span>

      </div>
      <div *ngIf="showIndicator === 'Overall'" class="team-scores-container">
        <div class="team-score">
          <span [ngClass]="team['teamReport']['teamSuccessteamScore'] === 0 ? 'no-response-rate' : ''"
                class="success-score-title">{{'team_score'|translate}}</span>
          <div class="success-score-knob">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 7 100 122" version="1.1" width="150px"
                 height="150px">
              <path [ngClass]="team['teamReport']['teamSuccessteamScore'] === 0 ? 'knob-no-values':''" class="grey" d="M40,90
                A40,40 0 1,1 100,90"
                    style="fill:none;"/>
              <path [style]="{'stroke-dashoffset': setKnobProgressBar(team['teamReport']['teamSuccessteamScore'])}"
                    [attr.stroke]="team['teamReport']['teamsuccessteamscoreColor']" id="svgProgress" class="progress"
                    d="M40,90
                A40,40 0 1,1 100,90"
                    style="fill:none;"/>
              <text [style]="team['teamReport']['teamSuccessteamScore'] === 0 ? 'fill:#BFBFBF':''" class="svgTextValue"
                    [attr.x]="team['teamReport']['teamSuccessteamScore'] === 0 ? 63 : 57"
                    y="70">{{team['teamReport']['teamSuccessteamScore']}}</text>
              <text class="svgTextPercentage" [attr.x]="team['teamReport']['teamSuccessteamScore'] === 0 ? 74 : 79"
                    y="69">%
              </text>
            </svg>
          </div>
          <!-- <p-knob [ngModel]="team['teamReport']['teamSuccessteamScore']" valueTemplate="{value}%" [size]="130"
                  [valueColor]="team['teamReport']['teamsuccessteamscoreColor']" [readonly]="true"></p-knob> -->
        </div>
        <div class="team-score">
          <span [ngClass]="team['teamReport']['responseRate'] === 0 ? 'no-response-rate' : ''"
                class="response-rate-title">{{'team_overview_response_rate' | translate}}</span>
          <img class="response-rate-icon"
               [src]="team['teamReport']['responseRate'] === 0 ? '../../../assets/svg/teams-overview-no-rr.svg':'../../../assets/svg/teams-overview-rr.svg'"
               alt="response-rate">
          <div [ngClass]="team['teamReport']['responseRate'] === 0 ? 'no-response-rate' : ''"
               class="d-flex flex-row align-items-end">
            <span class="response-rate-value">{{team['teamReport']['responseRate']}}</span>
            <span class="response-rate-percentage">%</span>
          </div>
        </div>
      </div>
      <div *ngIf="showIndicator !== 'Overall'" class="team-indicator">
        <div *ngIf="showIndicator === 'Performance'"
             [ngStyle]="({'background-color' : hexToRgb(team['teamReport']['performanceColor']) })"
             class="performance-indicator">
          <img [ngStyle]="({'background-color' : team['teamReport']['performanceColor'] })"
               class="indicator-icon-large" src="assets/svg/performance-white.svg">
          <div class="team-indicator-percentage">
            <div>
              <span class="team-indicator-value-percentage">{{team['teamReport']['Performance']}}</span>
              <span class="team-value-percentage">%</span>
            </div>
            <span class="team-indicator-title">{{'team_performance'| translate}}</span>
          </div>
        </div>
        <div *ngIf="showIndicator === 'Satisfaction'"
             [ngStyle]="({'background-color' : hexToRgb(team['teamReport']['satisfactionColor']) })"
             class="performance-indicator">
          <img
            [ngStyle]="({'background-color' : team['teamReport']['satisfactionColor'] })"
            class="indicator-icon-large" src="assets/svg/satisfaction-white.svg">
          <div class="team-indicator-percentage">
            <div>
              <span class="team-indicator-value-percentage">{{team['teamReport']['Satisfaction']}}</span>
              <span class="team-value-percentage">%</span>
            </div>
            <span class="team-indicator-title">{{'team_satisfaction'| translate}}</span>
          </div>
        </div>
        <div *ngIf="showIndicator === 'Motivation'"
             [ngStyle]="({'background-color' : hexToRgb(team['teamReport']['motivationColor']) })"
             class="performance-indicator">
          <img
            [ngStyle]="({'background-color' : team['teamReport']['motivationColor']})"
            class="indicator-icon-large" src="../../../assets/svg/motivation-white.svg">
          <div class="team-indicator-percentage">
            <div>
              <span class="team-indicator-value-percentage">{{team['teamReport']['Motivation']}}</span>
              <span class="team-value-percentage">%</span>
            </div>
            <span class="team-indicator-title">{{'team_motivation'| translate}}</span>
          </div>
        </div>
        <div *ngIf="showIndicator === 'Stress'"
             [ngStyle]="{'background-color' : hexToRgb(team['teamReport']['stressColor']) }"
             class="performance-indicator">
          <img
            [ngStyle]="{'background-color' : team['teamReport']['stressColor']}"
            class="indicator-icon-large" src="../../../assets/icons/stress-white.svg">
          <div class="team-indicator-percentage">
            <div>
              <span class="team-indicator-value-percentage">{{team['teamReport']['Stress']}}</span>
              <span class="team-value-percentage">%</span>
            </div>
            <span class="team-indicator-title">{{'team_stress_free'|translate}}</span>
          </div>
        </div>
      </div>

      <div class="team-indicators">
        <div (click)="setCurrentIndicator(i, 'Performance')"
             [ngStyle]="{'background-color' : hexToRgb(team['teamReport']['performanceColor']) }"
             class="indicator">
          <img [ngStyle]="({'background-color' : team['teamReport']['performanceColor'] })"
               class="indicator-icon" src="assets/svg/performance-white.svg">
          <span class="indicator-title">{{'team_performance'| translate}}</span>
          <div class="indicator-percentage">
            <span class="indicator-value-percentage">{{team['teamReport']['Performance']}}</span>
            <span class="percentage">%</span>
          </div>
        </div>
        <div (click)="setCurrentIndicator(i, 'Satisfaction')"
             [ngStyle]="({'background-color' : hexToRgb(team['teamReport']['satisfactionColor']) })"
             class="indicator">
          <img
            [ngStyle]="{'background-color' : team['teamReport']['satisfactionColor']}"
            class="indicator-icon" src="assets/svg/satisfaction-white.svg">
          <span class="indicator-title">{{'team_satisfaction'| translate}}</span>
          <div class="indicator-percentage">
            <span class="indicator-value-percentage">{{team['teamReport']['Satisfaction']}}</span>
            <span class="percentage">%</span>
          </div>
        </div>
        <div (click)="setCurrentIndicator(i, 'Motivation')"
             [ngStyle]="{'background-color' : hexToRgb(team['teamReport']['motivationColor'])}"
             class="indicator">
          <img
            [ngStyle]="{'background-color' : team['teamReport']['motivationColor'] } "
            class="indicator-icon" src="assets/svg/motivation-white.svg">
          <span class="indicator-title">{{'team_motivation'|translate}}</span>
          <div class="indicator-percentage">
            <span class="indicator-value-percentage">{{team['teamReport']['Motivation']}}</span>
            <span class="percentage">%</span>
          </div>
        </div>
        <div (click)="setCurrentIndicator(i, 'Stress')"
             [ngStyle]="{'background-color' : hexToRgb(team['teamReport']['stressColor']) }"
             class="indicator">
          <img
            [ngStyle]="{'background-color' : team['teamReport']['stressColor'] }"
            class="indicator-icon" src="assets/svg/stress-white.svg">
          <span class="indicator-title">{{'team_stress_free'|translate}}</span>
          <div class="indicator-percentage">
            <span class="indicator-value-percentage">{{team['teamReport']['Stress']}}</span>
            <span class="percentage">%</span>
          </div>
        </div>
      </div>

      <div class="team-dashboard-content">
        <button pButton pRipple type="button" label="{{'team_overview_team_dashboard' | translate}}" icon="bi bi-chevron-right" iconPos="right"
                class="team-dashboard" [routerLink]="'/team/'+team['uuid']"></button>
      </div>
      <ng-template pTemplate="footer">
        <div (click)="openMoreIndicators(i)" class="team-show-indicators">
          <i class="bi bi-list"></i>
          <span>{{showMore[i] ? ('team_overview_more'|translate) : ('team_overview_less'|translate)}}</span>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>
