import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from "primeng/radiobutton";

import { CustomLoopContainerComponent } from './containers/custom-loop-container/custom-loop-container.component';
import { ChooseTemplateComponent } from './components/choose-template/choose-template.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { GeneralCategoriesComponent } from './components/general-categories/general-categories.component';
import { SpecialCategoriesComponent } from './components/special-categories/special-categories.component';
import { TimingComponent } from './components/timing/timing.component';
import { ChooseTeamModalComponent } from './components/choose-team-modal/choose-team-modal.component';
import { ReviewCustomPollComponent } from './components/review-custom-poll/review-custom-poll.component';
import { CongratsComponent } from './components/congrats/congrats.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { OnboardingCategoriesComponent } from './components/onboarding-categories/onboarding-categories.component';
import { OnboardingScheduleComponent } from './components/onboarding-schedule/onboarding-schedule.component';



@NgModule({
  declarations: [
    CustomLoopContainerComponent,
    ChooseTemplateComponent,
    BreadcrumbComponent,
    GeneralCategoriesComponent,
    SpecialCategoriesComponent,
    TimingComponent,
    ChooseTeamModalComponent,
    ReviewCustomPollComponent,
    CongratsComponent,
    OnboardingCategoriesComponent,
    OnboardingScheduleComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ButtonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    TooltipModule,
    AppRoutingModule,
    AccordionModule,
    RadioButtonModule
  ],
  exports: [
    CustomLoopContainerComponent
  ]
})
export class CustomLoopsModule { }
