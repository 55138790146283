<button type="button" id="deleteModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#deleteModal">
</button>

<div class="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title"
            id="staticBackdropLabel">{{deleteType === 'team' ? ('workspace_tab2_delete' |translate) : 'Remove user'}}</h3>
        <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
             id="closeDeleteModal" style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <div *ngIf="deleteType === 'team'">
          <h3 style="padding: 5rem 2rem; text-align: center">{{'workspace_tab2_delete_question'| translate}}
            <br><br><b>{{delete}}</b></h3>
          <div style="float: right">
            <button class="btn-hallow" data-bs-dismiss="modal" aria-label="Close">{{'cancel_button'|translate}}</button>
            <button class="btn-red" (click)="deleteTeam()">{{'forgot_button'| translate}}</button>
          </div>
        </div>
        <div *ngIf="deleteType === 'user'">
          <h3
            style="padding: 5rem 2rem">{{'remove_user'|translate}} {{userDelete.user.detail['displayName'] | getDisplayName: userDelete.user.detail['firstname']: userDelete.user.detail['lastname']: userDelete.user['email']}}</h3>
          <div style="float: right">
            <button class="btn-hallow" data-bs-dismiss="modal" aria-label="Close">{{'cancel_button'|translate}}</button>
            <button class="btn-red" (click)="deleteWorkspaceMember()">{{'forgot_button'| translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="workspace-page" *ngIf="!showCreateRole && !showUpdateInvitation">
  <div class="page-header workspace-page-header">
    <h2 class="workspace-page-title">{{'left_menu_3'|translate}}</h2>
    <button (click)="index = 0" pButton type="button" label="{{'workspace_tab1'|translate}}"
            [ngClass]="index === 0 ? 'workspace-button-active':''" class="p-button-text workspace-button"></button>
    <button (click)="index = 1" pButton type="button" label="Teams"
            [ngClass]="index === 1 ? 'workspace-button-active':''"
            class="p-button-text workspace-button workspace-button-teams"></button>
    <button (click)="index = 2" pButton type="button" label="" [style]="{'font-family':'Poppins, sans-serif'}"
            [ngClass]="index === 2 ? 'workspace-button-active':''" class="p-button-text workspace-button ">
      {{'workspace_tab3'| translate}}
      <p-badge *ngIf="requests.length > 0" [value]="requests.length" size="large" severity="danger">!</p-badge>

    </button>
    <button (click)="index = 3" pButton type="button" label="{{'workspace_tab4'| translate}}"
            *ngIf="userPriorities.role.workspacePriority > 10"
            [ngClass]="index === 3 ? 'workspace-button-active':''" class="p-button-text workspace-button"></button>
  </div>
  <div class="workspace-page-body">
    <p-tabView [(activeIndex)]="index">
      <p-tabPanel>
        <div class="col-xl-12 workspace-information">
          <p-card>
            <div *ngIf="showWorkspaceDetails">
              <form [formGroup]="workspaceDetailsForm" (ngSubmit)="updateWorkspace()" autocomplete="off"
                    id="workspaceDetailsForm">
                <div class="row">
                  <div class="col-md-2">
                    <div class="avatar" (click)="userPriorities.role.workspacePriority > 10 && workspaceFile.click();">
                      <input type="file" hidden
                             #workspaceFile
                             name="workspaceFile"
                             id="workspaceFile"
                             (change)="uploadWorkspaceImage($event)"
                             accept="image/jpg,.jpeg,.png,.jpg"/>

                      <img class="user-picture-settings"
                           *ngIf="workspace && (workspace.avatar || workspace.name)"
                           src="{{workspace.avatar ?  baseUrl + '/uploads/workspace/'+ workspace.uuid + '/' + workspace.avatar + '?' + currentTime : '../../../assets/svg/image-placeholder2.svg'}}"
                           data-holder-rendered="true"
                           [ngStyle]="(workspace.avatar === null) ? {'padding-top' : '30px' } : {}">

                      <a class="register"
                         (click)="userPriorities.role.workspacePriority > 10 && workspaceFile.click();">
                        {{"upload_logo" | translate}}
                      </a>
                    </div>
                  </div>

                  <div class="col-md-8" style="padding: 5vh">
                    <div class="row">
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                        <label class="form-label">{{"settings_workspace_name"|translate}}</label>
                        <p class="light workspace-details">{{workspace.name}}</p>
                        <!--                      <input type="text" formControlName="name" class="form-control" [disabled]="desabled"-->
                        <!--                             id="workspaceName" placeholder="Workspace name"-->
                        <!--                             [ngClass]="{ 'is-invalid': submitted.workspaceSubmitted && e['name']['errors'] }"/>-->
                        <!--                      <div *ngIf="submitted.workspaceSubmitted && e['name']['errors']" class="invalid-feedback">-->
                        <!--                        <div *ngIf="e['name']['errors']['required']">Name is required</div>-->
                        <!--                        <div *ngIf="e['name']['errors']['minlength']">Name must be at least 1 character</div>-->
                        <!--                      </div>-->
                      </div>
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                        <label class="form-label">{{"settings_workspace_size"|translate}}</label>
                        <p class="light workspace-details">{{totalMembers}} members
                          and {{workspaceTeams.length}} teams</p>
                      </div>
                    </div>
                    <div class="row"
                         *ngIf="this.showWorkspaceDetails && userPriorities['role']['workspacePriority'] > 10">
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                        <label class="form-label">{{'settings_workspace_industry' | translate}}</label>
                        <div *ngIf="this.workspace['workspaceIndustry'] === null">
                          <ng-autocomplete
                            class="autocomplete-input"
                            placeHolder="Industry"
                            [(ngModel)]="this.workspace['workspaceIndustry']"
                            [data]="workspaceIndustries"
                            (selected)="changeIndustry($event)"
                            [searchKeyword]="searchIndustry"
                            [itemTemplate]="userTemplate"
                            [notFoundTemplate]="notFoundTemplate">
                          </ng-autocomplete>
                          <ng-template #userTemplate let-user>
                            <div style="display: flex">
                              <a [innerHTML]="user.name"></a>
                            </div>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <p>{{'step1_no_industry'|translate}}</p>
                          </ng-template>
                          <p class="smaller">{{'step1_industry'|translate}}</p>
                        </div>
                        <p class="light workspace-details"
                           *ngIf="this.workspace['workspaceIndustry'] !== null">{{this.workspace['workspaceIndustry']['name']}}</p>
                      </div>
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                        <div class="default-language">
                          <label class="form-label">{{'step1_language'|translate}}</label>
                          <div class="w-dl-questionmark-container">
                            <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                            <div class="workspace-dl-tooltip">{{'step1_language_yellow_question_mark'|translate}}</div>
                          </div>
                        </div>
                        <div [ngStyle]="userPriorities['role']['workspacePriority'] > 20 ? {}:{'pointer-events':'none'}" style="display: inline-flex; padding: .6rem; width: -webkit-fill-available " class="workspace-details">
                          <ngx-flag-picker
                            class="country"
                            style="font-size: 2rem; border-radius: 5px"
                            [showFlags]=true
                            [showLabels]=false
                            [showArrow]=true
                            [selectedCountryCode]="selectedCountryCode"
                            [countryCodes]="countryCodes"
                            (changedCountryCode)="changeLanguage($event)">
                          </ngx-flag-picker>
                          <p style="font-size: 1.6rem; padding: .2rem 1rem; margin: 0">{{languageDisplayed}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="row payment-details"
                         *ngIf="showMembersDetails && userPriorities.role.workspacePriority  === 40">
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                        <label class="form-label">{{'workspace_tab1_subscription'| translate}}:</label>
                        <p class="light workspace-details">{{plan.title}} <span
                          *ngIf="plan.pricePerMember !== 0"> - {{plan.pricePerMember}} {{plan.currency}}
                          / user per month</span>
                          <span *ngIf="plan.pricePerMember === 0" class="light" [routerLink]="'/settings/billing'"
                                style="cursor: pointer; color: rgb(48, 170, 233);"> {{'workspace_tab1_change_plan'| translate}}</span>
                        </p>
                      </div>
                      <div class="user-details form-group col-xl-6 col-lg-6 col-md-12"
                           *ngIf="plan.pricePerMember !== 0 && gotSimulate">
                        <label class="form-label">{{'workspace_tab1_estimated'| translate}}</label>
                        <p class="light workspace-details">{{simulate.totalWithoutVat}} {{plan.currency}}, billed
                          at {{simulate.endDate.date | date}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2" style="position: relative">
                    <div class="action-btns">
                      <button class="btn btn-blue save-button"
                              *ngIf="userPriorities['role']['workspacePriority'] > 20 && showWorkspaceDetails"
                              (click)="updateWorkspace()">{{'save_button' | translate}}
                      </button>
                    </div>
                  </div>
                </div>

              </form>
            </div>
          </p-card>
        </div>
      </p-tabPanel>

      <!--TEAMS TAB-->
      <p-tabPanel header="Header 2">
        <div class="col-lg-12 workspace-teams">
          <p-card [ngClass]="{'settings-card-height': showTeamsDetails}">
            <div class="small-card-header justify-content-between">
              <h2>Teams <span class="team-name-span" *ngIf="showMembersDetails"><i
                class='bi bi-people-fill nav__icon'></i>({{workspaceTeams.length}})</span></h2>
              <div class="action-btns" *ngIf="userPriorities.role.workspacePriority > 20">
                <button class="btn btn-hallow" *ngIf="showTeamsDetails" (click)="modal.openTeamModal()">
                  <i class='bi bi-plus-circle-fill nav__icon'></i> {{'workspace_tab2_add_team'| translate}}
                </button>
                <button class="btn btn-hallow ms-3" *ngIf="showTeamsDetails" (click)="openOrganizeTeamModal()">
                  {{'manage_team'| translate}}
                </button>
              </div>
            </div>

            <div class="inner-card">
              <div class="teams-secondary-header">
                <h6>{{'my_account_my_teams_name'| translate}}</h6>
                <h6>{{'team_type'|translate}}</h6>
                <h6>{{'user'|translate}}</h6>
              </div>

              <div *ngIf="showTeamsDetails">
                <div class="accordion" id="teams">
                  <div class="card" *ngIf="workspaceTeams.length === 0">
                    <div class="card-header">
                      <div class="positions-block">
                        <p class="medium mb-0 role-name">
                          No teams
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="users-list">
                    <div [ngClass]="!(i % 2) ?'team-list' :''" *ngFor="let team of workspaceTeams; let i = index">
                      <div id="headingTeams{{i}}">
                        <div class="positions-block teams-grid-layout">
                          <div class="team-name" *ngIf="team['editName'] === true">
                            <input class="form-control" [(ngModel)]="team.name" type="text"
                                   (keyup.enter)="patchTeamName(i)">
                            <button class="btn green-btn-text"
                                    (click)="patchTeamName(i)">{{'save_button' | translate}}</button>
                          </div>
                          <div class="team-name" *ngIf="team['editName'] === false">
                            <p class="light" *ngIf="userPriorities.role.workspacePriority > 10"
                               style="cursor: pointer; color: #2551bf"
                               routerLink="/team/{{team['uuid']}}">
                              {{team.name}}
                            </p>
                            <p class="light" *ngIf="userPriorities.role.workspacePriority === 10">{{team.name}}</p>
                          </div>
                          <div class="team-type-container">
                            <p-dropdown
                              *ngIf="userPriorities.role.workspacePriority > 20 && team.type" tooltipStyleClass="tooltip-style" pTooltip="{{team.questionCollections[team.questionCollections.length-1].title}}"
                              [(ngModel)]="team.questionCollections[team.questionCollections.length-1]" [options]="teamTypes"
                              (onChange)="patchTeamType($event.value, team.uuid)"
                              dropdownIcon="bi bi-chevron-down" optionLabel="name">
                              <ng-template pTemplate="selectedItem">
                                <div class="sort-item sort-item-value">
                                  <div class="sort-icon-container">
                                    <img class="sort-icon" src="../../../assets/svg/team-{{teamType[i].icon}}.svg">
                                  </div>
                                  <span style="font-size: 1.6rem;">{{teamType[i].name}}</span>
                                </div>
                              </ng-template>
                              <ng-template let-teamType pTemplate="item">
                                <div class="d-flex align-items-center">
                                  <img *ngIf="team.type" class="team-type-icon"
                                       src="../../../assets/svg/team-{{teamType.icon}}.svg">
                                  <span style="margin-left: .8rem;font-size: 1.4rem;">{{teamType.name}}</span>
                                </div>
                              </ng-template>
                            </p-dropdown>
                            <div *ngIf="userPriorities.role.workspacePriority <= 20" class="team-type-for-members">
                              <div class="sort-icon-container">
                                <img class="sort-icon" src="../../../assets/svg/team-{{teamType[i].icon}}.svg">
                              </div>
                              <span style="font-size: 1.6rem;">{{teamType[i].name}}</span>
                            </div>
                          </div>
                          <p class="light" style="margin-bottom: 0;cursor: default">{{team['totalMembers']}} {{('user' | translate) | lowercase}}</p>
                          <div class="team-count" *ngIf="userPriorities.role.workspacePriority > 20">
                            <mat-icon [style]="{'color':'#2551BF', 'margin-bottom':'1rem'}"
                                      [matMenuTriggerFor]="teamsMenu">more_horiz
                            </mat-icon>
                            <mat-menu #teamsMenu="matMenu" xPosition="before">
                              <button mat-menu-item>
                                <span class="teams-menu-item"
                                      (click)="team['editName'] = true">{{'workspace_tab2_edit_team'| translate}}</span>
                              </button>
                              <button mat-menu-item>
                                <span class="teams-menu-item delete-button-hallow" (click)="showDelete(team, 'team')">Delete Team</span>
                              </button>
                            </mat-menu>
                            <!-- STYLE FOR THE DROPDOWN-->
                            <ng-template class="dropdown" #teamMembersPop>
                              <div class="organisation-roles" *ngFor="let member of teamMembers[team['uuid']]">
                                <p class="category-name light">
                                  {{member.user.detail['displayName'] | getDisplayName: member.user.detail['firstname']: member.user.detail['lastname']: member.user['email']}}
                                </p>
                                <p class="roles light">{{member.label?.name}}</p>
                              </div>
                              <div class="organisation-roles" *ngIf="[teamMembers[team['uuid']]].length === 0">
                                <p class="category-name medium">No users</p>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </p-tabPanel>
      <!--MEMBERS-->
      <p-tabPanel header="Header 3">
        <div class="col-lg-12 workspace-members">
          <div style="margin-bottom: 3rem">
            <p-card [ngClass]="{'settings-card-height': showMembersDetails}"
                    *ngIf="requests.length > 0">
              <div class="row">
                <h2 style="padding: 2rem">{{'workspace_tab3_role_request_title'| translate}}</h2>
                <div *ngFor="let req of requests" class="col-md-6">
                  <div class="request-users">
                    <div style="display: flex">
                      <img class="user-image" style="margin-right: 5px"
                           *ngIf="req.user && (req.user.avatar || req.user.detail.displayName)"
                           src="{{req.user.avatar ? data.baseUrl + '/uploads/user/'+ req.user.uuid + '/' + req.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                           data-holder-rendered="true">
                      <p
                        class="bd-highlight">{{req.user.detail.firstname| getDisplayName: req.user.detail.firstname: req.user.detail.lastname: req.user.email}}</p>
                    </div>
                    <!--                  <p>{{req.role.label}}</p>-->
                    <p>{{'workspace_tab3_role_request_message' | translate}} </p>
                    <div class="request-buttons">
                      <i class="bi bi-check-square" style="color:green;cursor: pointer; margin-right: 1rem"
                         (click)="respondRequest(req.uuid, 'accepted')"></i>
                      <i class="bi bi-x-square" style="color:red; cursor: pointer"
                         (click)="respondRequest(req.uuid, 'declined')"></i>
                    </div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
          <div class="workspace-members-table">
            <p-card [ngClass]="{'settings-card-height': showMembersDetails}">
              <div class="small-card-header justify-content-between">
                <h2>{{'workspace_tab3'| translate}}<span class="team-name-span" *ngIf="showMembersDetails"><i
                  class='bi bi-person-fill nav__icon'></i>({{totalMembers}})</span></h2>
                <div class="action-btns">
                  <button class="btn btn-hallow wm-add-member-btn" *ngIf="userPriorities.role.workspacePriority > 20"
                          (click)="modal.openMemberModal()"><i class='bi bi-plus-circle nav__icon'></i>{{('member_add' | translate) | titlecase}}
                    <!--                      (click)="modal.showInviteEmail()">Add Member-->
                  </button>
                </div>
              </div>
              <div class="inner-card">

                <div class="workspace-members-sh">
                  <h6>{{'my_account_my_teams_name'| translate}}</h6>
                  <h6 class="hide-mobile" [style]="{'margin-left':'5.6rem'}">Status</h6>
                  <h6>{{'wo_role'|translate}}</h6>
                  <h6 [style]="{'margin-left':'5.6rem'}">{{'workspace_tab2' | translate}}</h6>
                </div>
                <div #membersDiv class="members-div" *ngIf="showMembersDetails">
                  <div class="users-list" id="organisation"  (scrollend)="onScroll($event)">
                    <div [ngClass]="!(i % 2) ? 'team-list' : ''"
                         *ngFor="let member of workspaceUsers; let i = index">
                      <div class="" id="headingOne{{i}}">
                        <div class="members-block">
                          <div class="user-name">
                            <img *ngIf="member.user['avatar'] !== null && member.user['avatar'].path !== null"
                                 class="user-image"
                                 src="{{this.apiwrapper.baseUrl + member.user['avatar'].path}}">
                            <img *ngIf="member.user.avatar === null" class="user-image"
                                 src="../../../../assets/svg/user-icon.svg">
                            <!--                      <i  class='bi bi-person-fill nav__icon user-image'></i>-->

                            <p style="font-weight: 450;" class="light">
                              {{member.user.detail['displayName'] | getDisplayName: member.user.detail['firstname']: member.user.detail['lastname']: member.user['email']}}
                            </p>
                            <img class="star"
                                 *ngIf="member.user['workspaceRole'] !== null && member.user['workspaceRole'].name === 'workspace-owner'"
                                 src="../../../../assets/svg/leader_flag.svg">
                            <img class="star"
                                 *ngIf="member.user['workspaceRole'] !== null && member.user['workspaceRole'].name === 'workspace-hr'"
                                 src="../../../../assets/svg/triangle-badge.svg">
                            <!--                      <p-->
                            <!--                               [ngStyle]="data.isMobile ? {'float' : 'right', 'margin-top' : '6px', 'cursor' : 'pointer'} : {'float' : 'right', 'margin-top' : '6px', 'cursor' : 'pointer', 'margin-right' : '20px'}"-->
                            <!--                               (click)="data.showAlert( 'Email copied')"-->
                            <!--                               icon="email-outline" matTooltip="{{member.user.email}}"></p>-->
                          </div>
                          <div class="member-status">
                            <img src="../../../../assets/svg/workspace-members-mail.svg"
                                 pTooltip="{{member.user['email']}}" tooltipPosition="bottom" alt="email">
                            <p [style]="{'margin-left':'1.8rem'}" class="light hide-mobile">{{member.status}}</p>
                          </div>

                          <div class="workspace-member-role" *ngIf="member.user['workspaceRole'] !== null">
                            <p *ngIf="member.user['workspaceRole'].name === 'workspace-owner'" class="light"
                               style="margin-bottom: 0">{{'owner'|translate}}</p>
                            <!-- <p
                              *ngIf="member.user['workspaceRole'].name !== 'workspace-owner' && userPriorities.role.workspacePriority > 1"
                              class="light" style="margin-bottom: 0">{{member.user['workspaceRole'].label}}</p> -->
                            <!-- <ng-select style="cursor: pointer;"
                              *ngIf="userPriorities.role.workspacePriority > 10 && !(member.user['workspaceRole'].name === 'workspace-owner' || member.user.workspaceRole.name === 'workspace-hr')"
                                [clearable]="false"
                                [searchable]="false"
                                (change)="changeMemberRole(member.user, $event)"
                                placeholder="{{member.user['workspaceRole'].label}}">
                                  <ng-option *ngFor="let role of workspacePriority" [value]="role">{{role['label']}}</ng-option>
                            </ng-select> -->
                            <p
                              *ngIf="userPriorities.role.workspacePriority <= 20 && !(member.user['workspaceRole'].name === 'workspace-owner')"
                              class="light"
                              style="margin-bottom: 0">{{member.user.workspaceRole.label}}</p>
                            <p-dropdown
                              *ngIf="userPriorities.role.workspacePriority > 20 && !(member.user['workspaceRole'].name === 'workspace-owner')"
                              (onChange)="changeMemberRole(member.user, $event)" [options]="workspacePriority"
                              dropdownIcon="bi bi-chevron-down" optionLabel="label"
                              [style]="{'font-family':'Poppins, sans-serif'}"
                              [placeholder]="member.user.workspaceRole.label" [appendTo]="membersDiv"></p-dropdown>
                          </div>
                          <div [ngStyle]="{'cursor':'default'}" *ngIf="userPriorities.role.workspacePriority <= 20" class="workspace-member-teams">
                            <span>
                              {{member.user.startGuidTeamLeader + member.user.startGuideTeamMember}}
                              {{(member.user.startGuidTeamLeader + member.user.startGuideTeamMember) > 1 || (member.user.startGuidTeamLeader + member.user.startGuideTeamMember) === 0 ? 'teams' : 'team'}}
                            </span>
                          </div>
                          <div *ngIf="userPriorities.role.workspacePriority > 20" class="workspace-member-teams" #userTeamsMenuTrigger="matMenuTrigger"
                               [matMenuTriggerFor]="userTeamsMenu" (click)="getUserTeams(member.user.uuid, i)">
                            <span>
                              {{member.user.startGuidTeamLeader + member.user.startGuideTeamMember}}
                              {{(member.user.startGuidTeamLeader + member.user.startGuideTeamMember) > 1 || (member.user.startGuidTeamLeader + member.user.startGuideTeamMember) === 0 ? 'teams' : 'team'}}
                            </span>
                            <mat-menu class="user-teams-menu" #userTeamsMenu="matMenu" xPosition="after">
                              <ng-template matMenuContent>
                                <div *ngIf="!showSpinnerOnUserTeams[i]" class="spinner-border text-primary"
                                     role="status">
                                  <span class="sr-only"></span>
                                </div>
                                <div class="users-teams" *ngFor="let userTeam of member?.teams">
                                  <span *ngIf="showSpinnerOnUserTeams[i]" class="user-team-text"
                                        [routerLink]="'/team/'+userTeam.team['uuid']">{{userTeam.team.name}}</span>
                                </div>
                              </ng-template>
                            </mat-menu>
                          </div>

                          <div class="buttons" *ngIf="userPriorities.role.workspacePriority > 20">
                            <mat-icon *ngIf="member.user.workspaceRole.label !== 'Owner'"
                                      [style]="{'color':'#2551BF', 'margin-right':'1rem'}"
                                      [matMenuTriggerFor]="teamsMenu">more_horiz
                            </mat-icon>
                            <mat-menu #teamsMenu="matMenu" xPosition="before">
                              <button mat-menu-item disabled>
                                <span class="teams-menu-item">{{'workspace_tab3_transfer'| translate}}</span>
                              </button>
                              <button mat-menu-item>
                                <span class="teams-menu-item delete-button-hallow"
                                      (click)="showDelete(member, 'user')">{{'workspace_tab3_delete'| translate}}</span>
                              </button>
                            </mat-menu>
                            <!-- <button class="btn more-dropdown" type="button"
                                    [disabled]="member.user['workspaceRole']['name'] === 'workspace-owner' || member.user['workspaceRole']['name'] === 'workspace-hr'"
                                    *ngIf="showMembersDetails && userPriorities.role.workspacePriority > 10"
                                    nbPopoverPlacement="bottom"></button>
                            <ng-template #memberPop>
                              <p *ngIf="member.user.workspaceRole.label !== 'Owner'"
                                 (click)="deleteWorkspaceMember(member.user['uuid'])">Delete Member</p>
                            </ng-template> -->
                          </div>
                        </div>
                      </div>
                      <div id="collapseOne{{i}}" class="collapse" aria-labelledby="headingOne"
                           data-parent="#organisation">
                        <div class="card-body">
                          <div class="organisation-roles" *ngFor="let member of memberTeams[member['uuid']]">
                            <p class="category-name light">{{member.user['team']['name']}} <span class="role"></span>
                            </p>
                            <p class="roles light">{{member.user.label?.name}}</p>
                          </div>
                          p
                          <div class="organisation-roles" *ngIf="[memberTeams[member.user['uuid']]].length === 0">
                            <p class="category-name">No teams</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h6 class="pending-members-title" *ngIf="pending.length > 0">{{'team_pending'|translate}}</h6>
                    <div class="card" *ngFor="let user of pending; let i = index">
                      <div class="card-header" id="pending{{i}}">
                        <div class="positions-block">
                          <div class="user-name">
                            <p class="">{{user['email']}}</p>
                          </div>
                          <div class="display-name">Ω
                            <p
                              class="">{{user['creator']['detail']['displayName'] | getDisplayName: user['creator']['detail']['firstname']: user['creator']['detail']['lastname']}}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div id="collapsePending{{i}}" class="collapse" aria-labelledby="headingTeams"
                           data-parent="#teams">
                        <div class="card-body">
                          <p>rol change select</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin: auto; width: max-content;">
                    <div class="spinner-border text-primary" role="status"  style="position: absolute; height: 3rem!important; width: 3rem!important;" *ngIf="loadMembers"></div>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Header 4">
        <div class="col-lg-12 workspace-activities">
          <p-card [ngClass]="{'settings-card-height': showMembersDetails}">

            <div class="inner-card">
              <div class="workspace-activities-header">
                <h6>Modified by</h6>
                <h6 class="hide-mobile">Action</h6>
                <h6>Date</h6>
              </div>
              <div *ngIf="showMembersDetails">
                <div class="activities-list" id="organisation">
                  <div [ngClass]="!(i % 2) ? 'team-list' : ''"
                       *ngFor="let activity of workspaceActivities; let i = index">
                    <div class="" id="headingOne{{i}}">
                      <div class="activity-block">
                        <div class="user-name">
                          <p-avatar
                            [image]="activity['admin']['avatar'] !== null && activity['admin']['avatar']['path'] !== null ? baseUrl + activity['admin']['avatar']['path'] : '../../../assets/svg/user-icon.svg'"
                            size="xlarge" shape="circle"></p-avatar>
                          <span
                            [style]="{'margin-left':'1rem', 'font-size':'1.6rem'}">{{activity['admin']['detail']['displayName']}}</span>
                        </div>
                        <p class="light hide-mobile">{{activity['actionText']}}</p>
                        <p [style]="{'margin-left':'1.9rem'}"
                           class="light hide-mobile">{{activity['created']['date'] | date}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>

  <div class="row" style="background-color: #f0f3fa">
    <!-- TEAMS -->


    <!-- USERS -->

  </div>
</div>
