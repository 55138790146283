import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApiwrapperService} from './apiwrapper.service';
import {StripeService} from 'ngx-stripe';
import {DataManipulationService} from './data-manipulation.service';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  private billingService = new BehaviorSubject(false);
  billingDone = this.billingService.asObservable();

  downgradeModalPlan = '';
  downgradeModalDate = '';
  downgradeModalText = '';

  constructor(public apiwrapper: ApiwrapperService, private stripeService: StripeService,
              public data: DataManipulationService) {
  }

  // add listeners for payment
  // after the intent is confirmed send true
  billingAccepted(value: boolean) {
    this.billingService.next(value);
  }

  addPaymentMethod() {
    this.data.showLoading();
    this.apiwrapper.postBillingMethod('billing').subscribe(
      res => {
        this.stripeService.redirectToCheckout({sessionId: res.stripe.sessionId}).subscribe(
          result => {
            this.data.hideLoading();
          }, err => {
            console.log(err);
            this.data.hideLoading();
            this.data.showAlertError('Cannot add payment method!');
          }
        );
      },
      err => {
        console.log(err);
        this.data.hideLoading();
        this.data.showAlertError('Cannot add payment method!');
      }
    );
  }

  setText(noLimit: any) {
    if (noLimit){
      this.downgradeModalText = `You won't be able to follow your teams' development over time, and you'll be limited in the way you setup your feedback collectors. Your ` + this.downgradeModalPlan + ` subscription will instantly be stopped, and you'll be charged for period ` + this.downgradeModalDate + ` until today.`;
    } else {
      this.downgradeModalText = `Your choice will freeze your current teams' data and lock the chart of improvements. You won't be able to invite more people into the workspace or make new teams. Your ` + this.downgradeModalPlan + ` subscription will instantly be stopped, and you'll be charged for period ` + this.downgradeModalDate + ` until today.`;
    }
  }
}
