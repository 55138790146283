import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-scaled-question-distribution',
  templateUrl: './scaled-question-distribution.component.html',
  styleUrls: ['./scaled-question-distribution.component.scss']
})
export class ScaledQuestionDistributionComponent implements OnInit {

  @Input() responseDistributionObs: Observable<any> | undefined;

  responseDistribution: any;
  circleResponses: {title: string, value: number}[] = [];
  mainTitle: number = 0;
  devidedCounter: number = 0;
  userLanguage: string = '';

  constructor() {
    //@ts-ignore
    this.userLanguage = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag.split("_")[0];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.responseDistributionObs?.subscribe(res => {
      if (res) {
        this.responseDistribution = res;
        this.getResponses(true);
        this.getMainTitle();
      }
    });
  }

  ngOnInit(): void {
  }

  getResponses(firstTime: boolean) {
    if(firstTime && this.circleResponses.length === 0) {
      Object.keys(this.responseDistribution?.responses).forEach(el => {
        this.circleResponses.push({title: el, value: this.responseDistribution?.responses[el]});
        if(this.responseDistribution?.responses[el] >= 10) {
          this.devidedCounter++;
        }
      });
    } else {
      this.circleResponses = [];
      this.devidedCounter = 0;
      Object.keys(this.responseDistribution?.responses).forEach(el => {
        this.circleResponses.push({title: el, value: this.responseDistribution?.responses[el]});
        if(this.responseDistribution?.responses[el] >= 10) {
          this.devidedCounter++;
        }
      });
    }
  }

  getMainTitle() {
    this.mainTitle = 0;
    const sameDisagree = this.responseDistribution.responses[this.userLanguage === 'en' ? 'Totally disagree':'Helt uenig'] + this.responseDistribution.responses[this.userLanguage === 'en' ? 'Mostly disagree':'Meget uenig'];
    const sameAgree = this.responseDistribution.responses[this.userLanguage === 'en' ? 'Totally agree':'Helt enig'] + this.responseDistribution.responses[this.userLanguage === 'en' ? 'Mostly agree':'Meget enig'];
    if(sameDisagree >= 70 || sameAgree >= 70) {
      this.mainTitle = 1;
    } else if(sameDisagree >= 35 && sameAgree >= 35) {
      this.mainTitle = 2;
    } else if (this.devidedCounter >= 5) {
      this.mainTitle = 3;
    } else {
      this.mainTitle = this.responseDistribution.eligibleForResult ? 5 : 4;
    }
  }

}
