import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {resolve} from "@angular/compiler-cli";

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss']
})
// @ts-ignore
export class ModalsComponent implements OnInit {
  createWorkspaceForm = new FormGroup({
    workspaceName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });
  workspaceCreator: any = {};
  showWorkspaceCreator: boolean = false;
  constructor(public apiwrapper: ApiwrapperService, public data: DataManipulationService, private router: Router) { }

  ngOnInit(): void {
  }

  openMemberModal() {
    this.data.getWorkspaceInv();
    // set workspace invitation
    // @ts-ignore
    let workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    this.data.inviteMessage = workspace.activeInvitationBody.body;
    // @ts-ignore
    document.getElementById('inviteModal').click();
    setTimeout(() => {
      // @ts-ignore
      if(document.getElementById(`inviteEmail`) !== null) {
        document.getElementById(`inviteEmail`)?.focus();
      }
    }, 500)
  }

  openTeamModal() {
    // @ts-ignore
    document.getElementById('teamsModal').click();
  }

  openOrganizerModal() {
    // @ts-ignore
    document.getElementById('organizeMembersModal').click();
  }

  openCollectorModal() {
    // @ts-ignore
    document.getElementById('collectorModal').click();
  }

  openWorkspaceUpgradeModal() {
    // @ts-ignore
    document.getElementById('workspaceUpdrageModalButton').click();
  }

  openUpgradeWorkspaceModal() {
    document.getElementById('contentSuccessful1')?.click();
  }

  goToBilling() {
    document.getElementById('closeWorkspaceUpdrageModal')?.click();
    this.data.lastRouteBeforeBilling = this.router.url;
    this.router.navigate(['/settings/billing']);
  }

  goToDashboard() {
    const workspaceUpdrageModalButton = document.getElementById('closeWorkspaceUpdrageModal');
    workspaceUpdrageModalButton?.click();
    this.router.navigate(['/']);
  }

  goToDashboardPlan() {
    // TODO REDIRECT
    if(this.data.lastRouteBeforeBilling === 'menu') {
      this.closeHurrayModal();
      this.router.navigate(['/']);
    } else {
      this.closeHurrayModal();
      this.router.navigate([this.data.lastRouteBeforeBilling]);
    }
  }

  closeHurrayModal() {
    document.getElementById('closeSuccessfulModal')?.click();
  }

  createWorkspace(event:any) {
    this.data.showLoading();
    if (this.createWorkspaceForm.valid) {
      // check if workspace exits
      this.apiwrapper.checkWorkspace(this.createWorkspaceForm.value.workspaceName).subscribe(
        res => {
          // create workspace
          this.apiwrapper.postCreateWorkspace(this.createWorkspaceForm.value.workspaceName).subscribe(
            r => {
              this.apiwrapper.patchWorkspace(r.uuid, {onboardingStep: 'done'})
                // tslint:disable-next-line:no-shadowed-variable
                .subscribe(res => {
                  this.data.hideLoading();
                  document.getElementById('closeCreateWorkspaceModal')?.click();
                  this.data.showAlert('Your workspace was created successfully.');
                }, err => {
                  this.data.hideLoading();
                  this.data.showAlertError(err.error.error.messages[0]);
                });
            },
            err => {
              this.data.hideLoading();
              if (err.status === 401) {
                // this.apiwrapper.refreshToken();
              }
              this.data.showAlertError(err.error.error.messages[0]);
            }
          );
        }, err => {
          this.data.hideLoading();
          this.data.showAlertError(err.error.error.messages[0]);
        }
      );
    } else {
      this.data.hideLoading();
      this.data .showAlertError('Please choose a name for your workspace.');
    }
  }

  openAdminRequestModal() {
    // @ts-ignore
    let creator = JSON.parse(localStorage.getItem('WORKSPACE')).creator;
    if (creator.avatar !== null) {
      this.data.workspaceCreatorImage = creator.avatar.path;
    }
    this.data.workspaceOwnerName = creator.detail.firstname;
    this.showWorkspaceCreator = true;
    document.getElementById('roleRequestModal')?.click();
    this.showWorkspaceCreator = true;
  }

  requestRole() {
    this.data.showLoading()
    this.apiwrapper.postRequestRole(this.data.roleWorkspaceAdmin).subscribe(
      resolve => {
        this.data.hideLoading();
        this.data.showAlert('Request administrator role sent');
        document.getElementById('closeRequestModal')?.click();
      }, error => {
        this.data.hideLoading();
        console.log(error)
      }
    )
  }
}
