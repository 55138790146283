<button id="questionModal" [hidden]="true" data-toggle="modal" data-target="#feedbackQuestionsModal"></button>

<div class="modal fade" id="feedbackQuestionsModal" tabindex="-1" role="dialog" aria-hidden="true"
     data-backdrop="static" data-keyboard="false"
     aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div *ngIf="choseImmediateLeader" class="modal-content immediate-leader-modal-content">
      <img class="logo" src="../../../assets/images/successteam-icon.svg" style="margin: 3rem 0 0 3rem;">
      <div class="modal-body immediate-leader-modal-body row">
        <div class="col-md-6 d-flex flex-column immediate-leader-side-text" style="padding: 8rem;">
          <span class="immediate-leader-header-title" 
            [innerHTML]="selectedCurrentImmediateLeader ? 
              ('immediate_leader_title3' | translate: 
                {leader: selectedCurrentImmediateLeader.detail.displayName ? selectedCurrentImmediateLeader.detail.displayName : selectedCurrentImmediateLeader.detail.firstname ? 
                  selectedCurrentImmediateLeader.detail.firstname +' '+ selectedCurrentImmediateLeader.detail.lastname : selectedCurrentImmediateLeader.email, 
                teamName: teamName,
                avatar: selectedCurrentImmediateLeader.avatar != null ? api.baseUrl + selectedCurrentImmediateLeader.avatar?.path : '../../../assets/svg/user-icon.svg'}) : 
              ('immediate_leader_title1' | translate)">
          </span>
          <div *ngIf="currentImmediateLeader" class="imm-leader-tooltip">
            <span>{{selectedCurrentImmediateLeader?.email}}</span>
          </div>
          <span class="immediate-leader-header-subtitle" [innerHTML]="selectedCurrentImmediateLeader ? ('immediate_leader_subtitle2' | translate) : ('immediate_leader_subtitle' | translate)"></span>
        </div>
        <div class="immediate-leader-row-container col-md-6">
          <div class="leaders-from-team" [style]="showChangeImmediateLeader ? {'height':'auto'}: ''">
            <div class="leaders-from-team-header">
              <img style="padding-right: 13px" src="../../../assets/svg/immediate-leader-crown.svg">
              <span>{{showChangeImmediateLeader ? ('immediate_leader_from_team2' | translate) : ('immediate_leader_from_team1' | translate)}}</span>
            </div>
            <div *ngIf="!showChangeImmediateLeader && immediateLeadersFromTeam?.length > 0" class="leaders-from-team-body" [style]="immediateLeadersFromTeam?.length > 2 ? {'overflow-y':'scroll'}:{}">
              <div class="from-team-leader-container" *ngFor="let leader of immediateLeadersFromTeam, let i = index, let first = first">
                <input class="form-check-input" type="checkbox" [(ngModel)]="checkedInLeader[i]" (ngModelChange)="pickImmediateLeader($event, leader, i)">
                <div class="from-team-leader" (click)="pickImmediateLeader($event, leader, i)">
                  <img class="from-team-leader-picture" [src]="leader.avatar != null ? api.baseUrl + leader.avatar?.path : '../../../assets/svg/user-icon.svg'">
                  <span>{{leader.detail.displayName ? leader.detail.displayName : leader.detail.firstname ? leader.detail.firstname +' '+ leader.detail.lastname : leader.email}}</span>

                </div>
              </div>
            </div>
            <div *ngIf="showChangeImmediateLeader" class="leaders-from-team-body">
              <div class="from-team-leader-container">
                <div class="from-team-leader">
                  <img class="from-team-leader-picture" [src]="selectedImmediateLeader.avatar != null ? api.baseUrl + selectedImmediateLeader.avatar.path : '../../../assets/svg/user-icon.svg'">
                  <span>{{selectedImmediateLeader.email}}</span>
                  <div (click)="showChangeImmediateLeader = !showChangeImmediateLeader" class="immediate-leader-change">Change</div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!showChangeImmediateLeader && immediateLeadersOutsideTeam?.length > 0" class="leaders-outside-team" [style]="immediateLeadersOutsideTeam?.length > 2 ? {'overflow-y':'scroll'}:{}">
            <div class="leaders-outside-team-header">
              <img style="padding-right: 13px" src="../../../assets/svg/user.svg">
              <span>{{'immediate_leader_outside_team' | translate}}</span>
            </div>
            <div class="leaders-from-team-body">
              <div class="from-team-leader-container" *ngFor="let outside of immediateLeadersOutsideTeam, let i = index">
                <input class="form-check-input" type="checkbox" [(ngModel)]="checkedInLeader[immediateLeadersFromTeam.length + i]" (ngModelChange)="pickImmediateLeader($event, outside, immediateLeadersFromTeam.length + i)">
                <div class="outside-team-leader" (click)="pickImmediateLeader($event, outside, immediateLeadersFromTeam.length + i)">
                  <img class="from-team-leader-picture" [src]="outside.avatar != null ? api.baseUrl + outside.avatar?.path : '../../../assets/svg/user-icon.svg'">
                  <span>{{outside.detail.displayName ? outside.detail.displayName : outside.detail.firstname ? outside.detail.firstname +' '+ outside.detail.lastname : outside.email}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer immediate-leader-footer">
        <button [ngClass]="currentImmediateLeader ? 'choose-current-imml' : 'btn btn-primary pf-modal-footer-payment-btn'" (click)="setUserImmediateLeader()">
          {{currentImmediateLeader ? ('immediate_leader_confirm_button2' | translate) : !currentImmediateLeader && selectedCurrentImmediateLeader ? ('immediate_leader_confirm_button_changes' | translate): ('immediate_leader_confirm_button' | translate)}}</button>
      </div>
    </div>
    <div *ngIf="!choseImmediateLeader" class="modal-content feedback-questions-modal-content" [ngStyle]="{'background-color':'transparent'}">
      <div class="modal-header-tip">
        <div class="iml-question-tooltip" [ngStyle]="showImmediateLeaderTooltip ? {'display':'flex'}:{}"><i class="bi bi-question-circle-fill text-warning iml-question-icon"></i>{{'immediate_leader_tooltip' | translate}}</div>
        <div class="immediate-leader-small-container">
          <img style="padding-right: 6px" src="../../../assets/svg/immediate-leader-crown.svg">
          <span>{{('immediate_leader_from_team2' | translate)}}</span>
          <div class="selected-immediate-leader">
            <img class="selected-immediate-leader-picture" [src]="selectedImmediateLeader?.avatar != null ? api.baseUrl + selectedImmediateLeader?.avatar.path : '../../../assets/svg/user-icon.svg'">
            <span>{{selectedImmediateLeader?.detail.displayName ? selectedImmediateLeader?.detail.displayName : selectedImmediateLeader?.detail.firstname ? selectedImmediateLeader?.detail.firstname +' '+ selectedImmediateLeader?.detail.lastname : selectedImmediateLeader?.email}}</span>
          </div>
        </div>
      </div>
      <div class="modal-header feedback-questions-modal-header">
        <div class="title-container">
          <img class="logo" src="../../../assets/images/successteam-icon.svg">
          <h2>{{'answer_h1'| translate}} {{teamName}},</h2>
          <button [ngStyle]="{'color':'#2551bf'}" id="questionModalClose" type="button"
                class="btn close modal-close-button" data-dismiss="modal" (click)="closeModal()"><span
            aria-hidden="true">&times;</span></button>
          <button id="questionClose" type="button" data-dismiss="modal" style="display: none"></button>
        </div>
      </div>
      <form [formGroup]="feedbackForm">
        <div class="modal-body feedback-questions-modal-body" [ngStyle]="showStatementReview ? {'flex-direction':'row', 'justify-content': 'center'}:{}">
          <div class="feedback-question-container" [ngClass]="showStatementReview ? 'feedback-question-container-not-applicable':''">
            <!-- <span class="span-statement-not-applic" *ngIf="showStatementReview">{{'not_applicable_cant_answer' | translate}}</span> -->
            <span
              class="feedback-question">"{{fromPreviousQuestion ? lastQuestion.title : currentQuestion?.title}}"</span>
          </div>

          <div class="feedback-answer-container" *ngIf="!showStatementReview">
            <div class="custom-slider">
              <ngx-slider formControlName="answer" [(value)]="value" [options]="options"></ngx-slider>
            </div>
            <span class="not-applicable" *ngIf="!fromPreviousQuestion && !showStatementReview"
                      (click)="showStatementReview = !showStatementReview; addReview = false">{{('not_applicable_button') | translate}}</span>
          </div>

          <div *ngIf="showStatementReview" class="statement-review-container">
            <div class="feedback-question-container" style="margin: 0; padding: 0;width: 100%; justify-content: flex-start;">
              <!-- <span class="not-applicable-tip" style="font-size: 1.2rem"><img style="margin: -2px .8rem 0 0;" src="../../../assets/svg/info_icon.svg">{{'not_applicable_subtitle'| translate}}</span> -->
              <h3 style="font-size: 1.8rem; font-weight: 500; margin: 0;">{{ (addReview ? 'not_applicable_title2' : 'not_applicable_title') | translate}}</h3>
              <div class="w-dl-questionmark-container">
                <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                <div class="workspace-dl-tooltip">{{'not_applicable_subtitle'|translate}}</div>
              </div>
            </div>
            
            <div *ngIf="!addReview" class="not-applicable-statements-container">
              <div class="not-applicable-statement" *ngFor="let review of data.notApplicable">
                <input class="not-applicable-check" type="checkbox" (change)="giveReason(currentQuestion.uuid, review.uuid)">
                <span>{{review.reason}}</span>
              </div>
              <div class="not-applicable-statement">
                <input class="not-applicable-check" type="checkbox" (change)="giveReason(currentQuestion.uuid, '')">
                <span>{{'other_button' | translate}}</span>
              </div>
            </div>
            <div *ngIf="addReview" style="display: grid; width: 100%;">
              <textarea class="task-description-input not-applicable-textarea"
                        formControlName="newReview" (keydown.enter)="addNewReview(currentQuestion)"
                        placeholder="..." type="text" autofocus></textarea>
              <div class="d-flex" style="margin-top: 3rem; gap: 2rem">
               <button class="btn btn-primary pf-modal-footer-payment-btn" (click)="addNewReview(currentQuestion)">{{'submit_button'|translate}}</button>
               <span class="not-applicable-back-arrow" *ngIf="!fromPreviousQuestion && showStatementReview"
                      (click)="addReview = !addReview"><i class="bi bi-arrow-left"></i></span>
              </div>
            </div>
            <span class="not-applicable-cancel-button" *ngIf="!fromPreviousQuestion && showStatementReview"
                      (click)="notApplicableBackButton()">{{('cancel_button') | translate}}</span>
          </div>
        </div>

        <div class="modal-footer justify-content-start align-items-center feedback-questions-footer" [ngStyle]="{'background-color':'#ffffff'}">
          <span class="question-counter">{{'answer_p1_1'| translate}} {{this.currentPollDetails?.maxStatements - questionNumber}}
            {{'answer_p1_2'| translate}}</span>
          <div class="container question-counter-container">
            <div class="progress feedback-question-progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                   [style]="{'width': feedbackQuestionProgress}" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center answer-buttons-container" *ngIf="!showStatementReview">
            <button (click)="goToPreviousQuestion()" [disabled]="disableButton"
                    [style]="previousQuestionButton ? {'display':'none'}:{'display':'inline-block'}"
                    class="btn btn-primary previous-question-btn">
<!--              <div *ngIf="disableButton" class="spinner-border text-primary" role="status">-->
<!--                <span class="sr-only"></span>-->
<!--              </div>-->
              {{'previous_button' | translate}}
            </button>
            <button (click)="fromPreviousQuestion ? editPreviousAnswer() : answerQuestion()"
                    class="btn btn-primary pf-modal-footer-payment-btn" [disabled]="disableButton">
              <div *ngIf="disableButton" class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
              </div>
              {{(this.currentPollDetails?.maxStatements - questionNumber > 1 ? 'next_button' : 'finish_button' )| translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

