<div class="main-container">
    <div class="container-header">
        <h2 style="font-size: 2.4rem;">{{'custom_poll_main_reporting_categories_title_onboarding' | translate}}</h2>
        <!-- <div class="user-teams-dropdown">
            <p-dropdown [(ngModel)]="sortByTeam" [options]="userTeams" (onChange)="selectTeamsForQuestions($event)"
                tooltipPosition="left" dropdownIcon="bi bi-caret-down-fill" optionLabel="name">
               <ng-template pTemplate="selectedItem">
                <div class="sort-item sort-item-value">
                  <div>{{sortByTeam?.name}}</div>
                </div>
              </ng-template>
              <ng-template let-sort pTemplate="item">
                <div class="sort-item">
                  <div>{{sort.name}}</div>
                </div>
              </ng-template>
            </p-dropdown>
        </div> -->
    </div>
    <div class="categories-and-statements-container">
        <div class="category-container" *ngFor="let checkin of reportingCategories, let index = index">
            <p-accordion 
                (onOpen)="emitCategoryStatements(checkin.collection.uuid, index)" 
                [ngClass]="checkin.color+'-shadow'" collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down">
                <p-accordionTab [selected]="showSelectedTabCateg[index]">
                    <ng-template pTemplate="header">
                        <h2 style="font-size: 1.6rem;margin-bottom: 0;">{{checkin.collection.title}}</h2>
                        <!-- <p-chart type="doughnut" [data]="data" [style]="{'width': '40%'}"></p-chart> -->
                        <div class="category-actions-number-container">
                            <img [src]="checkin.tasks > 0 ? '../../../assets/svg/action-active.svg':'../../../assets/svg/action-inactive.svg'">
                            <span>({{checkin.tasks}})</span>
                        </div>
                        <div class="category-responses-number-container">
                            <div class="hipchat">
                                <i class="bi bi-chat-fill"></i>
                            </div>
                            <span>({{checkin.questions}})</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="spinner-border text-primary categ-statement-spinner" role="status" *ngIf="!hasCategoryStatements"></div>
                        <div class="statements-main-container" *ngIf="hasCategoryStatements">
                            <div class="statement-container" *ngFor="let statement of categoriesStatements, let index = index">
                                <p-accordion *ngIf="categoriesStatements.length"
                                    collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down"
                                    (onOpen)="emitStatementResponses(statement.type, statement.uuid, index)"
                                    (onClose)="clearStatements()">
                                    <p-accordionTab [selected]="showSelectedTabState[index]">
                                        <ng-template pTemplate="header">
                                            <img *ngIf="statement.type === 'open'" src="../../../../../assets/svg/statement-icon-gray.svg">
                                            <img *ngIf="statement.type !== 'open'" src="../../../../../assets/svg/statement-icon-{{statement?.color}}.svg">
                                            <h2 style="font-size: 1.6rem;margin:0 0 0 1rem;" [innerHTML]="statement.title"></h2>
                                        </ng-template>
                                        <ng-template *ngIf="statement.type === 'open'" pTemplate="content">
                                            <div class="statement-responses-count">
                                                <span>{{statementResponseCount}} responses</span>
                                            </div>
                                            <div class="open-qs-responses-container" #openQuestionsContainer>
                                                <div class="response-container" *ngFor="let response of statementResponses">
                                                    <!-- <div *ngIf="currentUserRole >= 20" class="d-flex align-items-start">
                                                        <i style="font-size: 1.6rem;color: #C5CEE3;" class="bi bi-quote"></i>
                                                        <span style="color: #A2A2A2;font-size: 1.2rem;margin-left: .78rem;">{{response.user.email}}</span>
                                                    </div> -->
                                                    <span #content class="open-qs-response-title">{{response.response.title}}</span>
                                                    <div *ngIf="content.scrollHeight > 50" class="d-flex align-items-center">
                                                        <span class="show-more-button"
                                                          (click)="openResponsePreviewModal(response)">{{'custom_poll_main_reporting_show_more_btn' | translate}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="statementResponseCount !== 0 && statementResponsePage > 1" class="see-more-responses-btn">
                                                <p-paginator [rows]="1" [totalRecords]="statementResponsePage" (onPageChange)="changePage($event, statement.type, statement.uuid)"></p-paginator>
                                            </div>
                                        </ng-template>
                                        <ng-template *ngIf="statement.type === 'scaled'" pTemplate="content">
                                            <h4 style="margin-bottom: 0;" *ngIf="!statement.eligibleForScore">{{'insights_blue_statement' | translate}}</h4>
                                            <app-scaled-question-distribution *ngIf="statement.eligibleForScore" 
                                                [responseDistributionObs]="responseDistribution">
                                            </app-scaled-question-distribution>
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                        </div>
                        <div class="vr"></div>
                        <!-- <div *ngIf="hasCategoryStatements" class="create-action-container">
                            <h2 style="font-size: 2.4rem;">Actions</h2>
                            <p-tabView>
                                <p-tabPanel header="{{'custom_poll_create_action_btn1' | translate}}">
                                    <ng-template pTemplate="content">
                                        <form [formGroup]="actionForm" class="create-action-form">
                                            <div class="action-title-input-container">
                                                <img src="../../../../../assets/svg/action-inactive.svg">
                                                <input placeholder="{{'custom_poll_create_action_form_title_placeholder' | translate}}" class="action-title-input" formControlName="actionTitle" type="text">
                                            </div>
                                            <textarea class="action-description-input" formControlName="actionDescription" type="text" placeholder="{{'custom_poll_create_action_form_description' | translate}}"></textarea>
                                        </form>
                                        <div class="action-due-date-container">
                                            <span class="due-date-span">{{'custom_poll_create_action_deadline' | translate}}</span>
                                            <div class="start-date">
                                                <img class="calendar-icon" src="../../../../../assets/images/calendar-icon-gray.svg">
                                                <p-calendar placeholder="{{'custom_poll_create_action_select_date' | translate}}" class="calendar-picker" [(ngModel)]="actionDueDate" [minDate]="minDateValue" dateFormat="M.dd.yy"></p-calendar>
                                            </div>
                                        </div>
                                        <div class="action-assignee-container">
                                            <span class="assignee-span">{{'custom_poll_create_action_assigned_to' | translate}}</span>
                                            <div class="assignee-user-container">
                                                <img class="assignee-icon" src="../../../../../assets/svg/user-icon-gray.svg">
                                                <div class="assignee-dropdown">
                                                    <p-dropdown [(ngModel)]="actionAsignee" [options]="membersForSearch" placeholder="{{'custom_poll_create_action_assigned_search' | translate}}" (onChange)="setAssigneeForAction($event)"
                                                        tooltipPosition="left" dropdownIcon="bi bi-caret-down-fill" optionLabel="name">
                                                      <ng-template pTemplate="selectedItem">
                                                        <div class="sort-item sort-item-value">
                                                            <img class="action-assigned-user-icon"
                                                                src="{{(actionAsignee.avatar !== null && actionAsignee.avatar?.path !== null) ? this.data.baseUrl + actionAsignee.avatar?.path: '../../../../../assets/svg/user-icon.svg'}}">
                                                            <div>{{actionAsignee?.name}}</div>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template let-sort pTemplate="item">
                                                        <div class="sort-item">
                                                            <img class="action-assigned-user-icon"
                                                                src="{{(sort.avatar !== null && sort.avatar?.path !== null) ? this.data.baseUrl + sort.avatar?.path: '../../../../../assets/svg/user-icon.svg'}}">
                                                            <div>{{sort.name}}</div>
                                                        </div>
                                                    </ng-template>
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <button class="create-action-button"
                                            pButton type="button" 
                                            label="{{'custom_poll_create_action_create_btn' | translate}}"
                                            (click)="createAction(category)"></button>
                                        </div>
                                    </ng-template>
                                </p-tabPanel>
                                <p-tabPanel [disabled]="!categoryActionsArray.length" header="{{('custom_poll_create_action_btn2' | translate) + (categoryActionsArray.length ? '('+categoryActionsArray.length+')':'')}}">
                                    <ng-template pTemplate="content">
                                        <div class="assigned-actions-container">
                                            <div *ngFor="let action of categoryActionsArray" class="assigned-actions">
                                                <div class="assigned-action-header">
                                                    <div class="d-flex align-items-center gap-3">
                                                        <img src="../../../../../assets/svg/action-active.svg">
                                                        <h2 style="font-size: 1.6rem;">{{action.title}}</h2>
                                                    </div>
                                                    <span style="font-size: 1.6rem;color: #A2A2A2;white-space: pre-wrap;">{{action.description}}</span>
                                                </div>
                                                <hr class="solid">
                                                <div class="assigned-to-container">
                                                    <span style="color: #A2A2A2;font-size: 1.4rem;">{{'custom_poll_create_action_assigned_to' | translate}}</span>
                                                    <div class="d-flex align-items-center gap-3">
                                                        <img style="width: 3.6rem;height: 3.6rem;border-radius: 50%;" [src]="action.assignee.avatar !== null ? this.data.baseUrl+ action.assignee.avatar.path:'../../../../../assets/svg/user-icon.svg'" alt="user">
                                                        <span style="font-size: 1.4rem;">{{ action.assignee.detail.displayName ? action.assignee.detail.displayName : 
                                                            action.assignee.detail.firstname ? action.assignee.detail.firstname + action.assignee.detail.lastname : 
                                                            action.assignee.email}}</span>
                                                    </div>
                                                </div>
                                                <div class="assigned-due-date-container">
                                                    <span style="color: #A2A2A2;font-size: 1.4rem;">{{'custom_poll_create_action_deadline' | translate}}</span>
                                                    <span style="font-size: 1.4rem;">{{action.dueDate.date | date: 'dd.MM.yyyy'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </p-tabPanel>
                            </p-tabView>
                        </div> -->
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>
</div>