<!-- CREATE WORKSPACE -->

<div class="modal fade" id="createWorkspace" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Let's create a new workspace!</h3>
        <img src="assets/svg/close-button.svg" id="closeCreateWorkspaceModal" alt="X" data-bs-dismiss="modal"
             aria-label="Close"
             style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="createWorkspaceForm" (ngSubmit)="createWorkspace($event)">
              <p class="big">You can invite members later.</p>
              <label class="form-label">Workspace Name</label>
              <input class="form-control" formControlName="workspaceName" type="text">
              <button class="btn green-btn" style="margin-top: 30px" type="submit">Finish</button>
            </form>
          </div>
          <!--        <div class="col-lg-4 col-md-4 image-block">-->
          <!--          <img src="../../assets/images/extra_woman2@2x.png" style="width: 70%; display: block; margin: auto;">-->
          <!--        </div>-->
        </div>
      </div>
    </div>
  </div>
</div>
<button type="button" id="createWorkspaceModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#createWorkspace">
</button>


<!-- Invite members -->
<button type="button" id="inviteModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#inviteBackdrop">
</button>

<div class="modal fade" id="inviteBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel">{{'start_guide_step1'| translate}}</h3>
        <img src="assets/svg/close-button.svg" id="closeInviteModal" alt="X" data-bs-dismiss="modal" aria-label="Close"
             style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <app-add-members></app-add-members>
      </div>
    </div>
  </div>
</div>

<!-- Create teams -->
<button type="button" id="teamsModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#teamBackdrop">
</button>

<div class="modal fade" id="teamBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel">{{'createteam_title' | translate}}</h3>
        <img src="assets/svg/close-button.svg" id="closeCreateTeamModal" alt="X" data-bs-dismiss="modal"
             aria-label="Close"
             style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <app-add-teams></app-add-teams>
      </div>
    </div>
  </div>
</div>


<!-- Organize team members -->
<button type="button" id="organizeMembersModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#organizeBackdrop">
</button>

<div class="modal fade" id="organizeBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen" style="height: auto">
    <div class="modal-content organize-team">
      <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel">{{'manage_title'|translate}}</h3>
        <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
             (click)="data.goToTeamCreated()"
             id="closeOrganizeModal" style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <app-organize-teams style="height: 50rem;"></app-organize-teams>
      </div>
    </div>
  </div>
</div>


<!-- Setup collectors -->
<button type="button" id="collectorModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#collectorBackdrop">
</button>

<div class="modal fade collector-modal" id="collectorBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="staticBackdropLabel"></h3>
        <img src="assets/svg/close-button.svg" id="closeFeedbackLoopModal" alt="X" data-bs-dismiss="modal"
             aria-label="Close"
             style="margin: 0 0 3rem 0; cursor: pointer">
      </div>
      <div class="modal-body">
        <app-collector></app-collector>
      </div>
    </div>
  </div>
</div>

<!-- Workspace upgrade modal -->

<button type="button" id="workspaceUpdrageModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#workspaceUpdrageModal">
</button>

<div class="modal fade" id="workspaceUpdrageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content">
      <div class="modal-header workspace-upgrade-modal-header">
        <h3 class="workspace-upgrade-modal-title" id="staticBackdropLabel">Your workspace needs to be upgraded!</h3>
        <img src="assets/svg/close-button.svg" id="closeWorkspaceUpdrageModal" alt="X" data-bs-dismiss="modal"
             aria-label="Close"
             style="margin: 0 0 3rem auto; cursor: pointer">
      </div>
      <div class="modal-body workspace-upgrade-modal-body" *ngIf="this.data.userRole?.role.workspacePriority !== 40">
        <img class="man-with-rocket" src="../../../assets/images/man-with-rocket.png">
        <p class="text-center" style="font-size: 1.6rem;">Congrats, your team has shared lots of valuable feedback over
          the past weeks!</p>
        <p class="text-center workspace-upgrade-info">Now,
          <b>{{this.data.userRole?.workspace.creator.detail.displayName | getDisplayName: this.data.userRole?.workspace.creator.detail.firstname: this.data.userRole?.workspace.creator.detail.lastname: this.data.userRole?.workspace.creator.email}}</b>
          has to upgrade your workspace to <b>unlock the new insights collected
            since {{data.getDate(this.data.userRole?.workspace.limitReachedDate?.date)}}</b>
          and get <b>unlimited feedback statements</b> so you can continue being proactive on your team’s challenges.
        </p>
        <button pButton type="button" class="workspace-upgrade-button" style="font-family: Poppins, sans-serif;"
                (click)="goToDashboard()">See dashboard
        </button>
      </div>
      <div class="modal-body workspace-upgrade-modal-body" *ngIf="this.data.userRole?.role.workspacePriority === 40">
        <img class="man-with-rocket" src="../../../assets/images/man-with-rocket.png">
        <p class="text-center" style="font-size: 1.6rem;">So much team feedback was shared over the past weeks!</p>
        <p class="text-center workspace-upgrade-info">Upgrade to Essential to <b>unlock the new insights collected
          since {{data.getDate(this.data.userRole?.workspace.limitReachedDate?.date)}}.</b>
          You'll get <b>unlimited feedback statements</b> and continue being proactive on your team’s challenges.</p>
        <button pButton type="button" class="workspace-upgrade-button" style="font-family: Poppins, sans-serif;"
                (click)="goToBilling()">{{'left_menu_button'| translate}}
        </button>
        <p class="text-center" style="font-size: 1.5rem; margin-top: 1rem;">Starting from 39 kr. / user / month</p>
      </div>
    </div>
  </div>
</div>

<!--UPGRADE WORKSPACE-->
<button type="button" id="contentSuccessful1" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#contentSuccessfulModal">
</button>

<div>
  <div class="modal fade modalModal" id="contentSuccessfulModal" tabindex="-1" role="dialog"
       aria-labelledby="createTeamLabel" data-bs-backdrop="static" data-bs-keyboard="false"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header upgrade-workspace-modal-header">
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               id="closeSuccessfulModal" (click)="closeHurrayModal()" style="margin: 0 0 -3rem auto; cursor: pointer">
          <img style="width: 20rem;" src="../../../assets/images/hurray.png">
          <h3 class="title"
              style="color: #00194A; margin-top: .7rem; font-size: 2.2rem; font-family: Poppins, sans-serif">Your workspace has just been upgraded!</h3>
        </div>
        <div class="modal-body upgrade-workspace-modal-body">
          <img class="upgrade-workspace-checkmark" src="../../../assets/svg/hurray-green-checkmark.svg">
          <h3 style="font-size: 2.2rem; color:white; font-weight: 400;">You have just unlocked:</h3>
          <ul class="plan-details">
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Unlimited feedback answers</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Unlimited team insights</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Full Team Progress Overview</span>
            </div>
          </ul>
          <div style="text-align: center; padding: 1rem">
            <button pButton type="button" class="workspace-upgrade-button"
                    style="padding: 10px 50px;  font-family: Poppins, sans-serif; margin-bottom: 3rem;"
                    (click)="goToDashboardPlan()">Take me to my dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Request ROLE-->
<button type="button" id="roleRequestModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#roleRequestModalOpen">
</button>

<div>
  <div class="modal fade modalModal" id="roleRequestModalOpen" tabindex="-1" role="dialog"
       aria-labelledby="createTeamLabel" data-bs-backdrop="static" data-bs-keyboard="false"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" style="text-align: center">
        <div class="modal-header">
          <h3></h3>
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               id="closeRequestModal" style="margin: 0 0 3rem 0; cursor: pointer">
        </div>
        <div class="modal-body">
          <div style="padding-bottom: 4rem">
            <h3 style="padding: 5rem 2rem">You are about to request the Workspace Administrator role</h3>
            <div style="display: inline-flex; align-items: center;">
              <img *ngIf="data.workspaceCreatorImage !== ''"
                   class="user-image" style="width: 4rem; height: 4rem"
                   src="{{this.apiwrapper.baseUrl + data.workspaceCreatorImage}}">

              <h5><span style="font-weight: 600">{{data.workspaceOwnerName}}</span> will be notified about it and will need to confirm.</h5>
            </div>
          </div>
        </div>
        <div class="modal-footer request-footer">
          <button class="btn-hallow" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
          <button class="btn-blue request-button" (click)="requestRole()">Request role</button>
        </div>
      </div>
    </div>
  </div>
</div>
