export const environment = {
  production: true,
  // pixel: '2194164044091587',
  pixel: '551818436927394',
  // baseUrl: 'https://api.successteam.io',
  baseUrl: 'https://dev.api.successteam.dk',
  frontendUrl: 'https://dev.app.successteam.dk',
  stripe: 'pk_test_51H8RmJLHPeB9D6fh2ROfb9gUGLMyfqcb97cRsD7Zm22JKEq8GkojdJnO7DB8nyZ0YFUCd4HmpBkgr2jWqvec4Ywi00PaeiYlp4',
  teamWizard: 'flow_1680610823504',
  teamInsightWizard:'flow_1680689221428'
};
