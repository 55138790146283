import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomPollOnboardingService } from '../../services/custom-poll-onboarding.service';

@Component({
  selector: 'app-onboarding-schedule',
  templateUrl: './onboarding-schedule.component.html',
  styleUrls: ['./onboarding-schedule.component.scss']
})
export class OnboardingScheduleComponent implements OnInit {

  @Input() checkinsCategories: any[] = [];
  @Input() set setResumedDraft(value: any) {
    if (value) {
      this.checkinsCategories = [];
      this.getCheckinsCategories(value.uuid);
    }
  }

  @Output() checkinScheduleEvent = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();
  @Output() goBackEvent = new EventEmitter<any>();

  startDate: any;
  endDate: any;
  minStartDateValue: any;
  minEndDateValue: any;
  selectedRadioValue: any;

  radioButtons: any[] = [{label: this.translate.instant('custom_poll_onboarding_temp_schedule_end_date_r1'), id: 'radio1'}, {label: this.translate.instant('custom_poll_onboarding_temp_schedule_end_date_r2'), id: 'radio2'}];

  constructor(private translate: TranslateService, private onboardingService: CustomPollOnboardingService) { }

  ngOnInit(): void {
    const currentDate = new Date();

    this.startDate = currentDate;
    this.endDate = currentDate;
    this.minStartDateValue = new Date(new Date().setMonth(currentDate.getMonth() - 6));
    this.minEndDateValue = currentDate;
    this.selectedRadioValue = this.radioButtons[0];
  }

  getEndDate() {
    const currentDate = new Date();
    return new Date(currentDate.setFullYear(currentDate.getFullYear() + 50));
  }

  getCheckinsCategories(customPollUuid: string) {
    this.onboardingService.getChekinsSelectedCategories(customPollUuid).subscribe((res: any) => {
      res._embedded["timed-collections"].forEach((categ: any) => {
        this.checkinsCategories.push({
          categoryUuid: categ.timedQuestionCollection.uuid, 
          title: categ.timedQuestionCollection.title, 
          isMandatory: (categ.timedQuestionCollection.slug === 'week-2-check-in' || categ.timedQuestionCollection.slug === 'month-1-check-in')})
      });
    });
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

  nextStep() {
    let dates = {endDate: this.endDate, startDate: this.startDate};
    if (this.selectedRadioValue.id === 'radio1') {
      dates = {endDate: this.getEndDate(), startDate: this.startDate}
    }
    this.checkinScheduleEvent.emit(dates);
  }

  goBack() {
    this.checkinsCategories = [];
    this.goBackEvent.emit(1);
  }

  changeStartDate(event: any) {
    this.startDate = new Date(event);
  }

  changeEndDate(event: any) {
    this.endDate = new Date(event);
  }

  changeRadioState(radioValue: any) {

  }

}
