<!-- Button trigger modal -->
<button hidden id="loginForm" type="button" class="btn btn-primary" data-toggle="modal"
        data-target="#exampleModal"></button>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
     aria-hidden="true" data-backdrop="false" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="logo-block">
          <img src="../../assets/images/logo/successteam-symbol.svg">
        </div>
        <button type="button" id="closeModal" class="close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <!--Activate Modal-->
      <div class="modal-body">
        <div>
          <p class="medium">Activating workspace invitation</p>
        </div>
        <!--<p class="forgot-password" routerLink="/login" *ngIf="activate">LOGIN</p>-->
      </div>
    </div>
  </div>
</div>
