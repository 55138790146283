import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, map } from 'rxjs';
import { ApiwrapperService } from 'src/app/services/apiwrapper.service';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-custom-loop-container',
  templateUrl: './custom-loop-container.component.html',
  styleUrls: ['./custom-loop-container.component.scss']
})
export class CustomLoopContainerComponent implements OnInit {

  generalStatements: Subject<any> = new Subject<any>();
  specialStatements: Subject<any> = new Subject<any>();
  customPollReviewTeams: Subject<any> = new Subject<any>();
  selectedGeneralCategories: Subject<any> = new Subject<any>();
  selectedSpecialCategories: Subject<any> = new Subject<any>();
  selectedSpecialCategoryTeams: Subject<any> = new Subject<any>();
  customPollResponseRate: {}[] = [];

  choseTemplate: boolean = false;
  choseGeneralCategories: boolean = false;
  choseSpecialCategories: boolean = false;
  chooseTiming: boolean = false;
  chooseReview: boolean = false;
  chooseCongrats: boolean = false;
  getSelectedGenCategories: boolean = false;
  checkinCategories: boolean = false;
  checkinChoseTiming: boolean = false;

  costumPollTemplates: {}[] = [];
  customPolls: any[] = [];
  customPollUuid: any;
  customPollGeneralCategories: {}[] = [];
  customPollSpecialCategories: {}[] = [];
  customPollCheckinCategories: any[] = [];

  scoreColorInterval = [
    {min: 0, max: 39, color: '#E83032'},
    {min: 40, max: 59, color: '#FE9001'},
    {min: 60, max: 79, color: '#FFC828'},
    {min: 80, max: 100, color: '#00BD82'},
  ];
  
  customPollTimingDetails: any;
  currentStep: string = '0';
  customPollMainTitle = '';
  customPollType = '';
  congratsDetails: any;
  resumedDraft: any;
  newDraft: any;
  apvStartingDate: any = {daysRemaining: '', subtitle: 0};

  constructor(public apiwrapper: ApiwrapperService, public translate: TranslateService, public data: DataManipulationService) { }

  ngOnInit(): void {
    this.data.showLoading();
    this.getWorkspaceCustomPoll();
  }

  getWorkspaceCustomPoll() {
    this.setAllFalse();
    if(!this.resumedDraft && !this.newDraft && this.customPolls.length === 0) {
      this.apiwrapper.getWorkspaceCustomPoll().subscribe((res) => {
        if(res['_embedded']["custom-polls"].length === 0) {
          this.getCustomPollTemplates();
        } else {
          res['_embedded']["custom-polls"].forEach((poll: any) => {
            this.customPolls.push(poll);
          });
          this.getCustomPollResponseRate();
          this.getCustomPollTemplates();
        }
      });
    } else {
      const newOrResume = this.resumedDraft ? this.resumedDraft.setupStep : this.newDraft ? this.newDraft.setupStep : '';
      this.customPollUuid = this.resumedDraft ? this.resumedDraft.uuid : this.newDraft ? this.newDraft.uuid : '';
      switch(newOrResume) {
        case '1':
          this.choseGeneralCategories = true;
          this.getGeneralCategories();
          break;
        case '2':
          this.choseSpecialCategories = true;
          this.getSpecialCategories();
          break;
        case '3':
          this.chooseReview = true;
          this.getReviewPage();
          break;
        case '4':
          this.chooseTiming = true;
          this.getTimingPage();
          break;
      }
    }
  }

  getCustomPollTemplates() {
    this.apiwrapper.getWorkspaceCustomPollTemplates().subscribe((res) => {
      res['_embedded']["custom-poll-templates"].forEach((temp: any) => {
        this.costumPollTemplates.push(temp);
      });
      this.newDraft = null;
      this.choseTemplate = true;
      this.data.hideLoading();
    });
  }

  getGeneralCategories() {
    if(this.customPolls.length > 0 && !this.resumedDraft && !this.newDraft) {
      this.customPolls.forEach((poll: any) => {
        if(poll.customPollTemplate.questionCategories.length !== 0) {
          this.filterCustomPollGeneralCategories(poll, true);
        } else {
            this.filterCustomPollGeneralCategories(poll, false);
        }
      });
    } else if (this.customPolls.length > 0 && this.resumedDraft) {
      this.filterCustomPollGeneralCategories(this.resumedDraft, true);
    } else if (this.customPolls.length > 1 && this.newDraft) {
      this.filterCustomPollGeneralCategories(this.newDraft, true);
    }
  }

  filterCustomPollGeneralCategories(poll: any, hasCategories: boolean) {
    const generalCategories: any[] = [];
    poll.customPollTemplate.questionCategories.forEach((categ: any) => {
      if(categ.category.type === 'general') {
        generalCategories.push(categ);
      }
    });
    if(poll && hasCategories) {
      this.getSelectedGeneralCategories(poll.uuid);
      this.customPollGeneralCategories.push({
        pollName: poll.customPollTemplate.name,
        categories: generalCategories,
        selectedCategories: this.selectedGeneralCategories,
        pollUuid: poll.uuid
      });
      this.customPollMainTitle = poll.customPollTemplate.name;
      this.setCustomPollStep(poll.setupStep);
    } else {
      this.apiwrapper.getCustomPollGeneralCategories().subscribe((res: any) => {
        console.log(res);
      });
    }
    this.choseGeneralCategories = true;
    this.data.hideLoading();
  }

  getSelectedGeneralCategories(pollUuid: string) {
    this.apiwrapper.getCustomPollSelectedGeneralCategories(pollUuid).subscribe((res: any) => {
      this.selectedGeneralCategories.next({
          categories: res.categories,
          teams: res.teams
        });
    });
  }

  getGeneralCategoriesStatements(event: any) {
    this.apiwrapper.getGeneralCategoriesStatements(event.categoryUuid).subscribe((res: any) => {
      this.generalStatements.next({
        index: event.index,
        statements: res._embedded['category-questions']
      });
    });
  }

  getSpecialCategories() {
    if (this.customPolls.length > 0 && !this.resumedDraft && !this.newDraft) {
      this.customPolls.forEach((poll: any) => {
        this.filterCustomSpecialCategories(poll);
      });
    } else if (this.customPolls.length > 0 && this.resumedDraft) {
      this.filterCustomSpecialCategories(this.resumedDraft);
    } else if (this.customPolls.length >= 1 && this.newDraft) {
      this.filterCustomSpecialCategories(this.newDraft);
    }
    this.chooseTiming = false;
    this.choseGeneralCategories = false;
    this.choseSpecialCategories = true;
    this.data.hideLoading();
  }

  filterCustomSpecialCategories(poll: any) {
    const specialCategories: any[] = [];
    poll.customPollTemplate.questionCategories.forEach((categ: any) => {
      if(categ.category.type === 'special') {
        specialCategories.push(categ);
      }
    });
    this.getSelectedSpecialCategories(poll.uuid);
    this.customPollSpecialCategories.push({
      pollName: poll.customPollTemplate.name,
      categories: specialCategories,
      selectedCategories: this.selectedSpecialCategories,
      pollUuid: poll.uuid
    });
    this.customPollMainTitle = poll.customPollTemplate.name;
    this.setCustomPollStep(poll.setupStep);
  }

  getSelectedSpecialCategories(pollUuid: string) {
    this.apiwrapper.getCustomPollSelectedSpecialCategories(pollUuid).subscribe((res: any) => {
      this.selectedSpecialCategories.next({
          categories: res._embedded.categories,
        });
    });
  }

  getSpecialCategoriesStatements(event: any) {
    this.apiwrapper.getGeneralCategoriesStatements(event.categoryUuid).subscribe((res: any) => {
      this.specialStatements.next({
        index: event.index,
        statements: res._embedded['category-questions']
      });
    });
  }

  getSpecialCategorySelectedTeams(event: any) {
    this.apiwrapper.postSpecialCategoryTeams(event).subscribe((res:any) => {
      this.selectedSpecialCategoryTeams.next(res._embedded.teams);
    });
  }

  addHoursToDate(date: any) {
    const addHoursToStartDate = new Date(new Date(date?.date).setTime(new Date(date?.date).getTime() + (3 * 60 * 60 * 1000)));
    return addHoursToStartDate;
  }

  getTimingPage() {
    if (this.customPolls.length <=1 && !this.resumedDraft && !this.newDraft) {
      this.customPollTimingDetails = {invitation: this.customPolls[0].invitation, pollUuid: this.customPolls[0].uuid};
      this.customPollMainTitle = this.customPolls[0].customPollTemplate.name;
      this.setCustomPollStep(this.customPolls[0].setupStep);
    } else if (this.customPolls.length > 0 && this.resumedDraft) {
      this.customPollTimingDetails = {invitation: this.resumedDraft.invitation, pollUuid: this.resumedDraft.uuid};
      this.customPollMainTitle = this.resumedDraft.name;
      this.setCustomPollStep(this.resumedDraft.setupStep);
    } else if (this.customPolls.length >= 1 && this.newDraft) {
      this.customPollTimingDetails = {invitation: this.newDraft.invitation, pollUuid: this.newDraft.uuid};
      this.customPollMainTitle = this.newDraft.name;
      this.setCustomPollStep(this.newDraft.setupStep);
    }
    this.chooseReview = false;
    this.chooseTiming = true;
    this.data.hideLoading();
  }

  getReviewPage() {
    this.data.showLoading();
    if (this.customPolls.length <= 1 && !this.resumedDraft && !this.newDraft) {
      this.apiwrapper.getCustomPollSummary(this.customPolls[0].uuid).subscribe((res: any) => {
        this.customPollReviewTeams.next({categories: res._embedded["team-custom-polls-categories"], pollUuid: this.customPolls[0].uuid});
        this.customPollMainTitle = this.customPolls[0].customPollTemplate.name;
      });
    } else if (this.customPolls.length > 0 && this.resumedDraft) {
      this.apiwrapper.getCustomPollSummary(this.resumedDraft.uuid).subscribe((res: any) => {
        this.customPollReviewTeams.next({categories: res._embedded["team-custom-polls-categories"], pollUuid: this.resumedDraft.uuid});
        this.customPollMainTitle = this.resumedDraft.name;
      });
    } else if (this.customPolls.length >= 1 && this.newDraft) {
      this.apiwrapper.getCustomPollSummary(this.newDraft.uuid).subscribe((res: any) => {
        this.customPollReviewTeams.next({categories: res._embedded["team-custom-polls-categories"], pollUuid: this.newDraft.uuid});
        this.customPollMainTitle = this.newDraft.name;
      });
    }
    this.choseSpecialCategories = false;
    this.chooseReview = true;
  }

  getCongratsPage() {
    this.customPollMainTitle = this.resumedDraft ? this.resumedDraft.name : this.newDraft ? this.newDraft.name : this.customPolls[0].customPollTemplate.name;
    this.congratsDetails = {
      customUuid: this.resumedDraft ? this.resumedDraft.uuid : this.newDraft ? this.newDraft.uuid : this.customPolls[0].uuid, 
      date : this.apvStartingDate,
      type: this.resumedDraft ? this.resumedDraft.customPollTemplate.type : this.newDraft ? this.newDraft.customPollTemplate.type : this.customPolls[0].customPollTemplate.type };
    this.chooseTiming = false;
    this.chooseCongrats = true;
  }

  setCustomPollStep(step: any) {
    this.currentStep = step;
  }

  setCustomPollStepFromBreadCrumb(event: any) {
    this.apiwrapper.updateCustomPoll(this.customPolls[0].uuid, {setupStep: event}).subscribe((res:any) => {
      this.getWorkspaceCustomPoll();
      this.setCustomPollStep(res.setupStep);
    });
  }

  createCustomPoll(template: any) {
    this.data.showLoading();
    this.apiwrapper.createCustomPoll({customPollTemplateUuid: template.templateUuid}).subscribe((res) => {
      if(res.customPollTemplate.type === 'apv') {
        this.customPollType = res.customPollTemplate.type;
        if (this.customPolls.length > 1) {
          this.newDraft = res;
          this.filterCustomPollGeneralCategories(this.newDraft, true);
        }
        this.filterCustomPollGeneralCategories(res, true);
        this.customPolls.push(res);
        this.data.hideLoading();
      }
      if (res.customPollTemplate.type === 'onboarding') {
        this.setOnboardingCustomPoll(res);
        this.setCustomPollStep(res.setupStep);
        this.choseTemplate = false;
        this.checkinCategories = true;
        this.data.hideLoading();
      }
    });
  }

  setOnboardingCustomPoll(poll: any) {
    this.newDraft = poll;
    this.customPollMainTitle = poll.name;
    this.customPollType = poll.customPollTemplate.type;
  }

  resumeOnboardingCustomPoll(poll: any) {
    this.customPollMainTitle = poll.name;
    this.customPollType = poll.customPollTemplate.type;
    if (poll.setupStep === '1') {
      this.checkinCategories = true;
    } else if (poll.setupStep === '2') {
      this.checkinChoseTiming = true;
    }
    this.data.hideLoading();
  }

  createCustomPollGeneralCategories(event: any) {
    this.apiwrapper.postGeneralCategories(event).subscribe((res: any) => {});
    this.apiwrapper.updateCustomPoll(event.customPollUuid, {setupStep: 2}).subscribe((res:any) => {
      this.getSpecialCategories();
      this.setCustomPollStep(res.setupStep);
    });
  }

  createCustomPollSpecialCategories(event: any) {
    event.teams.forEach((team: any) => {
      team.teams.forEach((tem: any) => {
        this.apiwrapper.postSpecialCategories({customPollTemplateCategories: [team.categoryUuid], teamUuid: tem, customPollUuid: event.customPollUuid}).subscribe((res: any) => {
        });
      });
    });
    this.apiwrapper.updateCustomPoll(event.customPollUuid, {setupStep: 3}).subscribe((res:any) => {
      this.setCustomPollStep(res.setupStep);
    });
    this.data.showLoading();
    this.getReviewPage();
  }

  createTimingDetailsStep(event: any) {
    this.apvStartingDate = event.startingDate;
    this.apiwrapper.updateCustomPoll(event.customPollUuid, {invitation: event.invitation, startDate: event.startDate, endDate: event.endDate}).subscribe((res:any) => {
      this.setCustomPollStep(res.setupStep);
      if (this.resumedDraft) {
        this.resumedDraft.startDate = {date: this.addHoursToDate(res.startDate)};
      } else if (this.newDraft){
        this.newDraft.startDate = {date: this.addHoursToDate(res.startDate)};
      } else {
        this.customPolls[0].startDate = {date: this.addHoursToDate(res.startDate)};
      }
      this.startCustomPoll();
      this.getCongratsPage();
    });
  }

  startCustomPoll() {
    this.apiwrapper.postCustomPoll({customPollUuid: this.resumedDraft ? this.resumedDraft.uuid : this.newDraft ? this.newDraft.uuid : this.customPolls[0].uuid}).subscribe((res:any) => {
      this.getCongratsPage();
    });
  }

  setAllFalse() {
    this.choseTemplate = false;
    this.choseGeneralCategories = false;
    this.choseSpecialCategories = false;
    this.chooseTiming = false;
    this.chooseReview = false;
    this.checkinCategories = false;
    this.checkinChoseTiming = false;
    this.chooseCongrats = false;
  }

  goBack(event: any) {
    this.apiwrapper.updateCustomPoll(event.uuid !== '' ? event.uuid : this.customPolls[0].uuid, {setupStep: event.step}).subscribe((res:any) => {
      this.setAllFalse();
      this.setCustomPollStep(res.setupStep);
      if (this.resumedDraft) {
        this.resumedDraft.setupStep = res.setupStep;
        this.resumedDraft.startDate = this.addHoursToDate(res.startDate);
      } else if (this.newDraft) {
        this.newDraft.setupStep = res.setupStep;
        this.newDraft.startDate = this.addHoursToDate(res.startDate);
      } else {
        this.customPolls[0].setupStep = res.setupStep;
        this.customPolls[0].startDate = this.addHoursToDate(res.startDate);
      }
      this.getWorkspaceCustomPoll();
    });
  }

  endReviewAndGoToTiming(event: any) {
    this.apiwrapper.updateCustomPoll(event, {setupStep: 4}).subscribe((res:any) => {
      this.setCustomPollStep(res.setupStep);
    });
    // this.setPollDates(this.resumedDraft ? this.resumedDraft.startDate?.date : this.customPolls[0].startDate?.date);
    this.getTimingPage();
  }

  resumeDraft(event: any) {
    this.data.showLoading();
    this.resumedDraft = event;
    this.setCustomPollStep(this.resumedDraft.setupStep);
    if (this.resumedDraft.customPollTemplate.type === 'apv') {
      this.getWorkspaceCustomPoll();
    } else {
      this.choseTemplate = false;
      this.resumeOnboardingCustomPoll(this.resumedDraft);
    }
  }

  saveAndFinishLater(event: any) {
    if(this.resumedDraft) {
      this.resumedDraft = null;
    }
    if (this.newDraft) {
      this.newDraft = null;
    }
    this.setAllFalse();
    this.customPolls = [];
    this.costumPollTemplates = [];
    this.data.showLoading();
    this.getWorkspaceCustomPoll();
  }

  getCustomPollResponseRate() {
    if (this.customPolls.length) {
      this.customPolls.forEach((poll: any) => {
        poll.imageColor = this.data.colors.grey;
        this.apiwrapper.getCustomPollResponseRate(poll.uuid).subscribe((res: any) => {
          poll.reportingDetails = res;
          this.scoreColorInterval.forEach((interval: any) => {
            if (res.score >= interval.min && res.score <= interval.max) {
              poll.imageColor = interval.color;
            }
          });
          this.customPollResponseRate.push({uuid: poll.uuid, responseRate: res.responseRate, reportingDetails: res, pollScore: res.score});
        });
      });
    }
    this.data.hideLoading();
  }

  setCustomPollCheckinsCategories(event: any) {
    this.data.showLoading();
    let checkinCategories: any[] = [];
    event.checkinCategories.forEach((checkin: any) => {
      checkinCategories.push(checkin.categoryUuid);
    });
    this.apiwrapper.updateCustomPoll(this.newDraft ? this.newDraft.uuid: this.resumedDraft.uuid, {setupStep: 2}).subscribe((res: any) => {
      this.setCustomPollStep(res.setupStep);
      this.apiwrapper.setCustomPollTimedCollections(this.newDraft ? this.newDraft.uuid: this.resumedDraft.uuid, checkinCategories).subscribe((res:any) => {
        this.customPollCheckinCategories = event.checkinCategories;
        this.checkinCategories = false;
        this.checkinChoseTiming = true;
        this.data.hideLoading();
      });
    });
  }

  deleteCustomPollByUuid(event: any) {
    this.data.showLoading();
    this.apiwrapper.deleteCustomPoll(event.customPollUuid).subscribe((res: any) => {
      this.customPolls.forEach((poll: any, index: number) => {
        if(poll.uuid === event.customPollUuid) {
          this.customPolls.splice(index, 1);
        }
      });
      this.data.hideLoading();
    });
  }

  stopCustomPollByUuid(event: any) {
    this.data.showLoading();
    this.apiwrapper.stopCustomPoll(event.customPollUuid).subscribe((res:any) => {
      this.customPolls.forEach((poll:any) => {
        if (poll.uuid === event.customPollUuid) {
          poll = res;
        }
      });
      this.data.hideLoading();
    });
  }

  setCheckinSchedule(event: any) {
    this.data.showLoading();
    this.apiwrapper.updateCustomPoll(this.newDraft ? this.newDraft.uuid : this.resumedDraft.uuid, {startDate: event.startDate, endDate: event.endDate}).subscribe((res: any) => {
      this.startCustomPoll();
      this.checkinChoseTiming = false;
      this.data.hideLoading();
    });
  }

  checkinScheduleGoBack(step: any) {
    this.data.showLoading();
    this.apiwrapper.updateCustomPoll(this.newDraft ? this.newDraft.uuid : this.resumedDraft.uuid, {setupStep: step}).subscribe((res: any) => {
      this.setCustomPollStep(step);
      this.checkinCategories = true;
      this.checkinChoseTiming = false;
      this.data.hideLoading();
    });
  }

  endPollCreation() {
    this.saveAndFinishLater('');
  }
}
