import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public submitted: boolean = false;
  private resetMessage: any;
  public setEmail: string = '';

  constructor(public dataService: DataManipulationService,
              private router: Router,  private activeRoute: ActivatedRoute,
              private authService: AuthenticationService) {
    this.dataService.mailSent = false;

  }

  ngOnInit(): void {
    if (this.router.url.split('email=')[1]) {
      this.setEmail = this.router.url.split('email=')[1];
      localStorage.setItem('createPasswordUser', this.setEmail);
    }
    if (this.activeRoute.snapshot.paramMap.get('hash') != null) {
      this.resetHash = this.activeRoute.snapshot.paramMap.get('hash');
    }
  }

  mailForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  resetForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required]),
  });
  resetHash: any;

  onSubmixxst() {
    this.dataService.showAlert('Helloosoda da sda sd asdefas dsd asd asd as');

  }

  get e() {
    return this.mailForm.controls;
  }
  get f() {
    return this.resetForm.controls;
  }

  sendResetPasswordMail(event: any) {
    this.submitted = true;
    if (this.mailForm.invalid) {
      this.dataService.showAlertError('Please insert a valid email address');
      return;
    }
    this.dataService.showLoading();
    const email = this.e['email'].value;
    this.dataService.forgetEmail = this.e['email'].value
    this.authService.sendResetPasswordMail(email).subscribe((res) => {
      this.resetMessage = res.info.messages[0];
      this.dataService.mailSent = true;
      this.dataService.hideLoading();
      // show the mail sent page
    }, () => {
      this.dataService.showAlertError('There was an error sending you the reset password email. Try again later.');
    });
  }

  resetPassword(event: any) {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    const data = {
      password: this.f['password'].value,
      passwordConfirm: this.f['confirmPassword'].value
    };
    this.authService.resetPassword(data, this.resetHash).subscribe(() => {
      if (this.setEmail !== '') {
        localStorage.setItem('createPassword', data.password);
        this.dataService.showAlert('You have successfully set your password.');
      } else {
        this.dataService.showAlert('You have successfully reset your password.');
      }
      this.router.navigate(['login']);
    }, () => {
      if (this.setEmail !== '') {
        this.dataService.showAlertError('There was an error in setting your password. Try again.');
      } else {
        this.dataService.showAlertError('There was an error in resetting your password. Try again.');
      }
    });
  }
}
