<div class="main-container">
    <img src="../../../../../assets/images/success_icons_apv.png">
    <h2 style="font-size: 2.4rem; margin: 4.1rem 0 1.6rem 0;">{{details.type === 'apv' ? ('custom_poll_congrats_title' | translate):('custom_poll_onboarding_congrats_title'|translate)}}</h2>
    <span *ngIf="details.type === 'apv'" [ngStyle]="details.date.subtitle === 2 ? {'width':'45rem'}:{'width':'33rem'}" style="text-align: center;font-size: 1.6rem; margin: 4rem 0 6rem 0;">
        {{('custom_poll_congrats_subtitle_'+details.date.subtitle | translate: {x: details?.date.daysRemaining})}}</span>
    <span *ngIf="details.type === 'onboarding'" style="width: 45rem;text-align: center;font-size: 1.6rem; margin: 4rem 0 6rem 0;">{{'custom_poll_congrats_onboarding_subtitle' | translate}}</span>
    <button class="general-categories-next" 
            pButton type="button" 
            label="{{details.type === 'apv' ? ('custom_poll_congrats_button' | translate):('custom_poll_onboarding_congrats_button' | translate)}}"
            (click)="end()"></button>
</div>