import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public workspaces: any;
  public gotWorkspaces: boolean = false;
  public returnUrl: string = '';
  private userTimeZone: any;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  invitation: any;
  workspacesSelected: any;

  constructor(public data: DataManipulationService, private api: ApiwrapperService, private auth: AuthenticationService,
              private router: Router, private activeRoute: ActivatedRoute,) {
    this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit(): void {
    this.returnUrl = this.activeRoute.snapshot.queryParams['returnUrl'] || '/';
    if (this.activeRoute.snapshot.paramMap.get('hash') !== null) {
      this.impersonate(this.activeRoute.snapshot.paramMap.get('hash'));
    }
  }

  login() {
    this.auth.logout();
    this.data.showLoading();

    if (this.loginForm.invalid) {
      this.data.hideLoading();
      this.data.showAlertError('Please insert a valid email');
      return;
    }
    const username = this.loginForm.value.email;
    const password = this.loginForm.value.password.replace(/\s/g, "");
    this.auth.login(username, password).subscribe((user) => {
      localStorage.setItem('currentUser', JSON.stringify(user));
      if (user) {
        this.api.patchUser('', {timeZone: this.userTimeZone}).subscribe((res) => {});
      }
      // @ts-ignore
      let userWorkspaces = JSON.parse(localStorage.getItem('USER_WORKSPACES'));
      userWorkspaces = !userWorkspaces ? [] : userWorkspaces;

      const exist = false;
      // ACTIVATE INVITATION
      if (this.invitation) {
        // this.apiwrapper.postActivateInvitation(this.invitation).subscribe(
        this.api.activateInvitation(this.invitation).subscribe(
          res => {
            this.getUserWorkspace(userWorkspaces, exist, username);
          },
          error => {
            this.getUserWorkspace(userWorkspaces, exist, username);
          });
      } else {
        this.getUserWorkspace(userWorkspaces, exist, username);
      }

    }, (err) => {
      this.data.hideLoading();
      this.data.showAlertError(err.error.message);
    });
  }

  impersonate(hash: any) {
    this.auth.logout();
    this.data.showLoading();
    this.auth.impersonate(hash).subscribe(
      res => {
        localStorage.setItem('currentUser', JSON.stringify(res));
        localStorage.setItem('version', '3');
        this.getUserWorkspace([], false, '');
      }, (err) => {
        this.data.hideLoading();
        this.auth.logout();
        this.router.navigate(['/login']).then(() => {
          location.reload();
        });
        this.data.showAlertError(err.error.error.messages[0]);
      }
    );
  }

  getUserWorkspace(userWorkspaces: string | any[], exist: boolean, username: string) {
    let exists = exist;
    // tslint:disable-next-line:prefer-for-of
    if (username !== '') {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < userWorkspaces.length; i++) {
        if (userWorkspaces[i].user === username) {
          // exists = true;
          this.auth.setCurrentWorkspace(userWorkspaces[i].workspace);
          this.api.getUserDetails(userWorkspaces[i].workspace).subscribe(
            res => {
              // LOGIN SUCCESS ######
              // this.router.navigate(['/']).then(() => {
              //   location.reload();
              // });
                            
              // set language
              let lang = res.detail.preferredLanguage;
              if (lang.tag.toLowerCase().includes('dk')) {
                this.data.language.language = 'da';
                // this.switchLang('da');
                localStorage.setItem('language', JSON.stringify(lang));
                this.data.language.selectedCountryCode = 'dk';
              } else {
                this.data.language.language = 'en';
                localStorage.setItem('language', JSON.stringify(lang));
                // this.switchLang('en');
                this.data.language.selectedCountryCode = 'us';
              }

              setTimeout(()=>{
                this.gotWorkspaces = true;
                this.data.hideLoading();
              }, 1000)
            }, error => {
              this.data.showAlertError('Login error!')
              this.data.hideLoading();
            }
          );
        }
      }
    }
    if (!exists) {
      this.api.getUserWorkspaces().subscribe(
        // tslint:disable-next-line:no-shadowed-variable
        res => {
          this.workspaces = res;
          if (this.workspaces.length === 0) {
            if (this.activeRoute.snapshot.paramMap.get('hash') === null) {
              this.data.showAlertError('Your invitation is pending! Let\'s create a new workspace!');
            } else {
              this.data.showAlertError('Error in getting impersonate workspaces!');
            }
            this.auth.logout();
          } else if (this.workspaces.length === 1) {
            this.workspaceSelected(this.workspaces[0]);
          } else {
            if (username !== '') {
              res.forEach((el: { workspace: { workspaceSubscription: { workspacePlan: { isDisplayed: string; }; }; }; }) => {
                if (el.workspace.workspaceSubscription.workspacePlan.isDisplayed === 'yes') {
                }
              });
              this.workspaces.forEach((el: { workspace: { name: string | string[]; }; }, index: any) => {
                  if (el.workspace.name.includes(username)) {
                    this.workspaces.splice(index, 1);
                    if (this.workspaces.length === 1) {
                      this.workspaceSelected(this.workspaces[0]);
                      this.data.hideLoading();
                    }
                  }
                }
              );
            }
            this.gotWorkspaces = true;
            this.data.hideLoading();
          }
        }, () => {
        });

    }
  }

  public workspaceSelected(workspace: any) {
    // this.data.showAlertReport('Workspace selected is: ', workspace.workspace.name);
    this.data.setWorkspaceBillingInfo(workspace.workspace.workspaceBillingInfo);
    if (workspace.workspace.billDate !== null) {
      workspace.workspace.workspaceSubscription.workspacePlan.billDate = workspace.workspace.billDate.date;
    } else {
      workspace.workspace.workspaceSubscription.workspacePlan.billDate = new Date();
    }
    this.data.setWorkspacePlan(workspace.workspace.workspaceSubscription.workspacePlan);
    this.data.setWorkspaceBillingContacts(workspace.workspace.workspaceBillingContacts);
    this.auth.setCurrentWorkspace(workspace.workspace.uuid);
    localStorage.setItem('WORKSPACE', JSON.stringify(workspace.workspace));
    this.api.getUserDetails(workspace.workspace.uuid).subscribe(
      res => {
        localStorage.setItem('language', JSON.stringify(res.detail.preferredLanguage));
        this.auth.setCurrentUserWorkspace(res.email, workspace.workspace.uuid,
          workspace.workspace.workspaceSubscription.workspacePlan);
        this.router.navigate(['/']).then(() => {
          location.reload();
        });
      }
    );
  }
}
