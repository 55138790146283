<div *ngIf="!isMember">
  <div class="dashboard-header">
    <h2 class="dashboard-welcome-title" (click)="click()">
      {{'start_guide_title'|translate }}<span style="color: #365DBF;">{{ user?.detail.displayName | getDisplayName: user?.detail.firstname: user?.detail.lastname: user?.email }}</span></h2>
    <!-- <p-avatar [image]="user['avatar']['name']" size="xlarge" shape="circle"></p-avatar> -->
    <div class="avatar">
      <input type="file" hidden
             #file
             name="file"
             id="file"
             (change)="uploadImage($event)"
             accept="image/jpg,.jpeg,.png,.jpg"/>
      <img class="user-picture"
           (click)="file.click()"
           src="{{user.avatar ? baseUrl + '/uploads/user/'+ user.uuid + '/' + user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
           data-holder-rendered="true">
      <div class="upload-avatar-icon"><img (click)="file.click()" src="../../../assets/images/icon-feather-edit.png">
      </div>
    </div>
    <!--    <span class="dashboard-welcome-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>-->
  </div>
  <div class="dashboard-body">
    <div class="dashboard-steps-container">
      <div (click)="(activeStep===1)?modal.openMemberModal():''" class="step-container"
           [ngClass]="(activeStep === 1) ? 'member-pulse': 'cursor-default'">
        <div [ngStyle]="(step > 1) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">1</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{'start_guide_step1'| translate}}</h3>
        <i *ngIf="(activeStep === 1)" class="bi bi-chevron-right step-arrow"></i>
      </div>
      <div (click)="(activeStep===2)?modal.openTeamModal():''" class="step-container"
           [ngClass]="(activeStep === 2) ? 'member-pulse': 'cursor-default'">
        <div [ngStyle]="(step > 2) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">2</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{workspaceTeams.length > 1? ('step2_create_teams' | translate) : ('step2_create_team' | translate)}}</h3>
        <i *ngIf="(activeStep === 2)" class="bi bi-chevron-right step-arrow"></i>
      </div>
      <div (click)="(activeStep===3)?organizeTeams():''" class="step-container"
           [ngClass]="(activeStep === 3) ? 'member-pulse': 'cursor-default'">
        <div [ngStyle]="(step > 3) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">3</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{'start_guide_step3' | translate}} {{workspaceTeams.length > 1 ? ('teams' | translate) : ('team' | translate)}}</h3>
        <i *ngIf="(activeStep === 3)" class="bi bi-chevron-right step-arrow"></i>
      </div>
      <div (click)="(activeStep===4) ? setupCollectors() :''" class="step-container"
           [ngClass]="(activeStep === 4) ? 'member-pulse': 'cursor-default'">
        <div [ngStyle]="(step > 4) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">4</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{'start_guide_step4' | translate}}</h3>
        <i *ngIf="(activeStep === 4)" class="bi bi-chevron-right step-arrow"></i>
      </div>
      <div (click)="(activeStep===5) ? seeResults():''" class="step-container"
           [ngClass]="(activeStep === 5) ? ((lastCompleted)? 'member-pulse':'border-blue-box') : 'cursor-default'">
        <div [ngStyle]="(step > 5) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">5</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{"start_guide_step5"| translate}}</h3>
        <i *ngIf="(activeStep === 5)" class="bi bi-chevron-right step-arrow"></i>
      </div>
      <div (click)="goToBilling()" class="step-container" *ngIf="!hideStep6"
           [ngClass]="(activeStep === 6) ? 'member-pulse': 'cursor-default'">
        <div [ngStyle]="chosePlan ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
          <h3 [style]="{'margin':'0'}">6</h3>
        </div>
        <h3 [style]="{'margin':'0'}">{{'start_guide_step6'| translate}}</h3>
        <i *ngIf="(step === 6)" class="bi bi-chevron-right step-arrow"></i>
      </div>
    </div>

    <div class="dashboard-steps-progress">
      <div [ngStyle]="(step > 1) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           class="progress-ellipse">
        <img *ngIf="!(step > 1)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 1)" [style]="{'margin-top':'.8rem'}"
             src="../../../assets/svg/dashboard-checked.svg">
      </div>
      <div [ngStyle]="(step > 1) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
           class="progress-line"></div>

      <div [ngStyle]="(step > 2) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           class="progress-ellipse">
        <img *ngIf="!(step > 2)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 2)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
      </div>
      <div [ngStyle]="(step > 2) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
           class="progress-line"></div>

      <div [ngStyle]="(step > 3) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           class="progress-ellipse" (click)="organizeTeams()">
        <img *ngIf="!(step > 3)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 3)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
      </div>
      <div [ngStyle]="(step > 3) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
           class="progress-line"></div>

      <div [ngStyle]="(step > 4) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           class="progress-ellipse">
        <img *ngIf="!(step > 4)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 4)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
      </div>
      <div [ngStyle]="(step > 4) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
           class="progress-line"></div>

      <div [ngStyle]="(step > 5) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           class="progress-ellipse">
        <img *ngIf="!(step > 5)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 5)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
      </div>
      <div *ngIf="!hideStep6" [ngStyle]="(step > 5) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
           class="progress-line"></div>

      <div [ngStyle]="(step > 6) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
           *ngIf="!hideStep6" class="progress-ellipse">
        <img *ngIf="!(step > 6)" src="../../../assets/svg/dashboard-not-checked.svg">
        <img *ngIf="(step > 6)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
      </div>
    </div>
  </div>

  <!-- Review Results Modal -->
  <button type="button" id="reviewResultsModal" class="btn btn-primary" data-bs-toggle="modal" hidden
          data-bs-target="#reviewResultsBackdrop">
  </button>

  <div class="modal fade" id="reviewResultsBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="staticBackdropLabel">{{'review_main_title' | translate}}</h3>
          <div class="response-rate-container" *ngIf="gotNextPolling">
            <div class="modal-reposonse-rate">
              <h5 style="font-size:1.1rem; font-weight: 600; margin:0 .8rem 0 0;">{{'review_response' | translate}}</h5>
              <h5 class="response-rate-value">{{teamResponseRate}}</h5>
              <span class="response-rate-value-percentage">%</span>
              <img class="response-rate-orange-cloud" src="../../../assets/svg/response-rate-orange.svg">
            </div>
            <div class="next-results-container">
              <h5 class="results-header">{{'review_results' | translate}}</h5>
              <div class="next-results-days">
                <h5 style="font-size:3.4rem; margin-bottom:0;">{{data.calculateDays(teamResponseDate)}}</h5>
                <h5 style="font-size:1.1rem; margin-bottom:0;">{{'review_days'| translate}}</h5>
              </div>
            </div>
          </div>
          <img src="assets/svg/close-button.svg" id="closeFeedbackLoopModal" alt="X" data-bs-dismiss="modal"
               aria-label="Close" class="close-modal">
        </div>
        <div class="modal-body">
          <div class="review-results-body-container">
            <h3>{{'review_title'| translate}}</h3>
            <h5 class="results-list-header">{{'review_subtitle'| translate}}</h5>
            <ul class="results-list">
              <li>{{'review_point1'| translate}}</li>
              <li>{{'review_point2'| translate}}</li>
              <li>{{'review_point3'| translate}}<a target="_blank" href="https://successteam.crunch.help/da/udfordringer-handlinger/gennemga-resultater-tag-handling">{{"review_point4"|translate}}</a></li>
            </ul>
          </div>
          <div style="display: flex">
            <button (click)="goToTeam()" data-bs-dismiss="modal" style="margin: 1rem auto; padding: 0 2rem; text-align: center; width: auto; border: transparent"
                    class="btn-blue">{{'review_button'|translate}} - {{teamResponse?.name}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
