import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AuthenticationService} from "./authentication.service";


@Injectable({
  providedIn: 'root'
})
export class ApiwrapperService {
  public baseUrl = environment.baseUrl + '/';
  // @ts-ignore
  currentUser = JSON.parse(localStorage.getItem('currentUser'));

  constructor(private http: HttpClient, private auth: AuthenticationService) {
  }

  cashRemoval(){
    this.auth.logout();
  }

  getOption(teamId = '') {
    if (teamId !== '') {
      return {
        headers: new HttpHeaders({
          // @ts-ignore
          'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
          'X-Team': teamId
        })
      };
    } else {
      return {
        headers: new HttpHeaders({
          // @ts-ignore
          'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
        })
      };
    }

  }

  // AUTH
  postActivateInvitation(invitation: string) {
    return this.http.post<any>(this.baseUrl + 'user/accept-invitation/' + invitation, '', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchApproveInvitation(invitationUuid: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.patch<any>(this.baseUrl + 'workspace/approve-user/invitationUuid/' + invitationUuid, {isApproval: 'yes'}, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getSearchWorkspaceMember(search: string) {
    return this.http.get<any>(this.baseUrl + 'workspace/search-workspace-user/'+ search, this.getOption())
      .pipe(
        map(res => {
            return res._embedded.users;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  postResendInvitation(email: any) {
    return this.http.post<any>(this.baseUrl + 'user/activate', {email})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  checkWorkspace(workspace: string) {
    return this.http.get<any>(this.baseUrl + 'workspace/check-name/' + workspace)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceStatistic() {
    return this.http.get<any>(this.baseUrl + 'workspace/statistics', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // USER
  getUserDetails(uuid: string) {
    // with uuid from first workspace
    return this.http.get<any>(this.baseUrl + 'user', {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${this.currentUser.access_token}`,
        'x-workspace': uuid
      })
    })
      .pipe(
        map(res => {
            // if (res.detail.displayName === '' || res.detail.displayName === null) {
            //   res.detail.displayName = res.detail.firstname + '' + res.detail.lastname;
            // }
            localStorage.setItem('userDetails', JSON.stringify(res));
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }
  patchUserWorkspaceDetails(uuid: string, body: any) {
    // with uuid from first workspace
    return this.http.get<any>(this.baseUrl + 'workspace-user/' + uuid, {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${this.currentUser.access_token}`,
        'x-workspace': uuid
      })
    })
      .pipe(
        map(res => {
            // if (res.detail.displayName === '' || res.detail.displayName === null) {
            //   res.detail.displayName = res.detail.firstname + '' + res.detail.lastname;
            // }
            localStorage.setItem('userDetails', JSON.stringify(res));
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getUserNextSignUpStep() {
    // with uuid from first workspace
    return this.http.get<any>(this.baseUrl + 'user/my-next-sign-up-step', {})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getCurrentWorkspaceUserRolesPriority() {
    return this.http.get<any>(this.baseUrl + 'user-roles-priority', this.getOption())
      .pipe(
        map(res => {
            for (const priority of res) {
              if (priority.workspace.uuid === localStorage.getItem('WORKSPACE_UUID')) {
                return priority;
              }
            }
            return {};
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getAllRoles(type: string) {
    // type is WORKSPACE or TEAM
    return this.http.get<any>(this.baseUrl + 'roles' + '?resource=' + type, this.getOption())
      .pipe(
        map(res => {
            return res._embedded.roles;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  postMemberRole(userUuid: any, roleUuid: any, teamId: string | undefined) {
    const body = {
      userUuid,
      roleUuid
    };

    let url: any;
    let options: any;

    if (teamId !== '') {
      url = 'change-user-team-role';
      options = this.getOption(teamId);
    } else {
      url = 'change-user-workspace-role';
      options = this.getOption();
    }

    return this.http.post<any>(this.baseUrl + url, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchTeamMemberLabel(memberUuid: any, label: any, teamUuid: any) {
    const options = this.getOption(teamUuid);
    const body = {
      labelUuid: label.uuid
    };
    return this.http.patch<any>(this.baseUrl + 'team-member/' + memberUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchUserSignUpStep(data: any): Observable<any> {
    return this.http.patch<any>(this.baseUrl + 'user/my-sign-up-step', data, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  uploadAvatar(image: File) {
    // const headers = new Headers()
    const avatar: File = image;
    const url = this.baseUrl + 'user/avatar';
    const formData: FormData = new FormData();
    formData.append('avatar', avatar);
    return this.http.post(url, formData)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          },
        ),
      );
  }

  uploadWorkspaceAvatar(image: File) {
    const avatar: File = image;
    const url = this.baseUrl + 'workspace/avatar';
    const formData: FormData = new FormData();
    formData.append('avatar', avatar);
    return this.http.post(url, formData, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          },
        ),
      );
  }

  getUser(id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'user/' + id)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchUser(id: any, data: any): Observable<any> {
    return this.http.patch<any>(this.baseUrl + 'user', data, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getUserList(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'user', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }


  // user workspaces

  getUserWorkspaces(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'workspace/my-workspaces', {
      headers: {
        // Authorization: `Bearer ${this.currentUser.access_token}`,
      }
    })
      .pipe(
        map(res => {
            return res._embedded['my-workspaces'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getUserCreatedWorkspaces(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/my-workspaces', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // workspaces

  getWorkspacesList(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspaces', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  postCreateWorkspace(name: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'workspace', {name})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  deleteWorkspaceUser(id: string) {
    return this.http.delete<any>(this.baseUrl + 'workspace/workspace-user/' + id, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }
  getWorkspaceUser() {
    return this.http.get<any>(this.baseUrl + 'workspace-user', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  updateWorkspaceUser(uuid: string, body: object) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'update-workspace-user/' + uuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  deleteWorkspace(id: string): Observable<any> {
    const options = this.getOption();
    return this.http.delete<any>(this.baseUrl + 'workspaces/' + id, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getWorkspaces(id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'workspace-detail/' + id, this.getOption())
      .pipe(
        map(res => {
            localStorage.setItem('WORKSPACE_UUID', res.uuid);
            localStorage.setItem('WORKSPACE_NAME', res.name);
            localStorage.setItem('WORKSPACE', JSON.stringify(res));
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspacePlanPrice(uuid: string, currencyUuid: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'workspace-plan-price/' + uuid + (currencyUuid !== '' ? ('?currencyUuid='+currencyUuid):''), this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchWorkspace(id: any, data: any): Observable<any> {
    const headers = new HttpHeaders({
      'X-Workspace': id,
    });
    return this.http.patch<any>(this.baseUrl + 'workspace', data, {headers})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  // INVITATION
  inviteUserToWorkspace(emails: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'invitation', emails, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postWorkspaceInvitation(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'invitation-body', {body}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchWorkspaceInvitation(body: any, invitationUuid: string) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'workspace-invitation-body/' + invitationUuid, {body}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceInvitation() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/default-invitation', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getTeamInvitation(teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team/default-invitation', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getUserInvitations() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'invitations', options)
      .pipe(
        map(res => {
            return res._embedded.invitations;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getUsersFromWorkspace(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'workspace/workspace-users', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getRolesCountsFromWorkspace(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/members-labels', this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getTeamsFromWorkspace(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'workspace/workspace-teams', this.getOption())
      .pipe(
        map(res => {
            return res._embedded.teams;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMyCreatedWorkspacesTeams() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'team/my-created-teams', options)
      .pipe(
        map(res => {
            return res._embedded.teams;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMyTeamsLeader() {
    // const id = '2205d57a-0475-11ea-b7e9-f2d473d6d142';
    const id = '79270474-181a-11ea-bf7a-a2e30155d1e6';
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'team/my-label-teams/labelUuid/' + id, options)
      .pipe(
        map(res => {
            return res._embedded.teams;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspacesUserWithPagination(page: any): Observable<any> {
    const options = this.getOption();
    let url = '';
    if (page !== 0) {
      url = this.baseUrl + 'workspace/workspace-users' + '?page=' + page;
    } else {
      url = this.baseUrl + 'workspace/workspace-users';
    }
    return this.http.get<any>(url, options)
      .pipe(
        map(res => {
            res._embedded.users.forEach((el: {
                user: { detail: { displayName: any; firstname: any; lastname: any; }; };
                email: any;
              }) => {
                if (el.user.detail.displayName === null) {
                  el.user.detail.displayName = el.email;
                  el.user.detail.firstname = el.email;
                  el.user.detail.lastname = el.email;
                }
              }
            );
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspacesUser(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/workspace-users', options)
      .pipe(
        map(res => {
            res._embedded.users.forEach((el: {
                user: { detail: { displayName: any; firstname: any; lastname: any; }; };
                email: any;
              }) => {
                if (el.user.detail.displayName === null) {
                  el.user.detail.displayName = el.email;
                  el.user.detail.firstname = el.email;
                  el.user.detail.lastname = el.email;
                }
              }
            );
            return res._embedded.users;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  // MEMBERS

  postAddMemberToTeam(body: any, teamId: string | undefined): Observable<any> {
    const options = this.getOption(teamId);
    return this.http.post<any>(this.baseUrl + 'team-member', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchAddMemberToTeam(body: any, memberUuid: string): Observable<any> {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'team-member/' + memberUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchTeamMember(body: any, memberUuid: string): Observable<any> {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'team-member/' + memberUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getUserTeams(uuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'team-member/userUuid/' + uuid, options)
      .pipe(
        map(res => {
            return res._embedded.members;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMemberActivity(teamUuid: string | undefined): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-activity', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  deleteTeamMember(uuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.delete<any>(this.baseUrl + 'team-member/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  // MEMBERS LABEL
  getMemberLabelsWorkspace(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'team-member-label-categories', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  postMemberLabelsWorkspace(body: any): Observable<any> {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'team-member-label', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMemberLabelsTeam(labelUuid: string, teamUuid: string | undefined): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-member-label-detail/' + labelUuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  // INDICATORS

  patchIndicators(body: { indicator: { uuid: string; detail: { purpose: any; why: any; what: any; how: any; }; }; }) {
    const options = this.getOption();
    // return this.http.patch<any>(this.baseUrl + 'question-indicator/' + body.indicator.uuid, {
    return this.http.patch<any>(this.baseUrl + 'update-question-indicator/' + body.indicator.uuid, {
      purpose: body.indicator.detail.purpose,
      why: body.indicator.detail.why,
      what: body.indicator.detail.what,
      how: body.indicator.detail.how,
    }, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamIndicators(teamUuid: string | undefined): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team/indicator-overview', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  // QUESTIONS

  getQuestionsSuperCategories(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'poll-question-indicator-categories', options)
      .pipe(
        map(res => {
            return res._embedded['poll-question-indicator-categories'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getQuestionsPoll(teamUuid: string | undefined, pollUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'poll-questions/pollUuid/' + pollUuid, options)
      .pipe(
        map(res => {
            return res._embedded['poll-question'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getQuestionsCategories(uuid: string): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'question-indicators/' + uuid, options)
      .pipe(
        map(res => {
            return res._embedded['question-indicators'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getQuestionsByIndicator(teamUuid: string | undefined, indicatorUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-questions/indicator-siblings-questions/indicatorUuid/' + indicatorUuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  patchTeamQuestion(question: any, uuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team-question/' + uuid, question, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchTeamQuestionToggle(question: any, uuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team-question/' + uuid, question, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getQuestionsByCategories(uuid: string): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'question-indicator/' + uuid, options)
      .pipe(
        map(res => {
            return res.questions;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  postTeamQuestions(teamUuid: string | undefined, body: any): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'team-question', body, options)
      .pipe(
        map(res => {
            return res.data;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getTeamQuestionsList(uuid: string | undefined, page: any): Observable<any> {
    const options = this.getOption(uuid);
    return this.http.get<any>(this.baseUrl + 'team-questions', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamQuestionsByIndicator(teamUuid: string | undefined, indicatorUuid: string, page: string): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-questions?indicatorUuid=' +
      indicatorUuid + '&page=' + page, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  deleteQuestions(uuid: string) {
    const options = this.getOption();
    return this.http.delete<any>(this.baseUrl + 'question/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamType() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'question/question-collections', options)
      .pipe(
        map(res => {
            return res._embedded['question-collections'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // INDICATORS
  getIndicatorInterval() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'indicator-intervals', options)
      .pipe(
        map(res => {
            return res._embedded.indicatorIntervals;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getIndicatorDetails(uuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'question-indicator-data/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // POLL
  postCreatePoll(body: { teamUuid: string | undefined; }) {
    const options = this.getOption(body.teamUuid);
    return this.http.post<any>(this.baseUrl + 'poll', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postCreateRandomPoll(body: { teamUuid: string | undefined; }) {
    const options = this.getOption(body.teamUuid);
    return this.http.post<any>(this.baseUrl + 'poll-generate-random', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postAssingPoll(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'assign-poll', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postTeamAssignPoll(body: { teamUuid: string | undefined; }) {
    const options = this.getOption(body.teamUuid);
    return this.http.post<any>(this.baseUrl + 'team/assign-poll', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getTeamPolls(uuid: string | undefined) {
    const options = this.getOption(uuid);
    return this.http.get<any>(this.baseUrl + 'polls', options)
      .pipe(
        map(res => {
            return res._embedded.polls;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getMyAssignmentPolls(uuid: string | undefined, teamUuid: string) {
    const options = this.getOption(uuid);
    return this.http.get<any>(this.baseUrl + 'my-assignments/teamUuid/' + teamUuid, options)
      .pipe(
        map(res => {
            return res._embedded.assignments;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  //@ts-ignore
  getPollById(uuid, page, teamUuid) {
    // @ts-ignore
    const headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid,
      'X-Team': teamUuid
    })
    return this.http.get<any>(this.baseUrl + 'user-poll-questions/pollUuid/' + uuid + '?page=' + page, {headers})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postUserPollResopnse(body: any, teamUuid: string) {
    const headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid,
      'X-Team': teamUuid
    })
    return this.http.post<any>(this.baseUrl + 'user-poll-response', body, {headers})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchUserPollResopnse(body: any, pollResponseUuid: any, teamUuid: string) {
    const headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid,
      'X-Team': teamUuid
    })
    return this.http.patch<any>(this.baseUrl + 'poll-response/' + pollResponseUuid, body, {headers})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchPollAssignment(body: any, pollUuid: string) {
    const headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid,
    })
    return this.http.patch<any>(this.baseUrl + 'assignment/' + pollUuid, body, {headers})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  // Polling
  postTeamPolling(teamUuid: string | undefined, body: any) {
    // body ex:
    // {
    //   "pollingFrequencyUuid": "d7428524-4bff-11ea-8301-54ee75f3c5e9",
    //   "dayOfWeekToStart": "2",
    //   "startDate": "2019-09-20",
    //   "endDate": "2020-09-20"
    // }
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'polling', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  //@ts-ignore
  getTeamActivePolling(teamUuid) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-active-polling', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  //@ts-ignore
  getTeamPolling(teamUuid) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'pollings', options)
      .pipe(
        map(res => {
            return res._embedded.pollings;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getPollingFrequencies() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'polling/frequencies', options)
      .pipe(
        map(res => {
            // return res._embedded['polling-frequencies'];
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getPollingSuggestedFrequency(frequencyUuid: string, qNUmber: number ){
      const options = this.getOption();
      return this.http.get<any>(this.baseUrl + 'polling-suggested-frequencies/'+frequencyUuid +'?questionNumber='+qNUmber, options)
          .pipe(
              map(res => {
                      return res._embedded['polling-frequencies'];
                      // return res;
                  },
                  (err: any) => {

                      return err;
                  }
              )
          );
  }

  patchActivePolling(pollingUuid: string, teamUuid: string | undefined, body: any) {
    // can update: 'status' [ACTIVE / PAUSE / STOP], pollingFrequencyUuid, endDate
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'polling/' + pollingUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  // SKILLS
  getSkillLevels(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'user-skill-levels', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getUserSkillLevel(id: string): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'user-skill-level/skillUuid/' + id, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getSkillByLevel(id: string): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'skill/level/' + id, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postSkillLevel(id: any): Observable<any> {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'user-skill-level', {levelUuid: id}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchSkillLevel(levelId: any): Observable<any> {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'user-skill-level', {levelUuid: levelId}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getSkillCategories(): Observable<any> {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'skill-categories', options)
      .pipe(
        map(res => {
            return res._embedded['skill-categories'];
          },
          (err: any) => {


            return err;
          }
        )
      );
  }

  //  TASKS
  getteamTask(indicatorUuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'task/team-tasks/indicatorUuid/' + indicatorUuid, options)
      .pipe(
        map(res => {
            // return res._embedded['workspace-tasks'];
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMyTasks(type: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'task/my-tasks' + '?type=' + type, options)
      .pipe(
        map(res => {
            return res._embedded['my-tasks'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getTeamTasks(teamUUid: string | undefined, type: string, page: string, indicator: string) {
    let url;
    if (type !== '') {
      url = this.baseUrl + 'task/my-tasks' + '?type=' + type + '&page=' + page;
    } else {
      url = this.baseUrl + 'task/my-tasks' + '?page=' + page;
    }

    if (indicator !== 'all') {
      url = url + '&indicator=' + indicator
    }
    const options = this.getOption(teamUUid);
    return this.http.get<any>(url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getIndicatorTasks(indicatorUuid: string, color: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + '/task/team-suggested-tasks/indicatorUuid/' + indicatorUuid + '?color=' + color, options)
      .pipe(
        map(res => {
            return res._embedded['team-suggested-tasks'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }


  getMemberAssignments() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + '/my-assignments/workspaceUuid/' + localStorage.getItem('WORKSPACE_UUID'), options)
      .pipe(
        map(res => {
            return res._embedded.assignments;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getCompletedAssignments() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + '/my-completed-assignments', options)
      .pipe(
        map(res => {
            return res.assignments;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  createTeamTask(body: any, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'task', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  createAction(body: any, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'task', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  postAssignAction(body: any, teamUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'assign-task', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  postCreateAction(body: any, teamUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'task', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getStatementActions(teamUuid: string, uuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'task/question-tasks/questionUuid/' + uuid, options)
      .pipe(
        map(res => {
            return res._embedded['tasks'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getTaskColor() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'task/colors', options)
      .pipe(
        map(res => {
            return res._embedded['task-colors'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchActivateTask(taskUuid: string, body: any, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'task/team-task/' + taskUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchTask(taskUuid: string, data: any, response: any, teamUuid: string | undefined, questionUuid: any) {
    let body;
    if (!response) {
      body = {status: data, questionUuid: questionUuid};
    } else {
      body = {reviewUuid: data};
    }
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'task/' + taskUuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  deleteTask(uuid: string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.delete<any>(this.baseUrl + 'task/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }


  patchActionToggle(param: { title: any; status: any }, uuid: any | string, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'task/', param, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getTaskReview() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'task-reviews', options)
      .pipe(
        map(res => {
            return res._embedded['task-reviews'];
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  // TEAMS
  createWorkspaceTeam(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'create-team', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  checkTeamName(name: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'validate-team-name/' + name, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  createWorkspaceMultipleTeams(body: any) {
    // {
    //   "teams": [
    //   {
    //     "name": "team1",
    //     "questionCollections": [
    //       "uuidTeamType"
    //     ]
    //   }
    // ]
    // }
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'create-teams', body, options)
      .pipe(
        map(res => {
            return res._embedded['new-teams'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchTeam(body: any, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  patchTeamType(body: any, teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team/question-collection', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  getMyTeams() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'my-teams', options)
      .pipe(
        map(res => {
            return res._embedded.teams;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  //@ts-ignore
  getTeamMembers(uuid) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'team/members/teamUuid/' + uuid + '?activeOnly=true', options)
      .pipe(
        map(res => {
            return res._embedded.members;
          },
          (err: any) => {

            // show the error page
            return err;
          }
        )
      );
  }

  deleteTeam(uuid: string | undefined) {
    const options = this.getOption(uuid);
    return this.http.delete<any>(this.baseUrl + 'team', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  addToWorkspace(hash: string | null) {
    const headers = new HttpHeaders();
    return this.http.post<any>(environment.baseUrl + '/user/workspace-invitation/' + hash, null, this.getOption())
      .pipe(
        map(res => {
          return res;
        }, (err: any) => {
          return err;
        })
      );
  }

  activateInvitation(hash: string) {
    return this.http.post<any>(environment.baseUrl + '/user/workspace-invitation/' + hash, null)
      .pipe(
        map(res => {
          return res;
        }, (err: any) => {
          return err;
        })
      );
  }

  // NOTIFICATIONS
  getMyNotifcations(lastDays = 0, page = 1, resourceType = '', status = '') {
    let url = this.baseUrl + 'notification/my-notifications';
    url += '?page=' + page + '&limit=100';
    if (status !== '') {
      url += '&status=' + status;
    }
    if (resourceType !== '') {
      url += '&resourceType=' + resourceType;
    }
    if (lastDays !== 0) {
      const date = new Date();
      date.setDate(date.getDate() - lastDays);
      const startDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
      url += '&startDate=' + startDate;
    }
    const options = this.getOption();
    return this.http.get<any>(url, options)
      .pipe(
        map(
          res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  patchNotificationStatus(notification: { uuid: string; }) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'notification/' + notification.uuid, {status: 'read'}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }


  patchVacationMode(emailNotification: any) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'notification/my-notification-setting', {
      emailNotification,
      smsNotification: 'no',
      inAppNotification: 'yes'
    }, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchNotificationSettings(emailNotification: any, smsNotification: any) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'workspaces', {emailNotification, smsNotification}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  postReadAllNotification() {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + '/user/notifications/mark-as-read', {}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }


  // REPORTS
  getTeamIndicatorReport(teamUuid: string | undefined, date: any) {
    let url = '';
    if (date === '') {
      url = this.baseUrl + 'indicator-report';
    } else {
      url = this.baseUrl + 'indicator-report?startDate=' + date.next + '&endDate=' + date.today;
    }
    const options = this.getOption(teamUuid);
    return this.http.get<any>(url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamReport(teamUuid: string) {
    // get team/indicator-report
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team/indicator-report', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );

  }

  getPollReportStatements(pollUuid: string, teamUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'question-report/' + pollUuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamReportStatements(teamUuid: string | undefined, date: { next: string; today: string; }) {
    const options = this.getOption(teamUuid);
    let url: string = ''
    if (date.next !== '') {
      url = 'team-question-report?startDate=' + date.next + '&endDate=' + date.today;
    } else {
      url = 'team-question-report';
    }
    return this.http.get<any>(this.baseUrl + url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getStatementResponseDistributionByPollUuid(teamUuid: string, questionUuid: any, pollUuid: any, date: { next: string; today: string; }) {
    const options = this.getOption(teamUuid);
    let url: string = ''
    if (date.next !== '') {
      url = 'question-response-distribution/questionUuid/'+questionUuid+'?startDate='+ date.next + '&endDate=' + date.today;
    } else {
      url = 'question-response-distribution/questionUuid/'+questionUuid+'?pollUuid='+pollUuid;
    }
    return this.http.get<any>(this.baseUrl + url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getMatrixTeamReportStatements(teamUuid: string | undefined, indicatorUuid: string, date: {
    next: string;
    today: string;
  }) {
    const options = this.getOption(teamUuid);
    let url: string = ''
    if (date.next !== '') {
      url = 'team-question-report/indicatorUuid/' + indicatorUuid + '?startDate=' + date.next + '&endDate=' + date.today;
    } else {
      url = 'team-question-report/indicatorUuid/' + indicatorUuid;
    }
    return this.http.get<any>(this.baseUrl + url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamWorstStatements(teamUuid: string | undefined, url: string) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + url, options)
      .pipe(
        map(res => {
            return res._embedded['questions-suggested-tasks'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

//   GET  /team-questions/task-suggested-questions/indicatorUuid/{indicatorUuid}
// X-Team


  // getPollGraphic(pollUuid: string) {
  //   const options = this.getOption();
  //   return this.http.get<any>(this.baseUrl + 'graph-report/' + pollUuid, options)
  //     .pipe(
  //       map(res => {
  //           this.graph.setGraph(res);
  //           return res;
  //         },
  //         (err: any) => {
  //           // show the error page
  //           return err;
  //         }
  //       )
  //     );
  // }

  // METHODS
  getMethods(active: any, teamUuid: string | undefined) {
    let url;
    if (active) {
      url = 'dictionaries?onlyActive=yes';
    } else {
      url = 'dictionaries';
    }
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + url, options)
      .pipe(
        map(res => {
            return res._embedded.dictionaries;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  postMethod(body: any, teamUuid: string | undefined) {
    // xTeam
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'dictionary', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchMethod(body: any, uuid: string, teamUuid: string | undefined) {
    // xTeam
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'dictionary/' + uuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  patchActivateMethod(status: any, uuid: string) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'dictionary/' + uuid, {status}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  deleteMethod(uuid: string, teamUUid: string | undefined) {
    const options = this.getOption(teamUUid);
    return this.http.delete<any>(this.baseUrl + 'dictionary/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getTags() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'tags', options)
      .pipe(
        map(res => {
            return res._embedded.tags;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getMyTeamsPercentage(uuid: string, date: { next: string; today: string; }) {
    const options = this.getOption();
    let url;
    if (uuid === '') {
      url = this.baseUrl + 'workspace-question-report?startDate=' + date.next + '&endDate=' + date.today;
    } else {
      url = this.baseUrl + 'workspace-question-report/' + uuid + '?startDate=' + date.next + '&endDate=' + date.today;
    }
    return this.http.get<any>(url, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // BILLING
  getBillingPlans() {
    let headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid
    });

    return this.http.get<any>(this.baseUrl + 'workspace/workspace-plans', {headers})
      .pipe(
        map(res => {
            return res._embedded['workspace-plans'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // Update billing information

  // body= {
  //   "country": "Romania",
  //   "company": "Apidemia",
  //   "streetAddress": "Vladeasa 40",
  //   "unit": "Unit",
  //   "city": "Oradea",
  //   "region": "Bihor",
  //   "postalCode": 410001,
  //   "notes": "Additional information"
  // }

  patchBillingInfo(body: any) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'workspace-billing', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceLogs() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/workspace-log', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  verifyVat(countryCode: any, vatNumber: string) {
    return this.http.get<any>('http://www.apilayer.net/api/validate?access_key=85d0e24dcaf833aa9e9fc03e97ba0c0e&vat_number=' + vatNumber)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // generate intent for workspace to add the card for payment
  getPlanIntent(planUuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'stripe/setup-intent/' + planUuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchBillingPlan(uuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/change-plan/' + uuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {

            return err;
          }
        )
      );
  }

  getWorkspaceBillingMethod() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/payment-method', options)
      .pipe(
        map(res => {
            return res.payments;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getWorkspaceBillingHistory() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/invoices', options)
      .pipe(
        map(res => {
            return res._embedded['workspace-invoices'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }


  getSimulateInvoice() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'simulate-invoice', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  // add payment method

  postBillingMethod(route: string) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'workspace/add-payment-method', {
      route
    }, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // email*
  // first name / last name
  postBillingContact(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'workspace/workspace-billing-contact', body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  sendTeamReminder(teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'send-poll-reminder', {}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  sendTeamMemberReminder(teamUuid: string | undefined, memberUuid: string) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'send-poll-reminder/memberUuid/' + memberUuid, {}, options)
      .pipe(
        map(res => {
            return res;
          }, (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTaskStatements(uuid: string, teamUuid: string) {
    // get tasks questions
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'question/task-questions/taskUuid/' + uuid, options)
      .pipe(
        map(res => {
            return res._embedded['task-questions'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getTeamArchivedTasks(uuid: string | undefined) {
    const options = this.getOption(uuid);
    return this.http.get<any>(this.baseUrl + 'task/my-archived-tasks', options)
      .pipe(
        map(res => {
            return res._embedded['my-archived-tasks'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getNewChallenges(teamUUid: string | undefined) {
    const options = this.getOption(teamUUid);
    return this.http.get<any>(this.baseUrl + 'team/challenges', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  patchNewChallenge(actionUuid: string, teamUuid: string | undefined, status: any) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team-indicator-report-detail/' + actionUuid, {status}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getUpdateNewChallenges(teamUUid: string | undefined, indicatorUuid: string) {
    const options = this.getOption(teamUUid);
    return this.http.get<any>(this.baseUrl + 'task/team-suggested-tasks/indicatorUuid/' + indicatorUuid + '?color=black', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getTeamStatistics(teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-statistics', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getLockedContent() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-locked-content', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getGraphicChartOfImprovements(teamUuid: string | undefined) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'chart-of-improvements', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getBillingEstimate(planUuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'simulate-invoice/planUuid/' + planUuid, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getCouponValidation(coupon: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'validate-coupon/' + coupon, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  postCoupon(coupon: string) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'workspace/apply-coupon/' + coupon, {}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceSize() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-sizes', options)
      .pipe(
        map(res => {
            return res._embedded['workspace-sizes'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getWorkspaceStatistics() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/statistics', options)
      .pipe(
        map(res => {
            return res
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getWorkspaceIndustries() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-industries', options)
      .pipe(
        map(res => {
            return res._embedded['workspace-industries'];
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  sendError(errorMessage: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'error-report', {errorMessage}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspacesPollings() {
    // /workspace/team-polls
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace/team-polls', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getTeamIndicatorsExport(startDate: any, endDate: any, teamUuid: any) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-indicators-export?startDate=' + startDate + '&endDate=' + endDate, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  //get not applicable
  getNotApplicable() {
    // /workspace/team-polls
    let headers = new HttpHeaders({
      // @ts-ignore
      'X-Workspace': localStorage.getItem('WORKSPACE_UUID'),
      // @ts-ignore
      'X-Translate': JSON.parse(localStorage.getItem('language')).uuid
    });

    return this.http.get<any>(this.baseUrl + 'reviews', {headers})
      .pipe(
        map(res => {
            return res._embedded.reviews;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  postNewReview(reason: string, assignmentUuid: string) {
    // /workspace/team-polls
    const options = this.getOption();
    let assignment = {reason, assignmentUuid};
    return this.http.post<any>(this.baseUrl + 'review', assignment, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  postStatementReview(questionUuid: string, reviewUuid: string, teamUuid: string, assignmentUuid: string) {
    const options = this.getOption(teamUuid);
    let assignment = {questionUuid, reviewUuid, assignmentUuid};
    return this.http.post<any>(this.baseUrl + 'question-reviews', assignment, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // create new not applicable

  getAllLanguages() {
    return this.http.get<any>(this.baseUrl + 'languages')
      .pipe(
        map(res => {
            return res._embedded.languages;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  // Ask for admin role
  postRequestRole(roleUuid: string) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'workspace-request-role', {roleUuid}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getRoleRequest() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-request-roles', options)
      .pipe(
        map(res => {
            return res._embedded['workspace-request-roles'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  patchRoleRequest(uuid: string, status: any) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'workspace-request-role/'+uuid,{status}, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceGraph() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-indicator-reports', options)
      .pipe(
        map(res => {
          return res._embedded['workspace-indicator-reports'];
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceTrends() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-trends', options)
      .pipe(
        map(res => {
          return res
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceIndicators() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-indicator-report', options)
      .pipe(
        map(res => {
            return res
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getWorkspaceAlerts() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'workspace-alert', options)
      .pipe(
        map(res => {
            return res
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamsRanking(type:string, endDate: any,){
      const options = this.getOption();
      return this.http.get<any>(this.baseUrl + 'workspace/workspace-teams-ranking?sort='+ type + '&endDate=' + endDate, options)
          .pipe(
              map(res => {
                      return res
                  },
                  (err: any) => {
                      // show the error page
                      return err;
                  }
              )
          );
  }

  getUserImmediateLeaderSuggestions(teamUuid: any) {
    return this.http.get<any>(this.baseUrl + 'immediate-leader-suggestions', {
      headers: new HttpHeaders({
        'X-Team': teamUuid
      })
    })
      .pipe(
        map(res => {
            return res
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  getTeamLeadersImmediateMembers(teamUuid: string | undefined): Observable<any> {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'team-activity?sort=leader', options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            // show the error page
            return err;
          }
        )
      );
  }

  updateUserImmediateLeader(uuid: string, teamUuid: any, body: object) {
    const options = this.getOption(teamUuid);
    return this.http.patch<any>(this.baseUrl + 'team-member/' + uuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  getWorkspaceUsersStatistic(){
      const options = this.getOption();
      return this.http.get<any>(this.baseUrl + '/workspace/workspace-users-statistic', options)
          .pipe(
              map(res => {
                      return res;
                  },
                  (err: any) => {
                      // show the error page
                      return err;
                  }
              )
          );
  }


  getWorkspaceCustomPoll(){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-polls', options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getWorkspaceCustomPollTemplates(){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-templates')
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollGeneralCategories(){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-team-general-categories', options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollSelectedGeneralCategories(uuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-selected-general-categories/customPollUuid/' + uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollSelectedSpecialCategories(uuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + '/custom-poll-selected-special-categories/customPollUuid/' + uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getGeneralCategoriesStatements(uuid: string){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'category-questions/' + uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollSpecialCategories(){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'special-categories', options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollSummary(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-summary/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollAssigments(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'my-custom-assignments/workspaceUuid/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollResponseRate(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-statistics/customPollUuid/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  updateCustomPoll(uuid: string, body: object) {
    const options = this.getOption();
    return this.http.patch<any>(this.baseUrl + 'custom-poll/' + uuid, body, options)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  createCustomPoll(body: any){
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'create-custom-poll',body , options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  postGeneralCategories(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-team-general-categories',body , options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  postSpecialCategories(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-team-special-categories',body , options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  postSpecialCategoryTeams(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'special-category-selected-teams',body , options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  postCustomPoll(body: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'start-custom-poll',body , options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingCategories(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-selected-categories/customPollUuid/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingCheckins(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-statistics-timed-question-collections/customPollUuid/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingCheckinsScore(customPollUuid: any, categoryUuid: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-timed-question-collection-score/timedQuestionCollectionUuid/'+categoryUuid, {customPollUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }
  
  getReportingCheckinsStatements(uuid: any){
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + '/timed-collection-questions/'+uuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingCategoryStatements(customPollUuid: any, categoryUuid: any, teamsUuid: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-category-questions/categoryUuid/'+categoryUuid, {customPollUuid, teams: teamsUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingScaledStatementResponses(customPollUuid: any, statementUuid: any, teamsUuid: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-scaled-question-responses/questionUuid/'+statementUuid, {customPollUuid, teams: teamsUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getReportingOpenStatementResponses(customPollUuid: any, statementUuid: any, teamsUuid: any, page: number, limit: number) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-open-question-responses/questionUuid/'+statementUuid+'?page='+page+'&limit='+limit, {customPollUuid, teams: teamsUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCategoryScore(customPollUuid: any, categoryUuid: any, teamsUuid: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-category-score/categoryUuid/'+categoryUuid, {customPollUuid, teams: teamsUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getQuestionScore(customPollUuid: any, statementUuid: any, teamsUuid: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl + 'custom-poll-question-score/questionUuid/'+statementUuid, {customPollUuid, teams: teamsUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollTasks(customPollUuid: any, categoryUuid: string, teamUuid: any) {
    const options = this.getOption(teamUuid);
    const url = categoryUuid === '' ? 'custom-poll-tasks/customPollUuid/'+customPollUuid : 'custom-poll-tasks/customPollUuid/'+customPollUuid+'?categoryUuid='+categoryUuid
    return this.http.get<any>(this.baseUrl + url, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  postCustomPollTask(taskDetails: any, teamUuid: any) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl + 'custom-poll-task', taskDetails, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollTeamsForSelector(customPollUuid: any) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'my-custom-poll-leader-teams/customPollUuid/'+customPollUuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollTaskAssignees(customPollUuid: any) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-task-assignees/customPollUuid/'+customPollUuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getActionPlanStatus(customPollUuid: any, teamUuid: any) {
    const options = this.getOption(teamUuid);
    return this.http.get<any>(this.baseUrl + 'custom-poll-task-plan/customPollUuid/'+customPollUuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  setActionPlanDone(customPollUuid: any, teamUuid: any) {
    const options = this.getOption(teamUuid);
    return this.http.post<any>(this.baseUrl +  'custom-poll-task-plan', {customPollUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollTimedQuestions() {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-timed-question-collections', options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getCustomPollTimedQuestionsStatements(collectionUuid: string) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'timed-collection-questions/'+collectionUuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  getChekinsSelectedCategories(customPollUuid: any) {
    const options = this.getOption();
    return this.http.get<any>(this.baseUrl + 'custom-poll-selected-timed-question-collections/customPollUuid/'+customPollUuid, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  deleteCustomPoll(customPollUuid: string) {
    return this.http.delete<any>(this.baseUrl + 'custom-poll/' + customPollUuid, this.getOption())
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  setCustomPollTimedCollections(customPollUuid: any, customPollTimedQuestionCollections: any) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl +  'custom-poll-timed-collections', {customPollUuid, customPollTimedQuestionCollections}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }

  stopCustomPoll(customPollUuid: string) {
    const options = this.getOption();
    return this.http.post<any>(this.baseUrl +  'stop-custom-poll', {customPollUuid}, options)
        .pipe(
            map(res => {
                    return res;
                },
                (err: any) => {
                    // show the error page
                    return err;
                }
            )
        );
  }
}
