<div class="d-flex flex-column align-items-center justify-content-center card-body p-0 onboarding-window-content">
  <div class="w-100" style="height: 100%;">
    <div class="manage-team-body">
      <div class="row">
        <div class="col-md-5" *ngIf="onboardingStep === 5">
          <mat-card class="team-card no-shadow team-card-small">
            <mat-card-header style="background-color: #fafbfd; display: block">
              <mat-card-title class="card-title">{{'workspace_tab3'|translate}}</mat-card-title>
              <div class="d-flex justify-content-between align-items-center" style="padding-left: 20px;">
                <div>
                  <input class="form-check-input" type="checkbox" [(ngModel)]="checkedAllMembers"
                         style="margin-right: 5px;" id="allMembers"
                         (change)="checkAllMembers()"> <label style="margin: 0;">{{'step51_select'| translate}}</label>
                </div>

                <div class="search-input-container">
                  <span class="align-items-center p-input-icon-left">
                    <i class="bi bi-search search-icon" *ngIf="!searchingApi"></i>
                    <div class="spinner-border text-primary" role="status"  style="position:absolute;margin: 15px" *ngIf="searchingApi"></div>
                    <input id="searchMember" class="search-or-invite-input" type="text" pInputText [(ngModel)]="searchValue"
                           placeholder="{{'step51_search' | translate}}" style="font-size: 1.4rem; font-weight: 300; font-family: 'Poppins', sans-serif" (ngModelChange)="this.searching.next($event)"
                           (keyup.enter)="(emptyResult && searchValue !== '') ? inviteEmail() :''">
                   </span>
                   <div *ngIf="emptyResult && searchValue !== ''" class="invite-new-email" (click)="inviteEmail()">
                    <i class="bi bi-send send-icon"></i>
                    <b>{{'organizeteam_invite' | translate}}</b>
                    <span>{{searchValue}}</span>
                   </div>
                </div>
              </div>
            </mat-card-header>
            <mat-card-content style="background-color: white">
              <div
                [ngStyle]="{'height': 'calc('+innerHeight+'px - 460px)'}"
                cdkDropList
                id="workspace-members"
                #membersList="cdkDropList"
                [cdkDropListData]="members"
                [cdkDropListConnectedTo]="[teamLeadersList, teamMembersList]"
                class="example-list"
                (cdkDropListDropped)="drop($event)"
                (scrollend)="onScroll($event)">
                <!--                    <div class="example-box" *ngFor="let item of members" cdkDrag>{{item}}</div>-->
                <div *ngFor="let item of members; let i = index" cdkDrag class="workspace-member" [ngStyle]="(item.user.startGuidTeamLeader + item.user.startGuideTeamMember) > 0 ? {'background-color':'#EFF4FB'}:{'background-color':'#F9FAFD'}" (click)="checkMember(i)">
                  <input class="form-check-input" type="checkbox" value="{{item.checked}}"
                         [ngModel]="item.checked">
                  <img class="user-picture" style="width: 30px; margin-right: 5px"
                       *ngIf="item.user"
                       src="{{item.user.avatar ? data.baseUrl + '/uploads/user/'+ item.user.uuid + '/' + item.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                       data-holder-rendered="true">
                  <p class="bd-highlight">{{item.user.detail.displayName ? item.user.detail.displayName===item.user.email ? item.user.email : item.user.detail.displayName + ' ('+ item.user.email +')' : item.user.detail.firstname ? item.user.detail.firstname +' '+ item.user.detail.lastname + ' ('+ item.user.email +')': item.user.email}}</p>
<!--                  <span pTooltip="{{getUserTeams(item.teams)}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"-->
<!--                        [tooltipDisabled]="(item.user.startGuidTeamLeader + item.user.startGuideTeamMember) === 0"-->
                  <span  [ngClass]="((item.user.startGuidTeamLeader + item.user.startGuideTeamMember) > 0) ? 'assign-member' : 'unassign-member'"
                        class="ms-auto bd-highlight">{{((item.user.startGuidTeamLeader + item.user.startGuideTeamMember) > 0) ? ('step51_member_status2'| translate) : ('step51_member_status1'| translate)}}</span>
                </div>
                <!-- <div *ngIf="emptyResult && searchValue !== ''" class="workspace-member" (click)="inviteEmail()">
                  <i class="bi bi-send-plus nav__icon"></i>
                  <p class="bd-highlight" style="padding-left: 10px">{{searchValue}}</p>
                  <span
                    class="ms-auto bd-highlight">invite</span>
                </div> -->

                <div *ngIf="members.length === 0 && searchValue === ''" class="no-workspace-member">
                  <p class="bd-highlight" style="padding-left: 10px">{{'manage_all_assigned'| translate}}</p>
                  <br>
                  <p class="bd-highlight" style="padding-left: 10px">{{'manage_missing'|translate}}</p>
                </div>
              </div>
              <div style="margin: auto; width: max-content;">
                <div class="spinner-border text-primary" role="status"  style="position: absolute" *ngIf="data.loadMembers"></div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="" *ngIf="onboardingStep === 7" [ngClass]="onboardingStep === 7 ? 'col-md-3' : ''"></div>
        <div class="" [ngClass]="onboardingStep === 7 ? 'col-md-6' : 'col-md-7'">
          <mat-card class="team-card no-shadow">
            <!--IF SINGLE TEAM-->
            <mat-card-header *ngIf="onboardingStep === 7">
              <mat-card-title style="font-size: 2.4rem">{{'does_this_title'|translate}}</mat-card-title>
              <mat-card-subtitle>{{'does_this_subtitle'|translate}}</mat-card-subtitle>
            </mat-card-header>

            <!--IF MULTIPLE TEAMS-->
            <mat-card-header *ngIf="onboardingStep === 5" class="step-5-card-header">
              <mat-card-title class="step-5-card-title card-title">
                {{(teams.length > 1) ? 'Teams' : 'Team ' + selectedTeam?.name}}
                <!-- <span class="p-badge-info badge-warning-single-team leader-pulse" *ngIf="teamMembers.length > 0 && teamMembers.length < 4">
                          <img src="../../../assets/svg/triangle-alert.svg" alt=""  tooltipStyleClass="tooltip-style" pTooltip="Tip: You'll need at least a team leader and 4 team members in each team."></span> -->
                <span *ngIf="teamMembers.length > 0 && teamMembers.length < 4" class="tip members-warning"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'step51_note'| translate}}</span>

              </mat-card-title>
              <div class="organize-teams-carousel">
                <p-carousel [value]="teams" [numVisible]="3" *ngIf="teams.length>1"
                  [numScroll]="3" [circular]="true">
                  <ng-template let-team pTemplate="item" let-i="index">
                    <div class="product-item" [ngStyle]="team === selectedTeam ? {'margin':'8px 0'}:{}" (click)="openTeamTab(team)">
                      <!--check badge-->
                      <span class="p-badge-info badge-check" *ngIf="team.members >= 4">
                            <img src="../../../assets/svg/check.svg" alt=""></span>
                      <!--triangle badge-->
                      <span class="p-badge-info badge-warning leader-pulse" *ngIf="team.members > 0 && team.members < 4">
                            <img src="../../../assets/svg/triangle-alert.svg" alt=""  tooltipStyleClass="tooltip-style" pTooltip="Tip: You'll need at least a team leader and 4 team members in each team."></span>

                      <div [ngClass]="team === selectedTeam ? 'teams-buttons-clicked' : 'teams-buttons' "
                          style="text-align: center" tooltipStyleClass="tooltip-style" pTooltip="{{team.name}}" tooltipPosition="bottom" [tooltipDisabled]="isInnerHeight">
                        <div class="teams-buttons-text">
                            <span style="background-color: #ffffff;color: black; border-radius: 50px; margin: 2px 0; padding: 4px 12px;">{{team.name | uppercase | slice :0:1}}</span>
                            <p class="align-items-center justify-content-center carousel-team-name"> {{team.name}}</p>
                            <img *ngIf="team.questionCollections.length > 0"  pTooltip="{{team.questionCollections[team.questionCollections.length-1].title | titlecase}}" tooltipPosition="bottom"
                                tooltipStyleClass="tooltip-style" class="team-type-icon" src="../../../assets/svg/team-{{team.questionCollections[team.questionCollections.length-1].slug | lowercase}}.svg">
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-carousel>
              </div>
            </mat-card-header>

            <mat-card-content style="background-color: white">


              <mat-card class="team-card"
                        [ngClass]="teamLeaders.length === 0 && onboardingStep === 5 ? 'leader-pulse' : 'no-shadow'">
                <mat-card-header class="leader-header">
                  <mat-card-title class="card-title"><img style="padding-right: 10px" src="../../../assets/svg/crown.svg"
                                       alt="woman_curly">{{'organizeteam_leaders' | translate}}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="leader-card">
                  <div
                    cdkDropList
                    id="leaders"
                    #teamLeadersList="cdkDropList"
                    [cdkDropListData]="teamLeaders"
                    [cdkDropListConnectedTo]="[teamMembersList]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)">

                    <div *ngFor="let item of teamLeaders; let i = index" cdkDrag
                         class="team-leader justify-content-between">
                      <div class="d-flex align-items-center">
                        <img class="user-picture" style="width: 30px; margin-right: 5px;"
                             *ngIf="item.user"
                             src="{{item.user.avatar ? data.baseUrl + '/uploads/user/'+ item.user.uuid + '/' + item.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                             data-holder-rendered="true">
                        <p class="bd-highlight">{{item.user.detail.displayName ? item.user.detail.displayName===item.user.email ? item.user.email : item.user.detail.displayName + ' ('+ item.user.email +')' : item.user.detail.firstname ? item.user.detail.firstname +' '+ item.user.detail.lastname + ' ('+ item.user.email +')': item.user.email}}</p>
                      </div>
                      <img *ngIf="onboardingStep === 5" class="delete-button"
                           src="../../../assets/svg/team-delete-button.svg"
                           style="cursor: pointer; padding: 0 10px"
                           (click)="!item.teamMember ? deleteTeamLeader(i) : deleteMemberOpenModal(item, selectedTeam, i, 'leader')" alt="">
                    </div>
                    <div class="team-leader-add">
                      {{'step51_leaders_text'|translate}}
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>

              <mat-card class="team-card"
                        [ngClass]="teamMembers.length === 0 &&  onboardingStep === 5 ? 'member-pulse' : 'no-shadow'">
                <mat-card-header class="member-header">
                  <mat-card-title class="card-title"><img style="padding-right: 10px" src="../../../assets/svg/user.svg"
                                       alt="woman_curly">
                    {{'step51_team_members' | translate}}
                  </mat-card-title>
                </mat-card-header>
                <mat-card-content class="member-card">
                  <div
                    cdkDropList
                    id="members"
                    #teamMembersList="cdkDropList"
                    [cdkDropListData]="teamMembers"
                    [cdkDropListConnectedTo]="[teamLeadersList]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)">

                    <div *ngFor="let item of teamMembers; let i = index" cdkDrag
                         class="team-member justify-content-between">
                      <div class="d-flex align-items-center">
                        <img class="user-picture" style="margin-right: 5px;"
                             *ngIf="item.user"
                             src="{{item.user.avatar ? data.baseUrl + '/uploads/user/'+ item.user.uuid + '/' + item.user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
                             data-holder-rendered="true">
                        <p class="bd-highlight">{{item.user.detail.displayName ? item.user.detail.displayName===item.user.email ? item.user.email : item.user.detail.displayName + ' ('+ item.user.email +')' : item.user.detail.firstname ? item.user.detail.firstname +' '+ item.user.detail.lastname + ' ('+ item.user.email +')': item.user.email}}</p>
                      </div>
                      <img *ngIf="onboardingStep===5" class="delete-button"
                           src="../../../assets/svg/team-delete-button.svg"
                           style="cursor: pointer; padding: 0 10px"
                           (click)="!item.teamMember ? deleteTeamMember(i) : deleteMemberOpenModal(item, selectedTeam, i, 'member')" alt="">
                    </div>

                    <!--                      <div class="example-box" *ngFor="let item of teamMembers" cdkDrag>{{item}}</div>-->
                    <div class="team-member-add">
                      {{'step51_drag_text'|translate}}
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="sticky-footer">
      <div (click)="copyInvitationLink()" class="d-flex align-items-center copy-invite-link">
        <i *ngIf="invitationLinkCopied" class="bi bi-patch-check-fill ng-star-inserted" style="color: green; font-size: 1.8rem;"></i>
        <i *ngIf="!invitationLinkCopied" style="height: 2.8rem; font-size: 1.8rem; color: #2551BF" class="bi bi-link-45deg"></i>
        <span [style]="invitationLinkCopied ? 'color: green' : 'color: #2551BF'" tooltipStyleClass="tooltip-style"  [pTooltip]="!invitationLinkCopied? ('invite_team'|translate): ''">{{invitationLinkCopied ? ('copy_link'|translate):('manage_copy_link'| translate)}}</span>
      </div>
      <div class="right-buttons">
        <button class="btn btn-text" *ngIf="teams.indexOf(selectedTeam)>0"
                (click)="selectTeam(false)">{{'organizeteam_previous' | translate}}
        </button>
        <button class="btn btn-hallow" style="margin: 0 1rem"
                *ngIf="teams.indexOf(selectedTeam) !== teams.length-1 " (click)="selectTeam(true)">
          {{'next_team_button' | translate}}
        </button>
        <!--        <button class="btn primary-btn px-5 py-2" (click)="addMembers(true)">Done</button>-->
        <button pButton pRipple class="btn primary-btn" style="margin: 0 1rem" (click)="this.addMembers(true)">{{'done_button' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<button type="button" id="deleteOrChangeMemberButton" class="btn btn-primary" data-toggle="modal" hidden
        data-target="#deleteOrChangeMember">
</button>

<div class="modal fade" id="deleteOrChangeMember" data-backdrop="static" data-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content member-changes-box" style="box-shadow: 0px 3px 30px #A6B1CE14; border-radius: 2rem;">
      <div class="modal-header member-changes-header">
        <div class="member-changes-title">
          <h3 class="modal-title" id="staticBackdropLabel">{{'team_confirmation_needed' | translate}}</h3>
          <img src="../../../assets/svg/close-button.svg" alt="X" data-dismiss="modal" aria-label="Close"
               id="closeDeleteOrChangeMemberModal" style="margin: 0 0 2rem auto; cursor: pointer">
        </div>
        <p id="description">
          {{('team_remove_member' | translate) + (memberToDelete.user?.detail.displayName | getDisplayName: memberToDelete.user?.detail.firstname: memberToDelete.user?.detail.lastname: memberToDelete.user?.email) + ' from team ' + memberToDelete.team?.name + ' ?'}}
        </p>
      </div>
      <div class="modal-body member-changes-body">
        <div class="member-changes-actions">
          <button
            (click)="deleteMember(memberToDelete)"
            label="Yes"
            pButton type="button"
            class="member-workspace-delete-button"></button>
          <button
                  (click)="deleteWorspaceMember(memberToDelete)"
                  label="Remove them from workspace"
                  pButton type="button"
                  class="member-changes-delete-button"></button>

          <button (click)="deleteOrChangeMemberCloseModal()"
                  label="Cancel"
                  pButton type="button"
                  class="member-changes-cancel-button"></button>
        </div>
      </div>
    </div>
  </div>
</div>
