<div class="dashboard-header">
    <h2 class="dashboard-welcome-title">
      {{'start_guide_title'|translate}} <span style="color: #365DBF;">{{ user?.detail.displayName | getDisplayName: user?.detail.firstname: user?.detail.lastname: user?.email }}</span></h2>
    <div class="avatar">
      <input type="file" hidden
             #file
             name="file"
             id="file"
             (change)="uploadImage($event)"
             accept="image/jpg,.jpeg,.png,.jpg"/>
      <img class="user-picture"
           (click)="file.click()"
           src="{{user.avatar ? baseUrl + '/uploads/user/'+ user.uuid + '/' + user.avatar.name : '../../../assets/svg/user-icon.svg' }}"
           data-holder-rendered="true">
      <div class="upload-avatar-icon"><img (click)="file.click()" src="../../../assets/images/icon-feather-edit.png">
      </div>
    </div>
</div>
<div class="dashboard-body">
    <div class="member-congrats-container">
      <div class="banner-container">
        <img class="banner-image" src="../../../assets/images/ok_green.png">
        <div class="banner-text" *ngIf="activeStep <= 2">
          <h1 class="banner-text-title">{{'blue_banner_title'| translate}}</h1>
          <span class="banner-text-description">{{'blue_banner_description'|translate}}</span>
        </div>
        <div class="banner-text" *ngIf="activeStep > 2">
          <h1 class="banner-text-title">{{'loop_started_title'| translate}}</h1>
          <span class="banner-text-description">{{'blue_banner_description2'|translate}}</span>
        </div>
      </div>
      <!-- <img class="banner" src="../../../assets/images/workspace-member-banner.png"> -->
      <div class="dashboard-progress-container">
          <div class="dashboard-steps-container">
              <div class="step-container"
                   [ngClass]="(activeStep === 1) ? 'border-blue-box': 'cursor-default'">
                <div [ngStyle]="(step > 1) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
                  <h3 [style]="{'margin':'0'}">1</h3>
                </div>
                <h3 [style]="{'margin':'0'}">{{'workspace_member_step1' | translate}}</h3>
                <i *ngIf="(activeStep === 1)" class="bi bi-chevron-right step-arrow"></i>
              </div>
              <div class="step-container"
                   [ngClass]="(activeStep === 2) ? 'border-blue-box': 'cursor-default'">
                <div [ngStyle]="(step > 2) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
                  <h3 [style]="{'margin':'0'}">2</h3>
                </div>
                <h3 [style]="{'margin':'0'}">{{'workspace_member_step2'| translate}}</h3>
                <i *ngIf="(activeStep === 2)" class="bi bi-chevron-right step-arrow"></i>
              </div>
              <div class="step-container" (click)="goToTeam()"
                   [ngClass]="(activeStep === 3 && userPolls.length > 0) ? 'member-pulse': 'cursor-default'">
                <div [ngStyle]="(step > 3) ? {'background-color':'#2551BF', 'color':'#FFFFFF'}:{}" class="step-number">
                  <h3 [style]="{'margin':'0'}">3</h3>
                </div>
                <h3 [style]="{'margin':'0'}">{{'workspace_member_step3'| translate}}</h3>
                <i *ngIf="(activeStep === 3)" class="bi bi-chevron-right step-arrow"></i>
              </div>
          </div>

          <div class="dashboard-steps-progress">
              <div [ngStyle]="(step > 1) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
                   class="progress-ellipse">
                <img *ngIf="!(step > 1)" src="../../../assets/svg/dashboard-not-checked.svg">
                <img *ngIf="(step > 1)" [style]="{'margin-top':'.8rem'}"
                     src="../../../assets/svg/dashboard-checked.svg">
              </div>
              <div [ngStyle]="(step > 1) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
                   class="progress-line"></div>

              <div [ngStyle]="(step > 2 && memberTeams.length >0) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
                   class="progress-ellipse">
                <img *ngIf="!(step > 2 && memberTeams.length >0)" src="../../../assets/svg/dashboard-not-checked.svg">
                <img *ngIf="(step > 2 && memberTeams.length >0)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
              </div>
              <div [ngStyle]="(step > 2) ? {'border-color':'#00BD82'} : {'border-color':'#D6D6D6'}"
                   class="progress-line"></div>

              <div [ngStyle]="(step > 3) ? {'background-color':'#00BD82'} : {'background-color':'#D6D6D6'}"
                   class="progress-ellipse">
                <img *ngIf="!(step > 3)" src="../../../assets/svg/dashboard-not-checked.svg">
                <img *ngIf="(step > 3)" [style]="{'margin-top':'.8rem'}" src="../../../assets/svg/dashboard-checked.svg">
              </div>
            </div>
      </div>
    </div>

    <h1 style="margin: 2rem 5rem;">{{'workspace_member_learning'| translate}}</h1>
    <div class="quick-learning-center-container">
      <div class="quick-learning-block" *ngFor="let quick of quickLearningText">
        <h1 class="quick-learning-title">{{quick.title}}</h1>
        <span class="quick-learning-description">{{quick.description}}</span>
        <span *ngIf="quick.link !== ''" class="quick-learning-link"><a [href]="quick.link" target="_blank" style="text-decoration: none;">{{quick.linkText}}</a></span>
        <span *ngIf="quick.link === ''" class="quick-learning-link" (click)="modal.openAdminRequestModal()" style="cursor: pointer">{{quick.linkText}}</span>
      </div>
    </div>
</div>
