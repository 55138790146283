import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {Router} from '@angular/router';
import {DataManipulationService} from '../../../services/data-manipulation.service';
import {environment} from '../../../../environments/environment';
import {MustMatch} from '../../../helpers/must-match.validator';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @Output() imageChange = new EventEmitter();
  workspace: any;
  workspaceTeams: any;
  userDetails: any;
  savedSuccess = {accountSavedSuccess: false, workspaceSavedSuccess: false};
  currentTime = 1;
  userDetailsForm: FormGroup |any;
  workspaceDetailsForm: FormGroup;
  baseUrl;
  submitted = {accountSubmitted: false, workspaceSubmitted: false};
  showUserDetails = false;
  showTeamsDetails = false;
  prioritySubscription: Subscription;
  userDetailsSubscription!: Subscription;
  userDetailsChanged: boolean = false;
  userPriorities: { role: { workspacePriority: any; }; } | undefined;
  workspacePriority = [];
  showMyTeams = false;
  phoneError = true;
  private myPriories: any;
  disabled: boolean = true;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;

  selectedCountryCode = 'gb';
  countryCodes = ['gb', 'dk'];
  apiLanguages = [];
  languageDisplayed: string ='';
  userTimeZone: any;

  constructor(public apiwrapper: ApiwrapperService, private router: Router, public translate: TranslateService,
              public formBuilder: FormBuilder, private data: DataManipulationService) {
    this.baseUrl = environment.baseUrl;
    this.getTime();
    this.apiLanguages = this.data.apiLanguages;
    // @ts-ignore
    let language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
    if (language.toLowerCase().includes('us')){
      this.selectedCountryCode = 'gb'
    } else if(language.toLowerCase().includes('dk')){
      this.selectedCountryCode = 'dk'
    }

    this.workspaceDetailsForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(1)]
      ]
    });
    this.prioritySubscription = this.data.userPriorities
      .subscribe((value: any) => {
        this.myPriories = value;
        if (value.role) {
          if (value.role.workspacePriority <= 10) {
            this.workspaceDetailsForm.controls['name'].disable();
          }
          this.userPriorities = value;
          // @ts-ignore
          this.getWorkspacePriority(this.userPriorities.role.workspacePriority);
        }
      });

    this.getUserDetails();
    this.getMyTeamsFromWorkspace();

    if (localStorage.getItem('upgrading')){
      localStorage.removeItem('upgrading');
    }
  }

  ngOnInit() {
    localStorage.setItem('headerText', 'Settings');
    this.userDetailsForm = this.formBuilder.group({
      firstname: ['', [
        Validators.required,
        Validators.minLength(1)]],
      lastname: ['', [
        Validators.required,
        Validators.minLength(1)]],
      displayName: ['', [
        Validators.minLength(1)]],
      phone: ['', []],
      email: [{value: '', disabled:true}],
      password: ['', [
        Validators.minLength(6)]],
      confirmPassword: ['', []],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    localStorage.removeItem('currentTeam');
    this.apiwrapper.getWorkspacesUser().subscribe(res => {
      this.data.setWorkspaceMembers(res);
      return true;
    }, err => {
      return false;
    });
    this.userDetailsSubscription = this.userDetailsForm.valueChanges.subscribe((x: any) => {
      if(this.userDetails.detail.firstname !== x['firstname']
      || this.userDetails.detail.displayName !== x['displayName'] ||
      this.userDetails.detail.lastname !== x['lastname'] ||
      (this.userDetails.detail.phone !== x['phone']['internationalNumber'] && x.phone?.internationalNumber !== '')){
        if(!this.userDetailsForm.invalid){
          this.userDetailsChanged = true;
        }
      } else if(x['confirmPassword'] === x['password'] && x['confirmPassword'] !== ""){
        if(!this.userDetailsForm.invalid){
          this.userDetailsChanged = true;
        }
      } else {
        this.userDetailsChanged = false;
      }

   })
  }

  ngOnDestroy() {
    this.prioritySubscription.unsubscribe();
    this.userDetailsSubscription.unsubscribe();
  }

  getUserDetails() {
    // @ts-ignore
    this.apiwrapper.getUserDetails(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.userDetails = res;
        // this.getMyTeamsFromWorkspace();
        this.userDetailsForm.setValue({
          email: res.email,
          firstname: res.detail.firstname,
          lastname: res.detail.lastname,
          displayName: res.detail.displayName,
          phone: res.detail.phone,
          password: '',
          confirmPassword: ''
        });
        this.showUserDetails = true;
        this.userTimeZone = res.detail.timezone;
        this.changeLanguage(res.detail.preferredLanguage.tag.split("_")[0], false)
      }, error => {
        console.log(error);
      });
  }


  getMyTeamsFromWorkspace() {
    this.data.showLoading();
    this.apiwrapper.getCurrentWorkspaceUserRolesPriority().subscribe(
      resolve => {
        // this.apiwrapper.getWorkspaceTeams().subscribe(res => {
        // @ts-ignore
        this.apiwrapper.getUserTeams(JSON.parse(localStorage.getItem('userDetails')).uuid).subscribe(res => {
          resolve.teams.forEach((role: { team: { uuid: any; }; role: { label: any; }; }) => {
            res.forEach((team: { team: { uuid: any; role: any; }; }) => {
              if (role.team.uuid === team.team.uuid) {
                team.team.role = role.role.label;
              }
            });
          });
          this.workspaceTeams = res;
          this.data.hideLoading();
          this.showMyTeams = true;
        }, error => {
          this.data.hideLoading();
          console.log(error);
        });
      });

  }

  get f() {
    return this.userDetailsForm.controls;
  }

  updateUserAccount() {
    this.data.showLoading();
    this.submitted.accountSubmitted = true;
    if (this.userDetailsForm.invalid && !this.phoneError) {
      this.data.hideLoading();
      return;
    }
    let data;
    if (this.userDetailsForm.value.password === '') {
      data = {
        firstname: this.userDetailsForm.value.firstname,
        lastname: this.userDetailsForm.value.lastname,
        displayName: this.userDetailsForm.value.displayName,
        phone: this.userDetailsForm.value.phone?.internationalNumber ? this.userDetailsForm.value.phone?.internationalNumber:'',
        email: this.userDetailsForm.value.email
      };
    } else {
      data = {
        firstname: this.userDetailsForm.value.firstname,
        lastname: this.userDetailsForm.value.lastname,
        displayName: this.userDetailsForm.value.displayName,
        phone: this.userDetailsForm.value.phone?.internationalNumber ? this.userDetailsForm.value.phone?.internationalNumber:'',
        email: this.userDetailsForm.value.email,
        password: this.userDetailsForm.value.password,
        passwordConfirm: this.userDetailsForm.value.confirmPassword
      };
    }
    this.apiwrapper.patchUser(this.userDetails.uuid, data).subscribe(res => {
      this.userDetailsChanged = false;
      this.data.hideLoading();
      this.data.showAlert('Your account was updated successfully.\n');
      this.showSaved('account');
      this.submitted.accountSubmitted = false;
      this.data.setChangedUserData(true);
      this.getUserDetails();
    }, err => {
      this.data.hideLoading();
      this.data.showAlertError('Could not update account!');
      this.data.showAlertError(typeof err.error.error.messages === 'object' ?
        this.data.parseError(err.error.error.messages) : err.error.error.messages);
    });
  }

  uploadImage(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.apiwrapper.uploadAvatar(file).subscribe(
              (res: any) => {
                this.userDetails.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.showSaved('account');
              },
              (error) => {
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }

  get e() {
    return this.workspaceDetailsForm.controls;
  }

  // @ts-ignore
  showSaved(block) {
    if (block === 'account') {
      this.savedSuccess.accountSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.accountSavedSuccess = false;
      }, 3001);
    }
    if (block === 'workspace') {
      this.savedSuccess.workspaceSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.workspaceSavedSuccess = false;
      }, 3000);
    }
  }

  getWorkspacePriority(myPriority: number) {
    this.apiwrapper.getAllRoles('workspace').subscribe(
      res => {
        this.workspacePriority = [];
        // remove above role
        res.forEach((el: { workspacePriority: number; }) => {
          if (el.workspacePriority <= myPriority) {
            // @ts-ignore
            this.workspacePriority.push(el);
          }
        });
      }, err => {
        console.log(err);
      }
    );
  }

  userVacationMode() {
    this.data.showLoading();
    // todo SPINNER
    let emailNotification = '';
    // tslint:disable-next-line:triple-equals
    if (this.userDetails.notificationSetting.emailNotification == 'yes') {
      emailNotification = 'no';
    } else {
      emailNotification = 'yes';
    }
    this.apiwrapper.patchVacationMode(emailNotification).subscribe(
      res => {
        this.data.hideLoading();
        this.userDetails.notificationSetting.emailNotification = emailNotification;
      },
      err => {
        this.data.hideLoading();
        console.log(err);
      }
    );
  }

  hasError(event: boolean) {
    this.phoneError = event;
  }

  checkPhone() {
    if (this.userDetailsForm.value['phone'] !== null && (this.userDetailsForm.value['phone']?.nationalNumber.length > 0 && this.userDetailsForm.controls['phone'].invalid)){
      this.phoneError = true;
      return true;
    } else {
      this.phoneError = false;
      return false;
    }
  }

  getDisplayLanguage(language: any) {
    if (language.language === 'English') {
      this.languageDisplayed = language.language
    } else if (language.language === 'Danish'){
      if (language.language === 'English') {
        this.languageDisplayed = 'Engelsk'
      } else if (language.language === 'Danish') {
        this.languageDisplayed = 'Dansk'
      }
    }

    if (language.language === 'English') {
      this.selectedCountryCode = 'gb'
    } else if (language.language === 'Danish') {
      this.selectedCountryCode = 'dk'
    }
  }

  changeLanguage(data: any, changed: boolean) {
    this.userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (data === 'gb') {
      data = 'en';
    }
    this.apiLanguages.forEach((el: any) => {
      if (data === el.tag.split("_")[0].toLowerCase() || data === el.tag.split("_")[1].toLowerCase()) {
        this.data.language.language = el.tag.split("_")[0];
        this.getDisplayLanguage(el);
        this.translate.use(this.data.language.language);
        // this.workspace.language = el;
        this.apiwrapper.patchUser(this.userDetails.uuid, {preferredLanguageUuid: el.uuid, timeZone: this.userTimeZone}).subscribe((res) => {
          localStorage.setItem('userDetails', JSON.stringify(res));
          localStorage.setItem('language', JSON.stringify(el));
          if(changed) {
            this.reloadPage();
          }
        });
      }
    });
  }

  reloadPage(){
    window.location.reload();
  }
}
