import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-timing',
  templateUrl: './timing.component.html',
  styleUrls: ['./timing.component.scss']
})
export class TimingComponent implements OnInit {

  @Input() timingDetails: any;

  @Output() timingDetailsEvent = new EventEmitter<any>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();

  startDate: any;
  endDate: any;
  minDateValue: any;
  invitation: any;
  dropdownOptions: any;
  selectedPeriod: any;
  apvStartingDate: any;

  changedEndDate: boolean = false;


  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.dropdownOptions = [
      {name: this.translate.instant('custom_poll_timing_drop_w1'), value: 7},
      {name: this.translate.instant('custom_poll_timing_drop_w2'), value: 14},
      {name: this.translate.instant('custom_poll_timing_drop_w3'), value: 28}
    ];
    if(this.timingDetails?.date) {
      this.startDate = new Date(this.timingDetails.date);
    } else {
      this.startDate = new Date();
    }
    this.setPollDates();
    this.minDateValue = new Date();
    this.invitation = this.timingDetails.invitation;
  }

  changeStartDate(event: any) {
    this.startDate = new Date(event);
    if (this.selectedPeriod) {
      this.getEndDate(this.startDate, this.selectedPeriod);
    }
    this.setPollDates();
  }

  getEndDate(startDate: any, time: any) {
    this.selectedPeriod = time;
    let start = new Date(startDate);
    this.endDate = new Date(start.setDate(start.getDate() + this.selectedPeriod.value.value));
    this.changedEndDate = true;
  }

  changeInvitation(invitation: any) {
    this.invitation = invitation;
  }

  endTimingStep() {
    this.timingDetailsEvent.emit({invitation: this.invitation, startDate: this.startDate, endDate: this.endDate, customPollUuid: this.timingDetails.pollUuid, startingDate: this.apvStartingDate});
  }

  back() {
    this.stepBack.emit({step: 3, uuid: this.timingDetails.pollUuid});
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

  setPollDates() {
    const oneDay = 24 * 60 * 60 * 1000;
    const dayInHours = 1000 * 60 * 60;
    const today = new Date();
    const dateToCompare = this.startDate;
    let diffDays = Math.round(Math.abs((today.getTime() - dateToCompare.getTime()) / oneDay));
    const diffDaysInHours = Math.round(Math.abs((today.getTime() - dateToCompare.getTime()) / dayInHours));

    if(diffDays === 0 && (diffDaysInHours <= 24 && diffDaysInHours > 12)) {
      diffDays = 1;
    }
    this.apvStartingDate = {daysRemaining: diffDays, subtitle: diffDays > 0 ? 1 : 2};
  }

}
