import {Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {FormControl, Validators} from "@angular/forms";
import {debounceTime, distinctUntilChanged, fromEvent, Subject, Subscription} from "rxjs";
import {ModalsComponent} from "../modals/modals.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-organize-teams',
  templateUrl: './organize-teams.component.html',
  styleUrls: ['./organize-teams.component.scss']
})
export class OrganizeTeamsComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.innerHeight = window.innerHeight;
    if(window.innerHeight > 1000) {
      this.isInnerHeight = true;
    }
  }

  onboardingStep = 5;
  members: any = [];
  workspaceMembers: any = [];
  teamLeaders: any = [];
  teamMembers: any = [];
  selectedTeam: any;
  private roleTeamLeader: any;
  private roleTeamMember: any;
  teams: any = [];
  membersInvited: any = [];
  public user: any;
  searchValue: any = '';
  emptyResult: boolean = false;
  invitationLinkCopied: boolean = false;
  memberToDelete: {user: any, team: any, uuid: any, index: any, type: any} = {user: null, team: null, uuid: '', index: '', type: ''};
  @HostListener('scroll', ['$event'])
  searching = new Subject<string>();
  searchingApi: boolean = false;

  onScroll(event: any) {
    if (((event.target.offsetHeight + Math.ceil(event.target.scrollTop) >= event.target.scrollHeight - 50) && !this.data.loadMembers)&& (this.data.workspaceMembersPage !==this.data.workspaceMaxMembersPage)) {
      // get the next user page
      this.data.loadMembers = true;
      this.data.getWorkspaceMembers(this.data.workspaceMembersPage + 1);
    }
  }

  emailCheck = new FormControl('', [Validators.required, Validators.email]);

  membersSubs: Subscription;
  teamsSubs: Subscription;
  checkedAllMembers: boolean = false;
  private searchResult: any = [];
  private invitedMember: any;
  private singleTeamParam: string | null | undefined;
  public isInnerHeight: boolean = false;
  innerHeight: any;


  constructor(public api: ApiwrapperService, public data: DataManipulationService,
              public modal: ModalsComponent, private activeRoute: ActivatedRoute) {

    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));

    this.membersSubs = data.gotMembersSubs.subscribe((gotMembers: any) => {
      if (gotMembers) {
        if (this.data.isInvited) {
          data.allMembers.forEach((user: any) => {
            if (user.user.email === this.invitedMember) {
              this.members = [...this.searchResult];
              // push member to this.members and this.workspaceMembers
              this.members.unshift(user);
              this.searchResult.unshift(user);
              this.workspaceMembers.push(user);
              this.invitedMember = '';
            }
          })
          this.data.isInvited = false;
        } else {
          this.members = [];
          this.workspaceMembers = [];
          this.members = data.allMembers;
          this.workspaceMembers = data.allMembers;
        }
      }
    })

    this.teamsSubs = this.data.gotOrganizeTeamsSubs.subscribe((gotTeams: any) => {
      if (gotTeams) {
        this.getTeams();
      }
    })

    this.searching.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.emptyResult = false;
          if (value.length > 0 ) {
            this.searchingApi = true;
            api.getSearchWorkspaceMember(value).subscribe(
                res => {
                  this.emptyResult = false;
                  res.forEach((el: any, index: number)=>{
                    el.teams = [];
                    this.teamLeaders.forEach((leader:any) => {
                      if (leader.uuid === el.uuid){
                        res.splice(index, 1);
                      }
                    })
                    this.teamMembers.forEach((member:any) => {
                      if (member.uuid === el.uuid){
                        res.splice(index, 1);
                      }
                    })
                  })
                  this.searchingApi = false;
                  if (res.length > 0) {
                    // check if member is team member or team leader and remove from team
                    this.members = res;
                  } else if (res.length === 0){
                    let l = false;
                    let m = false;
                    // check if search is in team
                    this.teamLeaders.forEach((leader:any) => {
                      if (leader.user.email === value){
                        l =true;
                      }
                    })
                    this.teamMembers.forEach((member:any) => {
                      if (member.user.email === value){
                        m = true;
                      }
                    })
                    if (!l && !m){
                      this.emptyResult = true;
                    }
                  }
                }
            )
          } else {
            this.data.setGotMembers(true);
          }
        });
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    if(window.innerHeight > 1000) {
      this.isInnerHeight = true;
    }

    // get all members
    // get all teams / get team(s) for organize
    this.api.getAllRoles('team').subscribe(
      res => {
        res.forEach((el: { name: string; uuid: any; }) => {
          if (el.name === 'team-admin') {
            this.roleTeamLeader = el;
          } else if (el.name === 'team-member') {
            this.roleTeamMember = el;
          }
        });
      }, err => {
        console.log(err);
      });
  }

  ngOnDestroy(): void {
    this.membersSubs.unsubscribe();
    this.teamsSubs.unsubscribe();
  }


  drop(event: any) {
    let checkedMembers = false;
    this.members.forEach((member: any) => {
      if (member.checked) {
        checkedMembers = true;
      }
    })

    // if members are checked and containers are not the same move all the checked members
    if ((event.previousContainer.id !== event.container.id) && checkedMembers) {
      // this.members.forEach((el: any, index: any, object:any) => {
      for (let i = 0; i < this.members.length; i++) {
        let el = this.members[i];
        if ((event.container.id === 'leaders') && el.checked) {
          el.checked = false;
          this.teamLeaders.push(el);
          this.members.splice(i, 1);
          --i;
        } else if ((event.container.id === 'members') && el.checked) {
          el.checked = false;
          this.teamMembers.push(el);
          this.members.splice(i, 1);
          --i;
        }

        this.selectedTeam.leaders = this.teamLeaders.length;
        this.selectedTeam.members = this.teamMembers.length;
      }

    } else if (event.previousContainer === event.container) {
      // if there is the same container move element to index
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // if not same container move item
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.selectedTeam.leaders = this.teamLeaders.length;
      this.selectedTeam.members = this.teamMembers.length;
    }


    for (let i = 0; i < this.teamLeaders.length; i++) {
      let el = this.teamLeaders[i];
      // set leader role
      // check if the team is already in member
      let num = 0;
      el.teams.forEach((team: any) => {
        if (team.name === this.selectedTeam.name) {
          team.role = this.roleTeamLeader;
          return;
        }
        ++num;
      });

      if (num === el.teams.length) {
        const userTeam = {
          uuid: this.selectedTeam.uuid,
          name: this.selectedTeam.name,
          assign: false,
          oldRole: {},
          role: this.roleTeamLeader,
        };
        el.teams.push(userTeam);
      }
    }

    for (let i = 0; i < this.teamMembers.length; i++) {
      let el = this.teamMembers[i];
      // set member role
      let num = 0
      el.teams.forEach((team: any) => {
        if (team.name === this.selectedTeam.name) {
          team.role = this.roleTeamMember;
          return
        }
        ++num;
      })


      if (num === el.teams.length) {
        const userTeam = {
          uuid: this.selectedTeam.uuid,
          name: this.selectedTeam.name,
          assign: false,
          oldRole: {},
          role: this.roleTeamMember,
        };
        el.teams.push(userTeam);
      }
    }
  }

  // SELECT TEAM
  openTeamTab(team: any) {
    this.checkedAllMembers = false;
    // if (team === this.selectedTeam) {
    //   return;
    // }
    for (let i = 0; i < this.teamLeaders.length; i++) {
      this.members.push(this.teamLeaders[i]);
    }
    this.teamLeaders = [];

    for (let i = 0; i < this.teamMembers.length; i++) {
      this.members.push(this.teamMembers[i]);
    }
    this.teamMembers = [];

    this.selectedTeam = team;
    for (let i = 0; i < this.members.length; i++) {
      let el = this.members[i];
      // @ts-ignore
      if (el.teams.length > 0) {
        // @ts-ignore
        el.teams.forEach((memberTeam) => {
          if (memberTeam.uuid === team.uuid) {
            // check the team role and add it to the correct array
            if (memberTeam.role.teamPriority === 20) {
              el.teamMember = true;
              this.teamLeaders.push(el);
            } else if (memberTeam.role.teamPriority === 10) {
              el.teamMember = true;
              this.teamMembers.push(el);
            }
            this.members.splice(i, 1);
            --i;
          }
        })
      }
    }
    this.searchResult = this.members;
  }

  deleteTeamLeader(i: number) {
    // move team leader back to workspace members
    let member = this.teamLeaders[i];
    if (member.teams.length > 0) {
      // remove the team
      for (let i = 0; i < member.teams.length; i++) {
        if (member.teams[i].uuid === this.selectedTeam.uuid) {
          member.teams.splice(i, 1);
        }
      }
    }
    this.selectedTeam.leaders--;
    this.members.push(this.teamLeaders[i]);
    this.teamLeaders.splice(i, 1);
  }

  deleteTeamMember(i: number) {
    let member = this.teamMembers[i];
    if (member.teams.length > 0) {
      // remove the team
      for (let i = 0; i < member.teams.length; i++) {
        if (member.teams[i].uuid === this.selectedTeam.uuid) {
          member.teams.splice(i, 1);
        }
      }
    }

    // move team member back to workspace members
    this.selectedTeam.members--;
    this.members.push(this.teamMembers[i]);
    this.teamMembers.splice(i, 1);
  }

  inviteEmail() {
    if ((/\s/g).test(this.searchValue)) {
      this.searchValue = this.searchValue.replace(/\s/g, "");
    }
    // invite if the email is not in members
    // check if valid email
    this.emailCheck.setValue(this.searchValue);
    if (this.emailCheck.valid) {
      this.data.showLoading();
      // invite member to workspace and add it to workspace members :)
      this.api.inviteUserToWorkspace({
        emails: [this.searchValue],
        roleUuid: this.data.roleWorkspaceMember
      }).subscribe(res => {
        // TODO after invitation add user
        // get the user by search
        this.api.getSearchWorkspaceMember(this.searchValue).subscribe(
          res => {
            res[0].teams= [];
            this.data.allMembers.unshift(res[0]);
            this.members = this.data.allMembers;
            this.data.hideLoading();
          }, error => {
            this.data.hideLoading();
          })
        // after invite push user to members and workspace-members as first
        this.data.isInvited = true;
        this.invitedMember = this.searchValue;
        this.searchValue = '';
        // get all members and trigger subs
        // @ts-ignore
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error sending the invitation. Try again a bit later.');
      });
    } else {
      this.data.showAlertError('"' + this.searchValue + '" not a valid email');
    }
  }

  getTeams() {
    this.data.showLoading();
    // get teams for organize
    this.teams = this.data.organizeTeams;
    let teamsNumber = 0;
    // check if team has members
    this.teams.forEach((team: any) => {
      // change team type  physical workplace to office
      if (team.questionCollections.length > 0){
        if (team.questionCollections[team.questionCollections.length-1].slug.toLowerCase() === 'physical workplace'){
          team.questionCollections[team.questionCollections.length-1].slug = 'office';
        }
      }
      // if team has members get members and assign them properly
      if (team.totalMembers > 0) {
        this.api.getTeamMembers(team.uuid).subscribe(
          res => {
            // add the team to the user
            // name / uuid / role
            res.forEach((el: {
              user: any;
              uuid: any;
            }) => {
              this.members.forEach((member: {
                teams: any;
                user: any;
                assign: boolean;
                uuid: any;
                memberUuidToDelete: any;
              }) => {
                if (member.user.uuid === el.user.uuid) {
                  member.teams.push({
                    name: team.name,
                    uuid: team.uuid,
                    assign: true,
                    oldRole: el.user.teamRole,
                    role: el.user.teamRole
                  });
                  member.memberUuidToDelete = el.uuid;
                }
              })
            })
            this.workspaceMembers = [...this.members];
            teamsNumber = teamsNumber + 1;
            this.showModal(teamsNumber);
          }
        );
      } else {
        teamsNumber = teamsNumber + 1;
      }
      this.showModal(teamsNumber);
    })
  }

  showModal(number: number) {
    if (this.teams.length === number) {
      this.selectedTeam = this.teams[0];
      this.openTeamTab(this.selectedTeam);
      this.data.hideLoading();
      this.modal.openOrganizerModal();
    }
  }

  addMembers(nextTeam: boolean) {
    // add the members that are in leaders and team members to the list
    if (nextTeam) {
      // invite members
      this.data.showLoading();
      let assignTeam = 0;
      let teamMembersAdded = 0;
      // show modal to show user how many teams are empty
      this.members.concat(this.teamLeaders).concat(this.teamMembers).forEach((el: any) => {
        if (el.teams.length > 0) {
          // el.uuid - user uuid
          el.teams.forEach((team: any) => {
              // teamUuid = team.uuid
              // roleUuid = team.role.uuid
              if (!team.assign) {
                assignTeam = assignTeam + 1;
                this.api.postAddMemberToTeam({userUuid: el.user.uuid, roleUuid: team.role.uuid},
                  team.uuid).subscribe(
                  res => {
                    teamMembersAdded = teamMembersAdded + 1;
                    if (teamMembersAdded === assignTeam) {
                      this.data.hideLoading();
                      if (this.data.openFromTeam) {
                        this.data.addedMembersToTeam(true);
                      } else {
                        this.data.getAllTeams = false;
                        this.data.getWorkspaceTeams()
                      }
                      // @ts-ignore
                      document.getElementById('closeOrganizeModal').click();
                      this.data.goToTeamCreated();
                    }
                  }, err => {
                    //todo - remove after
                    teamMembersAdded = teamMembersAdded + 1;
                    if (teamMembersAdded === assignTeam) {
                      this.data.hideLoading();
                      if (this.data.openFromTeam) {
                        this.data.addedMembersToTeam(true);
                      } else {
                        this.data.getAllTeams = false;
                        this.data.getWorkspaceTeams()
                      }
                      // @ts-ignore
                      document.getElementById('closeOrganizeModal').click();
                      // don't redirect to team if in onboarding
                      this.data.goToTeamCreated();
                    }
                  })
              }

              // todo check to see if the users that was already in team has different role
              // and assign current role
              // change team role iz going before adding member
              if (Object.entries(team.oldRole).length !== 0 && team.role.uuid !== team.oldRole.uuid) {
                assignTeam = assignTeam + 1;
                // change user role ERROR - if user
                this.api.postMemberRole(el.user.uuid, team.role.uuid, team.uuid).subscribe(
                  res => {
                    teamMembersAdded = teamMembersAdded + 1;
                    if (teamMembersAdded === assignTeam) {
                      // get user
                      this.data.hideLoading();
                      // @ts-ignore
                      document.getElementById('closeOrganizeModal').click();
                      this.data.goToTeamCreated();
                      this.data.getWorkspaceMembers(1);

                      // if single team in organize and url uuid === teamUuid - get team members in team
                      if (this.data.openFromTeam) {
                        this.data.addedMembersToTeam(true);
                      }
                    }
                  }, err => {
                    this.data.showAlertError(err.error.error.messages[0]);
                  });

              }
            }
          )
        }
      })

      if (assignTeam === 0) {
        this.data.hideLoading();
        document.getElementById('closeOrganizeModal')?.click();
      }
      // after invite members to the teams, after the invitation process is completed go to next screen
    } else {
      // this.selectTeam(true);
    }
  }

  selectTeam(next: boolean) {
    if (next) {
      // this.selectedTeam = this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) + 1];
      this.openTeamTab(this.teams[this.teams.indexOf(this.selectedTeam) + 1]);
    } else {
      // this.selectedTeam = this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) - 1];
      this.openTeamTab(this.teams[this.teams.indexOf(this.selectedTeam) - 1]);
    }
  }

  checkAllMembers() {
    this.members.forEach((el: { checked: boolean; }) => {
        el.checked = this.checkedAllMembers;
      }
    )
  }

  getUserTeams(teams: any) {
    if (teams.length === 0) {
      return 'Member not assign';
    } else {
      let response = '';
      teams.forEach((team: { name: string; }) => {
        response = response + team.name + '\n'
      });
      return response;
    }
  }

  checkMember(i: number) {
    this.members[i].checked = !this.members[i].checked;
    let check = true;
    this.members.forEach((el: { checked: any; }) => {
      if (!el.checked) {
        //uncheck all
        check = false;
      }
    })
    this.checkedAllMembers = check;
  }

  copyInvitationLink() {
    this.invitationLinkCopied = true;
    navigator.clipboard.writeText(this.data.workspaceInvitation + localStorage.getItem('WORKSPACE_UUID'));
    setTimeout(() => {
      this.invitationLinkCopied = false;
    }, 1000)
  }

  deleteMemberOpenModal(member: any, team: any, index: number, type: string ) {
    this.memberToDelete = {user: member.user, team: team, uuid: member.memberUuidToDelete, index: index, type: type};
    const openModal = document.getElementById('deleteOrChangeMemberButton');
    openModal?.click();
  }

  deleteMember(member: any) {
    this.data.showLoading();
    this.api.deleteTeamMember(member.uuid, member.team?.uuid).subscribe(res => {
      if(member.type === 'member') {
        this.deleteTeamMember(member.index);
      } else {
        this.deleteTeamLeader(member.index);
      }
      this.deleteOrChangeMemberCloseModal();
      this.data.hideLoading();
      this.data.showAlert('Member deleted.');
    }, err => {
      this.data.hideLoading();
      this.data.showAlertError(err.error.error.messages[0]);
    });
  }

  deleteWorspaceMember(member: any) {
    this.data.showLoading();
    this.api.deleteWorkspaceUser(member.uuid).subscribe(res => {
      this.data.hideLoading();
      if(member.type === 'member') {
        this.deleteTeamMember(member.index);
      } else {
        this.deleteTeamLeader(member.index);
      }
      this.deleteOrChangeMemberCloseModal();
      this.data.showAlert('Member removed.');
    }, err => {
      this.data.hideLoading();
      this.data.showAlertError(err.error.error.messages[0]);
    });
  }

  deleteOrChangeMemberCloseModal() {
    const closeModal = document.getElementById('closeDeleteOrChangeMemberModal');
    closeModal?.click();
  }
}
