import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import {catchError, filter, take, switchMap, map} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {ApiwrapperService} from '../services/apiwrapper.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService, private api: ApiwrapperService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.access_token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`
        }
      });
    }
    // @ts-ignore
    return next.handle(request).pipe(catchError(error => {
      // this.apiwrapper.sendError(JSON.stringify(error)).subscribe(
      //   res => {
      //     console.log(res);
      //   }
      // );

      if (error.url.includes('/oauth/refresh') && error.error.error_description.includes('refresh token is invalid')){
        localStorage.setItem('auth_exp', 'true');
        this.authService.logout();
      }
      if (error instanceof HttpErrorResponse && error.status === 401) {
        if (!request.url.includes('generate')) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
        // } else if (error instanceof HttpErrorResponse && error.status === 403) {
        //   localStorage.clear();
        //   location.reload();
      }else if (error instanceof HttpErrorResponse && error.status === 402) {
        // @ts-ignore
        document.getElementById('workspaceUpdrageModalButton').click()
      } else if (error instanceof HttpErrorResponse && error.status === 500) {
          console.log('500 error');
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: any) {
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('currentUser'));
    user.access_token = token.access_token;
    user.refresh_token = token.refresh_token;
    localStorage.setItem('currentUser', JSON.stringify(user));
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token.access_token}`
      }
    });
  }

  // @ts-ignore
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser.refresh_token) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addToken(request, token));
          }));
      } else {
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addToken(request, jwt));
          }));
      }
    } else {
        this.authService.logout();
    }
  }
}
