<div *ngIf="!data.showMobileWarning" class="mobile-warning">
  <span class="mobile-warnining-text">{{'best_experience'|translate}}</span>
  <i class="bi bi-x mobile-warnining-close-button" (click)="data.showMobileWarning = !data.showMobileWarning"></i>
</div>
<div class="header justify-content-between">
  <div class="logo-block" routerLink="/">
    <!--    <a href="/">-->
    <!--      <picture>-->
    <!--        <source media="(min-width: 767px)" srcset="../../../assets/images/successteam-logo-white.svg">-->
    <!--        <img src="../../../assets/images/logo.png" style="width: auto;">-->
    <!--      </picture>-->
    <!--    </a>-->
  </div>
  <div class="header-btn">
    <!--    TODO-->
    <div
      *ngIf="workspace?.workspaceSubscription.status === 'active' && workspace?.workspaceSubscription.workspacePlan.requirePayment === 'no' && this.data.userRole.role.workspacePriority > 10"
      class="feedback-plan-container">
      <span class="plan-title">{{'top_menu_free_title'| translate}}</span>
      <span
        class="feedback-answers-left">{{(statistics?.responses > statistics?.responseLimit) ? 0 : statistics?.responseLimit - statistics?.responses}}</span>
      <span
        style="font-size: 1.4rem;">{{'top_menu_free_text'| translate}} <b>{{workspace.workspaceSubscription.workspacePlan.attribute.questionLimit}}</b></span>
    </div>

    <div *ngIf="customPollAssignment" class="respond-to-custom-poll-container"
      [ngClass]="workspace?.workspaceSubscription.status === 'active' && workspace?.workspaceSubscription.workspacePlan.requirePayment === 'no' && 
                this.data.userRole.role.workspacePriority > 10 && customPollAssignment ? '':''">
      <h2 class="custom-poll-title">{{customPollAssignment?.customPoll.name}}</h2>
      <h2 class="custom-poll-time-remaining">{{'custom_poll_header_days_to_complete' | translate :{days: getRemainingDays(customPollAssignment?.customPoll.startDate, customPollAssignment?.customPoll.endDate)} }}</h2>
      <div (click)="goToQaToRespond(customPollAssignment.uuid)" class="respond-button">
        <span>{{'custom_poll_header_respond_now' | translate}}</span>
      </div>
    </div>

    <div style="display: flex;
    float: right;
    align-items: center;">
      <!-- <button class="feedback-btn" (click)="getTeamPoll()" *ngIf="showPollButton() && !onboarding"
              style="margin-right: 10px">Give Feedback
      </button> -->
      <div class="workspace-name">
        <div class="dropdown" *ngIf="!onboarding">
          <button class="workspace-name-dropdown" id="workspaceNameDropdown" type="button"
                  data-toggle="dropdown" aria-haspopup="true">
            <img *ngIf="workspace?.avatar !== null"
                 style="width: 4rem; height: 4rem; margin-right: 10px;  object-fit: cover;"
                 class="rounded-circle z-depth-2" alt="100x100"
                 src="{{workspace.avatar ?  baseUrl + '/uploads/workspace/'+ workspace.uuid + '/' + workspace.avatar + '?' + currentTime : null}}"
                 data-holder-rendered="true">
            <span>{{getWorkspaceName()}}</span>
          </button>
          <div class="dropdown-menu user-dropdown" aria-labelledby="workspaceNameDropdown">
<!--            <p class="user-email" style="cursor: pointer" (click)="showCreateWorkspaceModal()" *ngIf="this.data.userRole.role.workspacePriority > 10">+ Add Workspace</p>-->
            <p class="user-email" style="cursor: pointer; padding: 10px 20px" *ngFor="let workspace of workspaces"
               (click)="changeWorkspace(workspace.workspace)">{{workspace.workspace.name}}</p>
          </div>
        </div>
      </div>
      <div class="workspace-name">
        <div class="workspace-name-dropdown" role="button"
             data-toggle="dropdown" aria-haspopup="true">
          <span class="separator"></span>
          <img class="user-avatar"
               src="{{(user.avatar !== null && user.avatar.path !== null) ? this.data.baseUrl + user.avatar.path: '../../../assets/svg/user-icon.svg'}}"
               alt="">
          <!--        <p class="user-profile-picture user-name" style="color: white; cursor: default; margin-top: 12px">-->
          <!--          {{ user.detail.displayName | getDisplayName: user.detail.firstname: user.detail.lastname: user.email }}</p>-->
          <a class="user-profile-picture" id="loginUser"
             (click)="showUser = !showUser; showNotifications = false"
             aria-expanded="false" data-display="static" style="color: white">
            {{ user.detail.displayName | getDisplayName: user.detail.firstname: user.detail.lastname: user.email }}
            <span class="badge"></span>


          </a>

          <div class="dropdown-menu user-dropdown" aria-labelledby="loginUser">
            <p class="user-email">{{user.email}}</p>
            <div class="user-content">
              <a role="button" aria-haspopup="true" aria-expanded="false" data-display="static"
                 [routerLink]="'/settings/account'"
                 (click)="showUser = false">{{'top_menu_my_account'| translate}}</a>

              <a role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"
                 (click)="logOutUser()">{{'top_menu_logout'|translate}}</a>

            </div>
          </div>
        </div>
        <span class="separator"></span>

      </div>
      <img src="../../../assets/svg/bell.svg" style="width:20px; color: white" alt="" routerLink="/notifications">
      <!--  <nb-icon *ngIf="!onboarding" class="workspace-name-dropdown" style="color: white" icon="bell-outline" routerLink="/notifications">-->
      <!--  </nb-icon>-->
      <span class="badge" *ngIf="!onboarding && data.notificationsBadge>0"
            routerLink="/notifications">{{data.notificationsBadge}}</span>
      <span *ngIf="!onboarding  && data.isMobile" class="separator"></span>

      <!--  <a *ngIf="data.isMobile" (click)="toggleSidebar()" href="#" id="toggleMenu" class="sidebar-toggle">-->
      <!--    <nb-icon icon="menu-outline"></nb-icon>-->
      <!--  </a>-->
    </div>
  </div>
</div>

