import {Component, OnInit} from '@angular/core';
import {ApiwrapperService} from '../../services/apiwrapper.service';
import {DataManipulationService} from '../../services/data-manipulation.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public notifications = {
    read: [],
    get_read: false,
    unread: [],
    get_unread: false
  };
  public unreadPage = 1;
  public readPage = 1;
  public readNotifications = [];
  public unreadNotifications = [];
  readAllNotification = false;
  readNotificationsDates = [];
  unreadNotificationsDates = [];
  gotNotifications = false;

  constructor(public apiwrapper: ApiwrapperService, public data: DataManipulationService) {
  }

  // status : unread, read, deleted
  // resourceType : other, invitation, reminder
  ngOnInit() {
    this.getNotifications(1, 'unread');
    this.getNotifications(1, 'read');
  }

  //@ts-ignore
  getNotifications(page, status) {
    this.data.showLoading();
    this.apiwrapper.getMyNotifcations(0, page, '', status).subscribe(
      res => {
        if (!this.readAllNotification) {
          //@ts-ignore
          this.notifications['' + status] = res._embedded.notifications;
          //@ts-ignore
          this.notifications['get_' + status] = true;

          //@ts-ignore
          this.getNotificationDates(this.notifications['' + status], status);
        }
        if (status === 'unread') {
          this.data.notificationsBadge = res._total_items;
          if (this.data.notificationsBadge > 0){
            this.readAll();
          }
        }
        this.data.hideLoading();
        this.gotNotifications = true;
      }, err => {
        console.log(err);
        this.data.hideLoading();
        this.gotNotifications = true;
      }
    );
  }

  //@ts-ignore
  getNotificationDates(notification, status) {
    if(status === 'unread') {
      for (let date of notification) {
        //@ts-ignore
        this.unreadNotificationsDates.push(date.created.date);
      }
    }
    if(status === 'read') {
      for (let date of notification) {
        //@ts-ignore
        if (!this.readNotificationsDates.includes(date.created.date.slice(0,10))) {
          //@ts-ignore
          this.readNotificationsDates.push(date.created.date.slice(0,10));
        }
      }
    }
  }

  readAll() {
    this.readAllNotification = true;
    this.apiwrapper.postReadAllNotification().subscribe(
      res => {
        this.getNotifications(1, 'unread');
      }, err => {
        console.log(err);
      }
    );
  }

  unreadScroll() {
    this.unreadPage = this.unreadPage + 1;
    //@ts-ignore
    this.unreadNotifications.concat(this.getNotifications(this.unreadPage, 'unread'));
  }
  readScroll() {
    this.readPage = this.readPage + 1;
    //@ts-ignore
    this.readNotifications.concat(this.getNotifications(this.readPage, 'read'));
  }

  //@ts-ignore
  openNotification(notification, id) {
    // this.updateBadge(type, null);
    if (notification.status === 'unread') {
      this.apiwrapper.patchNotificationStatus(notification).subscribe(
        res => {
          //@ts-ignore
          this.notifications.unread[id].status = 'read';
          // this.notifications[id].status = 'read';
          //@ts-ignore
          this.data.setRefreshNotifications(true);
        },
        err => {
          console.log(err);
        }
      );
    }
  }
}
