import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiwrapperService} from '../../services/apiwrapper.service';
import {DataManipulationService} from '../../services/data-manipulation.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: []
})
export class HeaderComponent implements OnInit, OnDestroy {
  currentTime = 1;
  showNotifications = false;
  showUser = false;
  teamSelected = false;
  private team: any;
  private refreshNotificationsSubscription: Subscription | undefined;
  private refreshWorkspacesSubscription: Subscription | undefined;
  private refreshNotifications: boolean | undefined;
  userRole: any;
  user: any;
  prioritySubscription: Subscription | undefined;
  menuToggleSubscription: Subscription | undefined;
  userPriorities: never[] | undefined;
  baseUrl;
  workspace: any;
  private refreshUserDataSubscription: Subscription | undefined;
  onboarding = false;
  workspaces: any;
  statistics: any;
  customPollAssignment: any;
  hasCustomAssignment: boolean = false;

  constructor(public apiwrapper: ApiwrapperService,
              private authService: AuthenticationService,
              private router: Router, public data: DataManipulationService) {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() {
    if (localStorage.getItem('ONBOARDING')) {
      this.onboarding = true;
    }
    // this.data.closeMenu.subscribe((res) => {
    //   if (res) {
    //     this.toggleSidebar();
    //   }
    // });
    this.refreshUserDataSubscription = this.data.changedUserData.subscribe((refreshUserData: any) => {
      if (refreshUserData) {
        // @ts-ignore
        this.apiwrapper.getUserDetails(localStorage.getItem('WORKSPACE_UUID')).subscribe(res => {
          this.getUserDetails();
        });
      }
    });
    this.data.changeName.subscribe((res: any) => {
      if (res) {
        this.getWorkspace();
      }
    });
    this.getWorkspace();
    this.getCustomPollAssigments();
    this.getUserWorkspaces();
    this.getUserDetails();
    this.prioritySubscription = this.data.userPriorities
      .subscribe(value => {
        this.userPriorities = value;
      });

    this.menuToggleSubscription = this.data.menuButtonSubs
      .subscribe((value: any) => {
        if (value) {
          // document.getElementById('toggleMenu').click();
        }
      });

    // get the notifications
    this.getNotifications();
    this.refreshNotificationsSubscription = this.data.refreshHeaderNotifications.subscribe((refreshNotifications: boolean | undefined) => {
      this.refreshNotifications = refreshNotifications;
      if (refreshNotifications) {
        // this.data.setRefreshHeaderNotifications(false);
      }
    });

    this.refreshWorkspacesSubscription = this.data.workspaceAdded.subscribe(refreshWorkspaces => {
      if (refreshWorkspaces) {
        this.getWorkspace();
      }
    });

    // TODO get statistics if PERMISSIONS
    if (this.data.userRole.role.workspacePriority > 10) {
      this.getWorkspaceStatistics();
    }
  }

  ngOnDestroy() {
    // @ts-ignore
    this.refreshUserDataSubscription.unsubscribe();
    // @ts-ignore
    this.prioritySubscription.unsubscribe();
    // @ts-ignore
    this.refreshNotificationsSubscription.unsubscribe();
    // @ts-ignore
    this.menuToggleSubscription.unsubscribe();
    // @ts-ignore
    this.refreshWorkspacesSubscription.unsubscribe();
  }

  getWorkspace() {
    // @ts-ignore
    this.apiwrapper.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.workspace = res;
        this.getTime();
      }, error => {
      });
  }

  toggleSidebar(): boolean {
    // this.sidebarService.toggle(true, 'menu-sidebar');
    // this.layoutService.changeLayoutSize();
    return false;
  }

  logOutUser() {
    this.authService.logout();
    location.reload();
    // this.router.navigate(['/login']);
  }

  returnHeaderText() {
    return localStorage.getItem('headerText');
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }

  getUserDetails() {
    // if (user.detail.displayName === '' || user.detail.displayName === null) {
    //   user.detail.displayName = user.detail.firstname + '' + user.detail.lastname;
    // }
    // localStorage.setItem('userDetails', JSON.stringify(user));
    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));
  }

  getTeamPoll() {
    this.data.setMenuPoll(true);
  }

  private getNotifications(page = 1) {
    this.apiwrapper.getMyNotifcations(0, page, '', 'unread').subscribe(
      res => {
        this.data.notificationsBadge = res._total_items;
      },
    );
  }

  showPollButton() {
    let returnValue = 0;
    // only on team page
    if (localStorage.getItem('currentTeam') && this.router.url.includes('team')) {
      this.teamSelected = true;
      this.team = localStorage.getItem('currentTeam');
      // @ts-ignore
      // @ts-ignore
      for (const team of this.userPriorities.teams) {
        if (team.role && team.team.uuid === this.team) {
          // if (team.role.name === 'team-member') {
          //   returnValue = 0;
          //   break;
          // } else {
          returnValue = 1;
          //   break;
          // }
        }
      }
      return !!returnValue;
    } else {
      return false;
    }
  }

  getMobile() {
    // get the display with
    if (window.innerWidth < 766) {
      return true;
    } else {
      return false;
    }
  }

  onClickedOutside() {
    this.showUser = false;
    this.showNotifications = false;
  }

  getWorkspaceName() {
    return localStorage.getItem('WORKSPACE_NAME');
  }

  getUserWorkspaces() {
    this.apiwrapper.getUserWorkspaces().subscribe(
      res => {
        this.workspaces = res;
        // res.forEach((el, index) => {
        //   if (el.name === this.workspace.name) {
        //     this.workspaces.splice(index, 1);
        //   }
        // });
        // @ts-ignore
        const user = JSON.parse(localStorage.getItem('userDetails'));
        this.workspaces.forEach((el: { workspace: { name: string | any[]; }; }, index: any) => {
            if (el.workspace.name.includes(user.email)) {
              this.workspaces.splice(index, 1);
            }
          }
        );
      }, err => {
        console.log(err);
      }
    );
  }

  public showCreateWorkspaceModal() {
    document.getElementById('createWorkspaceModal')?.click();

  }

  changeWorkspace(workspace: any) {
    const currentWorkspace = localStorage.getItem('WORKSPACE_UUID');
    if (currentWorkspace !== workspace.uuid) {
      this.data.showLoading();
      localStorage.removeItem('PLAN_SELECTED');
      localStorage.setItem('WORKSPACE_UUID', workspace.uuid);
      localStorage.setItem('WORKSPACE', JSON.stringify(workspace));
      localStorage.setItem('WORKSPACE_NAME', workspace.name);
      // this.currentWorkspace = workspace.uuid;
      this.data.setWorkspaceBillingInfo(workspace.workspaceBillingInfo);
      if (workspace.billDate !== null) {
        workspace.workspaceSubscription.workspacePlan.billDate = workspace.billDate.date;
      } else {
        workspace.workspaceSubscription.workspacePlan.billDate = new Date();
      }
      this.data.setWorkspacePlan(workspace.workspaceSubscription.workspacePlan);
      this.data.setWorkspaceBillingContacts(workspace.workspaceBillingContacts);
      this.apiwrapper.getUserDetails(workspace.uuid).subscribe(
        res => {
          this.authService.setCurrentUserWorkspace(res.email, workspace.uuid, workspace.workspaceSubscription.workspacePlan);
          if (workspace.onboardingStep !== 'done') {
            location.reload();
          }
          this.router.navigate(['/']).then(() => {
            this.getNotifications();
            this.data.setWorkspaceChanged(true);
            this.data.setChangeName(true);
            this.data.setRefreshHeaderNotifications(true);
            this.data.setRefreshMenuTeams(true);
            this.data.hideLoading();
            location.reload();
          });
        }
      );
    }
  }

  private getWorkspaceStatistics() {
    this.apiwrapper.getWorkspaceStatistics().subscribe(
      res => {
        this.statistics = res;
      }
    )
  }

  private getCustomPollAssigments() {
    const workspaceUuid = localStorage.getItem('WORKSPACE_UUID');
    this.apiwrapper.getCustomPollAssigments(workspaceUuid).subscribe((res: any) => {
      let assignments = res._embedded["custom-assignments"];
      if (assignments.length !== 0) {
        for(let i=0; i < assignments.length; i++) {
          if (new Date().setHours(0, 0, 0, 0) < new Date(assignments[i].customPoll.endDate?.date).setHours(0, 0, 0, 0)) {
            this.customPollAssignment = assignments[i];
            return;
          }
        }
      }
    });
  }

  getRemainingDays(start: any, end: any) {
    if(start !== null || end !== null) {
      const oneDay = 24 * 60 * 60 * 1000;
      const today = new Date();
      const endDate = new Date(end.date);
      let startDate = new Date(start).getTime() > today.getTime() ? start : today;
      const remainingDays = Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));
      if (!Number.isNaN(remainingDays)) {
        return remainingDays;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  goToQaToRespond(assignmentUuid: any) {
    const url = 'https://qa.successteam.com/customPoll?customQaId='+assignmentUuid;
    //@ts-ignore
    window.open(url, '_blank').focus();
  }
}
