import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataManipulationService} from '../../services/data-manipulation.service';
import {ApiwrapperService} from '../../services/apiwrapper.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from "../../services/authentication.service";
import {DeviceDetectorService} from "ngx-device-detector";
import {formatDate} from "@angular/common";
import {resolve} from "@angular/compiler-cli";
import {translate} from "zxcvbn3/dist/feedback";

interface SortingByIndicators {
  name: string,
  icon: string,
  type: string
}

interface SortingByTeamType {
  name: string,
  icon: string,
  type: string,
  show: boolean
}

@Component({
  selector: 'app-teams-overview',
  templateUrl: './teams-overview.component.html',
  styleUrls: ['./teams-overview.component.scss']
})
export class TeamsOverviewComponent implements OnInit, OnDestroy {
  showTeams = false;
  teams: any = [];
  teamsIndicators: any = {};
  indicatorInterval = [];
  indicators = [];
  showTeamChartButton = false;
  private prioritySubscription: Subscription | undefined;

  sortByIndicators: SortingByIndicators | undefined;
  sortByTeamType: SortingByTeamType | any;
  sortByPerformance: any | undefined;
  sortByDate: any | undefined;
  // sorting = ['Score', 'Performance', 'Motivation', 'Satisfaction', 'Stress'];
  sortingByIndicators: SortingByIndicators[] = [
    {name: 'Overall', icon: 'overall', type: ''},
    {name: 'Performance', icon: 'performance', type: ''},
    {name: 'Motivation', icon: 'motivation', type: ''},
    {name: 'Satisfaction', icon: 'satisfaction', type: ''},
    {name: 'Stress', icon: 'stress', type: ''},
  ];
  sortingByTeamType: SortingByTeamType[] = [];
  sortingByType: SortingByTeamType[] = [
    {name: 'Sort by type', icon: 'filter', type: 'all', show: true},
    {name: 'Remote', icon: 'remote', type: 'remote', show: false},
    {name: 'Office', icon: 'office', type: 'office', show: false},
    {name: 'Hybrid', icon: 'hybrid', type: 'hybrid', show: false}
  ];
  sortingByPerformance: { name: string, type: boolean }[] = [
    {name: 'Sort by score', type: true},
    {name: 'Highest Score', type: true},
    {name: 'Lowest Score', type: false},
  ];
  sortingByDate: { name: string }[] = [
    {name: 'Last month'},
    {name: 'Last week'},
    {name: 'Last day'},
  ];
  checkTeams = false;
  sortType = true;
  userPriorities: any;
  gotTeams = false;
  loadData = false;
  teamsGotData = [];
  currentTeamIndicator = [];
  indicatorIndex: any;
  showTeamScore = [true, true, true, true];
  showMore: any;
  isIndicatorIndex = [false, false, false, false];
  showIndicator: string | undefined = 'Overall';
  knobProgress = 0;
  teamSuccessScore: any[] = [];
  teamHasNoTeamLead: { index: number, value: boolean }[] = [];

  constructor(public data: DataManipulationService, public api: ApiwrapperService, public auth: AuthenticationService, private deviceService: DeviceDetectorService) {
    this.teams = [];
    if (localStorage.getItem('upgrading')) {
      localStorage.removeItem('upgrading');
    }

  }

  ngOnDestroy(): void {
    if (this.prioritySubscription !== undefined) {
      this.prioritySubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loadData = false;
    this.showTeamChartButton = false;
    this.teams = [];
    localStorage.removeItem('currentTeam');
    setTimeout(() => {
      // @ts-ignore
      const workspacePlan = JSON.parse(localStorage.getItem('workspacePlan'));
      if (workspacePlan.requirePayment.toLowerCase() === 'yes') {
        this.showTeamChartButton = true;
      }
    }, 50);

    this.data.userPriorities
      .subscribe((value: any) => {
          if (!this.loadData) {
            this.getUserTeams()
            this.loadData = true;
          }
        }
      );
     this.showMore = new Array(this.teams.length).fill(true);
  }

  private getUserTeams() {
    this.teams = this.data.userTeams();
    this.getInterval()
    this.getTeamLeaders();
    // this.getTeamsIndicators();
  }

  private getInterval() {
    this.data.showLoading();
    this.api.getIndicatorInterval().subscribe(
      res => {
        this.indicatorInterval = res;
        this.processReport()
        this.data.hideLoading();
      }
    );
  }

  private processReport() {
    this.teams.forEach((team: any) => {
      if (team.questionCollections.length > 0) {
        team.questionCollections.forEach((el: any) => {
          switch (el.slug) {
            case 'hybrid':
              team.type = 'Hybrid'
              team.typeIcon = 'hybrid'
              this.sortingByType[3].show = true;
              return;
            case 'remote':
              team.type = 'remote'
              team.typeIcon = 'remote'
              this.sortingByType[1].show = true;
              return;
            case 'office':
              team.type = 'Office'
              team.typeIcon = 'office'
              this.sortingByType[2].show = true;
              return;
            case 'physical-workplace':
              team.type = 'Physical workplace'
              team.typeIcon = 'office'
              this.sortingByType[2].show = true;
              return;
          }
        })
      }
      Object.keys(team.teamReport).forEach((el) => {
        // console.log(el)
        this.indicatorInterval.forEach((interval) => {
          let numb = team.teamReport[el]
          // @ts-ignore
          if (interval.min <= numb && numb <= interval.max) {
            // @ts-ignore
            team.teamReport[el.toLowerCase() + 'Color'] = this.data.colors[interval.indicatorColor.color];
          } else if (numb === 0) {
            // @ts-ignore
            team.teamReport[el.toLowerCase() + 'Color'] = this.data.colors.grey;
            // @ts-ignore
          }
        });
      })
    })

    this.sortingByType.forEach((el: any)=> {
      if (el.show){
        this.sortingByTeamType.push(el);
      }
    })
    this.sortByTeamType = this.sortingByType[0];
    this.showTeams = true;
  }

  sortTeams() {
    // @ts-ignore
    let sort: string | undefined = '';
    if (this.sortByIndicators?.name === 'Overall') {
      sort = 'teamSuccessteamScore'
    } else {
      sort = this.sortByIndicators?.name;
    }
    if (this.sortByPerformance.type) {
      // @ts-ignore
      this.teams.sort((a, b) => (a.teamReport[sort] > b.teamReport[sort] ? -1 : 1));
    } else {
      // @ts-ignore
      this.teams.sort((a, b) => (a.teamReport[sort] > b.teamReport[sort] ? 1 : -1));
    }
  }

  changeSortType() {
    if (this.sortByIndicators?.name !== 'Overall') {
      this.sortType = !this.sortType;
      // this.sortTeams();
    }
  }


  // @ts-ignore
  setCurrentIndicator(index, title) {
    this.showTeamScore[index] = !this.showTeamScore[index];
    this.isIndicatorIndex[index] = !this.isIndicatorIndex[index];
    this.indicatorIndex = index;
    // @ts-ignore
    this.currentTeamIndicator[index] = title;
  }

  // @ts-ignore
  openMoreIndicators(index) {
    const indicators = document.getElementsByClassName('team-indicators');
    indicators[index].classList.toggle('active');
    this.showMore[index] = !this.showMore[index];
  }

  // @ts-ignore
  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgb(' + parseInt(result[1], 16) + ', ' + parseInt(result[2], 16) + ', ' + parseInt(result[3], 16) + ', 0.2)' : '';
  }

  notifyTeam(teamUuid: string) {
    this.data.showLoading();
    // @ts-ignore
    this.api.sendTeamReminder(teamUuid).subscribe(
      res => {
        this.data.hideLoading();
        this.data.showAlert('Reminder sent');
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('Error on sending reminder')
      }
    );
  }

  private getTeamLeaders() {
    this.teams.forEach((team: any) => {
      this.api.getMemberActivity(team.uuid).subscribe(
        res => {
          if (res.teamLeadersReport.length > 0) {
            team.leaders = [];
            res.teamLeadersReport.forEach((el: any) => {
              team.leaders.push(el.teamMember);
            })
          }
          team.gotMembers = true;
          this.data.hideLoading();
        }, err => {
          console.log(err)
        })
    });
  }


  sortByIndicator() {
    this.showIndicator = this.sortByIndicators?.name;
    // sortteams
    this.sortTeams();
  }

  setKnobProgressBar(succesScore: any) {
    this.knobProgress = Math.round((184 - (184 * succesScore) / 100));
    return this.knobProgress.toString();
  }

  open() {
    let device = this.deviceService.getDeviceInfo();
    let time = formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US');
  }

  sortTeamsByType() {
    
  }
}
