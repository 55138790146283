import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss']
})
export class ChooseTemplateComponent implements OnInit {
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.openDeleteModal[this.clickedCustomPollTemplateIndex] = false;
    this.openStopModal[this.clickedCustomPollTemplateIndex] = false;
  }

   @Input() workspaceTemplates: any;
   @Input() set workspaceCustomLoops(value: any) {
      this.customPolls = value;
   }
   
   @Output() templateEvent = new EventEmitter<any>();
   @Output() resumeDraftEvent = new EventEmitter<any>();
   @Output() deletePollEvent = new EventEmitter<any>();
   @Output() stopPollEvent = new EventEmitter<any>();

  templateMock: any = {
    title: 'APV',
    categories: { 
      apv: [
          {
            title: this.translate.instant('custom_poll_temp_apv_categ_general'),
            color: '#EDE4F6'
          },
          {
            title: this.translate.instant('custom_poll_temp_apv_categ_productivity'),
            color: '#F6E4F2'
          },
          {
            title: this.translate.instant('custom_poll_temp_apv_categ_leader'),
            color: '#F0EFE4'
          },
          {
            title: this.translate.instant('custom_poll_temp_many_more'),
            color: 'transparent'
          }
        ],
        onboarding: [
          {
            title: this.translate.instant('custom_poll_temp_onboarding_categ_week2'),
            color: '#F0EFE4'
          },
          {
            title: this.translate.instant('custom_poll_temp_onboarding_categ_moth1'),
            color: '#F6E4E4'
          },
          {
            title: this.translate.instant('custom_poll_temp_onboarding_categ_moth3'),
            color: '#EDE4F6'
          },
          {
            title: this.translate.instant('custom_poll_temp_onboarding_categ_moth6'),
            color: '#F6E4F2'
          },
        ],
      }
  };

  customPolls: any[] = [];
  customPollAssignments: any[] = [];

  userPriority: any;
  clickedCustomPollTemplateIndex: any;

  openDeleteModal: any[] = [];
  openStopModal: any[] = [];


  constructor(private translate: TranslateService, public data: DataManipulationService, public router: Router) { }

  ngOnInit(): void {
    this.openDeleteModal = new Array(this.customPolls.length).fill(false);
    this.openStopModal = new Array(this.customPolls.length).fill(false);
    this.userPriority = this.data.userRole.role.workspacePriority;
  }

  choseTemplate(templateUuid: string, templateType: string) {
    this.templateEvent.emit({templateUuid, templateType});
  }

  getRemainingDays(start: any, end: any) {
    const oneDay = 24 * 60 * 60 * 1000;
    const endDate = new Date(end);
    const today = new Date();
    let startDate;
    if (!start) {
      startDate = today;
    } else {
      startDate = new Date(start).getTime() > today.getTime() ? start : today;
    }
    if(start !== null || end !== null) {
      return Math.round(Math.abs((endDate.getTime() - startDate.getTime()) / oneDay));
    } else {
      return 0;
    }
  }

  resumeDraft(poll: any) {
    this.resumeDraftEvent.emit(poll);
  }

  goToPollReporting(poll: any) {
    this.data.setCustomPoll(poll);
    this.router.navigate(['/reporting/'+poll.uuid]);
  }

  openConfirmationModalForStop(event: any, index: number) {
    event.stopPropagation();
    this.clickedCustomPollTemplateIndex = index;
    this.openStopModal[index] = true;
  }

  openConfirmationModalForDelete(event: any, index: number) {
    event.stopPropagation();
    this.clickedCustomPollTemplateIndex = index;
    this.openDeleteModal[index] = true;
  }

  closeConfirmationModalForDelete(index: number) {
    this.openDeleteModal[index] = false;
  }

  closeConfirmationModalForStop(index: number) {
    this.openStopModal[index] = false;
  }

  deleteCustomPoll(pollUuid: string, modalIndex: number) {
    this.closeConfirmationModalForDelete(modalIndex);
    this.deletePollEvent.emit({customPollUuid: pollUuid});
  }

  stopCustomPoll(pollUuid: string, modalIndex: number) {
    this.closeConfirmationModalForStop(modalIndex);
    this.stopPollEvent.emit({customPollUuid: pollUuid});
  }
}
