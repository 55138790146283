import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup, NgForm, Validators} from '@angular/forms';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {StripeService, StripeCardComponent} from 'ngx-stripe';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-paid-feature',
  templateUrl: './paid-feature.component.html',
  styleUrls: ['./paid-feature.component.scss']
})
export class PaidFeatureComponent implements OnInit {

  @ViewChild('billingInfoForm') billingInfoForm!: NgForm;

  @Output() closePaidFeatureModal = new EventEmitter<boolean>();

  paymentForm = new FormGroup({
    // address: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    vatNumber: new FormControl(''),
    address: new FormControl('', [
      Validators.minLength(1)]),
    phoneNumber: new FormControl(''),
    country: new FormControl(''),
  });
  validBillingInfo = true;

  vatNumber: any;
  couponValidated: boolean = false;
  selectedCountryVat = 'dk';
  selectedCountry = 'dk';
  selectedCountryCode = 'dk';
  countryCodes = ['at', 'be', 'bg', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hu', 'ie', 'it', 'lv', 'lt', 'lu', 'mt', 'nl',
    'pl', 'pt', 'ro', 'sk', 'si', 'es', 'se'];
  countryLabels = {
    at: 'Austria',
    be: 'Belgium',
    bg: 'Bulgaria',
    hr: 'Croatia',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    ee: 'Estonia',
    fi: 'Finland',
    fr: 'France',
    de: 'Germany',
    gr: 'Greece',
    hu: 'Hungary',
    ie: 'Ireland',
    it: 'Italy',
    lv: 'Latvia',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mt: 'Malta',
    nl: 'Netherlands',
    pl: 'Poland',
    pt: 'Portugal',
    ro: 'Romania',
    sk: 'Slovakia',
    si: 'Slovenia',
    es: 'Spain',
    se: 'Sweden'
  };
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  billingInfo: any;
  coupon: string | undefined;
  private plansSimulate: any = [];
  public paymentMethods = [];
  private validCoupon: any;


  constructor(private api: ApiwrapperService, public data: DataManipulationService, private stripeService: StripeService,) {
    this.setBillingDetails(this.data.getWorkspaceBillingInfo());
  }

  ngOnInit(): void {
    this.showModalDialog();
  }

  showModalDialog() {
    const openModal = document.getElementById('paymentDetails');
    openModal?.click();
  }

  // 37503312
  verifyVat() {
    this.data.showLoading();
    if (!this.paymentForm.controls['vatNumber'].valid) {
      this.data.hideLoading();
    } else {
      const body = {
        vatNumber: this.selectedCountryVat.toUpperCase() + this.paymentForm.value.vatNumber
      };
      this.patchInfo(body, false);
    }
  }

  patchInfo(body: {}, addMethod: boolean) {
    this.api.patchBillingInfo(body).subscribe(
      res => {
        this.data.hideLoading();
        this.data.setWorkspaceBillingInfo(res);
        this.setBillingDetails(res);
        this.data.showAlert('The information was updated successfully.');
        if (addMethod) {
          this.data.showLoading();
          // hide the modal

          // send user to stripe
          this.addMethod('onboarding');
        }
      }, error => {
        this.data.hideLoading();
        this.data.showAlertError(error.error.error.messages[0]);
      }
    );
  }

  addMethod(route: string) {
    this.data.showLoading();
    this.api.postBillingMethod(route).subscribe(
      res => {
        this.stripeService.redirectToCheckout({sessionId: res.stripe.sessionId}).subscribe(
          result => {
            this.data.paymentFail = false;
            this.data.hideLoading();
          }, err => {
            console.log(err);
            this.data.hideLoading();
            this.data.showAlertError('Cannot add payment method!');
          }
        );
      },
      err => {
        console.log(err);
        this.data.hideLoading();
        this.data.paymentFail = true;
        this.data.showAlertError('Cannot add payment method!');
      }
    );
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }

  changeCountryCode(value: string): void {
    this.selectedCountry = value;
  }

  saveBillingInfo() {
    this.data.showLoading();
    if (!this.paymentForm.valid) {
      this.data.hideLoading();
      this.validBillingInfo = false;
      this.data.showAlertError('Please complete the form'!)
    } else {
      const body = {
        country: this.selectedCountry,
        company: this.paymentForm.value.companyName,
        streetAddress: this.paymentForm.value.address,
        phone: this.paymentForm.value.phoneNumber.internationalNumber,
      };
      this.patchInfo(body, true);
    }
  }

  private setBillingDetails(workspaceBillingInfo: any) {
    this.billingInfo = workspaceBillingInfo;
    this.paymentForm.controls['companyName'].setValue(workspaceBillingInfo.company);
    if (workspaceBillingInfo.vatNumber !== null) {
      this.paymentForm.controls['vatNumber'].setValue(workspaceBillingInfo.vatNumber.substring(2));
    }
    this.paymentForm.controls['address'].setValue(workspaceBillingInfo.streetAddress);
    this.paymentForm.controls['phoneNumber'].setValue(workspaceBillingInfo.phone);
    if (workspaceBillingInfo.country === null) {
      this.selectedCountryCode = 'dk';
      this.selectedCountry = 'dk';
    } else {
      this.selectedCountryCode = workspaceBillingInfo.country;
      this.selectedCountry = workspaceBillingInfo.country;
    }
  }

  goToPayment() {
    this.billingInfoForm.ngSubmit.emit();
  }

  closeModal() {
    this.closePaidFeatureModal.emit(false);
  }

  validateCoupon() {
    if (localStorage.getItem('onboardingValidCoupon')) {
      localStorage.removeItem('onboardingValidCoupon');
    }
    this.data.couponData = {};
    this.data.showLoading();
    //@ts-ignore
    this.api.getCouponValidation(this.coupon).subscribe(
      res => {
        this.couponValidated = true;
        localStorage.setItem('ONBOARDING_PLAN_SELECTED', JSON.stringify(res.workspacePlan));
        localStorage.setItem('onboardingValidCoupon', JSON.stringify(res));

        if (res.type === 'percentage' && res.percentage === 100) {
          this.addCoupon();
        } else if (res.percentage !== 100) {
          // save coupon and add it after payment added (res.hash)
          this.validCoupon = res;
        }
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('Invalid coupon code');
      }
    );
  }


  private addCoupon() {
    //@ts-ignore
    this.api.postCoupon(this.coupon).subscribe(
      response => {
        this.data.hideLoading();
        localStorage.setItem('ONBOARDING_PLAN_SELECTED', JSON.stringify(response.workspacePlan));
        localStorage.setItem('workspacePlan', JSON.stringify(response.workspacePlan));
        // document.getElementById('contentCoupon').click();
        // if coupon applied change estimated and blue banner
        location.reload();
      }, error => {
        this.data.hideLoading();
        this.data.showAlertError('Coupon couldn\'t be applied');
        console.log(error);
      }
    );
  }
}
