<div class="billing-container">
  <div class="billing-container-header">
    <!-- <h2 class="billing-title">Upgrade your plan</h2> -->
    <div class="billing-page-header">
      <button (click)="index = 0" pButton type="button" label="{{'billing_tab1'| translate}}"
              [ngClass]="index === 0 ? 'billing-button-active':''" class="p-button-text billing-button"></button>
      <button (click)="index = 1" pButton type="button" label="{{'billing_tab2'| translate}}"
              [ngClass]="index === 1 ? 'billing-button-active':''" class="p-button-text billing-button"></button>
<!--              [disabled]="!(currentWorkspacePlan.requirePayment.toLocaleLowerCase() !== 'no' || showInfo)"></button>-->
      <button (click)="index = 2" pButton type="button" label="{{'billing_tab3'| translate}}"
              [ngClass]="index === 2 ? 'billing-button-active':''" class="p-button-text billing-button "
              [disabled]="!(currentWorkspacePlan.requirePayment.toLocaleLowerCase() !== 'no' || showPayment || gotPaymentMethod)"></button>
      <button (click)="index = 3" pButton type="button" label="{{'billing_tab4'| translate}}"
              [ngClass]="index === 3 ? 'billing-button-active':''" class="p-button-text billing-button"
              [disabled]="!(currentWorkspacePlan.requirePayment.toLocaleLowerCase() !== 'no')"></button>
      <button (click)="index = 4" pButton type="button" label="{{'billing_tab5'| translate}}"
              [ngClass]="index === 4 ? 'billing-button-active':''" class="p-button-text billing-button"
              [disabled]="!(currentWorkspacePlan.requirePayment.toLocaleLowerCase() !== 'no')"></button>
    </div>
    <!-- <span class="next-billing-date">Next billing is in {{currentWorkspacePlan.billDate | date:'mediumDate'}}</span> -->
  </div>
  <div *ngIf="showPlans" class="billing-plan-container">
    <div class="billing-plan-body">
      <p-tabView [(activeIndex)]="index">
        <p-tabPanel>
          <h2
            style="font-size:2.6rem; color:#000000">{{currentWorkspacePlan.title === ('billing_tab1_free_plan' | translate) ? ('billing_tab1_title'| translate) : ('billing_tab1_current'| translate)}}</h2>
          <div class="info-banner"
               *ngIf="showNextBilling && currentWorkspacePlan.title.toLowerCase() !== 'personal' && currentWorkspacePlan.title !== ('billing_tab1_free_plan' | translate) && !gotFreeCoupon">
            <h6>{{'billing_tab1_banner_next_billing'| translate}} {{currentWorkspacePlan.billDate | date}}</h6>
          </div>
          <div *ngIf="gotFreeCoupon" class="alert-banner">
            <h6>{{'billing_tab1_banner_free_trial_1'| translate}} {{activeCoupon.workspacePlan.title}}
              {{'billing_tab1_banner_free_trial_2'| translate}} {{dataManip.getDate(activeCoupon.activeCouponExpirationDate)}}</h6>
          </div>
          <div *ngIf="gotPaymentMethod && paymentMethods.length===0 && gotFreeCoupon" class="danger-banner" (click)="goToBillingInfo()">
            <h6>{{'billing_tab1_banner_no_payment'| translate}}</h6>
          </div>
          <div *ngIf="gotCoupon" class="alert-banner">
            <h6>{{'billing_tab1_banner_discount_1'| translate}} {{(activeCoupon.type === 'percentage') ? activeCoupon.percentage + '% ' : activeCoupon.value + 'DKK ' }}
              {{'billing_tab1_banner_discount_2'|translate}} {{dataManip.getDate(activeCoupon.activeCouponExpirationDate)}}</h6>
          </div>

          <div *ngIf="currentWorkspacePlan.title === ('billing_tab1_free_plan' | translate) && !upgradePlan"
               class="overview-billing-plans">
            <div [ngClass]="plan.title === ('billing_tab1_free_plan' | translate) ? 'free-plan-card' : 'essential-plan-card'"
                 *ngFor="let plan of plans" class="billing-plan-card">
              <img class="billing-plan-image"
                   [ngStyle]="plan.title === ('billing_tab1_free_plan' | translate) ? {} : {'margin-right':'2rem'}"
                   [src]="plan.title === ('billing_tab1_free_plan' | translate) ? '../../../assets/images/ellipse-green-diamond.png':'../../../assets/images/ellipse-essential.png'" alt="">
              <h2 class="billing-plan-title"
                  [ngStyle]="plan.title === ('billing_tab1_free_plan' | translate) ? {'color':'#000000'}:{'color':'#FFFFFF'}">{{plan.title.toLocaleUpperCase()}}</h2>
              <ul [ngStyle]="plan.title === ('billing_tab1_free_plan' | translate) ? {'color':'#000000'}:{'color':'#FFFFFF'}"
                  [innerHTML]="plan.html"></ul>
              <div *ngIf="getCurrentPlan(plan)" class="billing-current-plan">
                <h4 [style]="{'margin-bottom':'0','font-weight':'300'}">{{'billing_tab1_current'| translate}}</h4>
              </div>
              <div *ngIf="plan.title != ('billing_tab1_free_plan' | translate)" class="billing-plan-currency">
                <h1 style="font-weight: normal;">{{plan.pricePerMember + ' ' + plan.currency}}</h1>
                <span style="font-size: 1.2rem;">{{'billing_tab1_price'| translate}}</span>
                <button (click)="upgradeBillingPlan(plan)" pButton type="button" label="{{'left_menu_button'| translate}}"
                        class="upgrade-plan-button"></button>
              </div>
            </div>
          </div>
          <div *ngIf="currentWorkspacePlan.title != ('billing_tab1_free_plan' | translate) || upgradePlan" class="plan-to-upgrade">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex flex-column plan-to-upgrade-info">
                <div class="d-flex align-items-center">
                  <img class="plan-to-upgrade-image" src="../../../assets/images/ellipse-essential.png" alt="">
                  <h2 class="plan-to-upgrade-title">
                    {{currentWorkspacePlan.title != ('billing_tab1_free_plan' | translate) ? currentWorkspacePlan.title.toLocaleUpperCase() : planToUpgrade.title.toLocaleUpperCase()}}
                  </h2>
                </div>
                <ul style="color: #FFFFFF; margin-top: 3.8rem;"
                    [innerHTML]="currentWorkspacePlan.title != ('billing_tab1_free_plan' | translate) ? currentWorkspacePlan.html : planToUpgrade.html"></ul>
              </div>
              <img class="plan-to-upgrade-logo" src="../../../assets/images/startup_straight.png">
            </div>
            <div *ngIf="currentWorkspacePlan.title === ('billing_tab1_free_plan' | translate)" class="billing-plans-footer">
              <div class="billing-plan-coupon">
                <input (keyup.enter)="validateCoupon()" [(ngModel)]="coupon" class="insert-coupon-input"
                       placeholder="{{'billing_tab1_coupon'| translate}}">
                <button (click)="validateCoupon()" pButton type="button" label="{{'billing_tab1_validate'| translate}}"
                        class="validate-coupon-button"></button>
              </div>
              <button pButton type="button" label="{{'next_button' | translate}}" class="next-subscription-button"
                      (click)="planSelected(planToUpgrade)"></button>
            </div>
            <div *ngIf="currentWorkspacePlan.title != ('billing_tab1_free_plan' | translate)" class="next-billing-footer">
              <div class="next-billing">
                <span>{{'billing_tab1_banner_next_billing'| translate}} {{currentWorkspacePlan.billDate | date}}</span>
              </div>
              <button pButton type="button" label="{{'cancel_subscription' | translate}}" class="cancel-subscription-button" (click)="cancelSubscription()"></button>
            </div>
          </div>
        </p-tabPanel>

        <!--BILLING INFO-->
        <p-tabPanel header="Header 2">
          <div class="billing-info">
            <form class="mt-4" [formGroup]="billingInfoForm" (ngSubmit)="saveBillingInfo()">
              <h1 [style]="{'color':'#000000'}">{{'billing_tab2'| translate}}</h1>
              <div class="billing-info-user-details">
                <div class="row mb-5">
                  <div class="col">
                    <label class="mb-3 billing-input-label">{{'billing_tab2_vat'| translate}}</label>
                    <div class="form-group d-flex align-items-center vat-number-form-group">
                      <ngx-flag-picker
                        class="vat-flag-picker"
                        [selectedCountryCode]="selectedCountryVat"
                        [countryCodes]="countryCodes"
                        [showArrow]="false"
                        [showFlags]="true"
                        (changedCountryCode)="changeSelectedCountryCode($event)">
                      </ngx-flag-picker>
                      <input class="form-control vat-number-input" formControlName="vatNumber" type="number"
                             id="vatNumber">
                    </div>
                  </div>
                  <div class="col pt-5 d-flex align-items-center">
                    <a class="text-primary retrieve-information" (click)="verifyVat()">{{'billing_tab2_retrieve'| translate}}</a>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col">
                    <label class="mb-3 billing-input-label">{{'billing_tab2_company'| translate}}*</label>
                    <input class="form-control billing-input" formControlName="companyName" id="companyName">
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col">
                    <label class="mb-3 billing-input-label">{{'billing_tab2_address'| translate}}</label>
                    <input class="form-control billing-input" formControlName="streetAddress" id="streetAddress">
                  </div>
                  <div class="col">
                    <label class="mb-3 billing-input-label">{{'billing_tab2_country'| translate}}</label>
                    <ngx-flag-picker class="d-flex country-flag-picker"
                                     [selectedCountryCode]="selectedCountry"
                                     [countryCodes]="countryCodes"
                                     [showArrow]="true"
                                     [showLabels]="true"
                                     [customLabels]="countryLabels"
                                     (changedCountryCode)="changeCountryCode($event)">
                    </ngx-flag-picker>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label class="mb-3 billing-input-label">{{'billing_tab2_phone'| translate}}</label>
                    <ngx-intl-tel-input
                      style="border: 1px solid #E8E8E8; outline: none; padding-top: 0;"
                      class="form-control ngx-tel-input"
                      [preferredCountries]="[CountryISO.Denmark]"
                      [selectedCountryISO]="CountryISO.Denmark"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
                                          SearchCountryField.Iso2,
                                          SearchCountryField.Name
                                       ]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [numberFormat]="PhoneNumberFormat.International"
                      [separateDialCode]="true"
                      formControlName="phone"
                    ></ngx-intl-tel-input>
                    <div
                      *ngIf="phoneError"
                      class="text-danger">
                      <div
                        *ngIf="phoneError">
                        {{'register_phone_error'| translate}}
                      </div>
                    </div>
                  </div>
                </div>
                <button class="next-subscription-button"
                        style="margin: 3rem 0 0 2rem; float: right">{{this.paymentMethods.length === 0 ? ('billing_tab2_add_payment' |translate) : ('billing_tab2_confirm' | translate)}}</button>
              </div>
              <!-- <div class="row">
                  <div class="form-group col-md-6 offset-md-3 col-12">
                      <button pButton type="submit" label="Save Info" class="billing-info-save-button"></button>
                  </div>
              </div> -->
              <p style="color: red" *ngIf="dataManip.paymentFail">{{'payment_failed' | translate}}</p>

            </form>
          </div>
        </p-tabPanel>
        <!--PAYMENT-->
        <p-tabPanel header="Header 3">
          <div class="payment-methods">
            <h1 class="payment-method-title">{{'billing_tab3'| translate}}</h1>
            <div class="payment-methods-selection">
              <div *ngFor="let payment of paymentMethods" [ngStyle]="{'background-color':'#00194A'}"
                   (click)="setPaymentMethod(1)"
                   class="payment-method">
                <div class="method-ckeck"><i class="bi bi-check check-icon"></i></div>
                <img class="payment-method-icon"
                     src="../../../../assets/images/card.png" alt="">
                <span [ngStyle]="{'color':'#FFFFFF'}"
                      class="payment-method-type">****{{payment['last4digits']}}</span>
              </div>
              <div [ngStyle]="{'background-color':'#00194A'}" (click)="addMethod()"
                   class="payment-method">
                <!--                <div *ngIf="this.innvoiceMethod" class="method-ckeck"><i class="bi bi-check check-icon"></i></div>-->
                <img [ngStyle]="{'margin-top':'3rem'}" class="payment-method-icon"
                     src="../../../../assets/images/card.png" alt="">
                <span [ngStyle]="{'color':'#FFFFFF'}"
                      class="payment-method-type"><i class='bi bi-plus-circle nav__icon'></i> {{'billing_tab2_button'| translate}}</span>
              </div>
            </div>

            <img style="width: 150px; display: block; margin: auto" src="../../../../assets/images/stripe-blurple.png" alt="">
          </div>
          <!-- <div class="payment-methods-buttons">
              <button *ngIf="gotPaymentMethod" pButton [label]="paymentMethods.length === 0 ? 'Add payment' : 'Update payment'" class="add-payment-button" (click)="addMethod()"></button>
          </div> -->
        </p-tabPanel>
        <p-tabPanel header="Header 4">
          <h1 class="billing-history-title">{{'billing_tab4'|translate}}</h1>
          <div class="container text-center" style="background-color: white; border-radius: 1rem; padding: 4rem">
            <div class="container">
              <div class="billing-history-header row">
                <span class="col-md-3">Date</span>
                <span class="col-md-6">Invoice</span>
                <span class="col-md-3">Price</span>
              </div>
            </div>

            <div *ngIf="gotInvoices && workspaceInvoices.length ===0">
              <h2 style="padding: 4rem 0">{{'no_invoices'| translate}}</h2>
            </div>
            <div *ngFor="let invoice of workspaceInvoices; index as i"
                 [ngStyle]="(i % 2) ? {'background-color':'#FFFFFF'}: {'background-color':'#F0F3FA'} "
                 class="row innvoice">
              <h3 class="col-md-3">{{invoice.created.date | slice :0:10}}</h3>
              <h3 class="col-md-6">{{invoice.invoiceNumber}}</h3>
              <h3 class="col-md-3">{{invoice.total}} DKK</h3>
            </div>
            <!-- <p-table [value]="testInvoice" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Invoice number</th>
                        <th>Invoice Details</th>
                        <th>Charged at</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr>
                        <td>{{invoice.invoiceNumber}}</td>
                        <td><p *ngFor="let details of invoice.invoiceDetails">
                            Successteam {{details.workspacePlan}}
                            : Usage of {{details.userCount}} seats ({{details.startDate.date |date}}
                            -{{details.endDate.date | date}})</p>
                    </td>
                        <td>{{invoice.dueDate.date | date}}</td>
                    </tr>
                </ng-template>
            </p-table> -->
          </div>
        </p-tabPanel>
        <p-tabPanel header="Header 5">
          <div class="billing-contact">
            <h1 [style]="{'color':'#000000'}">{{'billing_tab5_title'|translate}}</h1>
            <p [style]="{'font-size':'1.2rem','margin-bottom':'1rem'}">{{'billing_tab5_subtitle'|translate}}</p>
            <div *ngFor="let contact of contacts" class="contact-container">
              <p-avatar [label]="contact['email'][0] | uppercase" styleClass="mr-2" size="xlarge"
                        shape="circle"></p-avatar>
              <div class="contact-name">
                <h4>{{contact['email']}}</h4>
                <span
                  [style]="{'font-size':'1.2rem'}">{{(contact['firstName'] !== null || contact['lastName'] !== null) ?
                  contact['firstName'] + ' ' + contact['lastName'] : ''}}</span>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<!-- <div class="billding-body">
    <div class="billing-plan-container">
        <div class="billing-plan-header">

        </div>
        <div class="billing-plan-body">

        </div>
    </div>
</div> -->


<!--&lt;!&ndash; CHANGE PLAN TO FREE &ndash;&gt;-->

<!--<ng-template #changeToFreeDialog let-data let-ref="dialogRef">-->
<!--  <nb-card class="dialog-layout workspace-upgrade">-->
<!--    <nb-card-header style="display: block; text-align: center">-->
<!--      <img src="assets/images/alert-circle.svg" style="color: #f8bb86; width: 120px; height: 120px;">-->
<!--      <button type="button" hidden class="close" id="closeChangeToFreeDialog" (click)="ref.close()"></button>-->
<!--    </nb-card-header>-->
<!--    <nb-card-body>-->
<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <h3 class="title" style="text-align: center">Are you sure you want to switch to the Free plan?</h3>-->
<!--          <div style="display:inline-block; width: 100%">-->
<!--            <button class="btn red-btn" style="width: 45%; float: left" (click)="ref.close()">No, go back</button>-->
<!--            <button class="btn green-btn" style="width: 45%; float:right;" (click)="planSelected(currentPlan)">Yes, I am-->
<!--              sure-->
<!--            </button>-->
<!--          </div>-->
<!--          <p class="smallest" style="text-align: center">{{billing.downgradeModalText}}</p>-->
<!--          &lt;!&ndash;          <button class="cancel-btn btn btn-primary" >No</button>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </nb-card-body>-->
<!--  </nb-card>-->
<!--</ng-template>-->
<!--<button hidden id="changeToFreeDialog" (click)="openDialog(changeToFreeDialog)"></button>-->

<!--<ng-template #confirmDowngradeDialog let-data let-ref="dialogRef">-->
<!--  <nb-card class="dialog-layout workspace-upgrade" style="text-align: center">-->
<!--    <nb-card-body>-->
<!--      <div class="row">-->
<!--        <div class="col-md-12">-->
<!--          <h3 class="title" style="text-align: center">We're sorry to see you downgrade your subscription</h3>-->
<!--          <p class="big">You can upgrade again at anytime with just one click and your data will be restored.</p>-->
<!--          <div style="display:inline-block; width: 100%">-->
<!--            <button class="btn green-btn" style="width: 45%; horiz-align: center" (click)="ref.close()">Ok</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </nb-card-body>-->
<!--  </nb-card>-->
<!--</ng-template>-->
<!--<button hidden id="confirmDowngradeDialog" (click)="openDialog(confirmDowngradeDialog)"></button>-->

<!--<h3>Billing</h3>-->

<!--<nb-card class="billing-tabs">-->
<!--  <nb-card-body>-->
<!--    <mat-tab-group [(selectedIndex)]="tabIndex">-->
<!--      <mat-tab label="Overview">-->
<!--        <ng-template matTabContent>-->
<!--          <div-->
<!--            [nbSpinner]="!showPlans" nbSpinnerStatus="info">-->
<!--            <h1 *ngIf="showPlans && personalPlan()">Select your plan</h1>-->
<!--            <h1 *ngIf="showPlans && !personalPlan()">Upgrade your plan</h1>-->

<!--            <div class="info-banner" *ngIf="showNextBilling && currentWorkspacePlan.title.toLowerCase() !== 'personal' &&-->
<!--        currentWorkspacePlan.title.toLowerCase() !== 'free'">-->
<!--              <h6>Next billing is in {{currentWorkspacePlan.billDate | date}}</h6>-->
<!--            </div>-->
<!--            <div class="alert-banner" *ngIf="gotFreeCoupon">-->
<!--              <h6>You currently have a free trial of {{activeCoupon.workspacePlan.title}}-->
<!--                until {{dataManip.getDate(activeCoupon.activeCouponExpirationDate)}}</h6>-->
<!--            </div>-->
<!--            <div class="alert-banner" *ngIf="gotCoupon">-->
<!--              <h6>You currently have-->
<!--                a {{(activeCoupon.type === 'percentage') ? activeCoupon.percentage + '% ' : activeCoupon.value + 'DKK ' }}-->
<!--                discount until {{dataManip.getDate(activeCoupon.activeCouponExpirationDate)}}</h6>-->
<!--            </div>-->

<!--            <div class="pricing-list">-->
<!--              <div class="card" [ngClass]="{ 'selected': getCurrentPlan(plan) }" *ngFor="let plan of plans"-->
<!--                   (click)="setPlan(plan)">-->
<!--                <div>-->
<!--                  <h3 class="text-center">{{plan.title}}</h3>-->
<!--                  <div class="price">-->
<!--                    <h1 class="text-center">{{plan.currency}} {{plan.pricePerMember}},-</h1>-->
<!--                    <p class="text-center small">Per user per month</p>-->
<!--                  </div>-->
<!--                  <p class="best-value" *ngIf="plan.title.toLocaleLowerCase() === 'essential'">-->
<!--                    <nb-icon icon="star"></nb-icon>-->
<!--                    Most Popular-->
<!--                  </p>-->
<!--                  <p class="best-value" style="background-color: white"-->
<!--                     *ngIf="plan.title.toLocaleLowerCase() !== 'essential'">-->
<!--                    <nb-icon icon="star"></nb-icon>-->
<!--                  </p>-->
<!--                  <ul [innerHTML]="plan.html"></ul>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <hr>-->
<!--                  <div class="get-started">-->
<!--                  </div>-->
<!--                  <div class="plan-info">-->
<!--                    <div *ngIf="plan.title.toLocaleLowerCase() === 'free'">-->
<!--                      <p>Continue for free.</p>-->
<!--                      <p>No credit card needed.</p>-->
<!--                    </div>-->
<!--                    <div *ngIf="plan.title.toLocaleLowerCase() !== 'free'">-->
<!--                      <p>Pay for active accounts only.</p>-->
<!--                      <p>First billing in 30 days.</p>-->
<!--                    </div>-->
<!--                    <nb-toggle status="success" [checked]="getCurrentPlan(plan)"-->
<!--                               (click)="getCurrentPlan(plan)"></nb-toggle>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <hr>-->
<!--            <div class="billing-plan-footer">-->
<!--              <div class="coupon-input">-->
<!--                <input type="text" class="form-control" placeholder="Insert your coupon code here" [(ngModel)]="coupon"-->
<!--                       (keyup.enter)="validateCoupon()">-->
<!--                <button class="btn btn-blue ml-2" (click)="validateCoupon()">Validate and apply</button>-->
<!--              </div>-->
<!--              <div class="invoice-amount" style="display: block">-->
<!--                <div *ngIf="showPlanSimulated" class="invoice-amount-text">-->
<!--                  <p class="medium m-0">Estimated invoice amount excl.VAT:</p>-->
<!--                  <p class="small m-0">{{planSimulated?.totalWithoutVat}} DKK. to be billed-->
<!--                    at {{dataManip.getDate(planSimulated?.endDate.date)}}</p>-->
<!--                </div>-->
<!--                <p class="small m-0" *ngIf="showCouponPersonal">-->
<!--                  The applied code gives you-->
<!--                  a {{validCoupon?.type === 'percentage' ? validCoupon.percentage + '%' : validCoupon.value + 'DKK'}}-->
<!--                  discount for the {{validCoupon.workspacePlan.title}} plan.</p>-->
<!--                <button type="submit" class="btn green-btn" (click)="planSelected(currentPlan)">-->
<!--                  <nb-icon icon="arrow-forward-outline"></nb-icon>-->
<!--                  {{this.workspacePlan.title !== 'Personal' ? 'Change plan' : 'Choose plan'}}-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="Billing Info" [disabled]="disableMenu"-->
<!--               [style]="disableMenu?'pointer-events: none; color: grey':''">-->
<!--        <ng-template matTabContent>-->
<!--          <div class="billing-info">-->
<!--            <form [formGroup]="billingInfoForm" (ngSubmit)="saveBillingInfo()">-->
<!--              <h1>Company Information</h1>-->
<!--              <p class="big">This information will be included on all invoices made for your account.</p>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6 offset-md-3 col-12">-->
<!--                  <label class="form-label">Vat number</label>-->
<!--                  <div class="form-group d-flex align-items-end">-->
<!--                    <ngx-flag-picker-->
<!--                      [selectedCountryCode]="selectedCountryVat"-->
<!--                      [countryCodes]="countryCodes"-->
<!--                      [showArrow]="false"-->
<!--                      (changedCountryCode)="changeSelectedCountryCode($event)">-->
<!--                    </ngx-flag-picker>-->
<!--                    <input nbInput class="form-control" fullWidth-->
<!--                           [status]="(billingInfoForm.controls.vatNumber.invalid && !validBillingInfo) ? 'danger' : 'basic'"-->
<!--                           type="number" formControlName="vatNumber">-->
<!--                    <button class="btn btn-blue verify" type="button" (click)="verifyVat()">Retrieve information-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <label class="form-label">Company Name*</label>-->
<!--                  <input nbInput class="form-control" fullWidth-->
<!--                         [status]="(billingInfoForm.controls.companyName.invalid && !validBillingInfo) ? 'danger' : 'basic'"-->
<!--                         type="text" formControlName="companyName" required>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <label class="form-label">Address</label>-->
<!--                  <input nbInput class="form-control" fullWidth-->
<!--                         [status]="(billingInfoForm.controls.streetAddress.invalid && !validBillingInfo) ? 'danger' : 'basic'"-->
<!--                         type="text" formControlName="streetAddress">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <label class="form-label">Country</label>-->
<!--                  <ngx-flag-picker class="d-flex"-->
<!--                                   [selectedCountryCode]="selectedCountry"-->
<!--                                   [countryCodes]="countryCodes"-->
<!--                                   [showArrow]="true"-->
<!--                                   [showLabels]="true"-->
<!--                                   [customLabels]="countryLabels"-->
<!--                                   (changedCountryCode)="changeCountryCode($event)">-->
<!--                  </ngx-flag-picker>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <label class="form-label">Phone</label>-->
<!--                  <input type="text" fullWidth-->
<!--                         class="phone-flag"-->
<!--                         ng2TelInput formControlName="phone"-->
<!--                         placeholder="Phone"-->
<!--                         [ng2TelInputOptions]="{initialCountry: 'dk'}"-->
<!--                         (hasError)="hasError($event)"-->
<!--                         (ng2TelOutput)="getNumber($event)"-->
<!--                         (intlTelInputObject)="telInputObject($event)"-->
<!--                         (countryChange)="onCountryChange($event)"/>-->
<!--                  <p *ngIf="phoneError === false" style="color:red;">Phone number is not valid</p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <button type="submit" class="btn green-btn">Save info</button>-->
<!--                </div>-->
<!--              </div>-->

<!--            </form>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="Payment Methods" [disabled]="disableMenu"-->
<!--               [style]="disableMenu?'pointer-events: none; color: grey':''">-->
<!--        <ng-template matTabContent>-->
<!--          <div>-->
<!--            <h1>Payment Method</h1>-->
<!--            <p class="big" *ngIf="gotPaymentMethod && paymentMethods.length === 0">Add your payment method details to-->
<!--              activate your plan.-->
<!--              <br> The first billing date is in 30 days.</p>-->

<!--            <div class="row" *ngFor="let method of paymentMethods">-->
<!--              <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                <nb-card *ngIf="method.last4digits !== null">-->
<!--                  <nb-card-body>-->
<!--                    <i class="fa fa-credit-card fa-3x" style="padding-right: 10px"></i>-->
<!--                    <i [class]="'fa fa-cc-' + method.cardBrand+' fa-3x'"></i>-->
<!--                    <p class="big">Name: {{method.cardName}}</p>-->
<!--                    <p>Last 4 digits: {{method.last4digits}}</p>-->
<!--                    <p>Email: {{method.cardEmail}}</p>-->
<!--                    <p>Expiration date: {{method.expirationMonth}}/{{method.expirationYear}}</p>-->
<!--                    <p>Status: {{method.status}}</p>-->
<!--                  </nb-card-body>-->
<!--                </nb-card>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div style="text-align: center">-->
<!--              <button-->
<!--                *ngIf="gotPaymentMethod" class="btn green-btn mx-auto mb-3" (click)="addMethod()">-->
<!--                <nb-icon icon="plus-outline"></nb-icon>-->
<!--                {{paymentMethods.length === 0 ? 'Add payment' : 'Update payment'}}-->
<!--              </button>-->
<!--              <img style="height: 35px;" src="../../../../assets/images/stripe-blurple.png">-->
<!--            </div>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="History" [disabled]="disableMenu" [style]="disableMenu?'pointer-events: none; color: grey':''">-->
<!--        <ng-template matTabContent>-->
<!--          <div>-->
<!--            <h1>Invoice History</h1>-->
<!--            <p class="big" *ngIf="gotInvoices && workspaceInvoices.length === 0">Currently there ar no invoices</p>-->

<!--            <nb-card class="own-scroll my-actions invoice" *ngIf="workspaceInvoices.length > 0">-->
<!--              <nb-card-body class="secondary-header">-->
<!--                <h6>Invoice Number</h6>-->
<!--                <h6>Invoice Details</h6>-->
<!--                <h6>Charged at</h6>-->
<!--              </nb-card-body>-->

<!--              <nb-card-body class="action-list-block" *ngIf="workspaceInvoices.length > 0">-->
<!--                <nb-list class=""-->
<!--                         nbInfiniteList-->
<!--                         [threshold]="500"-->
<!--                         (bottomThreshold)="loadNextActivities()">-->
<!--                  <nb-list-item class="actions" *ngFor="let invoice of workspaceInvoices">-->
<!--                    <div class="">-->
<!--                      <p class="light">{{invoice.invoiceNumber}}</p>-->
<!--                    </div>-->
<!--                    <div class="">-->
<!--                      <p class="light" *ngFor="let details of invoice.invoiceDetails">-->
<!--                        Successteam {{details.workspacePlan}}-->
<!--                        : Usage of {{details.userCount}} seats ({{details.startDate.date |date}}-->
<!--                        -{{details.endDate.date | date}})</p>-->
<!--                    </div>-->
<!--                    <div class="">-->
<!--                      <p class="light">{{invoice.dueDate.date | date}}</p>-->
<!--                    </div>-->
<!--                    <div class="">-->
<!--                      <button class="green-btn-text" *ngIf="invoice.downloadLink !== null" style="cursor: pointer">-->
<!--                        <a href="{{invoice.downloadLink}}" target="_blank" style="color: #32cf8c"-->
<!--                        >-->
<!--                          <nb-icon icon="download-outline"></nb-icon>-->
<!--                        </a>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </nb-list-item>-->
<!--                </nb-list>-->
<!--              </nb-card-body>-->
<!--            </nb-card>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="Billing Contact" [disabled]="disableMenu"-->
<!--               [style]="disableMenu?'pointer-events: none; color: grey':''">-->
<!--        <ng-template matTabContent>-->
<!--          <div class="contact">-->
<!--            <h1>Invoice Contact Information</h1>-->
<!--            <p class="big">These contacts will receive the monthly invoices.</p>-->
<!--            <div class="row" *ngIf="!showContactForm">-->
<!--              <div class="offset-md-3 col-md-6 col-12">-->
<!--                <nb-card size="small">-->
<!--                  <nb-list>-->
<!--                    <nb-list-item *ngFor="let contact of contacts">-->
<!--                      <nb-user [name]="contact.email" [title]="(contact.firstName !== null || contact.lastName !== null) ?-->
<!--                   contact.firstName + ' ' + contact.lastName : ''">-->
<!--                      </nb-user>-->
<!--                    </nb-list-item>-->
<!--                  </nb-list>-->
<!--                </nb-card>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row" *ngIf="!showContactForm">-->
<!--              <div class="col-md-6 offset-md-3 col-12 d-flex justify-content-end">-->
<!--                <button (click)="showContactForm = !showContactForm" class="btn green-btn">Add</button>-->
<!--              </div>-->
<!--            </div>-->

<!--            <form [formGroup]="contactForm" (ngSubmit)="addContact()" *ngIf="showContactForm">-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <input nbInput class="form-control" fullWidth-->
<!--                         [status]="(contactForm.controls.email.invalid && !validContact) ? 'danger' : 'basic'"-->
<!--                         type="text" formControlName="email" placeholder="Email*">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <input nbInput class="form-control" fullWidth-->
<!--                         [status]="(contactForm.controls.firstName.invalid && !validContact) ? 'danger' : 'basic'"-->
<!--                         type="text" formControlName="firstName" placeholder="First Name">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="form-group col-md-6 offset-md-3 col-12">-->
<!--                  <input nbInput class="form-control" fullWidth-->
<!--                         [status]="(contactForm.controls.lastName.invalid && !validContact) ? 'danger' : 'basic'"-->
<!--                         type="text" formControlName="lastName" placeholder="Last Name">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-6 offset-md-3 col-12 d-flex justify-content-end">-->
<!--                  <button (click)="showContactForm = !showContactForm" class="btn back-btn">Cancel</button>-->
<!--                  <button type="submit" class="btn green-btn">Add contact</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="Workspace Activity" [disabled]="disableMenu"-->
<!--               [style]="disableMenu?'pointer-events: none; color: grey':''">-->
<!--        <ng-template matTabContent>-->
<!--          <div>-->
<!--            <nb-card class="own-scroll my-actions" *ngIf="workspaceActivities.length > 0">-->
<!--              <nb-card-body class="secondary-header">-->
<!--                <h6>Modified by</h6>-->
<!--                <h6>Action</h6>-->
<!--                <h6>Date</h6>-->
<!--              </nb-card-body>-->

<!--              <nb-card-body class="action-list-block" *ngIf="workspaceActivities.length > 0">-->
<!--                <nb-list class=""-->
<!--                         nbInfiniteList-->
<!--                         [threshold]="500"-->
<!--                         (bottomThreshold)="loadNextActivities()">-->
<!--                  <nb-list-item class="actions" *ngFor="let activity of workspaceActivities">-->
<!--                    <div class="user-details" *ngIf="activity.systemAdmin === null">-->
<!--                      <img *ngIf="activity.admin.avatar !== null && activity.admin.avatar.path !== null"-->
<!--                           src="{{baseUrl + activity.admin.avatar.path}}">-->
<!--                      <img *ngIf="activity.admin.avatar === null || activity.admin === null" class="user-image"-->
<!--                           src="../../../../assets/images/user-placeholder.png">-->
<!--                      <p class="light">{{activity.admin.detail.displayName}}</p>-->
<!--                    </div>-->
<!--                    <div class="user-details" *ngIf="activity.systemAdmin !== null">-->
<!--                      <img class="user-image"-->
<!--                           src="../../../../assets/images/user-placeholder.png">-->
<!--                      <p class="light">{{activity.systemAdmin.firstName + ' ' + activity.systemAdmin.lastName}}</p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <p class="light">{{activity.actionText}}</p>-->
<!--                    </div>-->
<!--                    <div class="">-->
<!--                      <p class="light">{{activity.created.date | date}}</p>-->
<!--                    </div>-->
<!--                  </nb-list-item>-->
<!--                </nb-list>-->
<!--              </nb-card-body>-->
<!--            </nb-card>-->
<!--          </div>-->
<!--        </ng-template>-->
<!--      </mat-tab>-->
<!--    </mat-tab-group>-->
<!--  </nb-card-body>-->
<!--</nb-card>-->
