<app-email-confirmation *ngIf="dataService.mailSent"></app-email-confirmation>
<div *ngIf="!dataService.mailSent" class="main-container">
  <div class="auth-container">
    <div class="left-side">
      <img class="logo" src="../../../assets/images/successteam-logo-white.svg" alt="succesteam_logo">
      <!--      <h1 class="">Forgot Your Password?</h1>-->
      <!--      <img class="startup-logo" src="../../../assets/images/startup.png" alt="">-->
    </div>
    <div class="right-side" *ngIf="!resetHash">
      <h1>{{'forgot_title'| translate}}</h1>
      <p>{{'forgot_subtitle' | translate}}</p>
      <form [formGroup]="mailForm" (ngSubmit)="sendResetPasswordMail($event)">
        <div class="input-container">
          <label>{{'forgot_email' | translate}}</label>
          <input class="form-control register-input" id="email" formControlName="email" type="email">
          <div *ngIf="mailForm.controls['email'].invalid && (resetForm.controls['email']?.dirty || resetForm.controls['email']?.touched)" class="text-danger">
            <div *ngIf="mailForm.controls['email']?.errors?.['required']">{{'register_email_error' | translate}}</div>
          </div>
        </div>
        <p class="blue-link" routerLink="/login"><i class="bi bi-arrow-left ms-2"></i>{{'forgot_back' | translate}}</p>
        <button class="btn register-submit-button" type="submit">
          {{'forgot_button' | translate}}
          <i class="bi bi-arrow-right ms-2"></i>
        </button>
      </form>
    </div>

    <div class="right-side" *ngIf="resetHash">
      <div class="form-block">
        <h1>{{'reset_pass_title'|translate}}</h1><br>
        <p>{{'reset_pass_sub_title'|translate}} <br> {{'reset_pass_sub_title_2'| translate}}</p>
        <form [formGroup]="resetForm" (ngSubmit)="resetPassword($event)">
          <div class="input-container">
            <label>Password</label>
            <input type="password" formControlName="password" class="form-control register-input" id="password"/>
            <!--            add password power-->
            <div
              *ngIf="resetForm.controls['password'].invalid && (resetForm.controls['password']?.dirty || resetForm.controls['email']?.touched)"
              class="text-danger">
              <div *ngIf="resetForm.controls['password']?.errors?.['required']">{{'register_password_error'| translate}}</div>
              <div *ngIf="resetForm.controls['password']?.errors?.['minlength']">{{'register_password_length_error' | translate}}</div>
            </div>
          </div>
          <div class="input-container">
            <label>{{'register_confirm_pass'| translate}}</label>
            <input type="password" formControlName="confirmPassword" class="form-control" id="confirmPassword"/>
            <div
              *ngIf="resetForm.controls['confirmPassword'].invalid && (resetForm.controls['confirmPassword']?.dirty || resetForm.controls['email']?.touched)"
              class="text-danger">
              <div
                *ngIf="resetForm.controls['confirmPassword'].invalid && (resetForm.controls['confirmPassword']?.dirty || resetForm.controls['email']?.touched)">
                Confirm Password is required
              </div>
              <div
                *ngIf="resetForm.controls['confirmPassword'].invalid && (resetForm.controls['confirmPassword']?.dirty || resetForm.controls['email']?.touched)">
                Passwords must match
              </div>
            </div>
          </div>
          <button class="btn register-submit-button" type="submit">
            {{'forgot_button' | translate}}
            <i class="bi bi-arrow-right ms-2"></i>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
