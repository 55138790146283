import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const currentUser = this.authenticationService.currentUserValue;
    const currentWorkspace = this.authenticationService.currentWorkspaceUuid;

    if (this.router.url.includes('login/impersonate/') && localStorage.getItem('impersonate') === null) {
      // this.authenticationService.logout();
      localStorage.setItem('impersonate', 'true');
      this.router.navigate(['/']);

      // this.router.navigate(['/']);
    }

    if (currentUser) {
      // logged in so return true
      if (currentWorkspace) {
        return true;
      } else {
        this.authenticationService.logout();
      }
    }


    // not logged in so redirect to login page with the return url
    if (localStorage.getItem('impersonate') !== null) {
      this.router.navigate(['/']);
      return false;
    } else {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
