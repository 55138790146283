import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {interval, Subscription} from 'rxjs';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {environment} from '../../../environments/environment';
import {ModalsComponent} from "../../components/modals/modals.component";

@Component({
  selector: 'app-workspace-members',
  templateUrl: './workspace-members.component.html',
  styleUrls: ['./workspace-members.component.scss']
})
export class WorkspaceMembersComponent implements OnInit {

  private refreshUserDataSubscription: Subscription | undefined;

  user: any;
  workspace: any;
  members: any = [];
  currentTime = 1;
  hideStep6 = false;
  isTeamAdmin = false;

  savedSuccess = {accountSavedSuccess: false, workspaceSavedSuccess: false};
  baseUrl: any;
  public step: number = 1;
  public activeStep: number = 1;
  workspaceTeams: any = [];
  memberTeams: any = [];

  membersSubs: Subscription;
  teamResponseRate: number = 0;
  teamResponseDate: number = 0;
  isMember: boolean = false;
  userPolls: any = [];
  private role: any;
  pageLanguage: any;
  quickLearningText: any;

  quickLearningTextEN = [
    {
      title: 'HONEST FEEDBACK IS VITAL',
      description: 'Sucessteam’s goal is to create happy and motivated teams and your honest feedback is a cornerstone.',
      linkText: 'See your quick guide to Successteam >',
      link: 'https://successteam.crunch.help/en/member-guides/team-members-guide-to-successteam'
    },
    {
      title: 'COMPLETE ANONIMITY',
      description: 'All feedback is anonymised, and we never share individual responses.',
      linkText: 'See how do we ensure anonymity >',
      link: 'https://successteam.crunch.help/en/feedbackcollector/are-all-answers-really-anonymous'
    },
    {
      title: 'CREATE YOUR OWN TEAMS',
      description: 'Do you want to invite more people to your workspace and set up more teams? ',
      linkText: 'Request the Workspace Administrator role',
      link: ''
    }
  ];
  quickLearningTextDK = [
    {
      title: 'ÆRLIG FEEDBACK ER AFGØRENDE',
      description: 'Sucessteams mål er at skabe glade og motiverede teams, og din ærlige feedback er en af grundstenene.',
      linkText: 'Se din lynguide til Successteam >',
      link: 'https://successteam.crunch.help/en/member-guides/team-members-guide-to-successteam'
    },
    {
      title: 'KOMPLET ANONYMITET',
      description: 'Al feedback er anonymiseret, og vi deler aldrig individuelle svar.',
      linkText: 'Se, hvordan vi sikrer anonymiteten >',
      link: 'https://successteam.crunch.help/en/feedbackcollector/are-all-answers-really-anonymous'
    },
    {
      title: 'OPRET DINE EGNE TEAMS',
      description: 'Ønsker du at invitere flere personer til dit workspace og oprette flere teams?',
      linkText: 'Anmod om rollen som Workspace Administrator',
      link: ''
    }
  ];
  private interval: any;

  constructor(public data: DataManipulationService, public apiwrapper: ApiwrapperService,
              private router: Router, public modal: ModalsComponent) {
    this.baseUrl = environment.baseUrl;
    this.membersSubs = data.gotMembersSubs.subscribe((gotMembers: any) => {
      if (gotMembers && !this.isMember) {
        this.getUserWorkspaces();
      }
    })
    this.data.autoOpenMemberFeedback = false;
    this.checkForStep();
  }

  ngOnInit(): void {
    this.role = this.data.userRole
    this.sortTeams(this.role);

    this.refreshUserDataSubscription = this.data.changedUserData.subscribe((refreshUserData: any) => {
      if (refreshUserData && !this.isMember) {
        // @ts-ignore
        this.apiwrapper.getUserDetails(localStorage.getItem('WORKSPACE_UUID')).subscribe(res => {
          this.getUserDetails();
        });
      }
    });

    this.getWorkspace();
    this.getUserDetails();
    this.checkSubscription()
    this.memberCompletedAssignments();
    this.userAssignments()

    // @ts-ignore
    let language =JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
    // @ts-ignore
    if(language.includes('en')) {
      this.quickLearningText = this.quickLearningTextEN;
    } else {
      this.quickLearningText = this.quickLearningTextDK;
    }

    if (localStorage.getItem('menuHome') === null) {
      this.data.showHomeMenu = 'member';
      localStorage.setItem('menuHome', 'member');
    }
  }

  ngOnDestroy() {
    // @ts-ignore
    this.refreshUserDataSubscription.unsubscribe();
    this.membersSubs.unsubscribe();
  }

  checkForStep() {
    if (this.data.userLink.teamMemberOnboardingStep !== undefined) {
      this.step = this.data.userLink?.teamMemberOnboardingStep.split("_")[1];
      this.activeStep = this.data.userLink?.teamMemberOnboardingStep.split("_")[1];
    }
  }


  getWorkspace() {
    // @ts-ignore
    this.apiwrapper.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {

        this.workspace = res;
        this.getTime();
      }, error => {
      });
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }

  setProgress(step: number) {
    if (this.step < step) {
      this.step = step;
      this.updateMemberStartGuide(step);
    }
    if (this.members.length === 1 && step === 2) {
      this.activeStep = 1;
    } else if (this.activeStep < step) {
      this.activeStep = step;
    }
  }

  getUserWorkspaces() {
    // get all users from data.allUsers
    this.members = this.data.allMembers;
    if (this.members.length > 4) {
      this.setProgress(2);
    }
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('userDetails'));
    this.members.forEach((el: { workspace: { name: string | any[]; }; }, index: any) => {
        if (el.workspace.name.includes(user.email)) {
          this.members.splice(index, 1);
        }
      }
    );
  }

  getUserDetails() {
    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));
  }

  // @ts-ignore
  showSaved(block) {
    if (block === 'account') {
      this.savedSuccess.accountSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.accountSavedSuccess = false;
      }, 3001);
    }
    if (block === 'workspace') {
      this.savedSuccess.workspaceSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.workspaceSavedSuccess = false;
      }, 3000);
    }
  }

  uploadImage(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.apiwrapper.uploadAvatar(file).subscribe(
              (res: any) => {
                this.user.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.showSaved('account');
              },
              (error) => {
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  private checkSubscription() {
    const plan = this.data.getWorkspacePlan()
    if (plan.requirePayment === 'yes') {
      this.hideStep6 = true;
    }
  }

  private sortTeams(role: any) {
    this.memberTeams = [];
    this.isTeamAdmin = false;
    role.teams.forEach((team: any) => {
      if (team.role !== null) {
        this.memberTeams.push(team);
        this.activeStep = 3;
        this.step = 3;
        this.updateMemberStartGuide(this.step);
        if (team.role.teamPriority > 10) {
          this.isTeamAdmin = true;
        }
      }
    });
  }

  private memberCompletedAssignments() {
    // hide the menu button
    this.apiwrapper.getCompletedAssignments().subscribe(
      el => {
        if (el > 0) {
          this.data.showHomeMenu = '';
          localStorage.setItem('menuHome', '');
          this.updateMemberStartGuide('done');
          // send to team dashboard or teams overview and remove from menu
          if (this.memberTeams.length > 1) {
            this.router.navigate(['/teams-overview']);
          } else if (this.memberTeams.length === 1) {
            this.router.navigate(['/team/' + this.memberTeams[0].team.uuid]);
          }
        }
      }
    )
  }

  userAssignments() {
    this.apiwrapper.getMemberAssignments().subscribe(
      el => {
        this.userPolls = el;
      }
    )
  }

  goToTeam() {
    if (this.userPolls.length > 0) {
      //   // go to teams-overview
      //   this.router.navigate(['/teams-overview']);
      // } else {
      //   // take member to team
      this.data.autoOpenMemberFeedback = true;
      this.router.navigate(['/team/' + this.userPolls[0].poll.team.uuid]);
      // go to team 1
    }
  }

  updateMemberStartGuide(step: any) {
    let teamMemberOnboardingStep = '';
    if (step !== 'done') {
      teamMemberOnboardingStep = 'step_' + step;
    } else {
      teamMemberOnboardingStep = 'done';
    }
    if (teamMemberOnboardingStep !== this.data.userLink.teamMemberOnboardingStep) {
      this.apiwrapper.updateWorkspaceUser(this.data.userLink.uuid, {teamMemberOnboardingStep}).subscribe(
        res => {
          this.data.userLink = res;
        }
      )
    }
  }
}
