import {Component, OnInit} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {MatChipSelectionChange} from "@angular/material/chips";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ENTER, SPACE} from "@angular/cdk/keycodes";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {of} from "rxjs";

@Component({
  selector: 'app-add-members',
  templateUrl: './add-members.component.html',
  styleUrls: ['./add-members.component.scss']
})
export class AddMembersComponent implements OnInit {

  membersInvited: any = [];
  inviteEmails: any = [];
  teamEmails = new FormControl('');
  readonly separatorKeysCodes = [ENTER, SPACE] as const;
  addOnBlur: boolean = true;
  emailCheck = new FormControl('', [Validators.required, Validators.email]);
  emailPermissionCheck: Boolean = false;
  previewMessageButton = false;
  public workspace: any;
  inviteViaLink = new FormGroup({
    invitationLink: new FormControl(this.data.workspaceInvitation),
    invitationLinkWithMessage: new FormControl('')
  });
  private roleMember: any;
  private roleAdmin: any;
  workspaceInvitationBody: any = {};

  constructor(public data: DataManipulationService, public api: ApiwrapperService) {
    // @ts-ignore
    if (localStorage.getItem('WORKSPACE')){
      // @ts-ignore
      this.workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
      //HACKISH
      if (!this.workspace.activeInvitationBody.body){
        // get the new workspace / reload
        // @ts-ignore
        this.api.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
          .subscribe(res => {
            localStorage.setItem('WORKSPACE', JSON.stringify(res));
            location.reload();
          }, error => {
          });
      }

    }

    this.api.getAllRoles('workspace').subscribe(
      res => {
        res.forEach((el: { name: string; uuid: any; }) => {
          if (el.name === 'workspace-admin') {
            this.roleAdmin = el.uuid;
            this.data.roleWorkspaceAdmin = el.uuid;
          }
          if (el.name === 'workspace-member') {
            this.roleMember = el.uuid;
            this.data.roleWorkspaceMember = el.uuid;
          }
        });
      }, err => {
        console.log(err);
      });
    this.workspaceInvitationBody = this.workspace.activeInvitationBody
    this.workspaceInvitationBody.body = this.workspace.activeInvitationBody.body.replace(/&#10;&#13;/g, '\n');



  }

  ngOnInit(): void {
  }

  selectedEmail(event: MatChipSelectionChange, email: any): void {
    if (event.selected) {
      this.teamEmails.setValue(email);
      this.removeEmail(email);
    }
    document.getElementById('inviteEmail')?.focus();
  }

  removeEmail(email: any): void {
    const index = this.inviteEmails.indexOf(email);
    if (index >= 0) {
      this.inviteEmails.splice(index, 1);
    }
  }

  addEmail(event: any): void {
    const value = (event.value || '').trim();
    let mails = [];
    if (value === '') {
      return;
    }
    if(value.indexOf(' ') > -1 ) {
      mails = value.split(' ');
    } else {
      mails = value.split(',').map((item: string) => item.trim()).filter((el: string) => el != '');
    }
    // check if multiple email addresses and add all that are valid
    mails.forEach((mail: string) => {
      mail = mail.replace(/\,/g, '');
      this.emailCheck.setValue(mail);
      if (this.emailCheck.valid) {
        if (!this.inviteEmails.includes(mail)) {
          if((this.data.membersInvited.filter((el:any) => el.user.email === mail)).length < 1) {
            this.inviteEmails.push(mail);
          } else {
            this.data.showAlertWarning('The user with email '+ mail +' was already invited to this workspace.');
          }
        } else {
          this.data.showAlertWarning('"' + mail + '" was already added in the email list.');
        }
        // this.inviteEmails.push({name: mail});
        // Clear the input value
        event.chipInput!.clear();
      } else {
        this.data.showAlertError('"' + mail + '" not a valid email');
      }
    });
  }

  previewMessageOnEmailInvitation() {
    if (this.previewMessageButton) {
      // update email
      this.data.showLoading();
      this.api.patchWorkspaceInvitation(this.workspaceInvitationBody.body, this.workspaceInvitationBody.uuid).subscribe(
        res => {
          this.data.showAlert('Your invitation was successfully updated!');
          this.workspace.workspaceInvitationBody = res;
          localStorage.setItem('WORKSPACE', JSON.stringify(this.workspace));
          this.data.inviteMessage = res.body;
          this.previewMessageButton = !this.previewMessageButton;
          this.data.hideLoading();
        }, err => {
          this.data.showAlertError('Unable to update invitation!');
          this.data.hideLoading();
          return;
        }
      );
    } else {
      this.previewMessageButton = !this.previewMessageButton;
    }
  }

  inviteMembers() {
    this.data.showLoading();
    if (this.inviteEmails.length > 0) {
      this.api.inviteUserToWorkspace({emails: this.inviteEmails, roleUuid: this.roleMember}).subscribe(res => {
        this.teamEmails.setValue('');
        this.inviteEmails = [];
        // get all members and trigger subs
        this.data.getAllMembers = false;
        this.data.getWorkspaceMembers(1);
        // @ts-ignore
        document.getElementById('closeInviteModal').click();
        this.data.hideLoading();
        this.data.showAlert('An invitation email was sent to the users!')
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error sending the invitation. Try again a bit later.');
      });
    }
  }
}
