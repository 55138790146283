import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {environment} from '../../../environments/environment';
import {ModalsComponent} from "../../components/modals/modals.component";

@Component({
  selector: 'app-workspace-members-leader',
  templateUrl: './workspace-members-leader.component.html',
  styleUrls: ['./workspace-members-leader.component.scss']
})
export class WorkspaceMembersLeadersComponent implements OnInit, OnDestroy {

  // @ts-ignore
  private step3Subscription: Subscription;

  user: any;
  workspace: any;
  members: any = [];
  currentTime = 1;
  hideStep6 = false;
  isTeamAdmin = false;
  userTeams: any = [];
  savedSuccess = {accountSavedSuccess: false, workspaceSavedSuccess: false};
  baseUrl: any;
  public step: number = 1;
  public activeStep: number = 1;

  membersSubs: Subscription;
  teamResponseRate: number = 0;
  teamResponseDate: string = '';
  isMember: boolean = false;
  userPolls: any = [];
  private role: any;
  lastCompleted: boolean = false;
  gotNextPolling: boolean = false;

  teamResponse: any;
  pollings: any;
  private teamEligible: boolean = false;
  private interval: number;
  private leaderCollector: Subscription;
  quickLearningText: any;


  quickLearningTextEN = [
    {
      title: 'Empower Your Team',
      description: 'Discover the Five essential types of recognition that drive productivity and team spirit. Unleash your team\'s potential now!',
      linkText: 'Explore Recognition Types',
      link: 'https://successteamweb.crunch.help/en/best-practice/5-types-of-recognition'
    },
    {
      title: 'Empower Your Leadership',
      description: 'Learn how to build a robust career development plan for your team members, fostering growth and success within your organization.',
      linkText: 'Explore Career Development',
      link: 'https://successteamweb.crunch.help/en/methods/career-development-plan'
    },
    {
      title: 'Unlock Employee Potential',
      description: 'Enhance your leadership with strategic questioning techniques. Go beyond surface-level answers and create a supportive, motivating environment.',
      linkText: 'Explore more',
      link: 'https://successteamweb.crunch.help/en/methods/the-right-questions'
    }
  ];
  quickLearningTextDK = [
    {
      title: 'Styrk dit team',
      description: 'Lær om de fem vigtige former for anerkendelse, der fremmer produktivitet og sammenhold. Skab de bedste rammer for at lykkes i fællesskab!',
      linkText: 'Udforsk typer af anerkendelse',
      link: 'https://successteamweb.crunch.help/en/best-practice/5-types-of-recognition'
    },
    {
      title: 'Styrk dit lederskab',
      description: 'Lær, hvordan du opbygger en stærk karriereudviklingsplan for dine teammedlemmer, så du skaber vækst og succes i jeres organisation.',
      linkText: 'Udforsk karriereudvikling',
      link: 'https://successteamweb.crunch.help/en/methods/career-development-plan'
    },
    {
      title: 'Frigør medarbejdernes potentiale',
      description: 'Styrk dit lederskab med strategiske spørgeteknikker. Gå ud over overfladiske svar og skab et støttende, motiverende miljø.',
      linkText: 'Udforsk mere',
      link: 'https://successteamweb.crunch.help/en/methods/the-right-questions'
    }
  ];


  constructor(public data: DataManipulationService, public apiwrapper: ApiwrapperService,
              private router: Router, public modal: ModalsComponent) {

    this.baseUrl = environment.baseUrl;
    this.membersSubs = data.gotMembersSubs.subscribe((gotMembers: any) => {
      if (gotMembers) {

      }
    })

    this.step3Subscription = this.data.collectorDashboard.subscribe(refreshTeam => {
      if (refreshTeam) {
        this.step = 3;
        this.updateLeaderStartGuide(this.step);
      }
    });

    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));

    this.interval = setInterval(() => {
      this.checkStep()
    }, 500)

    this.leaderCollector = this.data.leaderCollectorObs.subscribe(refreshPolls => {
      this.apiwrapper.getCurrentWorkspaceUserRolesPriority().subscribe(
          (res: any) => {
            this.data.setUserRoles(res);
            this.role = this.data.userRole
            this.sortTeams(this.role);
          });
    });
  }

  ngOnInit(): void {
    this.data.showHomeMenu = 'member-leader';
    localStorage.setItem('menuHome', 'member-leader');
    if(this.user.detail.preferredLanguage.tag.includes('en')) {
      this.quickLearningText = this.quickLearningTextEN;
    } else {
      this.quickLearningText = this.quickLearningTextDK;
    }
    this.role = this.data.userRole
    this.sortTeams(this.role);
    this.getWorkspace();
    this.getWorkspacePollings()
  }

  ngOnDestroy() {
    this.step3Subscription.unsubscribe();
    this.membersSubs.unsubscribe();
    this.leaderCollector.unsubscribe();
  }

  checkStep() {
    if (this.data.userLink.updateMemberStartGuide !== undefined) {
      this.step = this.data.userLink.updateMemberStartGuide.split("_")[1];
      this.activeStep = this.data.userLink.updateMemberStartGuide.split("_")[1];
      clearInterval(this.interval);
    }
  }

  getWorkspace() {
    // @ts-ignore
    this.apiwrapper.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.workspace = res;
        this.getTime();
      }, error => {
      });
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }


  // @ts-ignore
  showSaved(block) {
    if (block === 'account') {
      this.savedSuccess.accountSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.accountSavedSuccess = false;
      }, 3001);
    }

    if (block === 'workspace') {
      this.savedSuccess.workspaceSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.workspaceSavedSuccess = false;
      }, 3000);
    }
  }

  uploadImage(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.apiwrapper.uploadAvatar(file).subscribe(
              (res: any) => {
                this.user.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.showSaved('account');
              },
              (error) => {
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  private sortTeams(role: any) {
    this.userTeams = [];
    role.teams.forEach((team: any) => {
      if (team.role !== null) {
        if (team.role.teamPriority > 10 && !team.team.teamStartGuide) {
          this.userTeams.push(team.team);
          // check team details
          if (team.team.teamHaveActivePolling === "yes") {
            this.activeStep = 3
            this.step = 3
          } else if (team.team.totalMembers > 4) {
            this.teamEligible = true;
            this.activeStep = 2
            this.step = 2
          }
        }
        this.updateLeaderStartGuide(this.step);
      }
    });
  }


  organizeTeams() {
    // setup organize teams
    this.data.organizeTeams = this.userTeams;
    this.data.openFromTeam = false;
    this.data.setOrganizeTeams(true);
  }

  setupCollectors() {
    //setup collectors for all teams
    this.data.collectorTeams = this.userTeams;
    this.data.openFromTeam = false;
    this.data.openFromDashboard = true;
    this.data.setShowCollector(false);
    this.modal.openCollectorModal()
  }

  private getWorkspacePollings() {
    let lastPoolings: any;
    this.lastCompleted = false;
    this.apiwrapper.getWorkspacesPollings().subscribe(
      (res: any) => {
        this.pollings = res;
        if (res.lastCompletedPoll === null && res.nextCompletedPoll === null) {
          return;
        }
        if (res.lastCompletedPoll !== null) {
          this.lastCompleted = true;
          lastPoolings = res.lastCompletedPoll;
        } else {
          lastPoolings = res.nextCompletedPoll;
          // get the graph to show response rate  || p l m
          this.apiwrapper.getTeamStatistics(res.nextCompletedPoll.team.uuid).subscribe(
            res => {
              this.teamResponseRate = res.percentage;
            }, err => {
              console.log(err);
            }
          );
        }
        this.gotNextPolling = true;
        this.teamResponseRate = lastPoolings.team.report.responseRate;
        this.teamResponseDate = lastPoolings.expireDate.date.slice(0, 10);
        this.teamResponse = lastPoolings.team;
      }, error => {

      }
    )
  }

  seeResults() {
    if (this.pollings.lastCompletedPoll !== null && this.pollings.nextCompletedPoll !== null) {
      this.apiwrapper.patchWorkspace(this.workspace.uuid, {teamStartGuide: true}).subscribe(
        resolve => {
          // set the localstorage workspace to the new workspace
          localStorage.setItem('WORKSPACE', JSON.stringify(resolve));
          this.goToTeam();
        }
      );
      return;
    } else if (this.lastCompleted) {
      // go direct to team
      // if there is lastCompletedPole go to team after updating step
      // + if there is step 6 completed then hide dashboard
      // else open modal
      this.goToTeam();
    } else {
      document.getElementById('reviewResultsModal')?.click();
    }
  }

  goToTeam() {
    this.updateLeaderStartGuide('done');
    if (this.userTeams.length > 1) {
      this.router.navigate(['/teams-overview']);
    } else if (this.userTeams.length === 1) {
      this.router.navigate(['/team/' + this.userTeams[0].uuid]);
    }
  }

  updateLeaderStartGuide(step: any) {
    let updateMemberStartGuide = '';
    if (step !== 'done') {
      updateMemberStartGuide = 'step_' + step;
    } else {
      updateMemberStartGuide = 'done';
    }
    if (updateMemberStartGuide !== this.data.userLink.teamMemberOnboardingStep) {
      this.apiwrapper.updateWorkspaceUser(this.data.userLink.uuid, {updateMemberStartGuide}).subscribe(
        res => {
          this.data.userLink = res;
        }
      )
    }
  }
}
