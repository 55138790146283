import {Component, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ApiwrapperService} from "../../services/apiwrapper.service";

@Component({
  selector: 'app-add-teams',
  templateUrl: './add-teams.component.html',
  styleUrls: ['./add-teams.component.scss']
})
export class AddTeamsComponent implements OnInit {

  teamName = new FormControl('');
  workspaceTeams: {
    members: number;
    error: boolean;
    created: boolean;
    name: string, type: string, color: string
  }[] = [];
  public teamTypes: any = [];
  private createdTeams: any = [];

  constructor(public api: ApiwrapperService, public data: DataManipulationService) {
    this.workspaceTeams = [];
  }

  ngOnInit(): void {
    // get the team types
    this.api.getTeamType().subscribe(
      types => {
        this.teamTypes = types;
      }
    );
    this.workspaceTeams = [];

  }

  addTeam() {
    let teamExists = false;
    if (this.workspaceTeams.length > 0) {
      this.workspaceTeams.forEach((el: any) => {
        if (el.name === this.teamName.value) {
          this.data.showAlertError('Team already exist');
          teamExists = true;
        }
      })
    }
    if (!teamExists) {
      this.data.showLoading();
      this.api.checkTeamName(this.teamName.value).subscribe(res => {
        this.data.hideLoading();
        if (this.teamName.value.length > 0) {
          this.workspaceTeams.push({
            name: this.teamName.value,
            members: 0,
            created: false,
            error: false,
            type: '',
            color: 'red'
          });
          this.teamName.setValue('');
        } else {
          this.data.hideLoading();
          this.data.showAlertError('Please insert a team name')
        }
      }, () => {
        this.data.hideLoading();
        this.data.showAlertError('Team already exist');
      })
    }
  }

  deleteTeam(index: number) {
    this.workspaceTeams.splice(index, 1);
  }

  createTeam() {
    this.createdTeams = [];
    let body = {teams: [{}]}
    if (this.workspaceTeams.length === 0) {
      return;
    }
    this.data.showLoading();
    let checkTeams = 0;
    let error = false;
    this.workspaceTeams.forEach((team) => {
      checkTeams = checkTeams + 1;
      if (team.type === '') {
        team.error = true;
        error = true;
        this.data.showAlertError('Select team type for team: ' + team.name);
      }
      if (error && checkTeams === this.workspaceTeams.length) {
        this.data.hideLoading();
        return;
      }
    });
    if (!error) {
      let teamsNumber = 0;
      body = {teams: []};
      this.workspaceTeams.forEach((team) => {
        if (!team.created) {
          team.error = false;
          body.teams.push({
            name: team.name,
            questionCollections: [team.type]
          });

          // this.createTeamForm.controls.teamName.disable();
          // this.showCreateTeamMembers = true;

          if (team.created) {
            ++teamsNumber;
          } else {
            // create a team
            // this.api.createWorkspaceTeam(body).subscribe(res => {
            //   this.createdTeams.push(res);
            //   team.created = true;
            //   ++teamsNumber;
            //   if (this.workspaceTeams.length === teamsNumber) {
            //     this.data.getAllTeams = false;
            //     this.data.getWorkspaceTeams();
            //     this.workspaceTeams = [];
            //     this.data.openFromTeam = false;
            //     this.data.organizeTeams = this.createdTeams;
            //     this.data.setOrganizeTeams(true);
            //     if (teamsNumber === 1) {
            //       // go to team created
            //       this.data.newTeamCreated = res.uuid;
            //     }
            //
            //     document.getElementById('closeCreateTeamModal')?.click();
            //     if (this.data.startGuideStep === 0) {
            //       document.getElementById('organizeMembersModal')?.click();
            //     }
            //     // get user priority (for team menu)
            //     this.api.getCurrentWorkspaceUserRolesPriority().subscribe(
            //       (res: any) => {
            //         this.data.setUserRoles(res);
            //       });
            //
            //     this.data.hideLoading();
            //   }
            // }, err => {
            //   this.data.hideLoading();
            //   this.data.showAlertError('We were unable to create team: ' + team.name + '. Try again.');
            // });
          }
        } else if (team.created) {
          ++teamsNumber;
          if (this.workspaceTeams.length === teamsNumber) {
            this.data.getAllTeams = false;
            this.data.getWorkspaceTeams();
            this.workspaceTeams = [];
            // @ts-ignore
            document.getElementById('closeCreateTeamModal').click()
            this.data.hideLoading();
          }
        }
      });
    }

    this.api.createWorkspaceMultipleTeams(body).subscribe(
      res => {
        this.createdTeams = res;
        if (this.workspaceTeams.length === res.length) {
          this.data.getAllTeams = false;
          this.data.getWorkspaceTeams();
          this.workspaceTeams = [];
          this.data.openFromTeam = false;
          this.data.organizeTeams = this.createdTeams;
          this.data.setOrganizeTeams(true);
          if (res.length === 1) {
            // go to team created
            this.data.newTeamCreated = res[0].uuid;
          }

          document.getElementById('closeCreateTeamModal')?.click();
          if (this.data.startGuideStep === 0) {
            document.getElementById('organizeMembersModal')?.click();
          }
          // get user priority (for team menu)
          this.api.getCurrentWorkspaceUserRolesPriority().subscribe(
            (res: any) => {
              this.data.setUserRoles(res);
            });

          this.data.hideLoading();
        }
      },
      error => {
        this.data.hideLoading();
        console.log(error)
      }
    )
  }
}
