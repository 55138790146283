<!-- Modal -->
<button id="teamSelect" [hidden]="true" data-toggle="modal" data-target="#statementTeamSelect"></button>

<div>
  <div class="modal fade modalModal" id="statementTeamSelect" tabindex="-1" role="dialog"
       aria-labelledby="createTeamLabel"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="logo-block">
            <img src="../../../../assets/images/successteam-logo-black.svg" alt="">
          </div>
          <button type="button" id="closeTeamSelector" hidden data-dismiss="modal" aria-label="Close"></button>
          <img src="../../../../assets/svg/close-button.svg" alt="" data-dismiss="modal" style="cursor: pointer">
          <button type="button" id="closeTeam" hidden routerLink="/my-teams" data-dismiss="modal"
                  aria-label="Close">
          </button>
        </div>
        <div class="modal-body" style="text-align: center">
          <h2 class="medium">{{'select_team_to_edit_statements' | translate}}</h2>
          <p-dropdown
            [options]="teams"
            dropdownIcon="bi bi-chevron-down"
            optionLabel="name"
            [(ngModel)]="team"
            (onChange)="getCategoryIndicators()"
            dropdownIcon="bi bi-chevron-down"
            placeholder="Select team...">
            <!--                <option [value]="team" *ngFor="let team of teams">{{team.name}}</option>-->
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MODAL 2-->
<div>
  <div class="modal fade modalModal" id="statementTeamSelect2" tabindex="-1" role="dialog"
       aria-labelledby="createTeamLabel"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="logo-block">
            <img src="../../../../assets/images/logo.png" alt="">
            <h3></h3>
          </div>
          <button type="button" id="closeTeamSelector2" hidden data-dismiss="modal" aria-label="Close"></button>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-9 col-md-9">
              <p class="medium">{{'select_team_to_edit_statements' | translate}}</p>
              <label class="form-label">Select Team</label>
              <select [(ngModel)]="team" (change)="getCategoryIndicators()"
                      class="browser-default custom-select">
                <option value="" disabled hidden selected>Select team...</option>
                <!--                <option selected hidden disabled [value]="null">Select team!</option>-->
                <option [ngValue]="team" *ngFor="let team of teams">{{team.name}}</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-3 image-block">
              <img src="../../../../assets/images/bearded_man.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CREATE STATEMENT -->
<button id="createStatementButton" [hidden]="true" data-toggle="modal" data-target="#createStatement"></button>
<!-- Modal -->
<div>
  <div class="modal fade modalModal" id="createStatement" tabindex="-1" role="dialog"
       aria-labelledby="createTeamLabel"
       aria-hidden="true" data-backdrop="static" data-keyboard="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div class="logo-block">
            <img src="../../../../assets/images/logo.png" alt="">
            <h3>Create statement for {{createStatementCategory}}</h3>
          </div>
          <div class="action-btns">
            <button class="toggle-on">{{'active' | translate}}</button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <form [formGroup]="createStatementForm" (ngSubmit)="createStatement($event)">
                <div class="form-group">
                  <label class="form-label">Statement Name</label>
                  <input class="form-control" formControlName="statementTitle" type="text">
                  <!--                <input formControlName="statementDescription" type="text" placeholder="Enter action description!">-->
                </div>

                <div class="row">
                  <div class="col-md-6 form-group">
                    <label class="form-label">Select Category</label>
                    <ng-select [clearable]="false"
                               formControlName="statementCategory"
                               [searchable]="false">
                      <ng-option [value]="category.value"
                                 *ngFor="let category of createNew| keyvalue">
                        {{category.key }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div class="col-md-6 form-group">
                    <label class="form-label">Select Sign</label>
                    <ng-select formControlName="statementSign"
                               [clearable]="false"
                               [searchable]="false"
                               appendTo="body">
                      <ng-option [value]="'positive'">POSITIVE</ng-option>
                      <ng-option [value]="'negative'">NEGATIVE</ng-option>
                    </ng-select>
                  </div>
                </div>
                <button class="btn green-btn" type="submit">{{'save_button' | translate}}</button>
                <button class="btn back-btn" data-dismiss="modal" aria-label="Close">Return</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--EDIT STATEMENT-->

<div *ngIf="showEdit">
  <div class="statement-dialog" role="document">
    <div class="statement-content">
      <div class="statement-header">
        <div class="logo-block d-inline-block">
          <h6>Statement name</h6>
        </div>
        <div class="action-btns">
          <button [ngClass]="(editStatement.question.status == 'active')?'toggle-btn-on':'toggle-btn-off'"
                  (click)="(editStatement.question.status == 'active')?editStatement.question.status = 'inactive':
                   editStatement.question.status = 'active'"></button>
          <button class="close" (click)="deleteStatement(editStatement.question.uuid)"></button>
        </div>
      </div>
      <div class="statement-body">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <div class="form-group">
                <textarea class="form-control" [(ngModel)]="editStatement.question.title" type="text"
                          placeholder="Statement name"></textarea>
              </div>
              <div class="form-group">
                <label class="form-label">Select Sign</label>
                <ng-select [clearable]="false"
                           [searchable]=false
                           [(ngModel)]="editStatement.question.sign"
                           appendTo="body">
                  <ng-option [value]="'positive'">POSITIVE</ng-option>
                  <ng-option [value]="'negative'">NEGATIVE</ng-option>
                </ng-select>
              </div>
              <button class="btn green-btn" type="submit" (click)="saveQuestion()">{{'save_button' | translate}}</button>
              <button class="btn back-btn" (click)="closeEdit()">Return</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="!showEdit">
  <div style="height:auto" class="page-header">
    <h2>{{'statements'|translate}}</h2>
    <div class="indicator-selectors justify-content-between">
      <div class="indicator-buttons">
        <div class="indicators">
          <button (click)="setSelector('showAll')" [ngStyle]="selectCircle === 'showAll' ? {'background-color':'#19AEFF', 'color': 'white'}:{}">All</button>
        </div>
        <div class="indicators">
          <button (click)="setSelector('Performance')" [ngStyle]="selectCircle === 'Performance' ? {'background-color':'#19AEFF'}:{}">
            <img  [src]="selectCircle === 'Performance' ? '../../../../assets/icons/performance-white.svg' : '../../../../assets/icons/performance.svg'"
                  alt="">
          </button>
          <p>{{'team_performance'| translate}}</p>
        </div>
        <div class="indicators">
          <button (click)="setSelector('Satisfaction')"  [ngStyle]="selectCircle === 'Satisfaction' ? {'background-color':'#19AEFF'}:{}">
            <img  [src]="selectCircle === 'Satisfaction' ? '../../../../assets/icons/satisfaction-white.svg' : '../../../../assets/icons/satisfaction.svg'"
                  alt="">
          </button>
          <p>{{'team_satisfaction' | translate}}</p>
        </div>
        <div class="indicators">
          <button (click)="setSelector('Motivation')" [ngStyle]="selectCircle === 'Motivation' ? {'background-color':'#19AEFF'}:{}">
            <img [src]="selectCircle === 'Motivation' ? '../../../../assets/icons/motivation-white.svg' : '../../../../assets/icons/motivation.svg'"
                 alt="">
          </button>
          <p>{{'team_motivation'|translate}}</p>
        </div>
        <div class="indicators">
          <button (click)="setSelector('Stress')"
                  [ngStyle]="selectCircle === 'Stress' ? {'background-color':'#19AEFF'}:{}">
            <img
              [src]="selectCircle === 'Stress' ? '../../../../assets/icons/stress-white.svg' : '../../../../assets/icons/stress.svg'"
              alt="">
          </button>
          <p>{{'team_stress_free'|translate}}</p>
        </div>
      </div>

      <div class="indicator-buttons">
        <div class="indicators">
          <button (click)="selectSquare = 'showAll'" [ngStyle]="selectSquare === 'showAll' ? {'background-color':'#19AEFF', 'color': 'white'}:{}">All</button>
        </div>
        <div class="indicators">
          <button (click)="selectSquare = 'Individual'"
                  [ngStyle]="selectSquare === 'Individual' ? {'background-color':'#19AEFF'}:{}">
            <img
              [src]="selectSquare === 'Individual' ? '../../../../assets/icons/individual-white.svg' : '../../../../assets/icons/individual.svg'"
              alt="">
          </button>
          <p>{{'indicators'|translate}}</p>
        </div>
        <div class="indicators">
          <button (click)="selectSquare = 'Team'"
                  [ngStyle]="selectSquare === 'Team' ? {'background-color':'#19AEFF'}:{}">
            <img
              [src]="selectSquare === 'Team' ? '../../../../assets/icons/culture-white.svg' : '../../../../assets/icons/culture.svg'"
              alt="">
          </button>
          <p>{{'team'|translate}}</p>
        </div>
        <div class="indicators">
          <button (click)="selectSquare = 'Leadership'"
                  [ngStyle]="selectSquare === 'Leadership' ? {'background-color':'#19AEFF'}:{}">
            <img
              [src]="selectSquare === 'Leadership' ? '../../../../assets/icons/leadership-white.svg' : '../../../../assets/icons/leadership.svg'"
              alt="">
          </button>
          <p>{{'leadership'|translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="statement-page">
    <div class="statements-block" style="background-color: #F0F3FA">
      <div class="statements-block">
        <div>
          <p class="alert-danger" *ngIf="questions[selectCircle]?.length == 0 && gotStatements">
            There are no statements!
          </p>
          <div *ngFor="let question of questions[selectCircle], let i = index">
            <div class="statement" [ngClass]="(!question.edit)?'statement-list':'statement-list-edited'"
                 *ngIf="question['category']['title'] == selectSquare || selectSquare == 'showAll'">
              <div class="statement-input">
                <p class="statements" *ngIf="!question.question.edit">{{question.question.title}}</p>
                <input class="statements" type="text" pInputText *ngIf="question.question.edit"
                       [(ngModel)]="question.question.title">
              </div>
              <div class="statement-category" *ngIf="!question.question.edit">
                <div class="indicator">
                  <img src="../../../../assets/icons/{{question['indicator'].title | lowercase}}.svg" alt="">
                </div>
                <div class="category">
                  <div class="image-background">
                    <img
                      *ngIf="question['category']['title'] == 'Member' || question['category']['title'] == 'Individual' "
                      src="../../../../assets/icons/individual-white.svg" alt="">
                    <img *ngIf="question['category']['title'] == 'Team'"
                         src="../../../../assets/icons/culture-white.svg" alt="">
                    <img
                      *ngIf="question['category']['title'] == 'Leader' || question['category']['title'] == 'Leadership'"
                      src="../../../../assets/icons/leadership-white.svg" alt="">
                  </div>
                  <span>{{question['category']['title']}}</span>
                </div>
              </div>
              <div class="statement-actions">
                <p-dropdown *ngIf="question.question.edit" [(ngModel)]="question.question.sign" placeholder="SIGN">
                  <option [value]="'positive'">Positive</option>
                  <option [value]="'negative'">Negative</option>
                </p-dropdown>
                <div class="buttons" *ngIf="question.question.status === 'frozen'">

                  <button class="info"
                          matTooltip="Feedback was collected through this statement. It can not be changed"></button>
                </div>
                <div class="buttons toggles" *ngIf="(question.question.status !== 'frozen') && requirePayment()">
                  <button [ngClass]="'edit'" *ngIf="userPriorities.role.workspacePriority === 20"
                          (click)="editingStatement(question, i, 'Performance')"></button>
                  <button *ngIf="!question.question.edit"
                          [ngClass]="(question.question.status == 'active')?'toggle-btn-on':'toggle-btn-off'"
                          (click)="questionToggled(question, 'Performance',i)"></button>
                </div>

                <div class="buttons toggles" *ngIf="(question.question.status !== 'frozen') && !requirePayment()">
                  <button [ngClass]="'edit'" *ngIf="userPriorities.role.workspacePriority === 20"
                          matTooltip="Upgrade to a Essential to be able to enable or disable statements."></button>
                  <button *ngIf="!question.question.edit"
                          matTooltip="Upgrade to a Essential to be able to enable or disable statements."
                          class="toggle-btn-off"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
