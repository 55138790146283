import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import Swal from 'sweetalert2';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataManipulationService} from '../../../services/data-manipulation.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  teams: any;
  constructor(public apiwrapper: ApiwrapperService, private data: DataManipulationService,
              private cdRef: ChangeDetectorRef) {
    if (localStorage.getItem('upgrading')){
      localStorage.removeItem('upgrading');
    }
  }

  private categories: any;
  private actions: { [name: string]: any; } = {};
  showActions = false;
  select = {
    Performance: 'showAll',
    Motivation: 'showAll',
    Satisfaction: 'showAll',
    Stress: 'showAll'
  };
  createActions: any;
  createNewFor = {
    Performance: {},
    Motivation: {},
    Satisfaction: {},
    Stress: {}
  };

  showEdit = false;
  // todo teamUuid
  team: { uuid: string | undefined; } | undefined;

  createActionForm = new FormGroup({
    actionTitle: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    actionDescription: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
    actionCategory: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
    actionColor: new FormControl(null, [
      Validators.required,
      Validators.minLength(1)]),
  });

  editAction: any;
  editActionIndex: any;
  editActionCategory: any;
  tasksColors: any;
  taskCreatedColor = 'Select indicator state...';

  taskEditColor: any;
  methods: any;
  // actionTags  = [];
  label = 'title';
  mentionItems = [
    {
      items: null,
      labelKey: 'title',
      triggerChar: '@',
    },
  ];

  ngOnInit() {
    localStorage.removeItem('TAG');
    setTimeout(() => {
      this.data.showLoading();
    }, 100);
    this.apiwrapper.getTeamsFromWorkspace().subscribe(
      res => {
        this.data.hideLoading();
        const teams = res._embedded.teams;
        if (teams.length === 0) {
        } else if (teams.length === 1) {
          this.teams = teams;
          this.team = teams[0];
          this.getCategoryIndicators();
        } else {
          this.teams = teams;
          // @ts-ignore
          document.getElementById('teamSelect').click();
          this.cdRef.detectChanges();
        }
      });
  }

  getActionsDetails() {
    // @ts-ignore
    document.getElementById('closeTeamSelector').click();
    this.getCategoryIndicators();
    this.getMethods();
  }

  // @ts-ignore
  onMentionSelect(selection) {
    if (localStorage.getItem('TAG')) {
      // @ts-ignore
      const allTags = JSON.parse(localStorage.getItem('TAG'));
      allTags.push(selection.uuid);
      localStorage.setItem('TAG', JSON.stringify(allTags));
    } else {
      const tag = [];
      tag.push(selection.uuid);
      localStorage.setItem('TAG', JSON.stringify(tag));
    }
    return '@' + selection.title;
  }

  // get the statements categories and the indicators
  getCategoryIndicators() {
    this.data.showLoading();
    this.apiwrapper.getQuestionsSuperCategories().subscribe(
      res => {
        this.data.hideLoading();
        this.categories = res;
        this.getActions();
        this.getCategoriesForCreate();
      },
      err => {
        console.log(err);
      }
    );
  }

  activeActions(statementName: string | number) {
    let active = 0;
    this.actions[statementName].forEach((el: { isActive: string; }) => {
      if (el.isActive === 'yes') {
        active++;
      }
    });
    return active;
  }

  private getActions() {
    this.categories[0].indicators.forEach((el: { uuid: string; title: string | number; }) => {
      // @ts-ignore
      this.apiwrapper.getteamTask(el.uuid, this.team.uuid).subscribe(
        res => {
          this.actions[el.title] = res.tasks;
        }
      );
    });
    this.showActions = true;
  }

  private getCategoriesForCreate() {
    this.categories.forEach((el: { indicators: any[]; title: string | number; }) => {
      el.indicators.forEach((indicator) => {
        // @ts-ignore
        this.createNewFor[indicator.title][el.title] = indicator.uuid;
      });
    });
  }

  actionsToggled(task: any, category: string, i: number) {
    let status: string;
    if (task.isActive === 'yes') {
      status = 'no';
    } else {
      status = 'yes';
    }

    this.data.showLoading();
    // @ts-ignore
    this.apiwrapper.patchActivateTask(task.uuid, {isActive: status}, this.team.uuid).subscribe(
      res => {
        this.data.hideLoading();
        this.actions[category][i].isActive = status;
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error with changing the status.');
      });
  }

//  togle action new api

  saveAction() {
    const action = this.editAction;
    const category = this.editActionCategory;
    const index = this.editActionIndex;
    const body = {
      title: action.title,
      description: action.description,
      isActive: action.isActive,
      colorUuid: this.taskEditColor,
      // @ts-ignore
      dictionaries: JSON.parse(localStorage.getItem('TAG')),
    };
    this.data.showLoading();
    // @ts-ignore
    this.apiwrapper.patchActivateTask(this.actions[category][index].uuid, body, this.team.uuid).subscribe(
      res => {
        this.actions[category][index] = res;
        this.data.hideLoading();
        this.closeEditAction();
        this.actions[category][index].edit = false;
        localStorage.removeItem('TAG');
        // this.data.showAlertSuccess('Action updated successfully', '');
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error updating the action.');
      }
    );
  }

  showCreate(indicator: any) {
    // display create modal
    this.createActions = indicator;
    // @ts-ignore
    document.getElementById('createActionButton').click();
  }

  // @ts-ignore
  closeEditAction() {
    this.showEdit = false;
    this.editAction = {};
    this.editActionIndex = 0;
    this.editActionCategory = '';
  }


  getMethods() {
    this.data.showLoading();
    // @ts-ignore
    this.apiwrapper.getMethods(true, this.team.uuid).subscribe(
      res => {
        this.data.hideLoading();
        res.forEach((el: { edit: boolean; }) => {
          el.edit = false;
        });
        this.methods = res;
        this.mentionItems[0].items = this.methods;
      }, err => {
        this.data.hideLoading();
      }
    );
  }

  // deleteAction(uuid) {
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You won\'t be able to revert this!',
  //     type: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#05D287',
  //     cancelButtonColor: '#F3454C',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value === true) {
  //       this.apiwrapper.deleteTask(uuid, this.team.uuid).subscribe(
  //         res => {
  //           this.showEdit = false;
  //           this.getActions();
  //           // this.data.showAlertSuccess('Action deleted successfully', '');
  //         }, err => {
  //           this.data.showAlertError('There was an error deleting the action.', '');
  //         }
  //       );
  //     }
  //   });
  //
  // }

  private getColors() {
    this.apiwrapper.getTaskColor().subscribe(
      res => {
        this.tasksColors = res;
      }, err => {
        console.log(err);
      }
    );
  }
}
