import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DataManipulationService} from '../../../services/data-manipulation.service';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {StripeService, StripeCardComponent} from 'ngx-stripe';
import {Router} from '@angular/router';
import {BillingService} from '../../../services/billing.service';
import {environment} from '../../../../environments/environment';
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  billingInfoForm = new FormGroup({
    companyName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    vatNumber: new FormControl(''),
    streetAddress: new FormControl('', [
      Validators.minLength(1)]),
    suit: new FormControl(''),
    city: new FormControl('', [
      Validators.minLength(1)]),
    state: new FormControl(''),
    postal: new FormControl(''),
    phone: new FormControl(''),
  });
  paymentForm = new FormGroup({
    // address: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
    vatNumber: new FormControl(''),
    address: new FormControl('', [
      Validators.minLength(1)]),
    phoneNumber: new FormControl(''),
    country: new FormControl(''),
  });

  contactForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email]),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
  });
  cardInfoForm = new FormGroup({
    nameOnCard: new FormControl('', [Validators.required]),
    cardInfo: new FormControl('', [Validators.required])
  });


  testInvoice: { date: string, invoice: string, price: string }[] = [
    {date: '14.05.2022', invoice: '1745678890-1-3', price: '5.5 EUR'},
    {date: '14.05.2022', invoice: '1745678890-1-3', price: '5.5 EUR'},
    {date: '14.05.2022', invoice: '1745678890-1-3', price: '5.5 EUR'}
  ];

  validBillingInfo = true;
  validContact = true;
  gotCouponNoPayment: boolean = true;
  hideBillingForm = false;
  billingInfo: any;
  billingPage = false;
  plans: any;
  showPlans = false;
  workspacePlan: any;
  baseUrl = environment.baseUrl;
  tabIndex = 0;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  showInfo: boolean = false;
  showPayment: boolean = false;

  @ViewChild(StripeCardComponent, {static: true}) card: StripeCardComponent | undefined;

  stripeTest: FormGroup | undefined;
  currentPlan = {
    title: '',
    billDate: '',
  };
  oldPlan = {
    title: '',
    billDate: '',
  };
  phoneError = false;
  showNextBilling = false;
  currentWorkspacePlan: any;
  selectedCountryCode = 'dk';
  selectedCountryVat = 'dk';
  selectedCountry = 'dk';
  countryCodes = ['at', 'be', 'bg', 'hr', 'cy', 'cz', 'dk', 'ee', 'fi', 'fr', 'de', 'gr', 'hu', 'ie', 'it', 'lv', 'lt', 'lu', 'mt', 'nl',
    'pl', 'pt', 'ro', 'sk', 'si', 'es', 'se'];

  countryLabels = {
    at: 'Austria',
    be: 'Belgium',
    bg: 'Bulgaria',
    hr: 'Croatia',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    dk: 'Denmark',
    ee: 'Estonia',
    fi: 'Finland',
    fr: 'France',
    de: 'Germany',
    gr: 'Greece',
    hu: 'Hungary',
    ie: 'Ireland',
    it: 'Italy',
    lv: 'Latvia',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mt: 'Malta',
    nl: 'Netherlands',
    pl: 'Poland',
    pt: 'Portugal',
    ro: 'Romania',
    sk: 'Slovakia',
    si: 'Slovenia',
    es: 'Spain',
    se: 'Sweden'
  };
  // @ts-ignore
  // labels: Record< this.countryLabels, string>;
  // cats: Record<this.countryLabels, this.countryCodes>
  public paymentMethods = [];
  public gotPaymentMethod = false;
  public workspaceInvoices: any = [];
  public gotInvoices = false;
  public contacts = [];

  activityPage = 1;
  activityMaxPages = 0;
  disableMenu = false;
  plansSimulate = [];
  showPlanSimulated = false;
  planSimulated: any;
  coupon: string | undefined;
  shownDowngrade = false;
  activeCoupon: any;
  gotFreeCoupon = false;
  gotCoupon = false;
  showCouponPersonal = false;
  validCoupon: any;
  index = 0;
  currencyInEuro = 0;
  upgradePlan = false;
  planToUpgrade: any;
  userNotFree: boolean = true;
  private free100coupon: boolean = false;

  constructor(public dataManip: DataManipulationService, public apiwrapper: ApiwrapperService, private stripeService: StripeService,
              private router: Router, private fb: FormBuilder, private billing: BillingService) {
    this.tabIndex = 0;
    this.gotFreeCoupon = false;
    this.showCouponPersonal = false;
    if (router.url.includes('success')) {
      this.billingSuccess();
    } else if (router.url.includes('cancel')) {
      this.billingCancel();
    }
    // if on billing page show all tabs
    this.billingPage = this.router.url.includes('billing');
    // @ts-ignore
    const workspacecData = JSON.parse(localStorage.getItem('WORKSPACE'));
    if (workspacecData.activeCoupon !== null) {
      if (workspacecData.activeCoupon.type === 'percentage' && workspacecData.activeCoupon.percentage === 100) {
        this.activeCoupon = workspacecData.activeCoupon;
        this.activeCoupon.activeCouponExpirationDate = workspacecData.activeCouponExpirationDate.date;
        this.gotFreeCoupon = true;
      } else {
        this.activeCoupon = workspacecData.activeCoupon;
        this.activeCoupon.activeCouponExpirationDate = workspacecData.activeCouponExpirationDate.date;
        this.gotCoupon = true;
      }
    }
    this.setBillingDetails(dataManip.getWorkspaceBillingInfo());
    this.dataManip.showLoading();
    this.getEstimation();


    this.apiwrapper.getWorkspaceBillingMethod().subscribe(
      res => {
        //@ts-ignore
        const payment: any[] = [];
        //@ts-ignore
        res.forEach(el => {
          if (el.cardBrand !== null) {
            payment.push(el);
          }
        });
        //@ts-ignore
        this.paymentMethods = payment;
        this.gotPaymentMethod = this.paymentMethods.length > 0;
      }, err => {
        console.log(err);
      }
    );

    this.apiwrapper.getWorkspaceBillingHistory().subscribe(
      res => {
        this.workspaceInvoices = res;
        this.gotInvoices = true;
      }, err => {
        console.log(err);
      }
    );
    this.contacts = this.dataManip.getWorkspaceBillingContacts();


    // this.planSelected(this.plans[2]);
  }

  ngOnInit() {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]]
    });
    // store the current plan
    this.currentPlan = this.dataManip.getWorkspacePlan();
    this.workspacePlan = this.dataManip.getWorkspacePlan();
    localStorage.setItem('PLAN_SELECTED', JSON.stringify(this.currentPlan));
    // if (localStorage.getItem('PLAN_SELECTED')) {
    //   this.currentPlan = JSON.parse(localStorage.getItem('PLAN_SELECTED'));
    // }
    this.oldPlan = this.dataManip.getWorkspacePlan();
    this.currentWorkspacePlan = this.dataManip.getWorkspacePlan();
    this.showNextBilling = true;
    console.log(this.currentPlan, this.currentWorkspacePlan, this.oldPlan);
    
  }

  // openDialog(dialog: TemplateRef<any>) {
  //   this.dialogService.open(dialog);
  // }
  //
  saveBillingInfo() {
    this.dataManip.showLoading();
    this.checkPhone();
    if (!this.billingInfoForm.valid && this.phoneError) {
      this.dataManip.hideLoading();
      this.validBillingInfo = false;
    } else {
      const body = {
        country: this.selectedCountry,
        company: this.billingInfoForm.value.companyName,
        // vatNumber: this.selectedCountryCode + this.billingInfoForm.value.vatNumber,
        streetAddress: this.billingInfoForm.value.streetAddress,
        unit: this.billingInfoForm.value.suit,
        city: this.billingInfoForm.value.city,
        region: this.billingInfoForm.value.state,
        postalCode: this.billingInfoForm.value.postal,
        phone: this.billingInfoForm.value.phone.internationalNumber,
        // notes: 'Additional information'
      };
      if (this.paymentMethods.length === 0 || this.paymentMethods[0]['last4digits'] === null) {
        this.patchInfo(body, true);
      } else {
        this.patchInfo(body, false);
      }
    }
  }

  //
  private setBillingDetails(workspaceBillingInfo: any) {
    this.billingInfo = workspaceBillingInfo;
    this.billingInfoForm.controls['companyName'].setValue(workspaceBillingInfo.company);
    if (workspaceBillingInfo.vatNumber !== null) {
      this.billingInfoForm.controls['vatNumber'].setValue(workspaceBillingInfo.vatNumber.substring(2));
    }
    this.billingInfoForm.controls['streetAddress'].setValue(workspaceBillingInfo.streetAddress);
    this.billingInfoForm.controls['suit'].setValue(workspaceBillingInfo.unit);
    this.billingInfoForm.controls['city'].setValue(workspaceBillingInfo.city);
    this.billingInfoForm.controls['state'].setValue(workspaceBillingInfo.region);
    this.billingInfoForm.controls['postal'].setValue(workspaceBillingInfo.postalCode);
    this.billingInfoForm.controls['phone'].setValue(workspaceBillingInfo.phone);
    if (workspaceBillingInfo.country === null) {
      this.selectedCountryCode = 'dk';
      this.selectedCountry = 'dk';
    } else {
      this.selectedCountryCode = workspaceBillingInfo.country;
      this.selectedCountry = workspaceBillingInfo.country;
    }
  }

  //@ts-ignore
  private storeBillingInfo(body) {
    this.billingInfo.company = body.company;
    this.billingInfo.vatNumber = body.vatNumber;
    this.billingInfo.streetAddress = body.streetAddress;
    this.billingInfo.unit = body.unit;
    this.billingInfo.city = body.city;
    this.billingInfo.region = body.region;
    this.billingInfo.postalCode = body.postalCode;
    this.billingInfo.phone = body.phone;
    // this.dataManip.setWorkspaceBillingInfo(this.billingInfo);
  }

  //@ts-ignore
  planSelected(plan) {
    this.dataManip.showLoading();
    this.currentPlan = plan;
    if (this.currentPlan.title.toLowerCase() === this.dataManip.getWorkspacePlan().title.toLowerCase()) {
      this.dataManip.showAlertInfo('The plan you are trying to select is already your current one.');
      this.dataManip.hideLoading();
      return;
    }
    // if plan switch form paid to free
    if (this.currentWorkspacePlan.requirePayment === 'yes' && plan.requirePayment === 'no' && !this.shownDowngrade) {
      this.shownDowngrade = true;
      // set current plan dialog name
      this.billing.downgradeModalPlan = this.dataManip.getWorkspacePlan().title;
      // @ts-ignore
      this.billing.downgradeModalDate = this.dataManip.getDateNoYear(JSON.parse(localStorage.getItem('WORKSPACE')).billStartDate);
      this.billing.setText(true);
      this.dataManip.hideLoading();
      return;
    }
    // STORE SELECTED PLAN
    localStorage.setItem('PLAN_SELECTED', JSON.stringify(plan));
    // after getting the intent check workspace billing details and after proceed to adding credit card
    // if no billing info go to billing info else go to card tab
    if (plan.requirePayment === 'no') {
      // workspace change plan to FREE
      this.apiwrapper.patchBillingPlan(plan.uuid).subscribe(
        res => {
          this.dataManip.hideLoading();
          // this.dataManip.showAlertSuccess(res.info.messages[0], '');
          // GET AND STORE WORKSPACE DATA
          this.billing.billingAccepted(true);
          if (this.shownDowngrade) {
            this.shownDowngrade = !this.shownDowngrade;
          } else {
            this.router.navigate(['/']);
          }
          // this.router.navigate(['/']);
          return;
        }, err => {
          console.log(err);
        }
      );
    } else if (!this.billingInfoForm.valid) {
      this.showInfo = true;
      // this.dataManip.showAlertInfo('Please complete the billing information.', '');
      // document.getElementById('nav-info-tab').click();
      this.index = 1;
      this.dataManip.hideLoading();
    } else {
      // get the intent and go to card
      this.billingClicked();
      // document.getElementById('nav-payment-tab').click();
    }
  }

  billingClicked() {
    // @ts-ignore
    const uuid = this.currentPlan.uuid;
    // change plan
    this.apiwrapper.patchBillingPlan(uuid).subscribe(
      res => {
        if (res.info) {
          // this.dataManip.showAlertInfo(res.info.messages[0], '');
          this.dataManip.setWorkspacePlan(this.currentPlan);
          // localStorage.setItem('workspacePlan', localStorage.getItem('PLAN_SELECTED'));
          localStorage.removeItem('PLAN_SELECTED');
          this.dataManip.showLoading();
          // @ts-ignore
          document.getElementById('contentSuccessful1').click()
        } else {
          this.dataManip.hideLoading();
          this.stripeService.redirectToCheckout({sessionId: res.stripe.sessionId}).subscribe();
        }
      }, err => {
        this.dataManip.hideLoading();
        console.log(err);
      }
    );
  }

  hasError(event: boolean) {
    this.phoneError = event;
  }

  getNumber(event: any) {
    this.billingInfoForm.controls['phone'].setValue(event);
  }

  telInputObject(event: any) {
  }

  onCountryChange(event: any) {
  }

  changeCountryCode(value: string): void {
    this.selectedCountry = value;
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }

  getIntent() {

  }

  private billingSuccess() {
    this.dataManip.showLoading();
    // if method is recent (less then 2 min)
    if (localStorage.getItem('PLAN_SELECTED')) {
      this.dataManip.showLoading();
      // @ts-ignore
      this.currentPlan = JSON.parse(localStorage.getItem('PLAN_SELECTED'));
      // @ts-ignore
      this.apiwrapper.patchBillingPlan(this.currentPlan.uuid).subscribe(
        res => {
          this.dataManip.getWorkspaceDetails();
          this.dataManip.hideLoading();
          this.disableMenu = false;
          if (localStorage.getItem('validCoupon')) {
            // @ts-ignore
            this.dataManip.couponData = JSON.parse(localStorage.getItem('validCoupon'));
            // @ts-ignore
            this.coupon = this.dataManip.couponData.hash;
            // @ts-ignore
            this.setPlan(this.dataManip.couponData.workspacePlan);
            this.addCoupon();
          } else {
            document.getElementById('contentSuccessful1')?.click();
          }
          // this.dataManip.showAlertSuccess('The payment information was added successfully.', '');
          // @ts-ignore
          localStorage.setItem('workspacePlan', localStorage.getItem('PLAN_SELECTED'));
          localStorage.removeItem('PLAN_SELECTED');
          // set current plan to PLAN_Selected
          this.billing.billingAccepted(true);
          // this.router.navigate(['/']);
          // window.location.href = '/';
          this.returnToTeam();
          return;
        }, err => {
          console.log(err);
        }
      );
    } else {
      this.dataManip.hideLoading();
    }
  }

  private billingCancel() {
    this.dataManip.showAlertError('There was an error with adding the payment information.');
  }

  //@ts-ignore
  getCurrentPlan(plan) {
    let currentPlan;
    //@ts-ignore
    if (JSON.parse(localStorage.getItem('PLAN_SELECTED'))) {
      //@ts-ignore
      currentPlan = JSON.parse(localStorage.getItem('PLAN_SELECTED'));
    } else {
      currentPlan = this.dataManip.getWorkspacePlan();
    }
    if (currentPlan.title !== '') {
      return plan.title.toLowerCase() === this.currentPlan.title.toLowerCase();
    } else if (localStorage.getItem('PLAN_SELECTED')) {
      return plan.title.toLowerCase() === this.currentPlan.title.toLowerCase();
    } else {
      //@ts-ignore
      const currentPlanElse = JSON.parse(localStorage.getItem('USER_WORKSPACES'))[0];
      return plan.title.toLowerCase() === currentPlanElse.plan.title.toLowerCase();
    }
  }

  personalPlan() {
    switch (this.oldPlan.title) {
      case 'Personal':
        this.disableMenu = true;
        return true;
      case '':
        return true;
      default:
        return false;
    }
  }

  // 37503312
  verifyVat() {
    this.dataManip.showLoading();
    if (!this.paymentForm.controls['vatNumber'].valid) {
      this.validBillingInfo = false;
      this.dataManip.hideLoading();
    } else {
      const body = {
        vatNumber: this.selectedCountryVat.toUpperCase() + this.billingInfoForm.value.vatNumber
      };
      this.patchInfo(body, false);
    }
  }

  //@ts-ignore
  patchInfo(body, addMethod) {
    this.dataManip.showLoading();

    this.apiwrapper.patchBillingInfo(body).subscribe(
      res => {
        this.dataManip.setWorkspaceBillingInfo(res);
        this.hideBillingForm = true;
        this.setBillingDetails(res);
        this.dataManip.showAlert('The information was updated successfully.');
        if (addMethod) {
          // go to billing info
          // @ts-ignore
          // if payment is 0
          if (this.paymentMethods.length === 0) {
            this.addMethod()
          }
          // this.showPayment = true;
          // this.index = 2;
        }
        this.dataManip.hideLoading();
      }, error => {
        this.dataManip.hideLoading();
        this.dataManip.showAlertError(error.error.error.messages[0]);
      }
    );
  }

  // addContact() {
  //   this.spinner.show();
  //   if (!this.contactForm.valid) {
  //     this.validContact = false;
  //     this.spinner.hide();
  //   } else {
  //     const body = {
  //       email: this.contactForm.value.email,
  //       firstName: this.contactForm.value.firstName,
  //       lastName: this.contactForm.value.lastName,
  //     };
  //     this.apiwrapper.postBillingContact(body).subscribe(
  //       res => {
  //         this.spinner.hide();
  //         this.dataManip.setWorkspaceBillingContacts(res.workspaceBillingContacts);
  //         this.showContactForm = false;
  //         this.contactForm.reset();
  //         this.contacts = this.dataManip.getWorkspaceBillingContacts();
  //         this.dataManip.showAlertSuccess('The billing contact was successfully added.', '');
  //       }, error => {
  //         this.spinner.hide();
  //         this.dataManip.showAlertError('There was an error adding the billing contact information.', 'error');
  //       }
  //     );
  //   }
  // }


  addMethod() {
    this.dataManip.showLoading();
    this.apiwrapper.postBillingMethod('billing').subscribe(
      res => {
        this.stripeService.redirectToCheckout({sessionId: res.stripe.sessionId}).subscribe(
          result => {
            this.dataManip.hideLoading();
          }, err => {
            console.log(err);
            this.dataManip.hideLoading();
            this.dataManip.showAlertError('Cannot add payment method!');
          }
        );
      },
      err => {
        console.log(err);
        this.dataManip.hideLoading();
        this.dataManip.showAlertError('Cannot add payment method!');
      }
    );
  }

  loadNextActivities() {
    if (this.activityPage <= this.activityMaxPages) {
      this.activityPage = this.activityPage + 1;
    }
  }

  redirectHome() {
    this.router.navigate(['/']);
  }

  clicked() {
    document.getElementById('contentSuccessful1')?.click();
  }

  //@ts-ignore
  setPlan(plan) {
    localStorage.setItem('PLAN_SELECTED', JSON.stringify(plan));
    this.currentPlan = plan;
    // set simulation by plan
    if (plan.requirePayment === 'yes') {
      this.plansSimulate.forEach(el => {
        //@ts-ignore
        if (el.workspacePlan === plan.title) {
          this.planSimulated = el;
          this.showPlanSimulated = true;
        }
      });
    } else {
      this.showPlanSimulated = false;
    }
  }

  validateCoupon() {
    if (localStorage.getItem('validCoupon')) {
      localStorage.removeItem('validCoupon');
    }
    this.dataManip.couponData = {};
    this.dataManip.showLoading();
    //@ts-ignore
    this.apiwrapper.getCouponValidation(this.coupon).subscribe(
      res => {
        if (res.type === 'percentage' && res.percentage === 100) {
          this.free100coupon = true;
        }
        this.setPlan(res.workspacePlan);
        this.dataManip.couponData = res;
        if (res.requirePayment === 'yes') {
          if (this.paymentMethods.length > 0) {
            this.addCoupon();
          } else {
            // check if personal and select plan according to coupon
            // if coupon not 100% needs to be applied after payment method added
            if (res.type === 'percentage' && res.percentage === 100) {
              this.free100coupon = true;
              this.addCoupon();
            } else if (res.percentage !== 100) {
              // save coupon and add it after payment added (res.hash)
              this.validCoupon = res;
              localStorage.setItem('validCoupon', JSON.stringify(res));
              this.showCouponPersonal = true;
              this.setPlan(res.workspacePlan);
            }
          }
        } else {
          this.addCoupon();
        }
        this.dataManip.hideLoading();
      }, err => {
        this.dataManip.hideLoading();
        this.dataManip.showAlertError('Invalid coupon code');
      }
    );
  }

  private addCoupon() {
    this.dataManip.showLoading();
    //@ts-ignore
    this.apiwrapper.postCoupon(this.coupon).subscribe(
      response => {
        localStorage.setItem('WORKSPACE', JSON.stringify(response));
        localStorage.setItem('PLAN_SELECTED', JSON.stringify(response.workspacePlan));
        localStorage.setItem('PLAN_SELECTED', JSON.stringify(response.workspacePlan));
        localStorage.setItem('workspacePlan', JSON.stringify(response.workspacePlan));
        this.getEstimation();

        if (this.free100coupon) {
          document.getElementById('contentSuccessful1')?.click();
        } else {
          this.dataManip.getWorkspaces();
        }
      }, error => {
        this.dataManip.hideLoading();
        this.dataManip.showAlertError('Coupon couldn\'t be applied');
        console.log(error);
      }
    );
  }

  getEstimation() {
    this.apiwrapper.getBillingPlans().subscribe(
      res => {
        this.showPlans = true;
        this.plans = res;
        //@ts-ignore
        res.forEach(el => {
          if (el.requirePayment === 'yes') {
          } else {
            this.showPlanSimulated = false;
          }
          // if (localStorage.getItem('upgrading') && el.title.toLowerCase() === 'essential'){
          //   this.currentPlan = el;
          // }
        });
        this.dataManip.hideLoading();
      }
    );
  }

  // clicks() {
  //   this.dataManip.couponData = this.activeCoupon;
  //   document.getElementById('contentCoupon').click();
  // }

  returnToTeam() {
    if (localStorage.getItem('upgrading')) {
      const team = localStorage.getItem('upgrading');
      localStorage.removeItem('upgrading');
      this.router.navigate(['/team/' + team]);
    }
  }

  currencyConvertToEuro(plan: any) {
    if (plan.currency === 'DKK') {
      return (plan.pricePerMember / 7.44).toFixed();
    }

    return 0;
  }

  upgradeBillingPlan(plan: any) {
    this.upgradePlan = !this.upgradePlan;
    this.planToUpgrade = plan;
  }

  setPaymentMethod(paymentMethod: any) {

  }

  cancelSubscription() {
    this.plans.forEach((el: any) => {
      if (el.requirePayment === 'no') {
        localStorage.setItem('PLAN_SELECTED', JSON.stringify(el));
        this.dataManip.showLoading();
        // after getting the intent check workspace billing details and after proceed to adding credit card
        // if no billing info go to billing info else go to card tab
        // workspace change plan to FREE
        this.apiwrapper.patchBillingPlan(el.uuid).subscribe(
          res => {
            this.billing.billingAccepted(true);
            this.dataManip.getWorkspaces();
            setTimeout(() => {
              // location.reload();
            }, 2000)
            this.dataManip.hideLoading();
            return;
          }, err => {
            this.dataManip.hideLoading();
            console.log(err);
          }
        );
      }
    })
  }

  goToBillingInfo() {
    this.index = 1;
    this.showInfo = true;
  }

  checkPhone() {
    if (this.billingInfoForm.value['phone'] !== null && (this.billingInfoForm.value['phone']?.internationalNumber.length > 0 && this.billingInfoForm.controls['phone'].invalid)){
      this.phoneError = true;
    } else {
      this.phoneError = false;
    }
  }
}
