import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-general-categories',
  templateUrl: './general-categories.component.html',
  styleUrls: ['./general-categories.component.scss']
})
export class GeneralCategoriesComponent implements OnInit {
  @Input() generalCategories: any;
  @Input() generalStatements: Observable<any> | undefined;
  openTeamModal: Subject<any> = new Subject<any>();


  @Output() generalCategoriesEvent = new EventEmitter<any>();
  @Output() getStatementsEvent = new EventEmitter<any>();
  @Output() saveAndFinishLater = new EventEmitter<any>();
  
  categoriesForm: FormGroup;

  genCategories: any;
  categoryStatements: any;
  defaultCategory: any;
  defaultCategoryTitle: string = '';
  selectedTeams: any;
  
  defaultCategoryStart: boolean = true;
  showTeamModal: boolean = false;
  showGeneralCategories: boolean = false;

  defaultStatementContainer = [{
    question: {title: this.translate.instant('custom_poll_gen_categ_prew_statements')}}];
  selectedCategory: any[] = [];
  statementsExtraQuestions: any[] = [];
  processExtraQuestions: any[] = [];


  generalCategoriesMock = [
    {
      title: 'Offensive Actions',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'General satisfaction',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'Productivity and workload',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    },
    {
      title: 'Immediate leader',
      statements : [
        {title: 'My general health is good'}, {title: 'I have had physical pain within the last 3 months'}, {title: 'Where have you had pain? (stomach, chest, hips, knees, arms and/or wrists)'}
      ]
    }
  ];

  constructor(private data: DataManipulationService, private ref: ChangeDetectorRef, private fb: FormBuilder, private translate: TranslateService) {
    this.categoriesForm = this.fb.group({
      category: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.processGeneralCategories();
    this.ref.detectChanges();
    this.generalStatements?.subscribe((res: any) => {
      this.categoryStatements = res.statements;
      this.statementsExtraQuestions = [];
      this.processStatementsExtraQuestions(this.categoryStatements);
    });
  }

  processGeneralCategories() {
    this.genCategories = this.generalCategories[0]?.categories;
    this.selectedCategory = new Array(this.genCategories.length).fill(false);
    this.generalCategories[0].selectedCategories.subscribe((data:any) => {
      this.selectedTeams = {fromGeneral: true, teams: data.teams};
      if(data.categories.length !== 0) {
        data.categories.forEach((category:any) => {
          this.genCategories.forEach((categ: any, index: number) => {
            if (category.uuid === categ.category.uuid) {
              this.selectedCategory[index] = true;
              this.onChange(categ.uuid, true);
            }
          });
        });
      } else {
        this.selectedCategory = new Array(this.genCategories.length).fill(true);
        this.genCategories.forEach((categ: any) => {
          this.onChange(categ.uuid, true);
        });
      }
    });
    
    this.defaultCategoryTitle = this.translate.instant('custom_poll_gen_categ_prew');
    this.categoryStatements = this.defaultStatementContainer;
    this.showGeneralCategories = true;
  }

  processStatementsExtraQuestions(statements: any) {
    statements.forEach((state: any, index: number) => {
      this.processExtraQuestion(state.question);
      const extraQuestions = this.removeDuplicates(this.processExtraQuestions, 'title');
      this.statementsExtraQuestions.push(extraQuestions);
      this.processExtraQuestions = [];
    });
  }

  removeDuplicates(myArray: any, prop: any) {
    return myArray.filter((obj: { [x: string]: any; }, pos: any, arr: any[]) => {
      return arr.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  processExtraQuestion(extraQuestion: any): any {
    if (extraQuestion.extraQuestion) {
      extraQuestion.extraQuestion.extra = true;
      this.processExtraQuestions.push(extraQuestion.extraQuestion);
      return this.processExtraQuestion(extraQuestion.extraQuestion);
    } else if (extraQuestion.responses.length) {
      extraQuestion.responses.forEach((response: any) => {
        if (response.extraQuestion) {
          response.extraQuestion.extra = true;
          this.processExtraQuestions.push(response.extraQuestion);
          return this.processExtraQuestion(response.extraQuestion);
        }
      });
    }
  }

  setCategoryStatements(index: number, category: any) {
    if (this.defaultCategory !== index) {
      this.getStatementsEvent.emit({categoryUuid : category.category.uuid, index: index});
    }
    this.defaultCategory = index;
    this.defaultCategoryStart = false;
    this.defaultCategoryTitle = category?.category.title + ' ' + this.translate.instant('custom_poll_gen_categ_prew_state');
  }

  onChange(category: string, isChecked: any) {
    const categoryFromArray = <FormArray>this.categoriesForm.controls['category'];

    if (isChecked?.target?.checked || isChecked === true) {
      categoryFromArray.push(new FormControl(category));
    } else {
      let index = categoryFromArray.controls.findIndex(x => x.value == category)
      categoryFromArray.removeAt(index);
    }
  }

  openTeamsModal() {
    this.openTeamModal.next(true);
    this.showTeamModal = true;
  }

  assignToAllTeams() {
    const teams: any[] = [];
    this.data.allTeams.forEach((team: any) => {
      teams.push(team.uuid);
    });
    this.setSelectedTeams(teams);
  }

  setSelectedTeams(teams: any) {
    if (teams !== 'empty' && teams !== '') {
      this.generalCategoriesEvent.emit({customPollTemplateCategories : this.categoriesForm.value.category, teams: teams, customPollUuid: this.generalCategories[0].pollUuid});
    }
    this.showTeamModal = false;
  }

  saveAndFinish() {
    this.saveAndFinishLater.emit();
  }

  onOpenAccordion(event: any, index: number) {
    
    
  }
}
