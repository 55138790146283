<div class="main-container">
    <div class="timing-header">
        <h2 class="timing-header-title">{{'custom_poll_timing_title' | translate}}</h2>
        <div class="date-picker-container">
            <span class="timing-header-subtitle">{{'custom_poll_timing_subtitle1' | translate}}</span>
            <div class="start-date">
                <img class="calendar-icon" src="../../../../../assets/images/calendar.png">
                <p-calendar class="calendar-picker" [(ngModel)]="startDate" (ngModelChange)="changeStartDate($event)" [minDate]="minDateValue" dateFormat="M.dd.yy"></p-calendar>
            </div>
            <span class="timing-header-subtitle">{{'custom_poll_timing_subtitle2' | translate}}</span>
            <div class="d-flex align-items-center">
                <div class="start-date">
                    <p-dropdown class="end-date-dropdown" placeholder="-" [options]="dropdownOptions" (onChange)="getEndDate(startDate, $event)"
                    dropdownIcon="bi bi-caret-down-fill" optionLabel="name"></p-dropdown>
                </div>
                <span *ngIf="changedEndDate" class="raport-title">{{'custom_poll_timing_drop_report' | translate}}{{endDate | date:'dd.MM.yyyy'}}</span>
            </div>
        </div>
        <span class="invitation-title">{{'custom_poll_timing_invitation' | translate}}</span>
        <textarea rows="15" class="invitation-textarea" placeholder="Dear Simon..." type="text" [(ngModel)]="invitation" (ngModelChange)="changeInvitation($event)"></textarea>
    </div>
    <div class="timing-footer">
        <button class="timing-back-btn" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}" (click)="back()"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button (click)="endTimingStep()"
            [ngStyle]="apvStartingDate.subtitle === 1 ? {'width':'19rem'}:{}"
            class="timing-next-btn" 
            pButton type="button" 
            label="{{'custom_poll_review_approve_btn'+apvStartingDate.subtitle | translate}}"
            [disabled]="!changedEndDate"></button>
    </div>
</div>