import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getDisplayName'
})
export class GetDisplayNamePipe implements PipeTransform {
  transform(displayName: string, firstName: string = '', lastName: string = '', email: string = ''): any {
    if (displayName === null || displayName === '' || displayName === undefined){
      if (firstName === null || lastName === null || firstName === undefined || lastName === undefined || firstName === '' || lastName === ''){
        return email;
      } else {
        return firstName + ' ' + lastName;
      }
    // } else if (displayName === null &&) {
    } else {
      if (displayName === email && (firstName === null || lastName === null)){
       return displayName;
      }      if (displayName === email){
       return firstName;
      }
      return displayName;
    }
  }
}
