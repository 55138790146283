import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { RegisterComponent } from './pages/register/register.component';
import {LoginComponent} from "./pages/login/login.component";
import {ResetPasswordComponent} from "./pages/reset-password/reset-password.component";
import {AuthGuard} from "./helpers/auth.guard";
import {HomeLayoutComponent} from "./layouts/home-layout.component";
import {LoginLayoutComponent} from "./layouts/login-layout.component";
import {LoginGuard} from "./helpers/login.guard";
import {ActivateAccountComponent} from "./pages/activateaccount/activate-account.component";
import {TeamsOverviewComponent} from "./pages/teams-overview/teams-overview.component";
import {TeamsComponent} from "./pages/teams/teams.component";
import {AccountComponent} from "./pages/settings/account/account.component";
import {WorkspaceComponent} from "./pages/settings/workspace/workspace.component";
import {ActionsComponent} from "./pages/settings/actions/actions.component";
import {StatementsComponent} from "./pages/settings/statements/statements.component";
import {MethodsComponent} from "./pages/settings/methods/methods.component";
import {IndicatorsComponent} from "./pages/settings/indicators/indicators.component";
import {BillingComponent} from "./pages/settings/billing/billing.component";
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import {AddToWorkspaceComponent} from "./pages/add-to-workspace/add-to-workspace.component";
import {AddToWorkspaceGuard} from "./helpers/add-to-workspace.guard";
import { WorkspaceMembersComponent } from './pages/workspace-members/workspace-members.component';
import {TeamAnalyseComponent} from "./pages/team-analyse/team-analyse.component";
import {TeamActionsComponent} from "./pages/team-actions/team-actions.component";
import {WorkspaceMembersLeadersComponent} from "./pages/workspace-members-leader/workspace-members-leader.component";
import { ErrorComponent } from './pages/error/error.component';
import { WorkspaceOverviewComponent } from './pages/workspace-overview/workspace-overview.component';
import { CustomLoopContainerComponent } from './pages/custom-loops/containers/custom-loop-container/custom-loop-container.component';
import { ReportingContainerComponent } from './pages/custom-loop-reporting/containers/reporting-container/reporting-container.component';

const routes: Routes = [
  {path: 'login/impersonate/:hash', component: LoginComponent, pathMatch: 'full'},

  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [AuthGuard]
      },

      {path: 'workspace-member', component: WorkspaceMembersComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'workspace-member-leader', component: WorkspaceMembersLeadersComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'team/:team', component: TeamsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      // {path: 'team-analyse', component: TeamAnalyseComponent, canActivate: [AuthGuard]},
      {path: 'team-analyse/:team', component: TeamAnalyseComponent, canActivate: [AuthGuard]},

      {path: 'team-actions', component: TeamActionsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      {path: 'team-actions/:team', component: TeamActionsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'teams-overview', component: TeamsOverviewComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'workspace-overview', component: WorkspaceOverviewComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'error', component: ErrorComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      // {path: 'polling', component: PollingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      // {path: 'library', component: LibraryComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'notifications', component: NotificationsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      {path: 'settings',  children: [

          // {path: '', component: SettingsComponent , pathMatch: 'full', canActivate: [AuthGuard]},
          {path: '', redirectTo: '' , pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'account', component: AccountComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'workspace', component: WorkspaceComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'billing', component: BillingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'actions', component: ActionsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'statements', component: StatementsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'methods', component: MethodsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

          {path: 'indicators', component: IndicatorsComponent, pathMatch: 'full', canActivate: [AuthGuard]},

        ]},

      {path: 'onboarding', component: OnboardingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'onboarding-success', component: OnboardingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'onboarding-cancel', component: OnboardingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      // {path: 'analyse', component: AnalyseComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'billing-success', component: BillingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'billing-cancel', component: BillingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      // {path: 'billing', component: BillingComponent, pathMatch: 'full', canActivate: [AuthGuard]},

      {path: 'add-to-workspace/:hash', component: AddToWorkspaceComponent, pathMatch: 'full', canActivate: [AddToWorkspaceGuard]},

      {path: 'feedback-templates', component: CustomLoopContainerComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      {path: 'reporting/:pollUuid', component: ReportingContainerComponent, pathMatch: 'full', canActivate: [AuthGuard]}
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'user/activate/:hash',
        component: ActivateAccountComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'register',
        component: RegisterComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'register/:invitation',
        component: RegisterComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'user/reset-password/:hash',
        component: ResetPasswordComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'user/set-password/:hash',
        component: ResetPasswordComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
      {
        path: 'login/:invitation',
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
      // {path: 'login/impersonate/:hash', component: LoginComponent, pathMatch: 'full', canActivate: [LoginGuard]}

    ]
  },
  // {path: 'team/:team', component: TeamsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
