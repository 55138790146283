<div class="reporting-page-container">
    <div *ngIf="currentCustomPoll" class="page-header custom-page-header justify-content-between">
        <h2 class="page-header-title">{{currentCustomPoll.name}}</h2>
        <div class="menu-buttons">
            <button class="menu-button"
                [ngClass]="isReporting ? 'menu-button-active':''"
                pButton type="button" 
                label="{{'custom_poll_main_reporting_btn1' | translate}}"
                (click)="changePages(true, false)"></button>
            <button class="menu-button"
                [ngClass]="isActionPlan ? 'menu-button-active':''"
                pButton type="button" 
                label="{{'custom_poll_main_reporting_btn2' | translate}}"
                (click)="changePages(false, true)"></button>
        </div>
        <div class="action-plan-completed-container">
            <input [disabled]="currentCustomPoll.customPollTemplate.type === 'onboarding'" class="action-plan-completed-check" type="checkbox" [(ngModel)]="actionPlanCompleted" (ngModelChange)="setACtionPlanDone($event)">
            <span [ngStyle]="!actionPlanCompleted ? {'color':'#FF3C78'}:{}">{{'custom_poll_create_action_completed' | translate}}</span>
        </div>
        <div class="generate-report-container">
            <i [ngStyle]="!actionPlanCompleted ? {'opacity':'20%'}:{}" class="bi bi-file-earmark-pdf-fill pdf-icon"></i>
            <span [ngStyle]="!actionPlanCompleted ? {'opacity':'20%', 'cursor':'default'}:{}" pTooltip="{{'wo_tooltip_coming_soon' | translate}}" tooltipStyleClass="tooltip-style" tooltipPosition="bottom" [tooltipDisabled]="!actionPlanCompleted">{{'wo_generate_report'| translate}}</span>
        </div>
    </div>
    <div class="loop-details-container" *ngIf="isReporting">
        <app-reporting-loop-details *ngIf="gotCategories || gotOnboardingReporting" [reportingDetails]="reportingDetails"></app-reporting-loop-details>
    </div>
    <div class="loop-categories-and-statements-container" *ngIf="isReporting">
        <app-categories-and-statements *ngIf="gotCategories"
            [reportingCategories]="reportingCategories"
            [categStatements]="reportingCategoriesStatements"
            [stateResponses]="reportingStatementsResponses"
            [reportingUserTeams]="userTeams"
            [categoryActions]="categoryActions"
            [actionAssignees]="actionAssignees"
            (categoryStatementEvent)="getCategoryStatements($event)"
            (statementResponsesEvent)="getStatementResponses($event)"
            (changedTeamEvent)="teamChanged($event)"
            (createActionEvent)="createCategoryAction($event)">
        </app-categories-and-statements>
        <app-onboarding-categories *ngIf="gotOnboardingReporting"
            [reportingCategories]="reportingCategories"
            [checkinStatements]="reportingCategoriesStatements"
            [stateResponses]="reportingStatementsResponses"
            (checkinStatementEvent)="getCheckinStatements($event)"
            (statementResponsesEvent)="getStatementResponses($event)">
        </app-onboarding-categories>
    </div>
    <div *ngIf="isActionPlan" class="loop-action-plan-container">
        <app-action-plan 
            [actions]="actionPlanActions"
            [actionsCategories]="reportingCategories"
            (filterCategories)="filterActionPlanActionsByCategory($event)">
        </app-action-plan>
    </div>
</div>