import {Component, OnInit} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-team-analyse',
  templateUrl: './team-analyse.component.html',
  styleUrls: ['./team-analyse.component.scss']
})
export class TeamAnalyseComponent implements OnInit {
  showTeams: boolean = false;
  currentTeamUuid: string = '';
  team: any;

  constructor(public data: DataManipulationService, private activeRoute: ActivatedRoute, private router: Router,private api:ApiwrapperService) {
    if (this.activeRoute.snapshot.paramMap.get('team')) {
      // @ts-ignore
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
      this.sortTeams();
      this.getTeamReport();
      data.showLoading();
    }
  }

  ngOnInit(): void {
  }

  private sortTeams() {
    const teams = this.data.userTeams();
    teams.forEach((el: any, index) => {
      if (el.uuid === this.currentTeamUuid) {
        this.team = el;
          this.api.getMemberActivity(el.uuid).subscribe(
            res => {
              this.data.setTeamLeaders(res.teamLeadersReport);
            }, err => {
              console.log(err);
            }
          );
        }
    })
  }

  private getTeamReport() {
    this.api.getTeamReport(this.currentTeamUuid).subscribe(
      res => {
        this.showTeams = true;
      }, err => {
        console.log(err);
      }
    );
  }
}
