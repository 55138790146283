<div class="row teams-overview-header">
  <!--  <a class="nav-link" style="text-align: right; cursor:pointer; font-size: 14px" (click)="auth.logout()">LOG OUT</a>-->
  <h2 style="width: auto;">{{'actions_title'| translate}}</h2>
  <div class="teams-sorting">
    <div class="sort-containers">
      <p-dropdown  (onChange)="changeTeam()" [options]="memberTeams"
                   [(ngModel)]="team" dropdownIcon="bi bi-caret-down-fill"
                   optionLabel="name">
        <ng-template pTemplate="selectedItem">
          <div class="sort-item sort-item-value">
            <div>{{team?.name}}</div>
          </div>
        </ng-template>
        <ng-template let-sort pTemplate="item">
          <div class="sort-item">
            <div>{{sort.name}}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
  </div>
  <div class="my-actions-buttons-container">
    <button pButton type="button" label="{{'all'|translate}}" (click)="getActions('all')"
                [ngClass]=" challengesService.sort === 'all'? 'indicator-button-active' : ''">
                <img class="indicator-button-icon" alt="logo" style="margin-right: -1.3rem;"
                   [src]="challengesService.sort === 'all' ? '../../../assets/svg/overall-white.svg': '../../../assets/svg/overall.svg'"/>
        </button>
        <button pButton type="button" label="{{'team_performance'|translate}}" (click)="getActions('Performance')"
                [ngClass]=" challengesService.sort === 'Performance'? 'indicator-button-active' : ''">
                <img class="indicator-button-icon" alt="logo" style="margin-right: .8rem;"
                   [src]="challengesService.sort === 'Performance' ? '../../../assets/svg/performance-white.svg': '../../../assets/svg/action-performance.svg'"/>
        </button>
        <button pButton type="button" label="{{'team_satisfaction'| translate}}" (click)="getActions('Satisfaction')"
                [ngClass]=" challengesService.sort === 'Satisfaction'? 'indicator-button-active' : ''">
                <img class="indicator-button-icon" alt="logo" style="margin-right: .8rem;"
                   [src]="challengesService.sort === 'Satisfaction' ? '../../../assets/svg/satisfaction-white.svg': '../../../assets/svg/action-satisfaction.svg'"/>
        </button>
        <button pButton type="button" label="{{'team_motivation'|translate}}" (click)="getActions('Motivation')"
                [ngClass]=" challengesService.sort === 'Motivation'? 'indicator-button-active' : ''">
                <img class="indicator-button-icon" alt="logo"
                   [src]="challengesService.sort === 'Motivation' ? '../../../assets/svg/motivation-white.svg': '../../../assets/svg/action-motivation.svg'"/>
        </button>
        <button pButton type="button" label="{{'team_stress_free'|translate}}" (click)="getActions('Stress')"
                [ngClass]=" challengesService.sort === 'Stress'? 'indicator-button-active' : ''">
                <img class="indicator-button-icon" alt="logo"
                   [src]="challengesService.sort === 'Stress' ? '../../../assets/svg/stress-white.svg': '../../../assets/svg/action-stress.svg'"/>
        </button>
        <button *ngIf="challengesService.myAssignActions.length > 0" style="margin: 0 0 0 auto;" pButton type="button" label="{{!showArchiveActions ? 'See Archieve':'Hide Archieve'}}" (click)="showArchiveActions = !showArchiveActions">
        </button>
  </div>
</div>
<div *ngIf="challengesService.showActions" class="row teams-overview-body justify-content-center">
  <app-my-actions [showArchive]="showArchiveActions"></app-my-actions>
</div>
