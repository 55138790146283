import { Component, Input, OnInit } from '@angular/core';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-reporting-loop-details',
  templateUrl: './reporting-loop-details.component.html',
  styleUrls: ['./reporting-loop-details.component.scss']
})
export class ReportingLoopDetailsComponent implements OnInit {

  @Input() set reportingDetails(value: any) {
    console.log(value);
    
    this.reportingDetailsValues = value;
  }
  reportingDetailsValues: any;
  workspaceResults: any;

  scoreColorInterval = {
    "red": {"min": 0, "max": 39},
    "orange": {"min": 40, "max": 59},
    "yellow": {"min": 60, "max": 79},
    "green": {"min": 80, "max": 100}
  };
  knobColor: string = this.data.colors.grey;
  responseRateColor: string = this.data.colors.grey;
  responseRateBackgroundColor: string = '';
  knobProgress: number | undefined;

  constructor(private data: DataManipulationService) { }

  ngOnInit(): void {}

  setKnobProgressBar() {
    if (document.getElementById("svgProgress")) {
      this.knobProgress = Math.round((184 - (184 * this.reportingDetailsValues.reportingDetails.score) / 100));
      //@ts-ignore
      document.getElementById("svgProgress").style.strokeDashoffset = this.knobProgress.toString();
      this.sortColors();
      this.sortColorsForResponseRate();
    } else {
      setTimeout(() => {
        this.setKnobProgressBar();
      }, 200);
    }
  }

  sortColors() {
    Object.keys(this.scoreColorInterval).forEach((color: any) => {
      // @ts-ignore
      const interval = this.scoreColorInterval[color];
      const value = this.reportingDetailsValues.reportingDetails.score;
      if (value >= interval.min && value <= interval.max) {
        //@ts-ignore
        this.knobColor = this.data.colors[''+color];
      }
    });
  }

  sortColorsForResponseRate() {
    Object.keys(this.scoreColorInterval).forEach((color: any) => {
      // @ts-ignore
      const interval = this.scoreColorInterval[color];
      const value = this.reportingDetailsValues.reportingDetails.responseRate;
      if (value >= interval.min && value <= interval.max) {
        //@ts-ignore
        this.responseRateColor = this.data.colors[''+color];
        this.responseRateBackgroundColor = color;
      }
    });
  }

}
