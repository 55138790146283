<div class="d-flex flex-column align-items-center justify-content-center card-body onboarding-window-content">
  <!--Feedback collector setup-->
  <div  *ngIf="data.editCollector" style="margin-left: auto;margin-right: 0;padding-bottom: 1rem; color: #375fc5">
    <p class="fl-card-description">{{'loop_edit_top_text'| translate}} {{data.nextCollector | date}}</p>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center pt-4 pb-4" style="background-color: #f5f7fc; width: 100%">
    <h1
      class="mb-5">{{data.pauseCollector ? ('loop_status'| translate) : (!data.editCollector ? ('step71_title'| translate) : ('loop_edit'| translate))}}</h1>
    <div class="d-flex justify-content-center align-items-center flex" style="width: 100%;">
      <div class="card ms-3 me-3 feedback-loop-card">
        <div class="card-body text-center">
          <p class="fl-card-description" style="margin-bottom: 40px;max-width: 20rem;">{{'step71_step1_title'| translate}}</p>
          <div class="frequency-container">
            <div *ngFor="let frequency of frequencyKeys; index as i; let first = first; let last = last;"
            [style]="first ? {'margin-right':'1.6rem'} : last ? {'margin-left':'1.6rem'} : {}"
            [ngClass]="selectedFrequency === frequency.type ? 'feedback-frequency-active' : ''"
             class="feedback-frequency">
              <p-radioButton [value]="frequency.type" [(ngModel)]="frequencySelected"
                           (onClick)="changeSlides()"></p-radioButton>
              <span class="mt-3" style="font-size: 1.6rem; font-weight: 500;">{{frequency.title}}
            </span>
          </div>
          </div>
        </div>
      </div>
      <div class="card ms-3 me-3 feedback-loop-card">
        <div class="card-body text-center">
          <p class="fl-card-description" style="max-width: 32rem;">{{'step71_step2_title'| translate}}</p>
          <div class="statements-number-slider">
            <ngx-slider  [(value)]="sliderValue" [options]="options" (valueChange)="getSuggestions()"></ngx-slider>
            <div class="d-flex flex-column">
              <h2 style="margin-top: -2rem;">{{sliderValue}} {{'statements'|translate}}</h2>
              <span *ngIf="sliderValue === recomandedStatements" style="font-size: 1.4rem;">({{'recommended'|translate}})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card ms-3 me-3 feedback-loop-card" *ngIf="!data.editCollector">
        <div class="card-body text-center">
          <app-feedback-questions-demo *ngIf="showFeedbackDemo"></app-feedback-questions-demo>
          <p style="max-width: 35.6rem;"
            class=" fl-card-description">{{data.pauseCollector ? ('step71_step3_title_restart' | translate) : ('step71_step3_title' | translate)}}</p>
          <div [ngClass]="startCollectorDate === 'TODAY' ? 'feedback-frequency-date-active':''" class="feedback-option-margin d-flex flex-row align-items-center p-4 feedback-frequency-date">
            <p-radioButton [value]="'TODAY'" [(ngModel)]="startCollectorDate"></p-radioButton>
            <span class="ms-4"
                  style="font-size: 1.8rem">{{data.pauseCollector ? ('restart_now' | translate) : ('step71_step3_option1' | translate)}}</span>
          </div>

          <div [ngClass]="startCollectorDate === 'PIKED' ? 'feedback-frequency-date-active':''" class="mt-3 d-flex flex-row align-items-center p-4 feedback-frequency-date">
            <p-radioButton [value]="'PIKED'" [(ngModel)]="startCollectorDate"></p-radioButton>
            <span class="ms-4"
                  style="margin-right: 20px; font-size: 1.8rem">{{data.pauseCollector ? ('restart_at' | translate) : ('pick_date' | translate)}}</span>
            <p-calendar class="calendar-collector" [(ngModel)]="pikedDate" [minDate]="minDateValue" dateFormat="dd.mm.yy"
                        (click)="pickDate()"></p-calendar>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center flex" style="width: 100%;margin-top: 4rem;">
      <div class="card ms-3 me-3 team-insights-container" [style]="data.editCollector ? 'width: auto': ''">
        <div class="card-body text-center">
          <p class=" fl-card-description">{{'get_report'|translate}}</p>
            <div class="team-insights-frequency-container">
              <div *ngFor="let frequency of frequencyKeys; index as i; let first = first; let last = last;"
              [style]="first ? {'margin-right':'1.6rem'} : last ? {'margin-left':'1.6rem'} : {}"
              [ngClass]="checkDisable(frequency.type) ? 'ts-feedback-frequency-active' : ''"
               class="ts-feedback-frequency">
                <p-radioButton [value]="frequency.type" [(ngModel)]="tsFrequencySelected" [disabled]="checkDisable(frequency.type)"
                             (onClick)="changeTsFrequency()"></p-radioButton>
                <span class="ms-3" style="font-size: 1.6rem; font-weight: 500;">{{frequency.title}}
              </span>
            </div>
        </div>
      </div>
      </div>
    </div>
    <div class="collector-buttons-container">
      <button *ngIf="!data.editCollector" class="btn btn-text px-5 py-2 mt-5"
              (click)="showFeedbackDemo = !showFeedbackDemo"
              style="width: 18rem; margin-left: 1rem;">{{'step61_button2'|translate}}</button>
      <button pButton pRipple class="btn btn-blue px-5 py-2 mt-5"
              (click)="data.pauseCollector? resumeCollector() :(data.editCollector? patchCollector() :doneSettingLoop())"
              [ngStyle]="data.editCollector ? {'width': 'auto', 'margin': 'auto'}:{'width': 'auto', 'margin': '0 auto 0 40rem'}" label="{{data.pauseCollector ? ('team_feedback_resume_loop_button' | translate): ('ready_button'| translate)}}"></button>
    </div>
    <div *ngIf="data.pauseCollector" style="margin-top: 2rem">
      <span  [ngStyle]="{'visibility': collectorEditChanged() ? '':'hidden'}"  class="tip"><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{'aware_due_changes'|translate}}</span>
    </div>
  </div>
</div>
