<div class="main-container">
    <div class="special-categories-header">
        <div class="title-container">
            <h2 class="special-categ-header-title">{{'custom_poll_spec_categ_title' | translate}}</h2>
            <div class="question-mark-tip-container">
                <span><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{('custom_poll_spec_team_tip'|translate)}}</span>
            </div>
            <!-- <div class="w-dl-questionmark-container">
                <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                <div class="workspace-dl-tooltip fl-tooltip">{{('custom_poll_spec_team_tip'|translate)}}</div>
            </div> -->
        </div>
        <div class="special-categories-body">
            <div class="categories-container">
                <form class="category-form" [formGroup]="specialCategoriesForm">
                    <div class="category" [ngClass]="defaultCategory === index && !defaultCategoryStart ? 'active-category':''" 
                        *ngFor="let category of specCategories, let index = index"  
                        (click)="setCategoryStatements(index, category)">
                            <input class="category-check" type="checkbox" [checked]="selectedCategory[index]" (change)="onChange(category.uuid, $event);openTeamsModal($event, category)">
                            <span [ngStyle]="selectedTeams.length > 0 ? {'margin':'0 2rem 0 1.2rem'}:{'margin':'0 auto 0 1.2rem'}" class="category-title">{{category?.category.title}}</span>
                            <div *ngFor="let tag of categoryFromTeams" 
                                [ngClass]="tag.teams.length > 0 && tag.categoryUuid === category.uuid ? 'category-tag-container':''">
                                <span *ngIf="tag.teams.length > 0 && tag.categoryUuid === category.uuid" class="selected-teams-length">
                                    {{tag.teams.length +(tag.teams.length === 1 ? ('custom_poll_spec_team_tag' | translate):('custom_poll_spec_teams_tag' | translate)) }}</span>
                            </div>
                            <i class="bi bi-chevron-right"></i>
                            <!-- <span *ngIf="defaultCategory === index && !defaultCategoryStart" class="assign-to-team" (click)=";$event.stopPropagation()">{{'custom_poll_spec_assign_to' | translate}}</span> -->
                    </div>
                </form>
            </div>
            <div class="category-statements-container">
                <h2 class="category-statement-header">{{defaultCategoryTitle}}</h2>
                <div [ngStyle]="!last ? {'margin-bottom':'2.7rem'}:{}" [ngClass]="defaultCategoryStart ? 'default-statement-container':''" class="category-statement-container" *ngFor="let categoryStatement of categoryStatements, let last = last, let index = index">
                    <span *ngIf="defaultCategoryStart" class="default-statement-title">{{categoryStatement.question.title}}</span>
                    <p-accordion *ngIf="!defaultCategoryStart && categoryStatements.length >= 1 && (categoryStatement.question.extraQuestion || statementsExtraQuestions[index]?.length !== 0)" collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down" (onOpen)="onOpenAccordion($event, index)">
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <span class="statement-title" [innerHTML]="categoryStatement.question.title" [ngClass]="defaultCategoryStart ? 'default-statement-title':''"></span>
                                <div class="quote" *ngIf="!defaultCategoryStart">
                                    <i class="bi bi-quote"></i>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="extra-question-container" *ngFor="let extraQuestion of statementsExtraQuestions[index]">
                                    <div class="quote">
                                        <i class="bi bi-quote"></i>
                                    </div>
                                    <span class="statement-title">{{extraQuestion.title}}</span>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>
                    <div class="d-flex align-items-center" [ngStyle]="{'gap':'.8rem'}" *ngIf="!defaultCategoryStart && categoryStatements.length >= 1 && (!categoryStatement.question.extraQuestion && statementsExtraQuestions[index]?.length === 0)">
                        <div class="quote" *ngIf="!defaultCategoryStart">
                            <i class="bi bi-quote"></i>
                        </div>
                        <span class="statement-title" [innerHTML]="categoryStatement.question.title" [ngClass]="defaultCategoryStart ? 'default-statement-title':''"></span>
                    </div>
                    
                </div>
                <!-- <span class="see-more-statements">{{'custom_poll_gen_categ_see_more' | translate}}</span> -->
            </div>
        </div>
    </div>
    <div class="special-categories-footer">
        <button class="special-categories-back-btn" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}" (click)="back()"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button (click)="nextStep()" 
            class="special-categories-next" 
            pButton type="button" 
            label="{{'next_button' | translate}}"></button>
    </div>
    <app-choose-team-modal *ngIf="showTeamModal" [fromGeneral]="teamsToSelectForModal" [getOpenModal]="openTeamModal.asObservable()" (teamsSelected)="setSelectedTeams($event)"></app-choose-team-modal>
</div>