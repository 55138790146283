import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() currentStep: any;
  @Input() currentPollType: any;

  @Output() jumpToStep = new EventEmitter<any>();


  constructor() {
    
   }

  ngOnInit(): void {
    
  }

  emitStepValue(step: number) {
    this.jumpToStep.emit(step);
  }

}
