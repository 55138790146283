<div *ngIf="!registered" class="main-container">
  <div class="auth-container">
    <div class="left-side">
      <img class="logo" src="../../../assets/images/successteam-logo-white.svg" alt="succesteam_logo">
<!--      <h1 class="">Startups</h1>-->
<!--      <p class="">Learn about creating the best and most effective teams as a startup</p>-->
<!--      <img class="startup-logo" src="../../../assets/images/startup.png" alt="">-->
    </div>
    <div class="right-side">
      <h1 class="">{{invited ? ('register_create_and_join' | translate) : ('register_create' | translate)}}</h1>
      <p>{{'register_existent_account' | translate}} <a class="blue-link" routerLink="/login">{{'register_existent_account_login' | translate}}</a></p>

      <form class="" [formGroup]="registerForm">
        <div class="input-row">
          <div class="input-container">
            <label class="" >{{'register_firstname' |translate}}</label>
            <input [ngStyle]="registerForm.controls['firstName'].status === 'INVALID' && (registerForm.controls['firstName'].dirty || registerForm.controls['firstName'].touched)
             ? {'border':'1px solid red'}:{}" class="form-control register-input" id="firstName" formControlName="firstName" type="text">
            <div
              *ngIf="registerForm.controls['firstName'].status === 'INVALID' && (registerForm.controls['firstName'].dirty || registerForm.controls['firstName'].touched)"
              class="text-danger">
              <div *ngIf="registerForm.controls['firstName']?.errors?.['required']">{{'register_first_name_error' | translate}}</div>
            </div>
          </div>
          <div class="input-container">
            <label class="" >{{'register_lastname' | translate}}</label>
            <input [ngStyle]="registerForm.controls['lastName'].invalid && (registerForm.controls['lastName'].dirty || registerForm.controls['lastName'].touched)
            ? {'border':'1px solid red'}:{}" class="form-control register-input" id="lastName" formControlName="lastName" type="text">
            <div
              *ngIf="registerForm.controls['lastName'].invalid && (registerForm.controls['lastName'].dirty || registerForm.controls['lastName'].touched)"
              class="text-danger">
              <div *ngIf="registerForm.controls['lastName']?.errors?.['required']">{{'register_last_name_error' | translate}}</div>
            </div>
          </div>
        </div>
        <div class="input-row">
          <div class="input-container">
            <label class="">{{'register_phone_number' |translate}}</label>
            <ngx-intl-tel-input
              (focusout)="checkPhone()"
              [ngStyle]="phoneError && registerForm.controls['phoneNumber'].invalid  ? {'border':'1px solid red'}:{}"
              class="form-control ngx-tel-input"
              style="border: 1px solid #E8E8E8"
              placeholder="000000000"
              [preferredCountries]="[CountryISO.Denmark]"
              [selectedCountryISO]="CountryISO.Denmark"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
               ]"
              [selectFirstCountry]="false"
              [maxLength]="15"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.International"
              [separateDialCode]="true"
              formControlName="phoneNumber"
            ></ngx-intl-tel-input>
            <div
              *ngIf="phoneError"
              class="text-danger">
              <div *ngIf="phoneError && registerForm.value['phoneNumber'] !== null && registerForm.controls['phoneNumber'].invalid">{{'register_phone_error' | translate}}</div>
              <div *ngIf="phoneError && registerForm.value['phoneNumber'] === null">{{'register_phone_error_input' | translate}}</div>
            </div>
          </div>
          <div class="input-container">
            <label class="">{{'register_email'|translate}}</label>
            <input [ngStyle]="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)
            ? {'border':'1px solid red'}:{}" class="form-control register-input" id="email" formControlName="email" type="email">
            <div
              *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)"
              class="text-danger">
              <div *ngIf="registerForm.controls['email']?.errors?.['required']">{{'register_email_error' | translate}}</div>
            </div>
          </div>
        </div>

        <div class="input-row">
          <div class="input-container password-container">
            <label class="">{{'register_password'| translate}}</label>
            <app-password-strenght-metter  [passwordToCheck]="passwordValidation.value"></app-password-strenght-metter>
            <input [ngStyle]="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)
            ? {'border':'1px solid red'}:{}" #passwordValidation class="form-control register-input" id="password" formControlName="password"
                   type="password">
            <div
              *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)"
               class="text-danger">
               <div *ngIf="registerForm.controls['password']?.errors?.['required']">{{'register_password_error' | translate}}</div>
               <div>{{'register_password_length_error' | translate}}</div>
            </div>
          </div>

          <div class="input-container">
            <label class="">{{'register_confirm_pass' | translate}}</label>
            <input [ngStyle]="registerForm.controls['confirmPassword'].invalid && (registerForm.controls['confirmPassword'].dirty || registerForm.controls['confirmPassword'].touched)
            ? {'border':'1px solid red'}:{}" class="form-control register-input" id="confirmPassword" formControlName="confirmPassword"
                   type="password">
            <div
              *ngIf="registerForm.controls['confirmPassword'].invalid && (registerForm.controls['confirmPassword'].dirty || registerForm.controls['confirmPassword'].touched)"
              class="text-danger">
              <div *ngIf="registerForm.controls['confirmPassword']?.errors?.['required']">{{'register_password_error' | translate}}</div>
              <div>{{'register_password_length_error' | translate}}</div>
            </div>
          </div>
        </div>
      </form>

      <div class="">
        <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="agreeCheck">
        <label class="form-check-label ms-2 p-top">{{'register_agree'| translate}}
          <a class="text-decoration-none" href="https://www.successteam.com/termsofuse/">{{'register_agree_terms'| translate}}</a></label>
      </div>
      <button class="btn register-submit-button" (click)="onSubmit()" [disabled]="!agreeCheck">
        {{'register_button' | translate}}
        <i class="bi bi-arrow-right ms-2"></i>
      </button>
    </div>
  </div>
</div>

<app-email-confirmation *ngIf="registered"></app-email-confirmation>
