import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Options, LabelType} from '@angular-slider/ngx-slider';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {Subscription} from "rxjs";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {translate} from "zxcvbn3/dist/feedback";
import {isUndefined} from "ngx-bootstrap/chronos/utils/type-checks";

@Component({
  selector: 'app-feedback-questions',
  templateUrl: './feedback-questions.component.html',
  styleUrls: ['./feedback-questions.component.scss']
})
export class FeedbackQuestionsComponent implements OnInit, OnDestroy {

  @Output() closeFeedbackModal = new EventEmitter<boolean>();
  teamName: string = 'Operation';
  disableButton: boolean = false;
  previousQuestionButton: boolean = true;
  value: any = 4;
  options: Options = {
    step: 1,
    showTicksValues: false,
    showTicks: true,
    stepsArray: [],
    translate: (value: number, label: LabelType) => {
      const lastElement: number = this.feedbackQuestions ? 6 : 0;
      if (this.answers) {
        switch (label) {
          case LabelType.Floor:
            return this.answers[0].title;
          case LabelType.Ceil:
            return this.answers[lastElement].title;
          default:
            return this.answers[value].title;
        }
      }
    }
  };
  showStatementReview: boolean = false;
  addReview: boolean = false;
  feedbackQuestions: {
    title: string,
    responses: { title: string, translation: string | null, uuid: string, weight: number }[]
  }[] = [
    {
      title: 'We motivate each other in the team',
      responses: [
        {
          title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0
        },
        {
          title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6
        },
        {
          title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2
        },
        {
          title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8
        },
        {
          title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4
        },
        {
          title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83
        },
        {
          title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100
        }
      ]
    },
    {
      title: 'If a team member fails, we collectively support that person',
      responses: [
        {
          title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0
        },
        {
          title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6
        },
        {
          title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2
        },
        {
          title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8
        },
        {
          title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4
        },
        {
          title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83
        },
        {
          title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100
        }
      ]
    },
    {
      title: 'My workload is consistent with what I feel I can overcome',
      responses: [
        {
          title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0
        },
        {
          title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6
        },
        {
          title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2
        },
        {
          title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8
        },
        {
          title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4
        },
        {
          title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83
        },
        {
          title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100
        }
      ]
    }
  ];
  answers: any;
  feedbackForm = new FormGroup({
    answer: new FormControl('', [Validators.required, Validators.minLength(1)]),
    newReview: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });
  questionAnswer: any;
  currentQuestion: any;
  questionNumber: number = 0;
  currentPoll = [];
  private feedbackSubs: Subscription;
  private collector: any = {teamUuid: '', teamCollector: ''};
  public currentPollDetails: {
    poll: { uuid: any };
    maxStatements: any;
    currentPage: any;
    uuid: any;
    maxPage: any
  } | undefined;
  private currentAssignmentUuid: any;
  showCurrentQuestion = false;
  feedbackQuestionProgress: any;
  lastQuestion: any = {};
  fromPreviousQuestion: boolean = false;
  //@ts-ignore
  selectedReview: any;
  selectedCountryCode = 'us';
  countryCodes = ['us', 'dk'];
  apiLanguages = [];
  immediateLeadersFromTeam: any = [];
  immediateLeadersOutsideTeam: any = [];
  choseImmediateLeader: any;
  currentImmediateLeader: any;
  immediateLeaders: any;
  checkedInLeader: any;
  selectedImmediateLeader: any;
  selectedCurrentImmediateLeader: any;
  showChangeImmediateLeader: boolean = false;
  showImmediateLeaderTooltip: boolean = true;
  disableCheckBoxes: any;
  
  constructor(public data: DataManipulationService, public api: ApiwrapperService,
              private router: Router) {

    this.apiLanguages = this.data.apiLanguages;
    this.feedbackSubs = this.data.teamPollObs.subscribe(res => {
      if (res) {
        // this.feedbackQuestions = this.data.feedbackQuestions;
        this.collector = this.data.teamCollector;
        this.teamName = this.data.currentTeam.name;
        this.getPollById(this.collector.teamCollector, 1);
        this.choseImmediateLeader = this.data?.currentUserImmediateLeader != null ? false : true;
        this.verifyImmediateLeaders();
        this.getImmediateLeaderSuggestions();
        if (!this.choseImmediateLeader) {
          this.startTimerForTooltip();
        }
      }
    });
  }

  ngOnInit(): void {
    this.currentPollDetails = {
      poll: {uuid: ''},
      uuid: '',
      maxStatements: this.feedbackQuestions.length,
      currentPage: 1,
      maxPage: 1,
    };

    this.currentQuestion = this.feedbackQuestions[this.questionNumber];
    this.getAnswerLabels([]);
    this.getFeedbackProgress();
  }

  ngOnDestroy(): void {
    this.feedbackSubs.unsubscribe();
  }

  showModalDialog() {
    const openModal = document.getElementById('questionModal');
    openModal?.click();
  }

  verifyImmediateLeaders() {
    if(this.choseImmediateLeader) {
      if(this.data.otherImmediateLeaders.length) {
        this.selectedCurrentImmediateLeader = this.data.otherImmediateLeaders[0];
        this.currentImmediateLeader = true;
      } else {
        this.selectedCurrentImmediateLeader = null;
        this.currentImmediateLeader = false;
      }
    } else {
      this.selectedCurrentImmediateLeader = null;
      this.currentImmediateLeader = false;
    }
  }

  // get the members assignee

  getPollById(poll: any, page: number) {
    if (page === 1) {
      this.currentPoll = [];
    }
    this.getStatementNotApplicable();
    this.questionNumber = 0;
    this.api.getPollById(poll.poll.uuid, page, this.collector.teamUuid).subscribe(
      res => {
        this.currentPoll = this.currentPoll.concat(res._embedded['user-poll-questions']);
        this.feedbackQuestions = this.currentPoll.concat(res._embedded['user-poll-questions']);
        if (this.currentPoll.length === 0) {
          this.data.hideLoading();
        }
        this.currentPollDetails = {
          poll: {uuid: poll.poll.uuid},
          uuid: poll.uuid,
          maxStatements: res._total_items,
          currentPage: page,
          maxPage: res._page_count,
        };
        if (page === res._page_count) {
          this.data.hideLoading();
          // @ts-ignore
          document.getElementById('questionModal').click();
          this.data.hideLoading();
        } else if (this.currentPollDetails.currentPage < this.currentPollDetails.maxPage) {
          // @ts-ignore
          this.currentPollDetails.currentPage = this.currentPollDetails.currentPage + 1;
          this.getPollById(this.currentPollDetails, this.currentPollDetails.currentPage);
        }
        this.currentAssignmentUuid = poll.uuid;
        this.currentQuestion = this.currentPoll[this.questionNumber];
        this.getAnswerLabels(this.currentQuestion.responses);
        this.showCurrentQuestion = true;
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError(err.error.error.messages[0]);
      }
    );
  }

  getImmediateLeaderSuggestions() {
    let immediateLeadersNumber = 0;
    if (this.choseImmediateLeader) {
      this.immediateLeadersFromTeam = this.data.immediateLeadersFromTeam;
      this.immediateLeadersOutsideTeam = this.data.immediateLeadersOutsideTeam;
      this.checkedInLeader = new Array(immediateLeadersNumber).fill(false);
      immediateLeadersNumber = this.immediateLeadersFromTeam.length + this.immediateLeadersOutsideTeam.length;
      if (this.immediateLeadersFromTeam.length === 1 && this.immediateLeadersOutsideTeam.length === 0) {
        if(!this.currentImmediateLeader) {
          this.selectedImmediateLeader = this.immediateLeadersFromTeam[0];
          this.setUserImmediateLeader();
        }
      } else if (this.immediateLeadersOutsideTeam.length === 1 && this.immediateLeadersFromTeam.length === 0) {
        if(!this.currentImmediateLeader) {
          this.selectedImmediateLeader = this.immediateLeadersOutsideTeam[0];
          this.setUserImmediateLeader();
        }
      } else {
        if(!this.currentImmediateLeader) {
          this.selectedImmediateLeader = this.immediateLeadersFromTeam[0];
          this.checkedInLeader[0] = true;
        }
      }
    } else {
      this.selectedImmediateLeader = this.data?.currentUserImmediateLeader;
    }
  }

  pickImmediateLeader(event: any, leader: any, leaderIndex: number) {
    if (event) {
      this.currentImmediateLeader = false;
      this.selectedImmediateLeader = leader;
      this.checkedInLeader.forEach((el: any, index: number) => {
        if (leaderIndex === index) {
          this.checkedInLeader[index] = true;
        } else {
          this.checkedInLeader[index] = false;
        }
      });
    } else {
      if(this.selectedCurrentImmediateLeader != null) {
        this.currentImmediateLeader = true;
      }
      this.selectedImmediateLeader = null;
      this.checkedInLeader.fill(false);
    }
  }

  setUserImmediateLeader() {
    if (this.selectedImmediateLeader === null) {
      return;
    } else {
      const leaderUuid = !this.currentImmediateLeader ? this.selectedImmediateLeader.uuid : this.selectedCurrentImmediateLeader.uuid;
      this.api.updateUserImmediateLeader(this.data.currentUserTeamMemberUuid, this.data.currentTeam.uuid, {leaderUuid}).subscribe(
        res => {
          this.data.userLink = res;
        }
      );
      this.choseImmediateLeader = !this.choseImmediateLeader;
      this.startTimerForTooltip();
    }
  }

  startTimerForTooltip() {
    setTimeout(() => {
      this.showImmediateLeaderTooltip = false;
    }, 5000);
  }

  getStatementNotApplicable() {
    this.api.getNotApplicable().subscribe(
      res => {
        this.data.notApplicable = res;
      }
    )
  }

  answerQuestion() {
    const titleAnswer = this.answers[this.feedbackForm.controls['answer'].value].title;
    if (this.feedbackForm.controls['answer'].value === 0 || this.feedbackForm.controls['answer'].value.toString() === 'NaN') {
      this.feedbackForm.controls['answer'].setValue(0);
      this.feedbackForm.controls['answer'].setErrors(null);
    }
    if (this.feedbackForm.controls['answer'].valid) {
      let answerUuid = '';
      let answerIndex = 0;
      //@ts-ignore
      this.currentQuestion.responses.forEach((el, index) => {
        if (el.title === titleAnswer) {
          answerUuid = el.uuid;
          answerIndex = index;
        }
      });
      const body = {
        assignmentUuid: this.currentAssignmentUuid,
        pollUuid: this.currentPollDetails?.poll.uuid,
        questionUuid: this.currentQuestion.uuid,
        responseUuid: answerUuid,
      };
      this.lastQuestion = {
        title: this.currentQuestion.title,
        questionUuid: this.currentQuestion.uuid,
        responses: this.currentQuestion.responses,
        responseTitle: titleAnswer,
        responseIndex: answerIndex,
        responseUuid: answerUuid,
        //@ts-ignore
        pollUuid: ''
      };
      this.previousQuestionButton = false;
      this.disableButton = true;
      // @ts-ignore
      this.api.postUserPollResopnse(body, this.collector.teamUuid).subscribe(
        (res: any) => {
          this.answerStatement(res.uuid)
        }, (err: { error: { error: { messages: string[]; }; }; }) => {
          if (err.error.error.messages[0]) {
            document.getElementById('questionModalClose')?.click();
            console.log('Closed by errror')
            this.data.setTeamCollectorSubs(false);
            this.data.showAlertError(err.error.error.messages[0]);
          }
        }
      );
    }
  }

  answerStatement(uuid: string) {
    this.showStatementReview = false;
    this.addReview = false;
    if (uuid !== '') {
      this.lastQuestion.pollUuid = uuid;
    }
    this.feedbackForm.controls['answer'].setValue(3);
    this.questionNumber = this.questionNumber + 1;
    let bodyPatch: { status: any; };
    this.disableButton = false;
    // @ts-ignore
    if (this.currentPollDetails.maxStatements - this.questionNumber === 0) {
      bodyPatch = {status: 'completed'};
      this.api.patchPollAssignment(bodyPatch, this.currentAssignmentUuid)
        .subscribe(() => {
            this.data.setRefreshTeamPolls(true);
            if (bodyPatch.status === 'completed') {
              this.data.setRefreshPolls(true);
            }
          }
        );
      document.getElementById('questionModalClose')?.click();
      this.data.setTeamCollectorSubs(false);
      this.currentPoll = [];
      this.currentQuestion = {};
      this.data.showAlert('Congratulations! You\'ve completed the team feedback collector.');
      // TODO GET IN TEAM POLLS AND STATISTICS
    } else {
      bodyPatch = {status: 'in_progress'};
      this.currentQuestion = this.currentPoll[this.questionNumber];
    }
    // this.feedbackForm.reset();
    this.feedbackForm.controls['answer'].setValue(3);
    this.getFeedbackProgress();
  }

  editPreviousAnswer() {
    this.disableButton = true;
    const titleAnswer = this.answers[this.feedbackForm.controls['answer'].value].title;
    let answerUuid = '';
    //@ts-ignore
    this.lastQuestion.responses.forEach((el) => {
      if (el.title === titleAnswer) {
        answerUuid = el.uuid;
      }
    });
    const body = {
      //@ts-ignore
      pollResponseUuid: answerUuid,
    };
    this.api.patchUserPollResopnse(body, this.lastQuestion.pollUuid, this.collector.teamUuid).subscribe(
      (res: any) => {
        this.feedbackForm.controls['answer'].setValue(3);
        this.questionNumber = this.questionNumber + 1;
        this.lastQuestion = {};
        this.previousQuestionButton = true;
        this.fromPreviousQuestion = false;
        this.disableButton = false;
        return;
      }, (err: { error: { error: { messages: string[]; }; }; }) => {
        if (err.error.error.messages[0]) {
          this.previousQuestionButton = true;
          this.fromPreviousQuestion = false;
          this.disableButton = false;
          document.getElementById('questionModalClose')?.click();
          this.data.setTeamCollectorSubs(false);
          this.data.showAlertError(err.error.error.messages[0]);
        }
      }
    );
  }

  goToPreviousQuestion() {
    this.fromPreviousQuestion = true;
    this.previousQuestionButton = true;
    this.feedbackForm.controls['answer'].setValue(this.lastQuestion.responseIndex);
    this.questionNumber = this.questionNumber - 1;
  }

  getAnswerLabels(answers: any) {
    const labels = [];
    let ans: { title: string; }[] = [];
    if (answers.length === 0) {
      for (let i = 0; i < this.feedbackQuestions.length; i++) {
        ans = this.feedbackQuestions[i].responses;
      }
    } else {
      ans = answers;
    }


    for (let i = 0; i < ans.length; i++) {
      if (i === 3) {
        this.questionAnswer = i;
        this.feedbackForm.controls['answer'].setValue(i);
      }
      labels.push({value: i, title: ans[i].title});
    }
    this.options.stepsArray = labels;
    this.answers = labels;
  }

  getFeedbackProgress() {
    this.feedbackQuestionProgress = (this.questionNumber / this.currentPollDetails?.maxStatements * 100) + '%';
  }

  closeModal() {
    document.getElementById('questionModalClose')?.click();
    this.data.setTeamCollectorSubs(false);
    // this.data.collector
    // this.closeFeedbackModal.emit(false);
    this.data.addedMembersToTeam(true);
  }

  reviewStatement(event: any) {
    if (event.uuid === undefined) {
      this.addReview = true;
    }
  }

  addNewReview(statement: any) {
    this.data.showLoading();
    this.api.postNewReview(this.feedbackForm.value.newReview, this.currentAssignmentUuid).subscribe(
      res => {
        // this.addReview = false;
        this.feedbackForm.reset();
        this.giveReason(statement.uuid, res.uuid);
        this.data.hideLoading();
      }, error => {
        this.data.hideLoading();
      }
    )
  }

  giveReason(statement: string, reasonUuid: string) {
    if (reasonUuid === '') {
      this.addReview = true;
    } else {
      this.data.showLoading();
      this.api.postStatementReview(statement, reasonUuid, this.data.currentTeam.uuid, this.currentAssignmentUuid).subscribe(
        res => {
          // move to next statement and add 1 to the counter.
          this.answerStatement('');
          this.data.hideLoading();
        }, error => {
          this.data.hideLoading();
        }
      )
    }
  }

  notApplicableBackButton() {
    this.showStatementReview = !this.showStatementReview;
  }
}
