import {Component, ErrorHandler, OnDestroy} from '@angular/core';
import {ApiwrapperService} from '../services/apiwrapper.service';
// import {ModalsComponent} from '../components/modals/modals.component';
import {DataManipulationService} from '../services/data-manipulation.service';
import {Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {HostListener} from '@angular/core';
import {ModalsComponent} from "../components/modals/modals.component";
import {AuthenticationService} from "../services/authentication.service";
import {TranslateService} from "@ngx-translate/core";
import {readableStreamLikeToAsyncGenerator} from "rxjs/internal/util/isReadableStreamLike";


@Component({
  selector: 'app-home-layout',
  template: `
    <div *ngIf="onboarding && ( permissionsLoaded | async)">
      <router-outlet></router-outlet>
    </div>
    <app-member-info *ngIf="memberNoInfo"></app-member-info>
    <div *ngIf="(!onboarding && (permissionsLoaded | async)) && !memberNoInfo"
         style="background-color: #00194A !important;">
      <app-modals></app-modals>
      <app-header></app-header>
      <div>
        <div style="width: 82px">
          <app-menu></app-menu>
        </div>
        <div class="main-site-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class HomeLayoutComponent implements OnDestroy, ErrorHandler {
  firstLoad = true;
  permissionsLoaded: Promise<boolean> | undefined;
  serviceSubscription: Subscription;
  // private labelsSubscription: Subscription;
  private workspaceChanged = false;
  // private workspaceChangedSubscription: Subscription;
  refreshMenu = true;
  onboarding: boolean = false;
  startGuide: boolean = false;
  memberNoInfo: boolean = false;
  currentUser:any = {};
  user = {};
  private gotPermissions: boolean = false;

  constructor(private apiwrapper: ApiwrapperService, private modal: ModalsComponent,
              private data: DataManipulationService, private auth:AuthenticationService,
              private router: Router, public translate: TranslateService) {
    if (localStorage.getItem('userDetails') !== null){
      // @ts-ignore
      let language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
      if (language.toLowerCase().includes('en')){
        translate.use('en');
      } else if(language.toLowerCase().includes('dk')){
        translate.use( 'da');
      } else {
        translate.use('en');
      }
    } else {
      translate.use('en');
    }


    if (localStorage.getItem('WORKSPACE_UUID')!== null && localStorage.getItem('WORKSPACE')=== null){
      this.auth.logout();
      location.reload();
    }
    if (localStorage.getItem('version')=== null || localStorage.getItem('version')=== '2'){
      this.auth.logout();
      location.reload();
    }

    // this.spinner.show();
    // this.workspaceChangedSubscription = this.data.workspaceChanged.subscribe(workspaceChanged => {
    //   this.workspaceChanged = workspaceChanged;
    //   if (workspaceChanged) {
    //     this.refreshMenu = false;
    //     this.getPermissions();
    //   }
    // });
    router.events.subscribe((val: any) => {
      if ((val instanceof NavigationEnd && val.url !== '/login')) {
        if (!localStorage.getItem('WORKSPACE')) {
          // this.router.navigate(['/login']);
        } else {
          if (!this.gotPermissions) {
            this.getPermissions()
          }
        }
        if (val.url === '/') {
          // redirect to teams overview
          // this.router.navigate(['/teams-overview']);
        }
      }
    });

    if (localStorage.getItem('menuHome') !== null){
      // @ts-ignore
      this.data.showHomeMenu =   localStorage.getItem('menuHome').toString();
    }

    this.serviceSubscription = this.apiwrapper.getUserNextSignUpStep().subscribe(
      res => {
        sessionStorage.setItem('nextSignUpStep', res.nextSignUpStep);
        if (res.nextSignUpStep === 'details') {
          // this.modal.showUserProfileModal(true);
        }
      }, (err:any) => {
        console.log('ErRrRr: ', err)
        if (err.status === 401) {
          this.auth.refreshToken();
        }
      });
    if (localStorage.getItem('USER_WORKSPACES') === 'null') {
      setTimeout(() => {
        localStorage.clear();
        this.router.navigate(['/login']);
      }, 1000);
    } else {
      // @ts-ignore
      this.user = JSON.parse(localStorage.getItem('userDetails'));
      // this.labelsSubscription = this.apiwrapper.getMemberLabelsWorkspace().subscribe(res => {
      //   this.data.setWorkspaceLabels(res);
      // }, err => {
      //   console.log(err);
      // });
    }

    this.apiwrapper.getAllLanguages().subscribe(
      res => {
        this.data.apiLanguages = res;
        if (!localStorage.getItem('language')) {
          res.forEach((el: any) => {
            if (el.language.toLowerCase() === 'english') {
              localStorage.setItem('language', JSON.stringify(el));
            }
          });
        } else {
          // @ts-ignore
          const lang = JSON.parse(localStorage.getItem('language'));
          if (lang.tag.toLowerCase().includes('dk')) {
            this.data.language.language = 'da';
            // this.switchLang('da');
            this.data.language.selectedCountryCode = 'dk';
          } else {
            this.data.language.language = 'en';
            // this.switchLang('en');
            this.data.language.selectedCountryCode = 'us';
          }
        }
      }, err => {
        console.log(err);
      }
    );
  }

  handleError(error: any): void {
    alert(error);
    console.log('HERE IS THE ERROR !!!!! ' ,error );
    }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
  }

  getPermissions() {
    this.data.getUserLink();
    if (!localStorage.getItem('ONBOARDING_DONE')) {
      localStorage.setItem('ONBOARDING_DONE', 'NOT');
    }
    const onboarding = {
      workspaceName: '',
      workspaceRole: '',
      onboardingStep: '',
    };
    this.apiwrapper.getCurrentWorkspaceUserRolesPriority().subscribe(
      (res: any) => {
        this.data.setUserRoles(res);
        if (res.workspace.onboardingStep !== 'done') {
          this.onboarding = true;
          onboarding.workspaceName = res.workspace.name;
          onboarding.workspaceRole = res.role.name;
          onboarding.onboardingStep = res.workspace.onboardingStep;
          // this.spinner.hide();
          localStorage.setItem('ONBOARDING', JSON.stringify(onboarding));
          if (this.firstLoad) {
            this.permissionsLoaded = Promise.resolve(true);
            // this.data.hideLoading();
          }

          if (this.router.url === '/onboarding-success') {
            this.router.navigate(['/onboarding-success']);
          } else if (this.router.url === '/onboarding-cancel') {
            this.router.navigate(['/onboarding-cancel']);
          } else {
            this.router.navigate(['/onboarding']);
          }
        } else if (res.workspace.onboardingStep === 'done') {
          // @ts-ignore
          if (this.user.detail.firstname === null || this.user.detail.lastname === null) {
            // shoe modal for user to update details
            this.memberNoInfo = true;
          }
          this.onboarding = false;
          localStorage.setItem('ONBOARDING_DONE', 'DONE');
          this.data.setWorkspaceBillingInfo(res.workspace.workspaceBillingInfo);
          if (res.workspace.billDate !== null) {
            res.workspace.workspaceSubscription.workspacePlan.billDate = res.workspace.billDate.date;
          } else {
            res.workspace.workspaceSubscription.workspacePlan.billDate = new Date();
          }
          this.data.setWorkspacePlan(res.workspace.workspaceSubscription.workspacePlan);
          this.data.setWorkspaceBillingContacts(res.workspace.workspaceBillingContacts);
          if (this.firstLoad) {
            this.permissionsLoaded = Promise.resolve(true);
          }
          localStorage.removeItem('ONBOARDING');
          // get all workspace members and teams
          this.data.getWorkspaceMembers(1);
          this.data.getWorkspaceTeams();

          if (res.workspace.workspaceSubscription.workspacePlan.title.toLowerCase() === 'personal') {
            if (this.router.url === '/billing-success') {
              this.router.navigate(['/billing-success']);
            } else if (this.router.url === '/billing-cancel') {
              this.router.navigate(['/billing-cancel']);
            } else {
              this.router.navigate(['/billing']);
            }
          } else {
            // @ts-ignore
            if (JSON.parse(localStorage.getItem('WORKSPACE')).workspaceIndustry === null) {
              this.router.navigate(['/settings/workspace']);
            }

            // TODO add here redirect to home
            // check all teams of member - if the user has no teams redirect to workspace-member page
            // if the user has at least 1 team check the team role
            //  - if the user has the role of team-leader and the team has report - 0 redirect to workspace-member-leader page
            //  - if the user has the role of team-member and [no team-leader + team report > 0] redirect to workspace-member
            if (this.data.userRole.role.workspacePriority <= 20) {

              let isTeamLeader = false;
              let teamMember: boolean = false;
              let teamsNumber: number = 0
              this.data.userRole.teams.forEach((team: any, index: number) => {
                if (team.role !== null) {
                  teamsNumber = +1;
                  if (team.role.teamPriority > 10 && team.team.pollsCount < 2) {
                    // show home-leader button
                    isTeamLeader = true;
                  } else if (team.role.teamPriority === 10) {
                    // show home-member button
                    teamMember = true;
                  }
                  // if the user is team-leader and the team
                }
                // redirect the member to the stuff
                if (index === this.data.userRole.teams.length - 1) {
                  if (isTeamLeader && this.data.userLink.teamLeaderOnboardingStep !== 'done') {
                    this.data.hideLoading();
                    this.data.showHomeMenu = 'member-leader';
                    localStorage.setItem('menuHome', 'member-leader');
                    if (this.router.url === ''){
                      this.router.navigate(['/workspace-member-leader']);
                    }
                    // show the home leader button
                  } else if (teamMember && this.data.userLink.teamMemberOnboardingStep !== 'done') {
                    // check if the user has responded to any collectors
                    // if the user has answerd collectors redirect to team/teams
                    this.data.hideLoading();
                    if (localStorage.getItem('menuHome') === null) {
                      this.data.showHomeMenu = 'member';
                      localStorage.setItem('menuHome', 'member');
                    }
                    if (this.router.url === ''){
                      this.router.navigate(['/workspace-member']);
                    }
                  }
                } else {
                  // if user has start guide done send to team dashboard / teams overview
                }
              })
            } else if (this.data.userRole.role.workspacePriority > 20){
              this.data.showHomeMenu = 'workspace';
              localStorage.setItem('menuHome', 'workspace');
              // if the workspace has the startGuide = true (the start guide is done) hide the home button from menu
              //
              // TODO show loading and hide it after reentering dashboard
              // this.data.showLoading();
            }

            // show the upgrade modal if res.limitReachedDate !== null
            if ((res.workspace.limitReachedDate !== null && res.workspace.workspaceSubscription.workspacePlan.requirePayment === 'no') && this.data.showUpgradePlan) {
              this.data.showUpgradePlan = false;
              setTimeout(() => {
                document.getElementById('workspaceUpdrageModalButton')?.click()
              }, 3000)
            }
          }
        }
        this.refreshMenu = true;
        this.firstLoad = false;
        this.gotPermissions = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy() {
    try {
      this.serviceSubscription.unsubscribe();
      // this.labelsSubscription.unsubscribe();
      // this.workspaceChangedSubscription.unsubscribe();

    } catch (e) {
      console.log(e);
    }
    // this.serviceSubscription.unsubscribe();
    // this.labelsSubscription.unsubscribe();
    // this.workspaceChangedSubscription.unsubscribe();
  }

  resize(data: any) {
    console.log(navigator.userAgent);
  }
}
