import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {PixelService} from "ngx-pixel";
import {DeviceDetectorService} from "ngx-device-detector";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registered: boolean = false;
  showDetails: boolean = false;
  public invitation: any = null;

  registerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    phoneNumber: new FormControl('')
  });
  invitationResent: boolean = false;
  agreeCheck: boolean = false;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  phoneError: boolean = false;
  invited: boolean = false;
  languages : [] = [];

  constructor(public dataService: DataManipulationService, private api: ApiwrapperService, private auth: AuthenticationService,
              private router: Router, private activeRoute: ActivatedRoute, private pixel: PixelService,private device: DeviceDetectorService) {

    api.getAllLanguages().subscribe(
      res => {
        this.languages = res;
      }
    )
  }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.paramMap.get('invitation') !== null) {
      this.invitation = this.activeRoute.snapshot.paramMap.get('invitation');
      this.invited = true;
    }
  }

  onSubmit() {
    this.dataService.showLoading();
    if (this.registerForm.invalid) {
      this.dataService.hideLoading();
      this.registerForm.markAsDirty();
      if (this.registerForm.value['phoneNumber'] === null || this.registerForm.value['phoneNumber'].nationalNumber.length === 0) {
        this.phoneError = true;
      }
      this.registerForm.markAllAsTouched();
      this.dataService.showAlertError('Please complete the form')
      return;
    } else {
      this.register();
    }
  }

  register() {
    // check if form is valid
    // make Sure that agree is checked

    this.dataService.showLoading();
    if (this.registerForm.invalid) {
      this.dataService.showAlertError('Form not valid')
      this.dataService.hideLoading();
      return;
    }

    if (this.checkPhone()) {
      this.dataService.hideLoading();
      return;
    }
    const firstname = this.registerForm.value.firstName;
    const lastName = this.registerForm.value.lastName;
    const username = '';
    const email = this.registerForm.value.email;
    const password = this.registerForm.value.password;
    const confirmPassword = this.registerForm.value.confirmPassword;
    let phone = '';
    let phoneCountry = '';
    let languageUuid = '';
    this.languages.forEach((el:any)=>{
      if (el.tag.toLowerCase().includes(this.auth.language)){
        languageUuid = el.uuid;
      }
    })
    if (this.registerForm.value['phoneNumber'] !== null) {
      phone = this.registerForm.value.phoneNumber.internationalNumber;
      phoneCountry = this.registerForm.value.phoneNumber.countryCode;
    }
    if (password !== confirmPassword) {
      this.dataService.hideLoading();
      this.dataService.showAlertError('Password do not match')
      return;
    }

    // firstname: any,
    // lastname:any,
    // phone: any,
    // email: any,
    // password: any,
    // passwordConfirm: any,
    // invitation: string
    this.auth.register(
      firstname,
      lastName,
      phone,
      phoneCountry,
      email,
      password,
      confirmPassword,
      languageUuid,
      this.invitation,
    ).subscribe(res => {
      // this.sendGa();
      let device = this.device.getDeviceInfo();
      // send FACEBOOK PIXEL tracker
      this.pixel.trackCustom('register', {device: device.deviceType, date: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US')});
      this.dataService.hideLoading();
      this.dataService.registeredMember = username;
      this.registered = true;
    }, err => {
      console.log(err)
      this.dataService.hideLoading();
      this.dataService.showAlertError(err.error.error.messages[0]);
    });
    // this.mailSent = true;
  }

  onStrengthChanged(event: any) {
  }

  checkPhone() {
    if (this.registerForm.value['phoneNumber']!==null && (this.registerForm.value['phoneNumber'].nationalNumber.length > 0 && this.registerForm.controls['phoneNumber'].invalid)){
      this.phoneError = true;
      return true;
    } else {
      this.phoneError = false;
      return false;
    }
  }

  tryPixel(){
    this.pixel.trackCustom('register', {device: this.device.deviceType, email:'testing@email2.com', date: formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss a', 'en-US')});
  }
}
