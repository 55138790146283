import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {Router} from '@angular/router';
import {DataManipulationService} from '../../../services/data-manipulation.service';
import {environment} from '../../../../environments/environment';
import {MustMatch} from '../../../helpers/must-match.validator';
import {ModalsComponent} from "../../../components/modals/modals.component";
import {MatMenuTrigger} from '@angular/material/menu';
import {TranslateService} from "@ngx-translate/core";

interface TeamTypes {
  name: string,
  icon: string,
  type: string,
  uuid: string
}

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  @Output() imageChange = new EventEmitter();
  @ViewChild('userTeamsMenuTrigger') userTeamsMenuTrigger: MatMenuTrigger | undefined;
  workspace: any;
  workspaceUsers: any = {};
  workspaceRolesCount: any;
  workspaceTeams: any = [];
  userDetails: any;
  savedSuccess = {accountSavedSuccess: false, workspaceSavedSuccess: false};
  currentTime = 1;
  memberTeams = [];
  teamMembers = [];
  userDetailsForm: FormGroup;
  workspaceDetailsForm: FormGroup;
  phoneNumber = '(\\+\\d{1,3}[- ]?)?\\d{10}';
  baseUrl;
  submitted = {accountSubmitted: false, workspaceSubmitted: false};
  showMembers: string | undefined;
  showTeamsSpinner: string | undefined;
  showPositions = false;
  showUserDetails = false;
  showWorkspaceDetails = false;
  showTeamsDetails = false;
  showMembersDetails = false;
  leaderTeams: any | any[];
  prioritySubscription: Subscription;
  refreshMembersBoxSub: Subscription;
  refreshTeamsSubscription: Subscription;
  userPriorities: { role: { workspacePriority: any; }; } | any;
  pending = [];
  showPending: boolean | undefined;
  workspacePriority = [];
  workspaceRolesCountNumber = 0;
  roleName = '';
  roleCategory = 'Select category...';
  roleCategories = [];
  showCreateRole = false;
  showUpdateInvitation = false;
  plan: any;
  getTeams = false;
  simulate: any;
  gotSimulate = false;
  index = 0;
  workspaceActivities = [];
  deleteType: string = '';
  delete: string = '';
  teamDelete: any;
  userDelete: any;
  showSpinnerOnUserTeams: boolean[] = [false];
  teamType: TeamTypes[] = [];
  teamTypes: TeamTypes[] = [];

  selectedCountryCode = 'dk';
  countryCodes = ['dk', 'gb'];
  apiLanguages = [];

  workspaceIndustries = [];
  searchIndustry = 'name';
  requests: any = [];
  languageDisplayed = ''
  memberPage = 0;
  memberMaxPage = 0;
  totalMembers = 0;
  loadMembers = false;

  onScroll(event: any) {
    if (((event.target.offsetHeight + Math.ceil(event.target.scrollTop) >= event.target.scrollHeight - 100) && (this.memberPage !== this.memberMaxPage))) {
      // get the next user page
      this.loadMembers = true;
      this.getUsersFromWorkspace(this.memberPage + 1);
    }
  }

  constructor(public apiwrapper: ApiwrapperService, private router: Router, public modal: ModalsComponent,
              public formBuilder: FormBuilder, public data: DataManipulationService, public translate: TranslateService) {
    this.baseUrl = environment.baseUrl;
    // this.getTime();
    this.userDetailsForm = this.formBuilder.group({
      firstname: ['', [
        Validators.required,
        Validators.minLength(1)]],
      lastname: ['', [
        Validators.required,
        Validators.minLength(1)]],
      displayName: ['', [
        Validators.required,
        Validators.minLength(1)]],
      phone: ['', [Validators.pattern(this.phoneNumber)]],
      email: [{value: '', disabled: true}, [
        Validators.required,
        Validators.email]],
      password: ['', [
        Validators.minLength(6)]],
      confirmPassword: ['', []],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.workspaceDetailsForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(1)]
      ]
    });
    this.prioritySubscription = this.data.userPriorities
      .subscribe((value: any) => {
        if (value.role) {
          if (value.role.workspacePriority <= 10) {
            this.workspaceDetailsForm.controls['name'].disable();
          }
          this.userPriorities = value;
          // @ts-ignore
          this.getWorkspacePriority(this.userPriorities.role.workspacePriority);
        }
      });

    this.refreshTeamsSubscription = this.data.refreshMenuTeams.subscribe(refreshTeams => {
      if (this.getTeams) {
        this.showTeamsDetails = false;
        this.getTeamsFromWorkspace();
      }
      this.getTeams = true;
    });
    this.refreshMembersBoxSub = this.data.refreshMembersBox.subscribe((value: any) => {
      this.showMembersDetails = false;
      setTimeout(() => {
        // this.getUsersFromWorkspace();
        // this.getSimulate();
      }, 4000);
    });

    this.getWorkspace();
    this.getUserDetails();
    this.getUsersFromWorkspace(1);
    this.getTeamsFromWorkspace();
    this.getWorkspaceActivities();
    this.getRolesCountsFromWorkspace();
    this.getPending();
    this.getSimulate();
    this.getRoleRequests();
    this.apiLanguages = this.data.apiLanguages;
    this.getWorkspacePlanPrice();
    if (localStorage.getItem('upgrading')) {
      localStorage.removeItem('upgrading');
    }

    if (data.settingsWorkspaceMembers) {
      this.index = 2;
      data.settingsWorkspaceMembers = false;
    }
  }

  ngOnInit() {
    localStorage.setItem('headerText', 'Settings');
    localStorage.removeItem('currentTeam');
    this.apiwrapper.getTeamType().subscribe(
      types => {
        types.forEach((type: any) => {
          if (type.slug === 'physical-workplace'){
            type.slug = 'office';
          }
          if (type.title != 'General') {
            this.teamTypes.push({
              name: type.title,
              icon: type.slug === 'physical-workplace' ? 'office': type.slug,
              type: type.slug,
              uuid: type.uuid
            });
          }
        }); 
      }
    );
    this.apiwrapper.getWorkspacesUser().subscribe(res => {
      this.data.setWorkspaceMembers(res);
      return true;
    }, err => {
      return false;
    });
  }

  ngOnDestroy() {
    this.prioritySubscription.unsubscribe();
    this.refreshMembersBoxSub.unsubscribe();
    this.refreshTeamsSubscription.unsubscribe();
  }

  getWorkspacePlanPrice() {
    //@ts-ignore
    const workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    this.plan = this.data.getWorkspacePlan();
    this.apiwrapper.getWorkspacePlanPrice(this.plan.uuid, workspace.workspaceSubscription.currency.uuid).subscribe((res: any) => {
      this.plan.currency = res.currency.currency;
      this.plan.pricePerMember = res.pricePerMember;
    });
  }

  getWorkspace() {
    // @ts-ignore
    this.apiwrapper.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        // show industries
        if (res.workspaceIndustry === null) {
          this.data.showAlertInfo('Select workspace industry');
          this.apiwrapper.getWorkspaceIndustries().subscribe(
            resInd => {
              this.workspaceIndustries = resInd;
            }
          );
        }
        const invitationKeys = Object.keys(res.activeInvitationBody);
        invitationKeys.forEach((el) => {
          if (res.activeInvitationBody[el].uuid) {
            res.activeInvitationBody = res.activeInvitationBody[el];
          }
        });
        res.activeInvitationBody.body = res.activeInvitationBody.body.replace(/&#10;&#13;/g, '\n');
        // res.avatar = null;
        this.workspace = res;
        this.getDisplayLanguage(res.language);
        this.workspaceDetailsForm.setValue({
          name: res.name
        });
        this.showWorkspaceDetails = true;
      }, error => {
      });
  }

  getUserDetails() {
    // @ts-ignore
    this.apiwrapper.getUserDetails(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.userDetails = res;
        // this.userDetailsForm.patchValue(res.detail);
        this.userDetailsForm.setValue({
          email: res.email,
          firstname: res.detail.firstname,
          lastname: res.detail.lastname,
          displayName: res.detail.displayName,
          phone: res.detail.phone,
          password: '',
          confirmPassword: ''
        });
        this.showUserDetails = true;
      }, error => {
        console.log(error);
      });
  }

  getUsersFromWorkspace(page: number) {
    if (page === 1){
      this.workspaceUsers = [];
    }
    // this.showMembersDetails = false;
    this.apiwrapper.getWorkspacesUserWithPagination(page)
      .subscribe(res => {
        this.memberPage = page;
        this.memberMaxPage = res._page_count;
        this.totalMembers = res._total_items;
        this.loadMembers = false;
        this.workspaceUsers = this.workspaceUsers.concat(res._embedded.users);
        this.showMembersDetails = true;
      }, error => {
        this.loadMembers = false;
        console.log(error);
      });
  }

  getUserTeams(userUuid: any, userIndex: number) {
    this.apiwrapper.getUserTeams(userUuid).subscribe(res => {
      if (this.workspaceUsers[userIndex].teams === undefined && !this.showSpinnerOnUserTeams[userIndex]) {
        this.workspaceUsers[userIndex].teams = res;
        this.showSpinnerOnUserTeams[userIndex] = true;
      }
    });
  }

  getRolesCountsFromWorkspace() {
    this.apiwrapper.getRolesCountsFromWorkspace()
      .subscribe(res => {
        this.showPositions = true;
        this.workspaceRolesCountNumber = res.length;
        this.workspaceRolesCount = res;
      }, error => {
        console.log(error);
      });
  }

  getTeamsFromWorkspace() {
    this.data.showLoading();
    this.showTeamsDetails = false;
    this.apiwrapper.getTeamsFromWorkspace()
      .subscribe(res => {
        res.forEach((el: { editName: boolean; }) => {
          el.editName = false;
        });
        this.workspaceTeams = res;
        this.getTeamsType();
        this.showTeamsDetails = true;
        this.data.hideLoading();
        // this.getMyLeaderTeams();
      }, error => {
        this.data.hideLoading();
        console.log(error);
      });
  }

  getTeamsType() {
    this.workspaceTeams.forEach((team: any, index: number) => {
      if (team.questionCollections.length > 0) {
        const collection = team.questionCollections[team.questionCollections.length-1];
          switch (collection.slug.toLowerCase()) {
            case 'hybrid':
              team.type = 'Hybrid'
              team.typeIcon = 'hybrid'
              this.teamType[index] = {name: this.translate.instant('workspace_team_type_'+collection.slug) , icon: 'hybrid', type: 'hybrid', uuid: collection.uuid};
              return;
            case 'remote':
              team.type = 'Remote'
              team.typeIcon = 'remote'
              this.teamType[index] = {name: this.translate.instant('workspace_team_type_'+collection.slug), icon: 'remote', type: 'remote', uuid: collection.uuid};
              return;
            case 'office':
              team.type = 'Office'
              team.typeIcon = 'office'
              this.teamType[index] = {name: this.translate.instant('workspace_team_type_'+collection.slug), icon: 'office', type: 'office', uuid: collection.uuid};
              return;
            case 'physical-workplace':
              team.type = 'Physical workplace'
              this.teamType[index] = {
                name: this.translate.instant('workspace_team_type_'+collection.slug),
                icon: 'office',
                type: 'physical-workplace',
                uuid: collection.uuid
              };
              team.typeIcon = 'office'
              return;
          }
      }
    });
  }

  showDelete(element: { name: string; }, type: string) {
    this.deleteType = type;
    if (type === 'team') {
      this.delete = element.name;
      this.teamDelete = element;
    } else if (type === 'user') {
      this.delete = element.name;
      this.userDelete = element;
    }

    // @ts-ignore
    document.getElementById('deleteModalButton').click();
  }

  deleteTeam() {
    this.data.showLoading();
    this.apiwrapper.deleteTeam(this.teamDelete.uuid).subscribe(
      res => {
        this.data.setRefreshMenuTeams(true);
        // @ts-ignore
        document.getElementById('closeDeleteModal').click();
        this.data.showAlert('The team was deleted.');
        this.getTeamsFromWorkspace();
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error deleting the team. Try again.');
      });
  }

  // getMyLeaderTeams() {
  //   this.apiwrapper.getMyTeamsLeader()
  //     .subscribe(res => {
  //       this.leaderTeams = res;
  //       this.addTeamStars();
  //     }, error => {
  //       console.log(error);
  //     });
  // }

  addTeamStars() {
    const teams = this.workspaceTeams._embedded.teams;
    for (let i = 0; i < teams.length; i++) {
      this.leaderTeams.forEach((el: { uuid: any; }) => {
        if (this.workspaceTeams._embedded.teams[i].uuid === el.uuid) {
          this.workspaceTeams._embedded.teams[i].teamLeader = true;
        }
      });
    }
  }

  respondRequest(uuid: string, status: any) {
    this.data.showLoading();
    this.apiwrapper.patchRoleRequest(uuid, status)
      .subscribe(res => {
        if (status === 'accepted') {
          this.data.showAlert('Admin role granted')
          // set the member role;
          this.getUsersFromWorkspace(1);
        }
        this.requests.forEach((el: any, index: number, object: any) => {
          if (el.uuid === uuid) {
            // remove from array
            object.splice(index, 1);
          }
        })
        this.data.hideLoading();
      }, error => {
        console.log(error);
        this.data.showAlertError('Something went wrong')
        this.data.hideLoading();
      });
  }

  getTeamMembers(uuid: string) {
    this.showTeamsSpinner = uuid;
    this.apiwrapper.getTeamMembers(uuid)
      .subscribe(res => {
        // @ts-ignore
        this.teamMembers[uuid] = res;
        this.showTeamsSpinner = undefined;
      }, error => {
        console.log(error);
      });
  }

  getWorkspaceActivities() {
    this.apiwrapper.getWorkspaceLogs().subscribe(
      res => {
        this.workspaceActivities = res._embedded['workspace-log'];
        this.workspaceActivities.forEach((el: any, index, object) => {
          if (el.admin === null) {
            object.splice(index, 1);
          }
        })
      }, err => {
        console.log(err);
      }
    );
  }

  get f() {
    return this.userDetailsForm.controls;
  }

  // @ts-ignore
  uploadImage(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            // @ts-ignore
            document.getElementById('close').click();
            // this.data.showAlertError(`Your profile picture's width and height must be between 100 and 1000 pixels.`, '');
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.apiwrapper.uploadAvatar(file).subscribe(
              (res: any) => {
                this.userDetails.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.showSaved('account');
              },
              (error) => {
                // @ts-ignore
                document.getElementById('close').click();
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  // @ts-ignore
  uploadWorkspaceImage(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            // @ts-ignore
            document.getElementById('close').click();
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.data.showLoading();
            this.apiwrapper.uploadWorkspaceAvatar(file).subscribe(
              (res: any) => {
                this.getTime();
                this.data.setWorkspaceAdded(true);
                this.workspace.avatar = res.avatar;
                this.showSaved('workspace');
                this.data.setChangeImage(true);
                this.data.hideLoading();
              },
              (error) => {
                // @ts-ignore
                document.getElementById('close').click();
                this.data.hideLoading();
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }

  get e() {
    return this.workspaceDetailsForm.controls;
  }

  updateWorkspace() {
    this.data.showLoading();
    this.submitted.workspaceSubmitted = true;
    if (this.workspaceDetailsForm.invalid) {
      return;
    }
    const data = {
      name: this.workspaceDetailsForm.value.name,
    };
    this.apiwrapper.patchWorkspace(this.workspace.uuid, data)
      .subscribe(res => {
        this.data.hideLoading();
        this.showSaved('workspace');
        this.submitted.workspaceSubmitted = false;
        this.data.setChangeName(true);
        this.data.showAlert('Changes saved');
      }, error => {
        this.data.hideLoading();
        this.data.showAlertError('Error saving changes');
        if (error.error.error !== undefined) {
          // @ts-ignore
          document.getElementById('close').click();
          this.data.showAlertError(typeof error.error.error.messages === 'object' ?
            this.data.parseError(error.error.error.messages) : error.error.error.messages);
        } else {
          // @ts-ignore
          document.getElementById('close').click();
          this.data.showAlertError(error.message);
        }
      });
  }

  showSaved(block: string) {
    if (block === 'account') {
      this.savedSuccess.accountSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.accountSavedSuccess = false;
      }, 3000);
    }
    if (block === 'workspace') {
      this.savedSuccess.workspaceSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.workspaceSavedSuccess = false;
      }, 3000);
    }
  }

  private getPending() {
    this.apiwrapper.getUserInvitations().subscribe(
      res => {
        this.pending = res;
        this.showPending = true;
      },
      err => {
        return err;
      }
    );
  }

  getWorkspacePriority(myPriority: number) {
    this.apiwrapper.getAllRoles('workspace').subscribe(
      res => {
        this.workspacePriority = [];
        // remove above role
        res.forEach((el: { workspacePriority: number; name: string; }) => {
          if (el.workspacePriority <= myPriority && el.workspacePriority < 40) {
            // @ts-ignore
            this.workspacePriority.push(el);
          }
        });
      }, err => {
        console.log(err);
      }
    );
  }

  changeMemberRole(member: { workspaceRole: { label: any; }; uuid: string }, role: { value: any; target: any }) {
    // if user select same option don't do anything
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    if (member.workspaceRole.label != role.label) {
      // Swal.fire({
      //   title: `Assign the role  "${role.label}" to the member "${member.detail.displayName}" `,
      //   type: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#05D287',
      //   cancelButtonColor: '#F3454C',
      //   confirmButtonText: 'Yes!'
      // }).then((result) => {
      //   if (result.value === true) {
      // get the role uuid based of role.target.value
      this.workspacePriority.forEach((el: { uuid: string; label: any }) => {
        // tslint:disable-next-line:triple-equals
        if (el.label == role.value.label) {
          this.apiwrapper.postMemberRole(member.uuid, el.uuid, '').subscribe(
            res => {
              // this.getUsersFromWorkspace(1);
              this.data.showAlert('The member permission was changed.');
            }, err => {
              this.data.showAlertError(err.error.error.messages[0]);
            });
        }
      });
    } else {
      role.target.selectedIndex = '0';
    }
    // });

  }

  createRole() {
    if (this.roleName !== '' && this.roleCategory !== 'Select category...') {
      this.data.showLoading();
      this.apiwrapper.postMemberLabelsWorkspace({name: this.roleName, labelCategoryUuid: this.roleCategory}).subscribe(
        res => {
          // get rolse
          this.data.hideLoading();
          // @ts-ignore
          document.getElementById('close').click();
          this.data.showAlert('Role created successfully!');
          this.showCreateRole = !this.showCreateRole;
        }, err => {
          // @ts-ignore
          document.getElementById('close').click();
          this.data.showAlertError('Error on creating the role!');
          this.data.hideLoading();
        }
      );
    } else {
      // @ts-ignore
      document.getElementById('close').click();
      this.data.showAlertError('Please insert role name and select role category');
    }
  }

  showCreateRoleBlock() {
    this.data.showLoading();

    this.apiwrapper.getMemberLabelsWorkspace().subscribe(
      res => {
        this.data.hideLoading();
        this.roleCategories = res;
        this.showCreateRole = !this.showCreateRole;
      }, err => {
        // @ts-ignore
        document.getElementById('close').click();
        this.data.showAlertError('Sorry cannot create role at this moment!');
      }
    );
  }

  deleteWorkspaceMember() {
    this.data.showLoading();
    this.apiwrapper.deleteWorkspaceUser(this.userDelete.user.uuid).subscribe(
      res => {
        // remove workspace member from list;
        // this.getUsersFromWorkspace();
        this.getTeamsFromWorkspace();
        this.workspaceUsers.forEach((el:any, index: number)=>{
          if (el === this.userDelete){
            this.workspaceUsers.splice(index, 1);
          }
        })
        this.data.hideLoading();
        // @ts-ignore
        document.getElementById('closeDeleteModal').click();
        this.data.showAlert('Member removed.');
      }, err => {
        // this.getUsersFromWorkspace();
        this.getTeamsFromWorkspace();
        this.data.hideLoading();
        this.data.showAlertError('There was an error deleting the member.');
      });
  }


  toggleLeaderPolling(data: string, teamUuid: string | undefined) {
    let pollLeaderAssign;
    if (data === 'yes') {
      pollLeaderAssign = 'no';
    } else {
      pollLeaderAssign = 'yes';
    }

    this.apiwrapper.patchTeam({pollLeaderAssign}, teamUuid).subscribe(
      res => {
        this.getTeamsFromWorkspace();
      }, err => {
        console.log(err);
      }
    );
  }

  updateWorkspaceInvitation() {
    // &#10;&#13;
    // tslint:disable-next-line:max-line-length
    this.data.patchWorkspaceInvitation(this.workspace.activeInvitationBody.body.replace(/\n/g, '&#10;&#13;'), this.workspace.activeInvitationBody.uuid).then(
      () => {
        this.showUpdateInvitation = !this.showUpdateInvitation;
      }
    );
  }

  patchTeamName(team: string | number) {
    // tslint:disable-next-line:max-line-length
    this.apiwrapper.patchTeam({name: this.workspaceTeams[team].name}, this.workspaceTeams[team].uuid).subscribe(
      res => {
        this.workspaceTeams[team].editName = false;
        // after team change name get menu teams
        this.data.setRefreshMenuTeams(true);
        this.data.showAlert('Name updated successfully!');
        //  TODO get the teams / get the team new name
      }, err => {
        this.data.showAlertError('There was an error updating the team name.');
      }
    );
  }

  patchTeamType(teamType: any, teamUuid: any) {
    // this.apiwrapper.patchTeamType({questionCollections: [teamType.uuid]}, teamUuid).subscribe(
    //   res => {
    //     this.data.showAlert('Team type updated successfully!');
    //   }, err => {
    //     this.data.showAlertError('There was an error updating the team type.');
    //   }
    // );
    this.data.showAlertWarning(this.translate.instant('settings_workspace_teamtype_warning'));
  }

  private getSimulate() {
    this.apiwrapper.getSimulateInvoice().subscribe(
      res => {
        this.gotSimulate = true;
        this.simulate = res;
      }
    );
  }

  private getRoleRequests() {
    this.apiwrapper.getRoleRequest().subscribe(
      res => {
        this.requests = res;
        // set the tab workspace
        if (res.length > 0) {
          this.index = 2;
        }

      }, err => {
        console.log(err);
      }
    )
  }

  changeIndustry(industry: { uuid: any; }) {
    this.data.showLoading();
    this.apiwrapper.patchWorkspace(this.workspace.uuid, {workspaceIndustryUuid: industry.uuid})
      .subscribe(res => {
        this.data.hideLoading();
        this.data.showAlert('Industry updated');
        this.workspace = res;
        localStorage.setItem('WORKSPACE', JSON.stringify(res));
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError(err.error.error.messages[0]);
      });
  }

  openOrganizeTeamModal() {
    this.data.organizeTeams = this.data.allTeams;
    this.data.openFromTeam = false;
    this.data.setOrganizeTeams(true);
  }

  getDisplayLanguage(language: any) {
    // @ts-ignore
    const userLang = JSON.parse(localStorage.getItem('language'));
    if (userLang.language === 'English') {
      this.languageDisplayed = language.language
    } else if (userLang.language === 'Danish'){
      if (language.language === 'English') {
        this.languageDisplayed = 'Engelsk'
      } else if (language.language === 'Danish') {
        this.languageDisplayed = 'Dansk'
      }
    }

    if (language.language === 'English') {
      this.selectedCountryCode = 'gb'
    } else if (language.language === 'Danish') {
      this.selectedCountryCode = 'dk'
    }
  }

  changeLanguage(data: any) {
    if (data === 'gb') {
      data = 'en';
    }
    this.apiLanguages.forEach((el: any) => {
      if (data === el.tag.split("_")[0].toLowerCase() || data === el.tag.split("_")[1].toLowerCase()) {
        this.data.language.language = el.tag.split("_")[0];
        this.getDisplayLanguage(el);
        this.workspace.language = el;
        // patch WORKSPACE LANGUAGE
        this.apiwrapper.patchWorkspace(this.workspace.uuid, {languageUuid: el.uuid}).subscribe()
      }
    });
  }
}
