import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwrapperService} from '../../services/apiwrapper.service';
import {DataManipulationService} from '../../services/data-manipulation.service';

@Component({
  selector: 'app-add-to-workspace',
  templateUrl: './add-to-workspace.component.html',
  styleUrls: ['./add-to-workspace.component.scss']
})
export class AddToWorkspaceComponent implements OnInit {
  title = 'successTeam';

  constructor(private router: Router,
              private data: DataManipulationService,
              private activeRoute: ActivatedRoute,
              private service: ApiwrapperService) {
  }

  ngOnInit() {
    if (this.activeRoute.snapshot.paramMap.get('hash') != null) {
      this.activateUser(this.activeRoute.snapshot.paramMap.get('hash'));
    }
    this.data.showAlertInfo('Activating workspace invitation');
    setTimeout(() => {
    }, 100);
    // document.getElementById('loginForm').click();
  }

  activateUser(hash: string | null) {
    this.service.addToWorkspace(hash).subscribe(() => {
      this.data.showAlert('Joined workspace successfully!');
      this.router.navigate(['/']);
      this.data.setWorkspaceAdded(true);
      // go on the mew workspace

    }, (err) => {
      if (err.error.error.messages[0] !== 'This user already exists in the workspace.') {
        this.data.showAlertError('Joined workspace error! ' + err.error.error.messages[0]);
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/']);
      }
    });
  }
}
