import {Component, Input, OnInit} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ChallengesService} from "../../services/challenges.service";
import {moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";
import {environment} from "../../../environments/environment";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {LabelType} from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-my-actions',
  templateUrl: './my-actions.component.html',
  styleUrls: ['./my-actions.component.scss']
})
export class MyActionsComponent implements OnInit {

  @Input() showArchive: any;

  baseUrl = environment.baseUrl;
  tasksNumber = 5;
  actionModal: any = {};
  showModal: boolean = false;
  private team: any;
  taskStatements: any = [];
  selectedActionStatus: any;
  actionsStatus : {title: string, type: string, color: string}[] = [
    {title: 'To Do', type: 'to_do', color: '#F7F9FF'},
    {title: 'In Progress', type :'in_progress', color: '#E6D7F5'},
    {title: 'Done', type :'done', color : '#B9E8D9'},
    {title: 'Archive', type :'archived', color: '#E2E2E2'},
  ];

  constructor(public data: DataManipulationService, public challengesService: ChallengesService, private api: ApiwrapperService) {
    this.data.showLoading();
    // get team uuid
    this.team = this.data.currentTeam;
    setTimeout(() => {
      this.data.hideLoading()
    }, 1000);
  }

  ngOnInit(): void {
  }

  drop(event: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // post API whit new status of action
      // to_do / in_progress /  done / archived
      if (event.container.id === 'todolist') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'to_do');
      }  if (event.container.id === 'inProgressList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'in_progress');
      } else if (event.container.id === 'doneList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'done');
      } else if (event.container.id === 'archivelist') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'archived');
      }
    }
  }

  moveAction(event: any, status: string) {
    const task = event.previousContainer.data[event.previousIndex].teamTask;
    let message = '';
    switch (status) {
      case 'to_do':
        message = 'Great. The action was set in ToDo section'
        break;
      case 'in_progress':
        message = 'Great. The action was moved to In Progress section'
        break;
      case 'done':
        message = 'Great. The action was completed'
        break;
      case 'archived':
        message = 'Great. The action was archived for later'
        break;
    }


    if (this.data.showDemo) {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    } else {
      // move form ToDoList to InProgressList
      this.data.showLoading();
      this.api.patchTask(task.uuid, status, false, this.team.uuid, '').subscribe(
        res => {
          switch (event.previousContainer.id) {
            case 'todolist': 
              if(this.challengesService.myProblemsTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myProblemsTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'inProgressList':
              if(this.challengesService.myInProgressTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myInProgressTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'doneList':
              if(this.challengesService.myCompletedTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myCompletedTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
            case 'archiveList':
              if(this.challengesService.myArchivedTasks[event.previousIndex]['teamTask']['uuid'] === task.uuid) {
                //@ts-ignore
                this.challengesService.myArchivedTasks[event.previousIndex]['teamTask']['status'] = res.status;
              }
              break;
          }
          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          );
          event.container.data[event.currentIndex].teamTask = res
          this.data.hideLoading();
        }, err => {
          console.log(err);
          this.data.hideLoading();
        })
    }
  }

  showAction(item: any) {
    this.data.showLoading()
    this.actionModal = item;
    this.selectedActionStatus = {type: item.status};
    this.api.getTaskStatements(item.uuid, this.team.uuid).subscribe(
      res => {
        this.taskStatements = res;
        this.data.hideLoading();
        const openModal = document.getElementById('actionModalButton');
        openModal?.click();
        this.showModal = true;
      }, error => {
        console.log(error);
        this.data.hideLoading();
      }
    );
  }

  changeAction(status: any, selectedAction: any) {
    let previousContainer: string;
    let actionPreviousIndex: number;
    let currentContainer : string;
    // const closeModal = document.getElementById('closeActioneModal');
    switch (selectedAction.status) {
      case 'to_do':
        previousContainer = 'to_do';
        this.challengesService.myProblemsTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          } else if (task.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'in_progress':
        previousContainer = 'in_progress';
        this.challengesService.myInProgressTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'done':
        previousContainer = 'done';
        this.challengesService.myCompletedTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
      case 'archived':
        previousContainer = 'archived';
        this.challengesService.myArchivedTasks.forEach((task: any, index: any) => {
          if(task.teamTask.uuid === selectedAction.uuid) {
            actionPreviousIndex = index;
          }
        });
        break;
    }

    switch (status.type) {
      case 'to_do':
        //@ts-ignore
        currentContainer = 'to_do';
        break;
      case 'in_progress':
        //@ts-ignore
        currentContainer = 'in_progress';
        break;
      case 'done':
        //@ts-ignore
        currentContainer = 'done';
        break;
      case 'archived':
        //@ts-ignore
        currentContainer = 'archived';
        break;
    }
    this.data.showLoading();
    this.api.patchTask(selectedAction.uuid, status.type, false, this.team.uuid, '').subscribe(
      (res: any) => {
        switch (previousContainer) {
          case 'to_do': 
            if(this.challengesService.myProblemsTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myProblemsTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'in_progress':
            if(this.challengesService.myInProgressTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myInProgressTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'done':
            if(this.challengesService.myCompletedTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myCompletedTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
          case 'archived':
            if(this.challengesService.myArchivedTasks[actionPreviousIndex]['teamTask']['uuid'] === selectedAction.uuid) {
              //@ts-ignore
              this.challengesService.myArchivedTasks[actionPreviousIndex]['teamTask']['status'] = res.status;
            }
            break;
        }
        transferArrayItem(
          previousContainer === 'to_do' ? this.challengesService.myProblemsTasks : 
          previousContainer === 'in_progress' ? this.challengesService.myInProgressTasks : 
          previousContainer === 'done' ? this.challengesService.myCompletedTasks : this.challengesService.myArchivedTasks,
          currentContainer === 'to_do' ? this.challengesService.myProblemsTasks : 
          currentContainer === 'in_progress' ? this.challengesService.myInProgressTasks : 
          currentContainer === 'done' ? this.challengesService.myCompletedTasks : this.challengesService.myArchivedTasks,
          actionPreviousIndex,
          0,
        );
        this.data.hideLoading();
        // document.getElementById('closeActioneModal')?.click();
      }, (err: any) => {
        console.log(err);
        this.data.hideLoading();
      });
  }
}
