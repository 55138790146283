import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss']
})
export class ActionPlanComponent implements OnInit {

  @Input() set actions(value: any) {
    this.actionsArray = [...value];
  }

  @Input() set actionsCategories(value: any) {
    value.forEach((category: any) => {
      this.actionsCategoriesArray.push({name: category.category.title, uuid: category.category.uuid});
    });
    this.actionsCategoriesArray.unshift({name: this.translate.instant('custom_poll_create_action_all_categories'), uuid: ''});
  }

  @Output() filterCategories = new EventEmitter<any>();

  actionsArray: any[] = [];
  actionsCategoriesArray: any[] = [];

  sortByCategory: any;

  constructor(public data: DataManipulationService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  selectCategoryForActions(category: any) {
    this.filterCategories.emit(category.value.uuid);
  }

}
