import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { ApiwrapperService } from 'src/app/services/apiwrapper.service';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-categories-and-statements',
  templateUrl: './categories-and-statements.component.html',
  styleUrls: ['./categories-and-statements.component.scss']
})
export class CategoriesAndStatementsComponent implements OnInit {
  @Input() reportingCategories: any[] = [];
  
  @Input() set categStatements(value: any) {
    this.categoriesStatements = value;
    this.setStatementTab();
    this.statementResponses = [];
    this.getCategoryStatementScoreColor();
  }

  @Input() set reportingUserTeams(value: any) {
    if (value.selectedTeam !== null && value.selectedTeam !== undefined) {
      this.sortByTeam = value.selectedTeam;
      this.defaultSelectedTeam.push(value.selectedTeam.uuid);
    }
    this.userTeams = value.teams;
  }
  
  @Input() set stateResponses(value: any | undefined) {
    this.statementResponses = [];
    if (value !== undefined) {
      if (value.type === 'scaled') {
        this.getQuestionDistributionValues(value.responses);
      } else {
        this.statementResponses = value.responses._embedded.responses;
        this.statementResponseCount = value.responses._total_items;
        this.statementResponsePage = value.responses._page_count;
      }
    }
  }

  @Input() set categoryActions(value: any) {
    this.categoryActionsArray = value;
  }

  @Input() set actionAssignees(value: any) {
    this.filterUsersForAssignee(value);
  }
  
  @Output() categoryStatementEvent = new EventEmitter<any>();
  @Output() statementResponsesEvent = new EventEmitter<any>();
  @Output() changedTeamEvent = new EventEmitter<any>();
  @Output() createActionEvent = new EventEmitter<any>();

  actionForm = new FormGroup({
    actionTitle: new FormControl('', [Validators.required, Validators.minLength(1)]),
    actionDescription: new FormControl('', [Validators.minLength(1)]),
  });
  
  categoriesStatements: any[] = [];
  statementResponses: any[] | undefined = [];
  userTeams: any[] = [];
  showSelectedTabCateg: any[] = [];
  showSelectedTabState: any[] = [];
  scoreColorInterval: {color: string, min:number, max:number}[] = [
    {color: 'red', min: 0, max: 39},
    {color: 'orange', min: 40, max: 59},
    {color: 'yellow', min: 60, max: 79},
    {color: 'green', min: 80, max: 100},
  ];
  teams: any[] = [];
  defaultSelectedTeam: any[] = [];
  categoryActionsArray: any[] = [];
  membersForSearch: any[] = [];

  responseDistribution: any;
  sortByTeam: any;
  showedOpenQsResponse: any;
  previousShowSelectedTabCategIndex: any;
  previousShowSelectedTabStateIndex: any;
  actionDueDate: any;
  actionAsignee: any;
  minDateValue: any;
  searchValue: any = '';
  currentUser: any;
  currentUserRole: any;
  
  searching = new Subject<string>();
  
  statementResponseCount: number = 0;
  statementResponsePage: number = 0;
  
  showResponseModal: boolean = false;
  hasCategoryStatements: boolean = false;
  changedPage: boolean = false;
  searchingApi: boolean = false;
  emptyResult: boolean = false;
  
  constructor(public data: DataManipulationService) {
    //@ts-ignore
    this.currentUser = JSON.parse(localStorage.getItem('userDetails')?.toString());
    this.currentUserRole = this.data.userRole.role.workspacePriority;
  }

  ngOnInit(): void {
    this.getCategoryScoreColor();
    this.showSelectedTabCateg = new Array(this.reportingCategories.length).fill(false);
    this.minDateValue = new Date();
  }

  filterUsersForAssignee(membersToAssign: any) {
    this.membersForSearch = 
      membersToAssign
      .filter((member: any) => (member.status === 'active'))
      .map((member:any) => {
        return {name: member.detail.displayName ? member.detail.displayName : 
                      member.detail.firstname ? member.detail.firstname + member.detail.lastname : member.email,
                uuid: member.uuid, avatar: member.avatar}});
    this.membersForSearch.forEach((member:any, index:number) => {
      if (member.uuid === this.currentUser.uuid) {
        this.membersForSearch.splice(index, 1);
        this.membersForSearch.unshift(member);
      }
    });
  }

  emitCategoryStatements(categoryUuid: any, index: number) {
    this.categoriesStatements = [];
    if (this.previousShowSelectedTabCategIndex === undefined) {
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    } else {
      this.showSelectedTabCateg[this.previousShowSelectedTabCategIndex] = false;
      this.previousShowSelectedTabCategIndex = index;
      this.showSelectedTabCateg[index] = true;
    }
    this.hasCategoryStatements = false;
    this.categoryStatementEvent.emit({categoryUuid, teamUuid: this.defaultSelectedTeam});
  }

  getCategoryScoreColor() {
    let categoryScore = 0;
    this.reportingCategories.forEach((category: any) => {
      categoryScore = category.category.categoryScore;
      this.scoreColorInterval.forEach((scoreInterval: any) => {
        if (categoryScore >= scoreInterval.min && categoryScore <= scoreInterval.max) {
          category.color = scoreInterval.color;
        }
      });
    });
  }

  getCategoryStatementScoreColor() {
    let statementScore = 0;
    this.categoriesStatements.forEach((statement: any) => {
      if (statement.eligibleForScore) {
        statementScore = statement.score;
        this.scoreColorInterval.forEach((scoreInterval: any) => {
          if (statementScore >= scoreInterval.min && statementScore <= scoreInterval.max) {
            statement.color = scoreInterval.color;
          }
        });
      } else {
        statement.color = 'default';
      }
    });
  }

  emitStatementResponses(statementType: any, statementUuid: any, index: number) {
    if (this.previousShowSelectedTabStateIndex === undefined) {
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    } else {
      this.showSelectedTabState[this.previousShowSelectedTabStateIndex] = false;
      this.previousShowSelectedTabStateIndex = index;
      this.showSelectedTabState[index] = true;
    }
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: this.defaultSelectedTeam, page: 1, limit: 6});
  }

  
  selectTeamsForQuestions(event: any) {
    this.defaultSelectedTeam = [];
    if (event.value.uuid !== '') {
      this.defaultSelectedTeam.push(event.value.uuid);
    }
    this.changedTeamEvent.emit({changedTeamUuid: event.value.uuid});
    this.closeAllTabs();
  }
  
  closeAllTabs() {
    if (this.previousShowSelectedTabStateIndex !== undefined || this.previousShowSelectedTabCategIndex !== undefined) {
      this.showSelectedTabState[this.previousShowSelectedTabStateIndex] = false;
      this.showSelectedTabCateg[this.previousShowSelectedTabCategIndex] = false;
    }
  }

  setStatementTab() {
    this.hasCategoryStatements = true;
    this.showSelectedTabState = new Array(this.categoriesStatements.length).fill(false);
  }

  getQuestionDistributionValues(value: any) {
    if (value) {
      value.onInsight = true;
      value.eligibleForResult = true;
      this.responseDistribution = new Observable(subcriber => {
        subcriber.next(value);
      });
    }
  }

  openResponsePreviewModal(response: any) {
    this.showedOpenQsResponse = response;
    this.showResponseModal = true;
    const openModal = document.getElementById('actionModalButton');
    openModal?.click();
  }

  clearStatements() {
    this.statementResponses = [];
  }

  changePage(page: any, statementType: any, statementUuid: any) {
    this.statementResponses = [];
    this.statementResponsesEvent.emit({statementType, statementUuid, teamUuid: this.defaultSelectedTeam, page: page.page + 1, limit: 6});
  }

  createAction(category: any) {
    if (this.actionForm.valid && this.actionAsignee && this.actionDueDate) {
      const taskDetails = {
        title: this.actionForm.controls['actionTitle'].value,
        categoryUuid: category.category.uuid,
        assigneeUuid: this.actionAsignee.uuid,
        description: this.actionForm.controls['actionDescription'].value,
        dueDate: this.actionDueDate
      }
      category.tasks = category.tasks + 1;
      this.createActionEvent.emit(taskDetails);
      this.actionDueDate = {};
      this.actionAsignee = {};
      this.actionForm.reset();
    }
  }

  setAssigneeForAction(assignee: any) {
    
  }
}
