import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiwrapperService } from 'src/app/services/apiwrapper.service';

@Injectable({
  providedIn: 'root'
})
export class CustomPollOnboardingService {

  constructor(private api: ApiwrapperService) { }

  getChekinsCategories(): Observable<any> {
    return this.api.getCustomPollTimedQuestions();
  }

  getChekinsCategoriesStatements(collectionUuid: string): Observable<any> {
    return this.api.getCustomPollTimedQuestionsStatements(collectionUuid);
  }

  getChekinsSelectedCategories(customPollUuid: string): Observable<any> {
    return this.api.getChekinsSelectedCategories(customPollUuid);
  }
}
