import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {DataManipulationService} from './data-manipulation.service';
import { ApiwrapperService } from './apiwrapper.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private currentWorkspaceSubject: BehaviorSubject<any>;
  public currentWorkspace: Observable<any>;
  public language: string = '';

  constructor(private http: HttpClient, private dataService: DataManipulationService, private api: ApiwrapperService) {
    // @ts-ignore
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentWorkspaceSubject = new BehaviorSubject<any>(localStorage.getItem('WORKSPACE_UUID'));
    this.currentWorkspace = this.currentWorkspaceSubject.asObservable();
  }

  public get currentUserValue(): any {
    // @ts-ignore
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    return this.currentUserSubject.value;
  }

  public get currentWorkspaceUuid(): any {
    this.currentWorkspaceSubject = new BehaviorSubject<any>(localStorage.getItem('WORKSPACE_UUID'));
    return this.currentWorkspaceSubject.value;
  }

  setCurrentWorkspace(uuid: string) {
    localStorage.setItem('WORKSPACE_UUID', uuid);
    this.currentWorkspaceSubject.next(uuid);
  }

  setCurrentUserWorkspace(user: any, uuid: any, plan: any) {
    // @ts-ignore
    let userWorkspaces = JSON.parse(localStorage.getItem('USER_WORKSPACES'));
    userWorkspaces = !userWorkspaces ? [] : userWorkspaces;
    let exist = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < userWorkspaces.length; i++) {
      if (userWorkspaces[i].user === user) {
        userWorkspaces[i].workspace = uuid;
        userWorkspaces[i].plan = plan;
        exist = true;
      }
    }
    if (!exist) {
      userWorkspaces.push({user, workspace: uuid, plan});
    }
    localStorage.setItem('USER_WORKSPACES', JSON.stringify(userWorkspaces));
  }


  refreshToken() {
    // @ts-ignore
    const body = {
      grant_type: 'refresh_token',
      client_id: 'frontend',
      client_secret: 'secret',
      scope: 'api',
      // @ts-ignore
      refresh_token: JSON.parse(localStorage.getItem('currentUser')).refresh_token
    };
    return this.http.post<any>(environment.baseUrl + '/oauth/refresh', body).pipe(tap((res) => {
      this.currentUserSubject.next(res);
    }));
  }

  login(username: any, password: any) {
    const body = {
      grant_type: 'password',
      client_id: 'frontend',
      client_secret: 'secret',
      scope: 'api',
      username,
      password
    };
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(environment.baseUrl + '/oauth/generate', body, {
      headers
    }).pipe(map(res => {
      // user.authdata = window.btoa(username + ':' + password);
      localStorage.setItem('version', '3');
      this.currentUserSubject.next(res);
      return res;
    }));
  }

  impersonate(hash: any) {
    const body = {
      grant_type: 'password',
      client_id: 'admin',
      client_secret: 'secret',
      scope: 'api',
      hash,
    };
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(environment.baseUrl + '/user/impersonate', body, {
      headers
    }).pipe(map(res => {
      // user.authdata = window.btoa(username + ':' + password);
      this.currentUserSubject.next(res);
      return res;
    }));
  }

    activateUser(hash: string | null) {
    return this.http.get<any>(environment.baseUrl + '/user/activate/' + hash).pipe(map(res => {
      return res;
    }, (err: any) => {
      return err;
    }));
  }

  logout() {
    const workspaceUuid = localStorage.getItem('WORKSPACE_UUID');
    const workspaces = localStorage.getItem('USER_WORKSPACES');
    let tokenExpired = false;
    if (localStorage.getItem('auth_exp')) {
      localStorage.removeItem('auth_exp');
      tokenExpired = true;
    }
    localStorage.clear();
    // @ts-ignore
    localStorage.setItem('WORKSPACE_UUID', workspaceUuid);
    // @ts-ignore
    localStorage.setItem('USER_WORKSPACES', workspaces);
    // localStorage.setItem('USER_WORKSPACES', workspaces);
    // localStorage.removeItem('ONBOARDING_DONE');
    // localStorage.removeItem('currentUser');
    // localStorage.removeItem('PLAN_SELECTED');
    // localStorage.removeItem('USER_WORKSPACES');
    // localStorage.removeItem('userDetails');
    // localStorage.removeItem('workspaceBillingContacts');
    // localStorage.removeItem('workspacePlan');
    // localStorage.removeItem('workspaceBillingInfo');

    // localStorage.removeItem('WORKSPACE_UUID');
    this.currentUserSubject.next(null);
    this.currentWorkspaceSubject.next(null);

    if (tokenExpired) {
      this.dataService.showLoading();
      location.reload();
    } else {
      // location.reload();
    }
  }

  register(firstname: any,lastname:any, phone: any, phoneCountry:any, email: any, password: any, passwordConfirm: any, languageUuid: string, invitation: string) {
    let body;
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', '*');
    let url;
    if (invitation) {
      body = {
        firstname,
        lastname,
        phone,
        phoneCountry,
        email,
        password,
        passwordConfirm,
        languageUuid
      };
      url = environment.baseUrl + '/user/register-invitation/' + invitation;
    } else {
      body = {
        firstname,
        lastname,
        phone,
        phoneCountry,
        email,
        password,
        passwordConfirm,
        languageUuid
      };
      url = environment.baseUrl + '/user/register';
    }

    return this.http.post<any>(url, body, {
      headers
    })
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  sendResetPasswordMail(email: any) {
    return this.http.post<any>(environment.baseUrl + '/user/reset-password', {email})
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }

  resetPassword(data: any, hash: string) {
    return this.http.patch<any>(environment.baseUrl + '/user/reset-password/' + hash, data)
      .pipe(
        map(res => {
            return res;
          },
          (err: any) => {
            return err;
          }
        )
      );
  }
}
