<div class="main-container">
    <div class="general-categories-header">
        <div class="title-container">
            <h2 class="gen-categ-header-title">{{'custom_poll_gen_categ_title' | translate}}</h2>
            <div class="question-mark-tip-container">
                <span><i class="bi bi-question-circle-fill me-1 text-warning"></i>{{('custom_poll_gen_categ_tip'|translate: {templateName: generalCategories[0].pollName})}}</span>
            </div>
            <!-- <div class="w-dl-questionmark-container">
                <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                <div class="workspace-dl-tooltip fl-tooltip">{{('custom_poll_gen_categ_tip'|translate: {templateName: generalCategories[0].pollName})}}</div>
            </div> -->
        </div>
        <div class="general-categories-body">
            <div class="categories-container">
                <form class="category-form" [formGroup]="categoriesForm">
                    <div class="category" [ngClass]="defaultCategory === index && !defaultCategoryStart ? 'active-category':''" 
                        *ngFor="let category of genCategories, let index = index" 
                        (click)="setCategoryStatements(index, category)">
                            <input class="category-check" type="checkbox" [checked]="selectedCategory[index]" (change)="onChange(category.uuid, $event);$event.stopPropagation()">
                            <span class="category-title">{{category?.category.title}}</span>
                            <i class="bi bi-chevron-right"></i>
                    </div>
                </form>
            </div>
            <div class="category-statements-container">
                <h2 class="category-statement-header">{{defaultCategoryTitle}}</h2>
                <div [ngStyle]="!last ? {'margin-bottom':'1.6rem'}:{}" [ngClass]="defaultCategoryStart ? 'default-statement-container':''" class="category-statement-container" *ngFor="let categoryStatement of categoryStatements, let last = last, let index = index">
                    <span *ngIf="defaultCategoryStart" class="default-statement-title">{{categoryStatement.question.title}}</span>
                    <p-accordion *ngIf="!defaultCategoryStart && categoryStatements.length >= 1 && (categoryStatement.question.extraQuestion || statementsExtraQuestions[index]?.length !== 0)" collapseIcon="bi bi-chevron-up" expandIcon="bi bi-chevron-down" (onOpen)="onOpenAccordion($event, index)">
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <span class="statement-title" [innerHTML]="categoryStatement.question.title" [ngClass]="defaultCategoryStart ? 'default-statement-title':''"></span>
                                <div class="quote" *ngIf="!defaultCategoryStart">
                                    <i class="bi bi-quote"></i>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="extra-question-container" *ngFor="let extraQuestion of statementsExtraQuestions[index]">
                                    <div class="quote">
                                        <i class="bi bi-quote"></i>
                                    </div>
                                    <span class="statement-title">{{extraQuestion.title}}</span>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>
                    <div class="d-flex align-items-center" [ngStyle]="{'gap':'.8rem'}" *ngIf="!defaultCategoryStart && categoryStatements.length >= 1 && (!categoryStatement.question.extraQuestion && statementsExtraQuestions[index]?.length === 0)">
                        <div class="quote" *ngIf="!defaultCategoryStart">
                            <i class="bi bi-quote"></i>
                        </div>
                        <span class="statement-title" [innerHTML]="categoryStatement.question.title" [ngClass]="defaultCategoryStart ? 'default-statement-title':''"></span>
                    </div>
                    
                </div>
                <!-- <span class="see-more-statements">{{'custom_poll_gen_categ_see_more' | translate}}</span> -->
            </div>
        </div>
    </div>
    <div class="general-categories-footer">
        <button class="general-categories-back-btn" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button class="general-categories-all-teams" 
            pButton type="button" 
            label="{{'custom_poll_gen_categ_assign_to_teams' | translate}}"
            [disabled]="categoriesForm.value.category.length === 0"
            (click)="openTeamsModal()"></button>
        <button class="general-categories-next" 
            pButton type="button" 
            label="{{'custom_poll_gen_categ_assign_to_all' | translate}}" 
            [disabled]="categoriesForm.value.category.length === 0"
            (click)="assignToAllTeams()"></button>
    </div>
    <app-choose-team-modal *ngIf="showTeamModal" [fromGeneral]="selectedTeams" [getOpenModal]="openTeamModal.asObservable()" (teamsSelected)="setSelectedTeams($event)"></app-choose-team-modal>
</div>
    