<div class="d-flex flex-column align-items-center congratulation-container" *ngIf="!loopError">
  <div class="d-flex flex-row align-items-center succesteam">
    <img class="logo" src="../../../assets/images/logo.png" alt="succesteam_logo">
    <h4 class="text-white fw-bold" style="font-size: 2.6rem">successteam</h4>
  </div>

    <img class="congrats-logo" src="../../../assets/images/congrats.png" alt="congratulations">
    <h1 class="mt-3 text-white fw-bold"  style="font-size: 4.5rem">{{'loop_started_title'| translate}}</h1>
    <p
      class="mt-1 fw-normal text-white mb-auto congrats-subtitle">
      {{'loop_started_subtitle' | translate}}
    </p>

    <p class="mb-5 text-white" style="font-size: 1.8rem;">{{'loop_started_redirect'| translate}} <a [routerLink]="'/'">{{'loop_started_redirect_button'| translate}}</a></p>

</div>

<div class="d-flex flex-column align-items-center congratulation-container" *ngIf="loopError">
  <div class="d-flex flex-row align-items-center succesteam">
    <img class="logo" src="../../../assets/images/logo.png" alt="succesteam_logo">
    <h4 class="text-white fw-bold" style="font-size: 2.6rem">successteam</h4>
  </div>

    <img class="congrats-logo" src="../../../assets/images/red-x-circle.svg" alt="congratulations">
    <h1 class="mt-3 text-white fw-bold"  style="font-size: 4.5rem">{{'loop_started_oops'| translate}}</h1>

    <p class="mt-1 fw-normal text-white mb-auto congrats-subtitle">
      {{'loop_started_oops_error'| translate}}
    </p>

</div>
