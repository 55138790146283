import {LabelType, Options} from '@angular-slider/ngx-slider';
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EChartsOption} from 'echarts';
import {Subscription} from 'rxjs';
import {ModalsComponent} from 'src/app/components/modals/modals.component';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {ChallengesService} from 'src/app/services/challenges.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {Block} from 'notiflix/build/notiflix-block-aio';
import demo from "../../../assets/data/demo.json"
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workspace-overview',
  templateUrl: './workspace-overview.component.html',
  styleUrls: ['./workspace-overview.component.scss']
})
export class WorkspaceOverviewComponent implements OnInit {
  knobProgress: number = 0;
  workspaceTeams: any = [];
  showIndicators: boolean = false;
  dropdownOptions: { name: string, value: number }[];
  teamSortType = '';
  teamScoreSelector = 0;

  optionPie: EChartsOption = {
    tooltip: {
      trigger: 'none'
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: '50%',
        avoidLabelOverlap: false,
        label: {
          show: true,
        },

        color: ['#F78C02', '#1AC487'],
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {value: 0, name: 'Pending'},
          {value: 0, name: 'Active'}
        ]
      }
    ]
  };
  chartOption: EChartsOption = {
    xAxis: {
      type: 'category',
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
      data: [],
    },
    yAxis: {
      type: 'value',
      max: 100,
      min: 0,
      axisLabel: {
        color: '#000000',
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: 'axis'
    },
    series: [
      {
        name: 'Success Score',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        type: 'line',
        symbolSize: 10,
        smooth: true,
        color: '#19AEFF',
        // emphasis: {
        //   focus: 'series'
        // },
        tooltip: {
          trigger: 'item',
        },
      },
      {
        name: 'Response Rate',
        label: {
          // @ts-ignore
          normal: {
            show: false,
            position: 'top'
          },
        },
        data: [],
        tooltip: {
          trigger: 'axis',
        },
        type: 'line',
        symbolSize: 0,
        lineStyle: {
          color: '#FF764C',
          width: 2,
        },
        smooth: true,
        color: '#FF764C',
        // emphasis: {
        //   focus: 'series'
        // },
      },
    ],
  };
  indicator = {
    successteamScore: '../../../assets/svg/overall-white.svg',
    successteamScoreChart: '../../../assets/svg/overall-lightblue.svg',
    performance: '../../../assets/svg/performance-white.svg',
    performanceChart: '../../../assets/svg/performance-icon-lightblue.svg',
    motivation: '../../../assets/svg/motivation-white.svg',
    motivationChart: '../../../assets/svg/motivation-icon-lightblue.svg',
    satisfaction: '../../../assets/svg/satisfaction-white.svg',
    satisfactionChart: '../../../assets/svg/satisfaction-icon-lightblue.svg',
    stress: '../../../assets/svg/stress-white.svg',
    stressChart: '../../../assets/svg/stress-icon-lightblue.svg'
  };

  indicatorBackground = {
    Performance: this.data.colors.greyGradient,
    Motivation: this.data.colors.greyGradient,
    Satisfaction: this.data.colors.greyGradient,
    Stress: this.data.colors.greyGradient,
  };

  showPie: boolean = true;
  members = {
    total: 0,
    active: 0,
    pending: 0
  }
  totalTeams = 0;
  showTrends: boolean = false;
  chartType: string = '';
  showChart: boolean = true;

  scoreColorInterval = {
    "red": {"min": 0, "max": 39},
    "orange": {"min": 40, "max": 59},
    "yellow": {"min": 60, "max": 79},
    "green": {"min": 80, "max": 100}
  };
  knobColor: string = this.data.colors.grey;

  workspaceResults: any = {
    latestReport: {
      Motivation: 0,
      Performance: 0,
      Satisfaction: 0,
      Stress: 0,
      responseRate: 0,
      workspaceSuccessteamScore: 0
    },
    previousReport: {
      Motivation: 0,
      Performance: 0,
      Satisfaction: 0,
      Stress: 0,
      responseRate: 0,
      workspaceSuccessteamScore: 0
    }
  };
  private graph: any;
  public graphType = {
    type: '',
    title: '',
    showResponse: true
  };
  public gotAlert: boolean = false;
  public workspaceAlerts: any;
  language = '';
  eligibleForTrends: any;
  workspaceTrends: any;
  trendsProgressBarWidth: any;
  selectedTrendDate: any;
  progressBarPercentage: any;
  trendReportDaysRemaining: any;
  hideCover: boolean = false;
  startDateForNextReport: any;

  constructor(private activeRoute: ActivatedRoute, public data: DataManipulationService, public router: Router,
              public challengesService: ChallengesService, public modal: ModalsComponent,
              public api: ApiwrapperService, private translate: TranslateService) {
                this.dropdownOptions = [
                  {name: this.translate.instant('wo_sort_today'), value: 0},
                  {name: this.translate.instant('wo_sort_30_ago'), value: 30},
                  {name: this.translate.instant('wo_sort_90_ago'), value: 90}
                ];
  }

  ngOnInit() {
    //@ts-ignore
    this.language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
    this.getColors();
    this.teamSort('score');
    this.getWorkspaceMembers();
    this.getWorkspaceIndicators();
    this.getWorkspaceGraph();
    this.getWorkspaceTrends();
    this.getWorkspaceAlters();
    this.data.showLoading();
  }

  getColors() {
    this.api.getIndicatorInterval().subscribe(
      res => {
        //@ts-ignore
        res.forEach(el => {
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].min = el.min;
          // @ts-ignore
          this.scoreColorInterval[el.indicatorColor.color].max = el.max;
        });
      }
    );
  }

  setKnobProgressBar() {
    if (document.getElementById("svgProgress")) {
      this.knobProgress = Math.round((184 - (184 * this.workspaceResults.latestReport.workspaceSuccessteamScore) / 100));
      //@ts-ignore
      document.getElementById("svgProgress").style.strokeDashoffset = this.knobProgress.toString();
    } else {
      setTimeout(() => {
        this.setKnobProgressBar()
      }, 200);
    }
  }

  getWorkspaceIndicators() {
    this.api.getWorkspaceIndicators().subscribe(
      res => {
        // get the workspace indicators data
        this.workspaceResults = res;
        this.sortColors();
      }
    )
  }

  sortColors() {
    Object.keys(this.workspaceResults.latestReport).forEach((el: any, index) => {
      Object.keys(this.scoreColorInterval).forEach((color: any) => {
        // @ts-ignore
        const interval = this.scoreColorInterval[color];
        const value = this.workspaceResults.latestReport[el];
        if (value >= interval.min && value <= interval.max) {
          if (el === 'workspaceSuccessteamScore') {
            // @ts-ignore
            this.knobColor = this.data.colors['' + color];
          } else {
            // @ts-ignore
            this.indicatorBackground['' + el] = this.data.colors['' + color + 'Gradient'].toString();
          }
        } else if (value === 0) {
          // @ts-ignore
          this.indicatorBackground['' + el] = this.data.colors['greyGradient'].toString();
          // @ts-ignore
        }
        
      })
    });
    this.showIndicators = true;
    this.data.hideLoading();
  }

  getWorkspaceGraph() {
    this.api.getWorkspaceGraph().subscribe(
      res => {
        this.graph = res;
        this.changeChart('score', 'Success Score', true);
      }
    )
  }

  getWorkspaceTrends() {
    this.api.getWorkspaceTrends().subscribe(
      res => {
        this.workspaceTrends = res;
        if(res.latestTrendReport) {
          this.workspaceTrends.latestTrendReport.botTrends.sort((a:any,b:any) => (a.score > b.score ? 1 : -1));
          this.workspaceTrends.dates = [{date: formatDate(res.latestTrendReport.startDate.date, 'dd.MM.yyyy', 'en-US') + ' - ' + formatDate(res.latestTrendReport.endDate.date, 'dd.MM.yyyy', 'en-US')}];
          this.hideCover = true;
          this.showTrends = true;
        } else if(res.latestTrendReport === null && res.nextTrendReport){
          this.getTrendsProgressAndDates(res.nextTrendReport.date);
          this.eligibleForTrends = res.eligibleForNextReport;
          this.workspaceTrends['latestTrendReport']['topTrends'] = demo.trends.topTrends;
          this.workspaceTrends['latestTrendReport']['botTrends'] = demo.trends.botTrends;
          this.workspaceTrends.dates = [{date: formatDate(new Date(), 'dd.MM.yyyy', 'en-US') + ' - ' + formatDate(res.nextTrendReport.date, 'dd.MM.yyyy', 'en-US')}];
          this.hideCover = false;
          this.showTrends = true;
        } else {
          this.showTrends = false;
        }
        console.log(this.workspaceTrends);
        
      }
    );
  }

  getTrendsProgressAndDates(endDate: Date) {
    const today = new Date().getTime();
    const end = new Date(endDate).getTime();
    this.trendReportDaysRemaining = Math.floor((end - today) / 1000 / 60 / 60 / 24);
    this.progressBarPercentage = Math.floor(((90 - this.trendReportDaysRemaining) / 90) * 100) + '%';
    this.startDateForNextReport = new Date(new Date().setDate(new Date().getDate() - (90 - this.trendReportDaysRemaining)));
  }

  getTrendDate(event: any) {
    
  }

  changeChart(type: string, title: string, showResponse: boolean) {
    const lang = this.language.toLowerCase() === 'en_us' ? 'EN':'DK';
    switch(title) {
      case 'Satisfaction':
        title = lang === 'EN' ? 'Satisfaction':'Tilfredshed';
        break;
      case 'Stress Free':
        title = lang === 'EN' ? 'Stress Free':'Stressfri';
        break;
    }
    this.graphType = {type: type, title: title, showResponse: showResponse}
    // @ts-ignore
    this.chartOption.series[0].name = title;
    // @ts-ignore
    this.chartOption.series[1].name = lang === 'EN' ? 'Response Rate':'Svarprocent';
    // @ts-ignore
    this.chartOption.series[0].data = [];
    // @ts-ignore
    this.chartOption.series[1].data = [];
    // @ts-ignore
    this.chartOption.xAxis.data = [];
    this.graph.forEach((el: any) => {
      // @ts-ignore
      this.chartOption.xAxis.data.push(formatDate(el.created.date, 'dd MMM yyyy', 'en-US').slice(0, 6) + '');

      // @ts-ignore
      this.chartOption.series[0].data.push({value: el[type], name: title});
      if (showResponse) {
        // @ts-ignore
        this.chartOption.series[1].data.push({value: el.responseRate, name: lang === 'EN' ? 'Response Rate':'Svarprocent'});
      }
    })
    this.showChart = !this.showChart;
  }

  getWorkspaceMembers() {
    this.api.getWorkspaceUsersStatistic().subscribe(
      res => {
        this.members.total = res.totalMembers;
        this.members.active = res.activeMembers;
        this.members.pending = res.inactiveMembers;
        // @ts-ignore
        this.optionPie.series[0].data[0].value = this.members.pending;
        // @ts-ignore
        this.optionPie.series[0].data[1].value = this.members.active;
        this.showPie = !this.showPie;
      }
    )
  }

  teamSort(type: string) {
    Block.circle('#teamsRanking', {
      svgColor: 'rgba(0,189,130)'
    });
    // this.startDate, 'dd-MM-yyyy', 'en-US'), formatDate(this.endDate, 'dd-MM-yyyy', 'en-US')
    this.teamSortType = type;
    let endDate;
    if(this.teamScoreSelector !== 0) {
      let now = new Date();
      endDate = formatDate(new Date(now.setDate(now.getDate() - this.teamScoreSelector)), 'dd-MM-yyyy', 'en-US');
    } else {
      endDate = formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
    }
    
    this.api.getTeamsRanking(type, endDate).subscribe(
      res => {
        this.totalTeams = res.totalTeams;
        // this.workspaceTeams = res;
        if (res.botTeam !== null) {
          let teams = res.topTeams.concat(res.botTeam)
          // this.workspaceTeams.sort((a: any, b: any) => (a.teamReport.responseRate > b.teamReport.responseRate ? -1 : 1));
          teams.forEach((el: any) => {
            switch (type) {
              case  'score':
                el.reporting = el.teamReport.teamSuccessteamScore
                break;
              case  'responseRate':
                el.reporting = el.teamReport.responseRate
                break
              case  'LeadershipScore':
                el.reporting = el.teamReport.LeadershipScore
                break
              case  'TeamScore':
                el.reporting = el.teamReport.TeamScore
                break
            }
          })
          this.workspaceTeams = teams;
        }
        Block.remove('#teamsRanking');
      }, err => {
        console.log(err)
      })
  }

  private getWorkspaceAlters() {
    this.api.getWorkspaceAlerts().subscribe(
      res => {
        this.gotAlert = true;
        // @ts-ignore
        this.workspaceAlerts = res;
      }
    )
  }

  goToWorkspaceMembers() {
    this.data.settingsWorkspaceMembers = true;
    this.router.navigate(['/settings/workspace']);
  }

  getStatementsSelector(value: any) {
    this.teamScoreSelector = value.value;
    this.teamSort(this.teamSortType);
  }
}
