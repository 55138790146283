<div style="background-color: #F0F3FA;">
  <div style="background-color:#00194b ">
    <div style="border-radius: 35px 0 0 0;
    background-color: #FFFFFF;">
      <div class="workspace-overview-header">
      <h2 class="workspace-overview-title">{{'wo_overview'|translate}}</h2>
      <div class="generate-report-container">
        <i class="bi bi-file-earmark-pdf-fill pdf-icon"></i>
        <span pTooltip="{{'wo_tooltip_coming_soon' | translate}}" tooltipStyleClass="tooltip-style" tooltipPosition="bottom">{{'wo_generate_report'| translate}}</span>
      </div>
      </div>
    </div>
  </div>

  <div class="workspace-overview-body">
    <div class="row alert-space" style="justify-content: space-between">
      <div class="col-md-6 workspace-alert"  *ngIf="gotAlert" 
        [style]="(workspaceAlerts.sign === 'neutral') ? 'background-color: rgba(37, 81, 191, 0.56)' : 
          (workspaceAlerts.sign === 'negative') ? 'background-color: rgba(246, 66, 67, 0.56)':
          (workspaceAlerts.sign === 'positive') ? 'background-color: rgba(62, 207, 154, 0.56)':''">
        <div style="display: flex">
          <div class="thermometer-icons">
            <i class="bi bi-thermometer-high" 
              [style]="(workspaceAlerts.sign === 'neutral') ? 'color: #2551BF' : 
              (workspaceAlerts.sign === 'negative') ? 'color: #F64243':
              (workspaceAlerts.sign === 'positive') ? 'color: #3ECF9A':''"></i>
          </div>
          <div style="color: white; padding-left: 2rem">
            <h2>{{workspaceAlerts.title}}</h2>
            <p class="alert-description" style="font-size: 1.6rem;" [innerHTML]="workspaceAlerts.description"></p>
          </div>
        </div>
      </div>
      <div class="col-md-6 workspace-tips" style="display: flex; justify-content: space-between">
        <div style="display: flex">
          <img src="../../../assets/svg/tips_icon.svg" alt="" style="height: 4.5rem">
          <div style="color: white; padding-left: 2rem; color: black">
            <h2>{{'wo_tip_title' | translate}}</h2>
            <p style="font-size: 1.6rem;">{{'wo_tip_desc'| translate}}</p>
          </div>
          </div>
          <div>
            <a href="https://successteamweb.crunch.help/en/best-practice/5-types-of-recognition" target="_blank"
               style="display: block; padding: 3rem;font-size: 14px;">{{'wo_tip_button'| translate}}</a>
          </div>
      </div>
    </div>
    <div class="workspace-overall-container">
      <div class="overall-results-container">
        <div>
          <h2 style="font-weight: 400;">{{"wo_results" | translate}}</h2>
          <div class="workspace-succes-score">
            <h2 class="wss-title">{{'team_score' | translate}}</h2>
            <div class="wss-right">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="20 7 100 110" version="1.1" width="260px" height="260px">
                <path class="grey" d="M40,90
                    A40,40 0 1,1 100,90"
                      style="fill:none;"/>
                <path [attr.stroke]="knobColor" [attr.stroke-dasharray]="setKnobProgressBar()" id="svgProgress"
                      class="progress" d="M40,90
                    A40,40 0 1,1 100,90"
                      style="fill:none;"/>
                <path *ngIf="true"
                      [ngClass]="(workspaceResults.latestReport.workspaceSuccessteamScore > workspaceResults.previousReport.workspaceSuccessteamScore) ?
                        'svgArrowUp' : (workspaceResults.latestReport.workspaceSuccessteamScore < workspaceResults.previousReport.workspaceSuccessteamScore) ? 'svgArrowDown' : 'svgArrowEven'"
                      d="M15.358,9.708l.837-.837a.9.9,0,0,0,0-1.278L8.871.266a.9.9,0,0,0-1.278,0L.266,7.593a.9.9,0,0,0,0,1.278l.837.837A.906.906,0,0,0,2.4,9.693L6.723,5.151V15.984a.9.9,0,0,0,.9.9H8.833a.9.9,0,0,0,.9-.9V5.151l4.327,4.542A.9.9,0,0,0,15.358,9.708Z"></path>
                <text class="svgTextValue"
                      [attr.x]="62"
                      y="70">{{workspaceResults.latestReport.workspaceSuccessteamScore}}</text>
                <text class="svgTextPercentage"
                      [attr.x]="83"
                      y="70">%
                </text>
              </svg>
            </div>
            <div class="wss-left">
              <div class="wss-last-collector">
                  <span
                    *ngIf="workspaceResults.latestReport.workspaceSuccessteamScore > workspaceResults.previousReport.workspaceSuccessteamScore">
                    <img style="margin-bottom: .4rem;"
                         src="../../../assets/svg/indicator-arrow-up.svg"><b>{{(this.workspaceResults.latestReport.workspaceSuccessteamScore - this.workspaceResults.previousReport.workspaceSuccessteamScore) + '% '}}</b>{{'workspace_score_change'|translate}}</span>
                <span
                  *ngIf="workspaceResults.latestReport.workspaceSuccessteamScore < workspaceResults.previousReport.workspaceSuccessteamScore">
                    <img style="margin-bottom: .4rem;"
                         src="../../../assets/svg/indicator-arrow-down.svg"><b>{{(this.workspaceResults.latestReport.workspaceSuccessteamScore - this.workspaceResults.previousReport.workspaceSuccessteamScore) + '% '}}</b>{{'workspace_score_change'|translate}}</span>
                <span
                  *ngIf="workspaceResults.latestReport.workspaceSuccessteamScore === workspaceResults.previousReport.workspaceSuccessteamScore"><i
                  [style]="{'color':'#5e5e5e'}" class="bi bi-dash-lg"> </i> 0% {{'workspace_score_change'|translate}}</span>
              </div>
            </div>
          </div>
          <div class="teams-indicators-percentages">
            <div class="row mt-4" style="margin-top: 4rem !important;">
              <div
                [ngStyle]="{'background-color' : '#F0F3FA', 'border': '1px solid #E4EDFE' }"
                class="team-indicators-values col-lg-6 col-md-12">
                <img
                  [ngStyle]="(indicator['performance'] == '../../../assets/svg/performance-white.svg')? {'background-image' : indicatorBackground.Performance } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground.Performance +' solid' }"
                  [src]="indicator['performance']" class="indicators-icon-large">
                <span class="indicators-title">{{"team_performance"| translate}}</span>
                <div
                  [ngStyle]="(indicator['performance'] == '../../assets/svg/performance-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                  class="indicators-percentage-container col-lg-6 col-md-12">
                  <span class="percentage-value">{{workspaceResults.latestReport.Performance}}</span>
                  <span class="percentage">%</span>
                  <img class="indicator-arrow-img" [src]="workspaceResults.latestReport.Performance > workspaceResults.previousReport.Performance ? '../../../assets/svg/indicator-arrow-up.svg':
                    workspaceResults.latestReport.Performance < workspaceResults.previousReport.Performance ? '../../../assets/svg/indicator-arrow-down.svg':''">
                  <i *ngIf="workspaceResults.latestReport.Performance != 0"
                     [ngClass]="(workspaceResults.latestReport.Performance === workspaceResults.previousReport.Performance) ? 'bi bi-dash-lg even':''"
                     class="indicator-status-arrow">
                  </i>
                </div>
              </div>
              <div
                [ngStyle]="{'background-color' : '#F0F3FA', 'border': '1px solid #E4EDFE' }"
                class="team-indicators-values col-lg-6 col-md-12">
                <img
                  [ngStyle]="(indicator['satisfaction'] == '../../../assets/svg/satisfaction-white.svg')? {'background-image' : indicatorBackground.Satisfaction} : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground.Satisfaction+' solid' }"
                  [src]="indicator['satisfaction']" class="indicators-icon-large">
                <span class="indicators-title">{{'team_satisfaction'| translate}}</span>
                <div
                  [ngStyle]="(indicator['satisfaction'] == '../../assets/svg/satisfaction-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                  class="indicators-percentage-container col-lg-6 col-md-12">
                  <span class="percentage-value">{{workspaceResults.latestReport.Satisfaction}}</span>
                  <span class="percentage">%</span>
                  <img class="indicator-arrow-img" [src]="workspaceResults.latestReport.Satisfaction > workspaceResults.previousReport.Satisfaction ? '../../../assets/svg/indicator-arrow-up.svg':
                    workspaceResults.latestReport.Satisfaction < workspaceResults.previousReport.Satisfaction ? '../../../assets/svg/indicator-arrow-down.svg':''">
                  <i *ngIf="workspaceResults.latestReport.Satisfaction != 0"
                     [ngClass]="(workspaceResults.latestReport.Satisfaction === workspaceResults.previousReport.Satisfaction) ? 'bi bi-dash-lg even':''"
                     class="indicator-status-arrow">
                  </i>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div
                [ngStyle]="{'background-color' : '#F0F3FA', 'border': '1px solid #E4EDFE' }"
                class="team-indicators-values col-lg-6 col-md-12">
                <img
                  [ngStyle]="(indicator['motivation'] == '../../../assets/svg/motivation-white.svg')? {'background-image' : indicatorBackground.Motivation } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground.Motivation+' solid' }"
                  [src]="indicator['motivation']" class="indicators-icon-large">
                <span class="indicators-title">{{'team_motivation'|translate}}</span>
                <div
                  [ngStyle]="(indicator['motivation'] == '../../assets/svg/motivation-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                  class="indicators-percentage-container col-lg-6 col-md-12">
                  <span class="percentage-value">{{workspaceResults.latestReport.Motivation}}</span>
                  <span class="percentage">%</span>
                  <img class="indicator-arrow-img" [src]="workspaceResults.latestReport.Motivation > workspaceResults.previousReport.Motivation ? '../../../assets/svg/indicator-arrow-up.svg':
                    workspaceResults.latestReport.Motivation < workspaceResults.previousReport.Motivation ? '../../../assets/svg/indicator-arrow-down.svg':''">
                  <i *ngIf="workspaceResults.latestReport.Motivation != 0"
                     [ngClass]="(workspaceResults.latestReport.Motivation === workspaceResults.previousReport.Motivation) ? 'bi bi-dash-lg even':''"
                     class="indicator-status-arrow">
                  </i>
                </div>
              </div>
              <div
                [ngStyle]="{'background-color' : '#F0F3FA', 'border': '1px solid #E4EDFE' }"
                class="team-indicators-values col-lg-6 col-md-12">
                <img
                  [ngStyle]="(indicator['stress'] == '../../../assets/svg/stress-white.svg')? {'background-image' : indicatorBackground.Stress } : {'background-color' : data.colors.white, 'border': '2px '+indicatorBackground.Stress+' solid' }"
                  [src]="indicator['stress']" class="indicators-icon-large">
                <span class="indicators-title">{{'team_stress_free'|translate}}</span>
                <div
                  [ngStyle]="(indicator['stress'] == '../../assets/svg/stress-white.svg')? {'background-color' : '#F0F3FA' } : {'background-color' : data.colors.white}"
                  class="indicators-percentage-container col-lg-6 col-md-12">
                  <span class="percentage-value">{{workspaceResults.latestReport.Stress}}</span>
                  <span class="percentage">%</span>
                  <img class="indicator-arrow-img" [src]="workspaceResults.latestReport.Stress > workspaceResults.previousReport.Stress ? '../../../assets/svg/indicator-arrow-up.svg':
                    workspaceResults.latestReport.Stress < workspaceResults.previousReport.Stress ? '../../../assets/svg/indicator-arrow-down.svg':''">
                  <i *ngIf="workspaceResults.latestReport.Stress != 0"
                     [ngClass]="(workspaceResults.latestReport.Stress === workspaceResults.previousReport.Stress) ? 'bi bi-dash-lg even':''"
                     class="indicator-status-arrow">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-of-improvements">
        <h2 style="font-weight: 400;">{{'wo_progress_chart'| translate}}</h2>
        <div class="workspace-chart-main-container">
          <div class="team-chart-header">
            <button style="padding: 2.05rem 2.25rem;" pButton type="button" label="{{'all'|translate}}"
                    class="indicator-button"
                    [ngClass]="graphType.title === 'Success Score' ? 'indicator-button-active':''"
                    (click)="changeChart('score', 'Success Score', graphType.showResponse)"></button>
            <!-- <button pTooltip="Success Score" tooltipPosition="bottom" tooltipStyleClass="tooltip-style" class="indicator-button">
              <img class="indicator-button-icon" alt="logo"
                   [src]="indicator['successteamScoreChart']"/>
            </button> -->

            <button pTooltip="{{'team_performance'| translate }}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style" pButton
                    [ngClass]="graphType.title === 'Performance'? 'indicator-button-active':''"
                    class="indicator-button"
                    (click)="changeChart('performanceScore', 'Performance', graphType.showResponse)">
              <img class="indicator-button-icon" alt="logo"
                   [src]="graphType.title === 'Performance' ? indicator['performance'] : indicator['performanceChart']"/>
            </button>
            <button pTooltip="{{'team_satisfaction'| translate }}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style" pButton
                    [ngClass]="graphType.title === 'Satisfaction' || graphType.title === 'Tilfredshed' ? 'indicator-button-active':''"
                    class="indicator-button"
                    (click)="changeChart('satisfactionScore', 'Satisfaction', graphType.showResponse)">
              <img class="indicator-button-icon" alt="logo"
                   [src]="graphType.title === 'Satisfaction' || graphType.title === 'Tilfredshed' ? indicator['satisfaction'] : indicator['satisfactionChart']"/>
            </button>
            <button pTooltip="{{'team_motivation'| translate }}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style" pButton
                    [ngClass]="graphType.title === 'Motivation' ? 'indicator-button-active':''"
                    class="indicator-button"
                    (click)="changeChart('motivationScore', 'Motivation', graphType.showResponse)">
              <img class="indicator-button-icon" alt="logo"
                   [src]="graphType.title === 'Motivation' ? indicator['motivation'] : indicator['motivationChart']"/>
            </button>
            <button pTooltip="{{'team_stress_free'| translate }}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style" pButton
                    [ngClass]="graphType.title === 'Stress Free' || graphType.title === 'Stressfri' ? 'indicator-button-active':''"
                    class="indicator-button"
                    (click)="changeChart('stressScore', 'Stress Free', graphType.showResponse)">
              <img class="indicator-button-icon" alt="logo"
                   [src]="graphType.title === 'Stress Free' || graphType.title === 'Stressfri' ? indicator['stress'] : indicator['stressChart']"/>
            </button>
            <button pTooltip="{{'review_response'| translate}}" tooltipPosition="bottom" tooltipStyleClass="tooltip-style"
                    [ngClass]="graphType.showResponse ? 'indicator-button-active-score':'inactive'"
                    style="margin-left: auto; padding: .9rem;" class="indicator-button-score"
                    (click)="changeChart(graphType.type, graphType.title, !graphType.showResponse)">
              <img alt="logo" class="indicator-button-icon" style="height: 4rem; width: 4rem;"
                   [src]="graphType.showResponse ? '../../../assets/svg/response-rate-white.svg' : '../../../assets/svg/response-rate.svg'"/>
            </button>
          </div>
          <div *ngIf="showChart" echarts [options]="chartOption" [merge]="chartOption"
               class="chart chart-container line"></div>
          <div *ngIf="!showChart" echarts [options]="chartOption" [merge]="chartOption"
               class="chart chart-container line"></div>
        </div>
      </div>
    </div>
    <div [ngClass]="hideCover ? 'teams-trends-container':'teams-trends-container not-eligible'" *ngIf="showTrends">
      <div *ngIf="!hideCover" class="trends-blur-section">
        <span *ngIf="!eligibleForTrends" style="display: flex; flex-direction: column; align-items: center;" [innerHTML]="('wo_trends_not_eligible_title' | translate : {days: trendReportDaysRemaining})"></span>
        <span *ngIf="eligibleForTrends" style="display: flex; flex-direction: column; align-items: center;" [innerHTML]="('wo_trends_eligible_title' | translate : {days: trendReportDaysRemaining})"></span>
        <div style="display: flex; flex-direction: column;">
          <div class="progress trends-progress-bar">
            <div [ngStyle]="{'width': progressBarPercentage}" class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="90"></div>
          </div>
          <div class="d-flex align-items-center">
            <span style="font-size: 1.6rem; margin: 1rem auto 0 -5rem;">{{startDateForNextReport  | date : 'MMMM d'}}</span>
            <span style="font-size: 1.6rem; margin: 1rem -4rem 0 auto;">{{workspaceTrends.nextTrendReport.date  | date : 'MMMM d'}}</span>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h2 style="font-weight: 400;">{{"wo_trends_title"| translate}}</h2>
        <div class="trends-dates-dropdown">
          <p-dropdown [options]="workspaceTrends?.dates" (onChange)="getTrendDate($event)"
             dropdownIcon="bi bi-caret-down-fill" optionLabel="date"></p-dropdown>
         </div>
      </div>
      <div class="trends-container">
        <div class="good-trends-container">
          <h2>{{"wo_trends_left_title"| translate}}</h2>
          <div *ngFor="let e of workspaceTrends.latestTrendReport.topTrends; let first = first; let last = last" class="good-trend"
               [ngStyle]="first ? {'margin':'1.4rem 0 0 0'} : {'margin':'1.4rem 0 0 0'}"
               pTooltip="{{e.category.description}}" [escape]="false" tooltipPosition="bottom" tooltipStyleClass="trends-tooltip">
            <img src="../../../assets/svg/green_thumbs_up.svg">
            <span style="margin-top: .4rem;">{{e.category.title}}</span>
            <i class="bi bi-lightning-charge-fill" style="color: #BEC8DD; margin: .4rem 1rem 0 auto;"></i>
          </div>
        </div>
        <div class="improve-trends-container">
          <h2>{{"wo_trends_right_title"| translate}}</h2>
          <div *ngFor="let e of workspaceTrends.latestTrendReport.botTrends; let first = first; let last = last;let i = index"
               class="improve-trend" [escape]="false" pTooltip="{{e.category.description}}" tooltipPosition="bottom" tooltipStyleClass="trends-tooltip"
               [ngStyle]="first ? {'margin':'1.4rem 0 0 0'} : {'margin':'1.4rem 0 0 0'}">
            <i class="bi bi-exclamation-circle-fill" style="color: #FF9300; font-size: 2.1rem"></i>
            <span>{{e.category.title}}</span>
            <!-- <div *ngIf="!last && i >= 1" class="actions-tag">
              <p style="margin-bottom: 0;">{{i}} {{i === 1 ? 'action' : 'actions' }} </p>
              <i class="bi bi-lightning-charge-fill" style="font-size: 1.6rem;color: #2551BF;margin-left: .7rem;"></i>
            </div> -->
            <i class="bi bi-lightning-charge-fill"
               style="color: #BEC8DD; margin: .4rem 1rem 0 auto;"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="teams-container">
      <div class="teams-scores-container" id="teamsRanking">
        <h2
          style="font-weight: 400;">{{totalTeams + ' ' +  (totalTeams > 1 ? ('teams'| translate) : ('team'| translate))}}</h2>
        <div style="display: flex; justify-content: space-between;">
          <div class="teams-scores-sorting">
            <p-button label="{{'wo_sort_scores' | translate}}" class="score-button" (click)="teamSort('score')"></p-button>
            <p-button label="{{'wo_sort_activity' | translate}}" class="score-button" (click)="teamSort('responseRate')"></p-button>
            <p-button label="{{'wo_sort_leadership' | translate}}" class="score-button" (click)="teamSort('LeadershipScore')"></p-button>
            <p-button label="{{'wo_sort_team_culture' | translate}}" class="score-button" (click)="teamSort('TeamScore')"></p-button>
          </div>
          <div class="teams-scores-sorting">
           <p-dropdown [options]="dropdownOptions" (onChange)="getStatementsSelector($event.value)"
                      class="score-dropdown" dropdownIcon="bi bi-caret-down-fill" optionLabel="name"></p-dropdown>
          </div>
        </div>
        <div class="teams-scores-ranking">
          <div class="scores-header-row">
            <span style="margin: 0 2.2rem 0 3rem;">#</span>
            <span>Team Name</span>
            <span style="margin: 0 3rem 0 auto;">Score</span>
          </div>
          <div class="team-ranking">
            <div *ngFor="let team of workspaceTeams; let first = first; let last = last; let i = index"
                 class="team-placement-container" [routerLink]="'/team/'+team['uuid']">
              <i *ngIf="last" class="bi bi-three-dots-vertical dots"></i>
              <div *ngIf="i+1 <= 3 || last" class="team-placement"
                   [ngStyle]="first ? {'margin':'0 0 1rem 0'} : i+1 === 3 ? {'margin':'1rem 0 0 0'} : {}">
                <div [ngStyle]="last ? {'background-color':'#EE8702'} : {}" class="placement-number">{{(last ? totalTeams : (i + 1))}}</div>
                <span>{{team.name}}</span>
                <span style="margin-left: auto;">{{team.reporting}}%</span>
                <i style="font-size: 2.6rem; color: #B9C1D5;" class="bi bi-arrow-up-short"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="button-container" style="margin: 4.1rem 0 0 0">
          <p-button label="{{'teams_overview'| translate}}" icon="bi bi-arrow-right" iconPos="right"
                    [routerLink]="'/teams-overview'"></p-button>
        </div>
      </div>
      <div class="teams-chart-container">
        <h2
          style="font-weight: 400;">{{members.total}} {{'people' | translate}}</h2>
        <div echarts *ngIf="showPie" [options]="optionPie" [merge]="optionPie" class="chart chart-container pie"></div>
        <div echarts *ngIf="!showPie" [options]="optionPie" [merge]="optionPie" class="chart chart-container pie"></div>
        <div class="workspace-team-count">
          <i class="bi bi-person-fill person-icon" style="color: #2551BF;"></i>
          <span>{{'total' | translate}}</span>
          <span style="margin-left: auto;">{{members.total}}</span>
        </div>
        <div class="workspace-team-count">
          <i class="bi bi-person-fill person-icon" style="color: #1AC487;"></i>
          <span>{{'active' | translate}}</span>
          <span style="margin-left: auto;">{{members.active}}</span>
        </div>
        <div class="workspace-team-count">
          <i class="bi bi-person-fill person-icon" style="color: #F78C02;"></i>
          <span>{{'pending' | translate}}</span>
          <span style="margin-left: auto;">{{members.pending}}</span>
        </div>
        <div class="button-container" style="margin: 4.1rem 0 0 0">
          <p-button label="{{'manage_wo'|translate}}" icon="bi bi-arrow-right" iconPos="right" (click)="goToWorkspaceMembers()"></p-button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- /team/question-collection  questionCollections-->
