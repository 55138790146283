<button type="button" id="chooseTeamsModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#chooseTeamsModal">
</button>  
  <!-- Modal -->
<div class="modal fade" id="chooseTeamsModal" tabindex="-1" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content content-box">
        <div class="modal-header choose-teams-header">
            <img (click)="closeModal()" src="assets/svg/close-button.svg" id="closeModal" alt="X" data-bs-dismiss="modal"
             aria-label="Close" class="close-btn">
        </div>
        <div class="modal-body choose-teams-body">
            <h2 class="modal-title">{{'custom_poll_assign_modal_title' | translate}}</h2>
            <div class="d-flex align-items-center mb-5">
              <input class="team-check" type="checkbox" [checked]="isAllTeams" (change)="selectAllTeams($event)">
              <span class="select-all">{{'custom_poll_assign_modal_select_all' | translate}}</span>
            </div>
            <form class="team-form-container" [formGroup]="teamsForm">
                <div [ngStyle]="!last ? {'margin': '0 1.7rem 1.7rem 0'}:{}" class="team-container" *ngFor="let team of teamsToChoose, let index = index, let last = last">
                    <input class="team-check" type="checkbox" [checked]="selectedTeams[index]" (change)="onChange(team.uuid, $event)">
                    <span class="team-name">{{team.name}}</span>
                </div>
            </form>
        </div>
        <div class="modal-footer choose-teams-footer">
            <button class="assign-btn" pButton type="button" (click)="assignTeams()" label="{{fromGeneral ? ('custom_poll_assign_modal_confirm_btn' | translate):('step2_choice2_confirm' | translate)}}"></button>
        </div>
      </div>
    </div>
</div>