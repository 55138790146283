<div class="d-flex flex-column align-items-center email-confirmation-container">
    <div class="d-flex flex-row align-items-center succesteam" style="cursor: pointer" [routerLink]="'/login'">
        <img class="logo" src="../../../assets/images/logo.png" alt="succesteam_logo">
        <h4 class="text-white fw-bold" style="font-size: 2.6rem">successteam</h4>
    </div>
    <img class="email-sent-logo" src="../../../assets/images/email_sent.png" alt="email_sent">
    <h2 class="mt-3 text-white fw-bold" style="font-size: 4.5rem">{{'confirm_title'| translate}}</h2>
    <span class="mt-1 mb-auto fw-normal text-white email-confirmation-message">{{'confirm_subtitle'| translate}}</span>
    <a class="mb-5 text-white" (click)="data.mailSent ? resendForgotMail() :resendMail()" style="cursor:pointer; font-size: 1.8rem">{{'confirm_email_again'| translate}}</a>
</div>
