<button id="questionModal" [hidden]="true" data-toggle="modal" data-target="#feedbackQuestionsModal"></button>

<div class="modal fade" id="feedbackQuestionsModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false"
     aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content feedback-questions-modal-content">
      <div class="modal-header feedback-questions-modal-header">
        <div class="modal-header-tip">
          <span>{{'tryit_bluebanner' | translate}}</span>
          <button id="questionModalClose" type="button" class="btn close modal-close-button" data-dismiss="modal" (click)="closeModal()"><span aria-hidden="true">&times;</span></button>
        </div>
        <img class="logo" src="../../../assets/images/successteam-icon.svg">
        <h2>{{'tryit_title'| translate}} {{teamName}}...</h2>
      </div>
        <form [formGroup]="feedbackForm">
          <div class="modal-body feedback-questions-modal-body">
            <div class="feedback-question-container">
                <span class="feedback-question">{{fromPreviousQuestion ? lastQuestion.title : currentQuestion?.title}}</span>
            </div>
            <div class="feedback-answer-container">
              <div class="custom-slider">
                <ngx-slider formControlName="answer" [(value)]="value" [options]="options"></ngx-slider>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-center feedback-questions-footer">
            <span class="question-counter">{{'answer_p1_1'| translate}} {{this.currentPollDetails?.maxStatements - questionNumber}} {{'answer_p1_2'| translate}}</span>
            <div class="container question-counter-container">
              <div class="progress feedback-question-progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" [style]="{'width': feedbackQuestionProgress}" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center answer-buttons-container">
              <button (click)="goToPreviousQuestion()" [disabled]="disableButton"
              [style]="previousQuestionButton ? {'display':'none'}:{'display':'inline-block'}"
              class="btn btn-primary previous-question-btn">{{'previous_button'| translate}}</button>
              <button (click)="fromPreviousQuestion ? editPreviousAnswer() : nextQuestion()" [style]="previousQuestionButton ? {'margin':'0 9.7rem 0 auto'}:{'margin':'0'}" class="btn btn-primary pf-modal-footer-payment-btn" [disabled]="disableButton">
                <div *ngIf="disableButton" class="spinner-border text-primary" role="status">
                  <span class="sr-only"></span>
                </div>
                {{this.currentPollDetails?.maxStatements - questionNumber > 1 ? ('tryit_next' | translate) : ('tryit_finish' | translate)}}</button>
            </div>
          </div>
        </form>
    </div>
  </div>
</div>
