import {Component, OnInit} from '@angular/core';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {ChallengesService} from "../../services/challenges.service";

@Component({
  selector: 'app-team-actions',
  templateUrl: './team-actions.component.html',
  styleUrls: ['./team-actions.component.scss']
})
export class TeamActionsComponent implements OnInit {
  private currentTeamUuid: any = '';
  private roles: any = {};
  team: any;
  memberTeams: any = [];
  private noTeam: boolean = false;
  showArchiveActions: boolean = false;

  constructor(public data: DataManipulationService, private activeRoute: ActivatedRoute, private router: Router,
              private api: ApiwrapperService, public challengesService: ChallengesService,) {
    this.roles = data.userRole;
  }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.paramMap.get('team')) {
      this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
    } else {
      this.noTeam = true;
    }
    this.sortTeams()
  }

  private sortTeams() {
    const teams = this.data.userTeams();
    teams.forEach((el: any) => {
      if(!this.noTeam && (el.uuid === this.currentTeamUuid)){
        this.team = el;
        this.data.currentTeam = el;
      }
      if (el.myRole !== null) {
        if (el.myRole.teamPriority > 10) {
          this.memberTeams.push(el);
        }
      }
    })
    this.getTeamActions();
  }

  private getTeamActions() {
    if (this.noTeam) {
      this.team = this.memberTeams[0];
      this.data.currentTeam = this.memberTeams[0];
      this.currentTeamUuid = this.memberTeams[0].uuid;
    }

    // get actions for the current team
    this.challengesService.getTeamTasks(this.currentTeamUuid, '', 1, 'all');
  }

  changeTeam() {
    // get the actions for the team selected
    this.data.currentTeam = this.team.uuid;
    this.challengesService.getTeamTasks(this.team.uuid, '', 1, 'all');
  }

  getActions(indicator: string) {
    this.challengesService.sort = indicator;
    this.challengesService.getTeamTasks(this.challengesService.teamUuid, '', 1, indicator);
  }

}
