<button id="paymentDetails" [hidden]="true" data-toggle="modal" data-target="#paidFeatureModal"></button>

<div class="modal fade" id="paidFeatureModal" tabindex="-1" role="dialog" aria-hidden="true"
     aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content paid-feature-modal-content">
      <div class="modal-header paid-feature-modal-header">
        <div class="d-flex flex-column pt-2 align-items-center paid-feature-modal-header">
          <img class="paid-feature-img" src="../../../assets/images/paid_feature.png">
          <h5 class="text-white fw-bold">You've chosen a paid feature</h5>
          <span class="mt-1 text-white paid-feature-text">Upgrade to Essential or go back and switch your frequency to weekly and get started for free.</span>
        </div>
      </div>
      <div class="modal-body paid-feature-modal-body">
        <div class="d-flex justify-content-between align-items-center pt-4">
          <div class="pf-modal-body-left-content">
            <div class="d-flex flex-column justify-content-center paid-feature-content">
              <span class="fw-bold pb-2 border-bottom">Billing details</span>
              <form class="mt-4" #billingInfoForm="ngForm" [formGroup]="paymentForm" (ngSubmit)="saveBillingInfo()">
                <label class="mb-3">{{'billing_tab2_vat'| translate}}</label>
                <ngx-flag-picker class="country-vat-piker"
                                 [selectedCountryCode]="selectedCountryVat"
                                 [countryCodes]="countryCodes"
                                 [showArrow]="false"
                                 [showFlags]="true"
                                 (changedCountryCode)="changeSelectedCountryCode($event)">
                </ngx-flag-picker>
                <div class="row" style="align-items: center">
                  <div class="col">
                    <input class="form-control" formControlName="vatNumber" type="number" id="vatNumber">
                  </div>
                  <div class="col pt-5" style="display: contents">
                    <a class="text-primary retrieve-information" (click)="verifyVat()">{{'billing_tab2_retrieve'| translate}}</a>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <label class="mb-3">{{'billing_tab2_company'| translate}}</label>
                    <input class="form-control" formControlName="companyName" id="companyName">
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <label class="mb-3">{{'billing_tab2_address'| translate}}</label>
                    <input class="form-control" formControlName="address" id="address">
                  </div>
                  <div class="col">
                    <label class="mb-3">{{'billing_tab2_country'|translate}}</label>
                    <ngx-flag-picker class="d-flex country-piker"
                                     [selectedCountryCode]="selectedCountry"
                                     [countryCodes]="countryCodes"
                                     [showArrow]="true"
                                     [showLabels]="true"
                                     [customLabels]="countryLabels"
                                     (changedCountryCode)="changeCountryCode($event)">
                    </ngx-flag-picker>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <label class="mb-3">{{'billing_tab2_phone'|translate}}</label>
                    <ngx-intl-tel-input
                      class="form-control register-input"
                      style="border: none"
                      [cssClass]="'custom'"
                      [preferredCountries]="[CountryISO.Denmark]"
                      [selectedCountryISO]="CountryISO.Denmark"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                         ]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [numberFormat]="PhoneNumberFormat.National"
                      [separateDialCode]="true"
                      formControlName="phoneNumber"
                    ></ngx-intl-tel-input>
                  </div>
                </div>
                <p style="color: red" *ngIf="data.paymentFail">{{'payment_failed'| translate}}</p>
              </form>

              <br>
              <span class="fw-bold pb-2 border-bottom">Have a discount code?</span>
              <div class="row mt-3" *ngIf="!couponValidated">
                <div class="col-md-7">
                  <input (keyup.enter)="validateCoupon()" [(ngModel)]="coupon" class="form-control"
                         placeholder="Insert code">
                </div>
                <div class="col-md-5" style="text-align: center">
                  <button (click)="validateCoupon()" pButton type="button" label="{{'billing_tab1_validate'| translate}}"
                          class="validate-coupon-button"></button>
                </div>
              </div>
              <div class="row mt-3" *ngIf="couponValidated">
                <div class="col-md-7" style="display: flex; align-items: center;">
                  <h5>{{coupon}}</h5>
                  <i style="color: green; font-size: 20px; margin-left: 1rem" class="bi bi-patch-check-fill"></i>
                </div>
              </div>
            </div>

          </div>
          <div class="d-flex flex-column justify-content-center align-items-center p-3 pf-modal-body-right-content">
            <img class="paid-feature-modal-body-img" src="../../../assets/images/startup.png">
            <h5 class="fw-bold mt-4 mb-2">Essential plan</h5>
            <div style="text-align: center">
            <h4>39 dkk</h4>
            <h5>{{'billing_tab1_price'| translate}}</h5>
            <h5>Firstly billed in 30 days</h5>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Unlimited Team Insights</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Unlimited Users & Teams</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">E-mail & Phone Support</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Advanced Feedback Settings</span>
            </div>
            <div class="d-flex mt-3">
              <i class="bi bi-check-circle-fill me-2 text-success"></i>
              <span class="paid-feature-modal-body-text">Statement Management</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start paid-feature-modal-footer">
        <button type="button" class="btn btn-secondary mb-5 pf-modal-footer-back-btn" data-dismiss="modal"
                (click)="closeModal()">Go back
        </button>
        <button type="button" class="btn btn-primary mb-5 pf-modal-footer-payment-btn" (click)="goToPayment()">Go to
          Payment
        </button>
      </div>
    </div>
  </div>
</div>
