<div *ngIf="responseDistribution" class="response-distribution-main">
    <div class="response-distribution-header">
        <div class="response-and-date-container">
            <span class="responses-counter">{{responseDistribution.responsesCount}}{{'response_distribution_header_responses' | translate}}</span>
        </div>
    </div>
    <div class="response-distribution-body">
        <div class="response-distribution-main-title"
            [style]="this.responseDistribution.eligibleForResult ? {'margin': '0 auto 3.2rem'}:{'margin': '0 auto 2rem'}">
            {{'response_distribution_main_title'+mainTitle | translate}}
        </div>
        <div *ngIf="this.responseDistribution.eligibleForResult" class="response-circles-bar">
            <div [ngClass]="circle.value >= 60 ? 'big': 
                            (circle.value >= 40 && circle.value < 60) ? 'medium' : 
                            (circle.value >= 20 && circle.value < 40) ? 'small' : 
                            (circle.value > 0 && circle.value < 20) ? 'very-small' : 'none'" class="response-circle" *ngFor="let circle of circleResponses; let i = index" 
                            pTooltip="{{'response_distribution_tooltip' + ((circle.value >= 60) ? 1 : 
                                        (circle.value >= 40 && circle.value < 60) ? 2 :
                                        (circle.value >= 20 && circle.value < 40) ? 3 : 
                                        (circle.value > 0 && circle.value < 20) ? 4 : '') | translate}}" tooltipPosition="top" tooltipStyleClass="response-distribution-tooltip" [tooltipDisabled]="circle.value === 0">
                <span [ngClass]="responseDistribution?.onInsight ? 'wrap-title':''" class="circle-title">{{circle.title}}</span>
            </div>
        </div>
    </div>
</div>