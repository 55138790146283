<!-- Member Info -->
<button type="button" id="memberInfoModal" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#memberInfoBackdrop">
</button>

<div class="modal fade" id="memberInfoBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered let-know-you-modal-dialog">
    <div class="modal-content let-know-you-modal">
      <div class="modal-body p-0">
        <div class="containers-row-direction">
          <div class="description-side">
            <h1>{{'member_info_description_title' | translate}}</h1>
            <span>{{'member_info_description_subtitle' | translate}}</span>
            <img class="description-side-img" src="../../../assets/images/onboarding_side.png">
          </div>
          <div class="form-side">
            <div class="col-md-4 account-details" style="text-align: center">
              <div class="avatar">
                <input type="file" hidden
                       #file
                       name="file"
                       id="file"
                       (change)="uploadImage($event)"
                       accept="image/jpg,.jpeg,.png,.jpg"/>
                <div class="avatar-image-container">
                  <img [ngClass]="userDetails?.avatar ? 'user-picture-settings' : 'no-user-picture-settings'" (click)="file.click()"
                     src="{{userDetails?.avatar ? data.baseUrl + '/uploads/user/'+ userDetails.uuid + '/' + userDetails.avatar.name : 'assets/svg/user-icon.svg' }}"
                     data-holder-rendered="true">
                     <div class="add-and-remove-avatar">
                      <i class="bi bi-plus" (click)="file.click()"></i>
                     </div>
                </div>
                <span class="register">
                  {{'member_info_profile_picture' | translate}}
                </span>
              </div>
            </div>
            <form [formGroup]="userDetailsForm" autocomplete="off"
                id="userDetailsForm">
                <div class="input-row">
                  <div class="input-container">
                    <label class="" >{{'register_firstname'|translate}}</label>
                    <input [ngStyle]="userDetailsForm.controls['firstname'].status === 'INVALID' && (userDetailsForm.controls['firstname'].dirty || userDetailsForm.controls['firstname'].touched)
                     ? {'border':'1px solid red'}:{}" class="form-control" id="firstname" formControlName="firstname" type="text">
                    <div
                      *ngIf="userDetailsForm.controls['firstname'].status === 'INVALID' && (userDetailsForm.controls['firstname'].dirty || userDetailsForm.controls['firstname'].touched)"
                      class="text-danger">
                      <div *ngIf="userDetailsForm.controls['firstname']?.errors?.['required']">{{'register_first_name_error'| translate}}</div>
                    </div>
                  </div>
                  <div class="input-container">
                    <label class="" >{{'member_info_last_name' | translate}}</label>
                    <input [ngStyle]="userDetailsForm.controls['lastname'].invalid && (userDetailsForm.controls['lastname'].dirty || userDetailsForm.controls['lastname'].touched)
                    ? {'border':'1px solid red'}:{}" class="form-control register-input" id="lastname" formControlName="lastname" type="text">
                    <div
                      *ngIf="userDetailsForm.controls['lastname'].invalid && (userDetailsForm.controls['lastname'].dirty || userDetailsForm.controls['lastname'].touched)"
                      class="text-danger">
                      <div *ngIf="userDetailsForm.controls['lastname']?.errors?.['required']">{{'member_info_last_name_required' | translate}}</div>
                    </div>
                  </div>
                </div>
                <div class="input-row">
                  <div class="input-container">
                    <div class="d-flex align-items-center" style="margin-bottom: 9px;">
                      <label style="margin-bottom: 3px;">{{'my_account_display_name'| translate}}</label>
                      <div class="dn-question-icon-container">
                        <i class="bi bi-question-circle-fill text-warning dn-question-icon"></i>
                        <div class="question-tooltip">{{'member_info_last_dn_tooltip' | translate}}</div>
                      </div>
                    </div>
                    <input class="form-control register-input" id="displayName" formControlName="displayName" type="text">
                  </div>
                  <div class="input-container">
                    <label class="">{{'member_info_lang'| translate}}</label>
                    <div style="display: flex; padding: 1rem;border-radius: 7px; border: 1px #dee2e6 solid; background-color: white">
                      <ngx-flag-picker
                        class="country"
                        style="font-size: 2rem; border-radius: 5px"
                        [showFlags]=true
                        [showLabels]=false
                        [showArrow]=true
                        [selectedCountryCode]="selectedCountryCode"
                        [countryCodes]="countryCodes"
                        (changedCountryCode)="changeLanguage($event)">
                      </ngx-flag-picker>
                      <p style="font-size: 1.6rem; padding: .2rem 1rem; margin: 0">{{languageDisplayed}}</p>
                    </div>
                  </div>
                </div>
                <div class="input-row">
                  <div class="input-container password-container">
                    <label class="">{{'member_info_password' | translate}}</label>
                    <app-password-strenght-metter  [passwordToCheck]="passwordValidation.value"></app-password-strenght-metter>
                    <input [ngStyle]="userDetailsForm.controls['password'].invalid && (userDetailsForm.controls['password'].dirty || userDetailsForm.controls['password'].touched)
                    ? {'border':'1px solid red'}:{}" #passwordValidation class="form-control register-input" id="password" formControlName="password"
                           type="password">
                    <div
                      *ngIf="userDetailsForm.controls['password'].invalid && (userDetailsForm.controls['password'].dirty || userDetailsForm.controls['password'].touched)"
                       class="text-danger">
                       <div *ngIf="userDetailsForm.controls['password']?.errors?.['required']">{{'member_info_password_required' | translate}}</div>
                       <div>{{'member_info_minimum_6' | translate}}</div>
                    </div>
                  </div>
                  <div class="input-container">
                    <label class="">{{'register_confirm_pass'| translate}}</label>
                    <input [ngStyle]="userDetailsForm.controls['confirmPassword'].invalid && (userDetailsForm.controls['confirmPassword'].dirty || userDetailsForm.controls['confirmPassword'].touched)
                    ? {'border':'1px solid red'}:{}" class="form-control register-input" id="confirmPassword" formControlName="confirmPassword"
                           type="password">
                    <div
                      *ngIf="userDetailsForm.controls['confirmPassword'].invalid && (userDetailsForm.controls['confirmPassword'].dirty || userDetailsForm.controls['confirmPassword'].touched)"
                      class="text-danger">
                      <div *ngIf="userDetailsForm.controls['confirmPassword']?.errors?.['required']">{{'member_info_password_required' | translate}}</div>
                      <div *ngIf="userDetailsForm.controls['confirmPassword'].value.length < 5">{{'member_info_minimum_6' | translate}}</div>
                    </div>
                  </div>
                </div>
            <!-- <div class="row account-details">
              <div class="col-md-8">
                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="" >First name</label>
                    <input [ngStyle]="userDetailsForm.controls['firstname'].status === 'INVALID' && (userDetailsForm.controls['firstname'].dirty || userDetailsForm.controls['firstname'].touched)
                      ? {'border':'1px solid red'}:{}" class="form-control register-input" id="firstName" formControlName="firstname" type="text">
                    <p *ngIf="userDetailsForm.controls['firstname'].invalid && userDetailsForm.controls['firstname'].touched" style="color:red;">Please insert First name</p>

                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">Last name</label>
                    <input type="text" formControlName="lastname" class="form-control p-inputtext" pInputText
                           id="accountLastName" placeholder="Last name"/>
                    <p *ngIf="userDetailsForm.controls['lastname'].invalid && userDetailsForm.controls['lastname'].touched" style="color:red;">Please insert Last name</p>


                  </div>
                </div>

                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">Display name</label>
                    <input type="email" formControlName="displayName" class="form-control p-inputtext" pInputText
                           id="accountDisplayName" placeholder="Username"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">Phone number</label>
                    <ngx-intl-tel-input
                      (focusout)="checkPhone()"
                      [ngStyle]="!phoneError && userDetailsForm.controls['phone'].invalid  ? {'border':'1px solid red'}:{}"
                      class="form-control ngx-tel-input"
                      style="border: none"
                      placeholder="000000000"
                      [preferredCountries]="[CountryISO.Denmark]"
                      [selectedCountryISO]="CountryISO.Denmark"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryFlag]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectFirstCountry]="false"
                      [maxLength]="15"
                      [phoneValidation]="true"
                      [numberFormat]="PhoneNumberFormat.International"
                      [separateDialCode]="true"
                      formControlName="phoneNumber"
                    ></ngx-intl-tel-input>
                    <p *ngIf="phoneError" style="color:red;">Phone number is not valid</p>
                  </div>
                </div>

                <div class="row">
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">Password</label>
                    <input type="password" formControlName="password" class="form-control p-inputtext" pInputText
                           name="password"
                           id="accountPassword" matTooltip="Password must be at least 6 characters long"
                           placeholder="New password"
                           autocomplete="new-password"/>
                  </div>
                  <div class="user-details form-group col-xl-6 col-lg-6 col-md-12">
                    <label class="form-label">Confirm password</label>
                    <input type="password" formControlName="confirmPassword" class="form-control p-inputtext"
                           pInputText
                           id="accountConfirmPassword" placeholder="Confirm password"/>
                  </div>
                </div>

              </div>

                <button class="btn btn-blue" style="width: max-content; padding: 0 4rem">Get Started</button>
              </div>
            </div> -->
          </form>
          <div class="terms-container">
            <div class="terms-checkbox">
              <input class="form-check-input" type="checkbox" id="checkbox" [(ngModel)]="agreeCheck">
              <label class="form-check-label ms-2 p-top">{{'member_info_agree' | translate}}<a class="text-decoration-none" href="https://www.successteam.com/termsofuse/">{{'member_info_agree_terms' | translate}}</a></label>
            </div>
            <button class="btn get-started-button" (click)="onSubmit()" [disabled]="!agreeCheck">
              {{'member_info_get_started' | translate}}
              <i class="bi bi-arrow-right ms-2"></i>
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
