<div class="main-container">
    <div *ngIf="customPolls.length" class="active-polls">
        <div class="active-custom-poll" *ngFor="let poll of customPolls, let index = index">
            <!-- <span style="color: #858DA2; font-size: 1.2rem; margin-bottom: .5rem;">{{poll.status | titlecase}} {{'custom_poll_temp_loop_assessment' | translate}}</span> -->
            <div class="assignments-buttons-actions">
                <img *ngIf="poll.status === ('custom_poll_temp_loop_running' | translate)" (click)="openConfirmationModalForStop($event, index)" class="assignment-action-btn" src="../../../../../assets/svg/stop-icon.svg">
                <div *ngIf="openStopModal[index]" (click)="$event.stopPropagation()" class="delete-custom-poll-modal-container stop-poll">
                    <span [innerHTML]="('custom_poll_temp_loop_stop_modal_text' | translate: {templateName: poll.name})"></span>
                    <div class="modal-buttons-container">
                        <button class="modal-button no" pButton label="No" (click)="closeConfirmationModalForStop(index)"></button>
                        <button class="modal-button yes" pButton label="Yes" (click)="stopCustomPoll(poll.uuid, index)"></button>
                    </div>
                 </div>
                 <img class="assignment-action-btn" src="../../../../../assets/svg/icon-delete.svg" (click)="openConfirmationModalForDelete($event, index)">
                 <div *ngIf="openDeleteModal[index]" (click)="$event.stopPropagation()" class="delete-custom-poll-modal-container">
                    <span [innerHTML]="('custom_poll_temp_loop_delete_modal_text' | translate: {templateName: poll.name})"></span>
                    <div class="modal-buttons-container">
                        <button class="modal-button no" pButton label="No" (click)="closeConfirmationModalForDelete(index)"></button>
                        <button class="modal-button yes" pButton label="Yes" (click)="deleteCustomPoll(poll.uuid, index)"></button>
                    </div>
                 </div>
             </div>
            <div class="status-and-response-rate-container">
                <h2 style="font-size: 2.4rem;margin-bottom: 1rem;">{{poll.name}}</h2>
                <div class="status-and-response-rate">
                    <div class="response">
                        <div [style]="{'background-color':poll.imageColor}" class="response-rate-image-container">
                            <img [src]="this.data.baseUrl+poll.customPollTemplate.image">
                        </div>
                        <div class="response-rate-details">
                            <span class="response-rate-detail-title">
                                <b 
                                    pTooltip="{{'custom_poll_onboarding_temp_overall_score_tooltip' | translate}}" 
                                    tooltipPosition="bottom" 
                                    tooltipStyleClass="response-distribution-tooltip">{{(poll.customPollTemplate.type === 'apv' && !poll.reportingDetails?.score) ? 'Response Rate':'Score'}}
                                </b>
                            </span>
                            <h2 *ngIf="poll.customPollTemplate.type === 'onboarding'" 
                                style="font-size: 5.3rem; margin-bottom: 0;font-weight: 600;"
                                pTooltip="{{'custom_poll_onboarding_temp_overall_score_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">
                                {{poll.reportingDetails?.score && poll.onboardingEligibleForResult ?  poll.reportingDetails?.score+'%' : '-'}}
                            </h2>
                            <h2 *ngIf="poll.customPollTemplate.type === 'apv'" 
                                style="font-size: 5.3rem; margin-bottom: 0;font-weight: 600;"
                                pTooltip="{{'custom_poll_onboarding_temp_overall_score_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">
                                {{poll.reportingDetails?.score ? poll.reportingDetails?.score+'%' : poll.reportingDetails?.responseRate ? poll.reportingDetails?.responseRate+'%' : '-'}}
                            </h2>
                            <div class="reminder-container">
                                <img src="../../../../../assets/svg/send-reminder-envelope.svg">
                                <span>{{'custom_poll_temp_send_reminder' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="status">
                        <span class="status-title">Status: 
                            <i [ngStyle]="poll.status === ('custom_poll_temp_loop_running' | translate) ? {'color':'#16C285'}:{}" class="bi bi-dot"></i> 
                            <b [ngStyle]="poll.status === ('custom_poll_temp_loop_running' | translate) ? {'color':'#16C285'}:{}">{{poll.status}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_completed' | translate) || poll.status === 'Stopped'" class="status-title">{{'custom_poll_temp_loop_days_completed' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{poll.endDate?.date | date:'dd.MM.yyyy'}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_running' | translate) && poll.customPollTemplate.type === 'apv'" class="status-title">{{'custom_poll_temp_loop_days_until' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(poll.startDate?.date, poll.endDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_scheduled' | translate) " class="status-title">{{'custom_poll_temp_loop_days_scheduled' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(null, poll.startDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <span *ngIf="poll.status === ('custom_poll_temp_loop_draft' | translate) && poll.customPollTemplate.type === 'apv'" class="status-title">{{'custom_poll_temp_loop_days_until' | translate}} 
                            <b style="color: black; margin-left: .4rem;">{{getRemainingDays(poll.startDate?.date, poll.endDate?.date) + ('custom_poll_temp_loop_days' | translate)}}</b>
                        </span>
                        <span *ngIf="(poll.status === ('custom_poll_temp_loop_draft' | translate) || poll.status === ('custom_poll_temp_loop_running' | translate)) && poll.customPollTemplate.type === 'onboarding'" 
                            class="status-title">{{'custom_poll_temp_onboarding_participants' | translate:{participants: poll.reportingDetails?.assignments} }} 
                        </span>
                        <!-- <div class="see-report">
                            {{'custom_poll_temp_loop_see_report' | translate}}
                        </div> -->
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_completed' | translate) || poll.status === 'Stopped'"
                                (click)="goToPollReporting(poll)" 
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;max-width: 19.1rem;" 
                                class="template-button">
                                {{'custom_poll_temp_loop_see_report' | translate}}
                                <i class="bi bi-arrow-right"></i></button>
                        <button *ngIf="poll.customPollTemplate.type === 'onboarding' && poll.onboardingEligibleForResult"
                                [ngClass]="!poll.onboardingEligibleForResult ? 'see-report-btn':''"
                                (click)="goToPollReporting(poll)"
                                [disabled]="!poll.onboardingEligibleForResult" 
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;max-width: 19.1rem;" 
                                class="template-button"
                                pTooltip="{{'custom_poll_temp_loop_running_tooltip_onboarding' | translate}}" [tooltipDisabled]="poll.onboardingEligibleForResult" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">
                                {{'custom_poll_temp_loop_see_report' | translate}}
                                <i class="bi bi-arrow-right"></i></button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_scheduled' | translate)"
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;max-width: 19.1rem;" 
                                class="template-button see-report-btn"
                                [disabled]="true">
                                {{'custom_poll_temp_loop_see_report' | translate}}
                                <i class="bi bi-arrow-right"></i></button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_running' | translate) && poll.customPollTemplate.type === 'apv'" 
                                style="margin: 1.6rem 0 0 0;padding: 1rem 3rem;" 
                                class="template-button see-report-btn"
                                [disabled]="true"
                                pTooltip="{{'custom_poll_temp_loop_running_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">
                                {{'custom_poll_temp_loop_see_report' | translate}}
                                <i class="bi bi-arrow-right"></i></button>
                        <button *ngIf="poll.status === ('custom_poll_temp_loop_draft' | translate)" 
                            style="margin: 1.6rem 0 0 0; padding: 1rem 3rem;" 
                            class="template-button"
                            
                            (click)="resumeDraft(poll)">
                                {{'custom_poll_temp_loop_resume_button' | translate}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="poll-creator-details-container">
                <div class="created-by-container">
                    <span class="creator-created-by">{{'custom_poll_temp_created_by' | translate}}</span>
                    <div class="creator-details-container">
                        <img class="creator-avatar" [src]="poll.creator?.avatar?.path ? this.data.baseUrl+poll.creator.avatar.path:'../../../../../assets/svg/user-icon.svg'" alt="creator-avatar">
                        <span class="creator-details-text" [innerHTML]="('custom_poll_temp_creator' | translate: 
                        {creator: (poll.creator.detail.displayName ? poll.creator.detail.displayName : poll.creator.detail.firstname ? 
                            poll.creator.detail.firstname : poll.creator.detail.lastname ? poll.creator.detail.lastname : poll.creator.email), createdDate : (poll.created.date | date: 'dd LLL yyyy')})"></span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div [ngStyle]="!customPolls.length ? {'flex-direction':'row','align-items':'flex-start', 'gap':'5rem'}:{}" class="templates-to-choose">
        <div class="template-container-holder">
            <div class="template-container" *ngFor="let template of workspaceTemplates">
                <div [ngStyle]="template.type === 'onboarding' ? {'padding':'1rem 0'}:{}" class="template-container-header">
                    <div *ngIf="template.type === 'onboarding'" class="not-anonymus-container">
                        <div class="not-anonymus-info-container">
                            <img class="info-circle-icon" src="../../../../../assets/svg/info_circle_icon.svg">
                            <span>{{'custom_poll_temp_not_anonymous_info' | translate}}</span>
                        </div>
                        <img src="../../../../../assets/svg/comedy_mask.svg">
                        <span>{{'custom_poll_temp_not_anonymous' | translate}}</span>
                    </div>
                    <div class="d-flex flex-column align-items-center temp-image-and-title">
                        <img style="width: 7.2rem;height: 7.2rem;" src="../../../../../assets/svg/{{template.type}}-icon.svg">
                        <h2 [ngClass]="data.language.language === 'da' && template.type === 'apv' ? 'dk-title':''" class="template-title">{{template.name}}</h2>
                    </div>
                    <button [ngClass]="userPriority < 20 ? 'get-started-btn-dsb':''"
                        class="template-button"
                        pTooltip="{{'left_menu_11_tooltip' | translate}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip"
                        [tooltipDisabled]="userPriority >= 20" 
                        (click)="choseTemplate(template.uuid, template.type)"
                        [disabled]="(userPriority < 20)">
                        {{'custom_poll_temp_get_started_btn' | translate}}<i class="bi bi-arrow-right"></i></button>
                </div>
                <span class="template-text">{{template.description}}</span>
                <div class="template-categories-container">
                    <div class="category" *ngFor="let categ of templateMock.categories[template.type]">
                        <span class="" [ngStyle]="{'background-color': categ.color}">{{categ.title}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-us-container">
            <span class="contact-us">{{'custom_poll_temp_contact_us' | translate}}</span>
            <h2 class="contact-us-title">{{'custom_poll_temp_contact_us_title' | translate}}</h2>
            <span class="contact-us-text">{{'custom_poll_temp_contact_us_text1' | translate}}</span>
            <span [ngStyle]="{'margin-top':'1.2rem'}" class="contact-us-text" [innerHTML]="('custom_poll_temp_contact_us_text2' | translate)"></span>
        </div>
    </div>
</div>