import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-login-layout',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: []
})
export class LoginLayoutComponent {

  constructor(public translate: TranslateService, private activeRoute: ActivatedRoute, private auth: AuthenticationService) {
    if (this.activeRoute.snapshot.queryParamMap.has('lang')) {
      let lang = this.activeRoute.snapshot.queryParamMap.get('lang');
       if (lang === 'dk') {
         this.auth.language = 'da';
        translate.use('da')
      } else if (lang === 'en') {
         this.auth.language = 'en';
         translate.use('en');
       } else {
         this.auth.language = 'en';
         translate.use('en');
       }
    } else {
      if (localStorage.getItem('userDetails') !== null) {
        // @ts-ignore
        let language = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag;
        if (language.toLowerCase().includes('en')) {
          this.auth.language = 'en';
          translate.use('en');
        } else if (language.toLowerCase().includes('dk')) {
          this.auth.language = 'da';
          translate.use('da')
        }
      } else {
        this.auth.language = 'en';
        translate.use('en');
      }
    }
  }
}
