<button type="button" id="actionModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#actionModal">
</button>

<div class="modal fade" id="actionModal" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content action-box">
      <div class="modal-header action-header" *ngIf="showModal">
        <div class="action-header-title">
          <h3 style="color: #2551BF; font-size: 2.2rem;" class="modal-title" id="staticBackdropLabel">
            <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
            {{actionModal['title']}}</h3>
          <img src="assets/svg/close-button.svg" alt="X" data-bs-dismiss="modal" aria-label="Close"
               id="closeActioneModal" class="action-header-close-btn">
        </div>
        <p style="white-space: pre-wrap;" id="description"
           [innerHTML]="actionModal['displayDescription']"></p>
        <div class="action-author-container" style="margin: 1rem 3rem 1rem 2rem;">
          <img
            *ngIf="actionModal['author']['avatar'] !== null && actionModal['author']['avatar']['path'] !== null"
            class="action-user-avatar"
            src="{{baseUrl + actionModal['author']['avatar']['path']}}">
          <img style="width: 50px" *ngIf="actionModal['author']['avatar'] === null"
               src="../../../assets/svg/user-icon.svg" alt="">
          <div class="action-author-name">
            <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
<!--            <span>{{actionModal['author']['detail']['displayName']}}</span>-->
            <span>{{ actionModal['author']['detail']['displayName'] | getDisplayName: actionModal['author']['detail']['firstname'] : actionModal['author']['detail']['lastname']: actionModal['author']['email']}}</span>
          </div>
          <div class="action-status">
            <span style="font-size: 1.2rem;margin-right: .6rem;">
                  {{actionModal.status === 'to_do' ? ('insights_action_picked' | translate):
                    actionModal.status === 'in_progress' ? ('insights_action_started' | translate):
                    actionModal.status === 'done' ? ('insights_action_completed' | translate):
                    actionModal.status === 'archived' ? ('insights_action_archived' | translate) : ''}}
            </span>
            <span style="font-size: 1.2rem; font-weight: bold;">{{(actionModal.lastTaskHistory === null ? actionModal.created.date : actionModal.lastTaskHistory.created.date) | date: 'dd.MM.yyyy'}}</span>
            <div style="margin:0 0 0 1.4rem" class="action-status-dropdown" [ngClass]="selectedActionStatus?.type === 'to_do' ? 'dropdown-todo' :
              selectedActionStatus?.type === 'in_progress' ? 'dropdown-progress':
              selectedActionStatus?.type === 'done' ? 'dropdown-done' :
              selectedActionStatus?.type === 'archived' ? 'dropdown-archived':''">
              <p-dropdown [styleClass]="selectedActionStatus === 'in_progress' ? 'status-in-progress':''" [options]="actionsStatus" [(ngModel)]="selectedActionStatus" optionLabel="name" placeholder="{{('team_actions_'+ selectedActionStatus?.type | translate)}}" dropdownIcon="bi bi-chevron-down">
                <ng-template pTemplate="selectedItem">
                    <div *ngIf="selectedActionStatus">
                        
                    </div>
                </ng-template>
                <ng-template let-status pTemplate="item">
                  <div class="status-container" (click)="changeAction(status, actionModal)">
                    <span>{{status.title}}</span>
                    <div [style]="{'background-color':status.color}"class="status-circle-color"></div>
                  </div>
                </ng-template>
            </p-dropdown>
          </div>
          </div>
        </div>
      </div>
      <div class="modal-body action-body">
        <span
          class="modal-body-title">{{taskStatements.length > 0 ? ('insights_action_linked_to' | translate) : ('insights_action_no_statements' | translate)}}</span>
        <div class="modal-statements" *ngFor="let task of taskStatements">
          <img class="modal-statements-icon" src="../../../assets/svg/statement-icon-red.svg">
          <span class="statement-title">{{task.title}}</span>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="my-actions-container">
  <div class="my-actions-card-body" *ngIf="challengesService.myAssignActions.length > 0">
    <div
      cdkDropList
      id="todolist"
      #todoList="cdkDropList"
      [cdkDropListData]="challengesService.myProblemsTasks"
      [cdkDropListConnectedTo]="[progressList, doneList, archivedList]"
      class="actions-tasks-container"
      (cdkDropListDropped)="drop($event)">
      <div class="actions-tasks-todo">
        <span>{{'team_actions_to_do' | translate}}</span>
      </div>
      <div style="overflow: scroll;overflow-x: hidden; height: 57vh;">
        <div *ngFor="let item of challengesService.myProblemsTasks, let t = index" cdkDrag
             (click)="showAction(item['teamTask'])" style="cursor: pointer;">
          <div class="action-task-box" *ngIf="(challengesService.sort === 'all' || challengesService.sort === item['teamTask']['indicator']['title'])">
            <div>
            <span class="action-title">
              <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
              {{item['teamTask']['title']}}</span>
            </div>
            <div class="action-author-container">
              <img
                *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                class="action-user-avatar"
                src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
              <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                   src="../../../assets/svg/user-icon.svg" alt="">
              <div class="action-author-name">
                <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                <span class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
              </div>
              <!--              <button class="show-btn" (click)="showAction(item['teamTask'])">show action</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      cdkDropList
      id="inProgressList"
      #progressList="cdkDropList"
      [cdkDropListData]="challengesService.myInProgressTasks"
      [cdkDropListConnectedTo]="[todoList,doneList, archivedList]"
      class="actions-tasks-container"
      (cdkDropListDropped)="drop($event)">
      <div class="actions-tasks-progress">
        <span>{{'team_actions_in_progress' | translate}}</span>
      </div>
      <div style="overflow: scroll; overflow-x: hidden;height: 57vh;">
        <div *ngFor="let item of challengesService.myInProgressTasks, let p = index" cdkDrag
             (click)="showAction(item['teamTask'])" style="cursor: pointer;">
          <div class="action-task-box" *ngIf="(challengesService.sort === 'all' || challengesService.sort === item['teamTask']['indicator']['title'])">
            <div>
              <span class="action-title">
                <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
                {{item['teamTask']['title']}}</span>
            </div>
            <div class="action-author-container">
              <img
                *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                class="action-user-avatar"
                src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
              <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                   src="../../../assets/svg/user-icon.svg" alt="">
              <div class="action-author-name">
                <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                <span class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      cdkDropList
      #doneList="cdkDropList"
      id="doneList"
      [cdkDropListData]="challengesService.myCompletedTasks"
      [cdkDropListConnectedTo]="[todoList, progressList, archivedList]"
      class="actions-tasks-container"
      (cdkDropListDropped)="drop($event)">
      <div class="actions-tasks-done">
        <span>{{'team_actions_done' | translate}}</span>
      </div>
      <div style="overflow: scroll; overflow-x: hidden;height: 57vh;">
        <div *ngFor="let item of challengesService.myCompletedTasks, let p = index" cdkDrag
             (click)="showAction(item['teamTask'])" style="cursor: pointer;">
          <div class="action-task-box" *ngIf="(challengesService.sort === 'all' || challengesService.sort === item['teamTask']['indicator']['title'])">
            <div>
              <span class="action-title">
                <img style="margin-right: .4rem;" src="../../../assets/svg/action-active.svg">
                {{item['teamTask']['title']}}</span>
            </div>
            <div class="action-author-container">
              <img
                *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                class="action-user-avatar"
                src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
              <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                   src="../../../assets/svg/user-icon.svg" alt="">
              <div class="action-author-name">
                <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                <span class="author-name">{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
              </div>
              <!--              <button class="show-btn" (click)="showAction(item['teamTask'])">show action</button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngStyle]="showArchive ? {'display':'inline-block'}:{'display':'none'}"
      cdkDropList
      id="archivelist"
      #archivedList="cdkDropList"
      [cdkDropListData]="challengesService.myArchivedTasks"
      [cdkDropListConnectedTo]="[]"
      class="actions-tasks-container"
      (cdkDropListDropped)="drop($event)">
      <div class="actions-tasks-archived">
        <span>Archived</span>
      </div>
      <div style="overflow: scroll;overflow-x: hidden; height: 57vh;">
        <div *ngFor="let item of challengesService.myArchivedTasks, let a = index" cdkDrag
              (click)="showAction(item['teamTask'])" style="cursor: pointer;">
          <div class="action-task-box" *ngIf="(challengesService.sort === 'all' || challengesService.sort === item['teamTask']['indicator']['title'])">
            <div class="archive-action">
              <span class="action-title">
                <img style="margin-right: .4rem;" src="../../../assets/svg/action-archive.svg">
                {{item['teamTask']['title']}}</span>
            </div>
            <!-- <div class="action-author-container">
              <img
                *ngIf="item['teamTask']['author']['avatar'] !== null && item['teamTask']['author']['avatar']['path'] !== null"
                class="action-user-avatar"
                src="{{baseUrl + item['teamTask']['author']['avatar']['path']}}">
              <img style="width: 50px" *ngIf="item['teamTask']['author']['avatar'] === null"
                    src="../../../assets/svg/user-icon.svg" alt="">
              <div class="action-author-name">
                <span [style]="{'font-size' : '11px'}">{{'team_action_assigned_by'|translate}}</span>
                <span>{{ item['teamTask']['author']['detail']['displayName'] | getDisplayName: item['teamTask']['author']['detail']['firstname'] : item['teamTask']['author']['detail']['lastname']: item['teamTask']['author']['email']}}</span>
              </div>
              &lt;!&ndash;              <button class="show-btn" (click)="showAction(item['teamTask'])">show action</button>&ndash;&gt;
            </div> -->
          </div>
        </div>
      </div>
    </div>
    </div>
  <div class="empty-actions-card-body"
           *ngIf="challengesService.myAssignActions.length === 0">
        <div class="empty-actions-tasks">
          <div class="empty-tasks">
            <span>{{'team_actions_to_do' | translate}}</span>
          </div>
          <div class="action-task-box" *ngFor="let i of [].constructor(2)">
            <div class="d-flex flex-row">
              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>
              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"
                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
            </div>
            <div class="empty-action-author">
              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"
                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>
              <div class="d-flex flex-column">
                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"
                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>
                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"
                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-actions-tasks">
          <div class="empty-tasks">
            <span>{{'team_actions_in_progress' | translate}}</span>
          </div>
          <div class="action-task-box" *ngFor="let i of [].constructor(3)">
            <div class="d-flex flex-row">
              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>
              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"
                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
            </div>
            <div class="empty-action-author">
              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"
                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>
              <div class="d-flex flex-column">
                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"
                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>
                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"
                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="empty-actions-tasks">
          <div class="empty-tasks">
            <span>{{'team_actions_done' | translate}}</span>
          </div>
          <div class="action-task-box" *ngFor="let i of [].constructor(2)">
            <div class="d-flex flex-row">
              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>
              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"
                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
            </div>
            <div class="empty-action-author">
              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"
                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>
              <div class="d-flex flex-column">
                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"
                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>
                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"
                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="empty-actions-tasks">-->
<!--          <div class="empty-tasks">-->
<!--            <span>Archived</span>-->
<!--          </div>-->
<!--          <div class="action-task-box" *ngFor="let i of [].constructor(1)">-->
<!--            <div class="d-flex flex-row">-->
<!--              <i class="bi bi-lightning-charge-fill" style="font-size:1.2rem; color: #C1CBE9; margin-right: .8rem;"></i>-->
<!--              <p-skeleton width="16rem" height="1.6rem" borderRadius="8px" animation="none"-->
<!--                          [style]="{'background-color':'#ECEFF8'}"></p-skeleton>-->
<!--            </div>-->
<!--            <div class="empty-action-author">-->
<!--              <p-skeleton shape="circle" width="5rem" height="5rem" animation="none"-->
<!--                          [style]="{'margin-right':'.8rem', 'background-color':'#ECEFF8'}"></p-skeleton>-->
<!--              <div class="d-flex flex-column">-->
<!--                <p-skeleton width="8.9rem" height="1.2rem" borderRadius="8px" animation="none"-->
<!--                            [style]="{'margin-bottom':'.6rem', 'background-color':'#ECEFF8'}"></p-skeleton>-->
<!--                <p-skeleton width="7rem" height="1.6rem" borderRadius="8px" animation="none"-->
<!--                            [style]="{'background-color':'#ECEFF8'}"></p-skeleton>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
</div>
