import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {ChallengesService} from 'src/app/services/challenges.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import demo from "../../../assets/data/demo.json"
import {environment} from "../../../environments/environment";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-analyse',
  templateUrl: './analyse.component.html',
  styleUrls: ['./analyse.component.scss']
})
export class AnalyseComponent implements OnInit {

  dropdownOptions: { name: string, value: number }[] = [
    {name: this.data.language.language === 'en' ? 'Latest Responses':'Seneste Svar', value: 0},
    {name: this.data.language.language === 'en' ? 'Last 30 days':'Sidste 30 dage', value: 30},
    {name: this.data.language.language === 'en' ? 'Last 90 days':'Sidste 90 dage', value: 90}
  ];
  currentTeamUuid = '';
  timeSelector = 30;
  allStatements: any = [];
  statements: any = [];
  yellowStatements: any = [];
  orangeStatements: any = [];
  redStatements: any = [];
  greenStatements: any = [];
  defaultStatements: any = [];
  userLanguage: string = '';
  teamReport: any = {
    Individual: {
      title: 'Individual',
      indicators: []
    },
    Team: {
      title: 'Team',
      indicators: []
    },
    Leadership: {
      title: 'Leadership',
      indicators: []
    },
  };
  gotReport = false;
  gotStatements = false;
  members = [];
  bestButton: boolean = false;
  worstButton: boolean = false;
  actedUpon: boolean = false;
  showMoreText: any = [];

  matrix: any = {
    categories: {
      Individual: false,
      Team: false,
      Leadership: false,
    },
    indicators: {
      Performance: false,
      Motivation: false,
      Satisfaction: false,
      Stress: false
    }
  }
  lastMatrix = {
    category: '',
    indicator: ''
  }
  matrixSelected: boolean = false;
  private lastCategory: any = '';
  private lastIndicator: any = '';
  public team: any;
  public showMatrix: boolean = false;
  noMatrix: boolean = false;
  private dateSelected: number = 0;
  wizardStep: number = 0;
  statementWizard: number = 4;
  selectedTeamLeaderForAction: any;
  openedAccordionIndex: any;
  teamReportDate: {startDate: any, endDate: any} = {startDate: '', endDate: ''};
  responseDistribution: any = null;
  showSelectedTab: any;
  previousShowSelectedTabIndex: any;
  worstStatementsAccordionIndex: number = 0;
  pollDatesForResponseDistribution: any;

  constructor(public data: DataManipulationService, public api: ApiwrapperService, public router: Router,
              private activeRoute: ActivatedRoute, public challengesService: ChallengesService) {
    this.data.showLoading();
    //@ts-ignore
    this.currentTeamUuid = this.activeRoute.snapshot.paramMap.get('team');
    if (data.showDemo) {
      // set matrix
      // @ts-ignore
      this.teamReport = demo.matrix.categories;
      this.gotReport = true;
      // set questions
      this.processStatements(demo.statements);
    } else {
      this.getData();
    }

    this.data.userTeams().forEach((el: any) => {
      if (el.uuid === this.currentTeamUuid) {
        this.team = el;
      }
    })
    // @ts-ignore
    this.userLanguage = JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage.tag.split("_")[0];
    this.dropdownOptions = [
      {name: this.userLanguage === 'en' ? 'Latest Responses':'Seneste Svar', value: 0},
      {name: this.userLanguage === 'en' ? 'Last 30 days':'Sidste 30 dage', value: 30},
      {name: this.userLanguage === 'en' ? 'Last 90 days':'Sidste 90 dage', value: 90}
    ];
  }

  ngOnInit(): void {
    // @ts-ignore
  }

  getData() {
    this.getTeamReport();
    this.getStatement();
    this.getMemberActivity();
  }

  getTeamReport() {
    this.api.getTeamReport(this.currentTeamUuid).subscribe(
      res => {
        this.teamReportDate = {startDate : res.team?.teamReport.startDate.date, endDate:res.team?.teamReport.endDate.date };
        this.noMatrix = false;
        if (!res.info) {
          if (this.gotReport && this.gotStatements) {
            this.data.hideLoading();
            this.checkWizard();
          }
          this.processTeamReport(res);
        } else {
          this.showMatrix = true;
          this.noMatrix = true;
          this.gotReport = true;
          this.data.hideLoading();
        }

      }, err => {
        this.gotReport = true;
        this.data.hideLoading();
      }
    );
  }

  processTeamReport(report: any) {
    // add category
    this.showMatrix = false;
    report.reportDetails.forEach((el: any, index: number) => {
      if (el.indicatorColor === null) {
        el.indicatorColor = {color : 'gray'};
      }
      if (el.teamIndicatorPercentage === null) {
        el.teamIndicatorPercentage = 0;
      }

      // if the indicatorCategory.title is in teamReport push it into the indicator
      this.teamReport[el.indicatorCategory.defaultTitle]?.indicators.push({
        indicator: el.indicator,
        color: el.indicatorColor.color,
        percentage: el.teamIndicatorPercentage,
        eligibleForResult: el.eligibleForResult
      })

      if (report.reportDetails.length - 1 === index) {
        this.showMatrix = true;
        this.gotReport = true;
        if (this.gotReport && this.gotStatements) {
          this.data.hideLoading();
          this.checkWizard();
        }
      }
    });
    // add indicators
  }

  getStatement() {
    this.data.showLoading();
    let statementsDate;
    if (this.dateSelected !== 0) {
      statementsDate = this.data.getLastDays(this.dateSelected);
    } else {
      statementsDate = {next: '', today: ''}
    }
    this.api.getTeamReportStatements(this.currentTeamUuid, statementsDate).subscribe(
      res => {
        this.allStatements = [...res.questions];
        this.processStatements(res);
      }, err => {
        this.gotStatements = true;
        this.data.hideLoading();
      }
    );
  }
  private getMatrixStatements() {
    this.data.showLoading();
    let statementsDate;
    if (this.dateSelected !== 0) {
      statementsDate = this.data.getLastDays(this.dateSelected);
    } else {
      statementsDate = {next: '', today: ''}
    }
    let indicatorUuid :string = '';
    this.teamReport[''+this.lastCategory].indicators.forEach((el: any) => {
      if (el.indicator.title === this.lastIndicator){
        indicatorUuid = el.indicator.uuid;
      }
    })
    this.api.getMatrixTeamReportStatements(this.currentTeamUuid, indicatorUuid, statementsDate).subscribe(
      res => {
        this.allStatements = [...res.questions];
        this.processStatements(res);
      }, err => {
        this.gotStatements = true;
        this.data.hideLoading();
      }
    );
  }

  processStatements(res: any) {
    this.challengesService.newStatementsActions = {};
    this.greenStatements = [];
    this.yellowStatements = [];
    this.orangeStatements = [];
    this.redStatements = [];
    if (res.questions) {
      this.statements = [];

      res.questions.forEach((el: any) => {
        let hasAssignee = 0;
        el.question.tasks.forEach((task: any) => {
          if (task.frequency > 0) {
            ++hasAssignee;
          }
        })

        el.hasAssignee = hasAssignee;
        el.question.gotTasks = el.question.tasks.length > 0;
        this.statements.push(el);
        if (el.colorName === 'yellow') {
          this.yellowStatements.push(el);
          this.challengesService.addActionForm(el.question.uuid);
        } else if (el.colorName === 'orange') {
          this.orangeStatements.push(el);
          this.challengesService.addActionForm(el.question.uuid);
        } else if (el.colorName === 'red') {
          this.redStatements.push(el);
          this.challengesService.addActionForm(el.question.uuid);
        } else if (el.colorName === 'green') {
          el.question.noTasks = el.question.tasks.length === 0;
          this.greenStatements.push(el);
          this.challengesService.addActionForm(el.question.uuid);
        }
      });      
      this.gotStatements = true;
      if (this.gotReport && this.gotStatements) {
        if (this.bestButton){
          this.statements = this.greenStatements.concat(this.yellowStatements, this.orangeStatements, this.redStatements,this.defaultStatements);
        } else if (this.worstButton){
          this.statements = this.redStatements.concat(this.defaultStatements, this.orangeStatements, this.yellowStatements, this.greenStatements);
        }
        this.data.hideLoading();
      }
      if(this.responseDistribution === null) {
        this.getStatementResponseDistribution(this.worstStatementsAccordionIndex).subscribe((res:any) => {
          if (res) {
            this.getResponseDistributionValues(res);
          }
        });
        // this.responseDistribution = this.statements[0]['question']['responseDistribution'];
        // this.responseDistribution.peoplesCount = this.members.length;
        // this.responseDistribution.onInsight = true;
      }
      // this.[0].question.question.responseDistribution
      for(let i = 0; i < this.statements.length; i++) {
        this.showMoreText[i] = new Array(this.statements.length).fill(false);
      }
      this.showSelectedTab = new Array(this.statements.length).fill(false);
      // *****
      if (this.allStatements.length === 0 && this.statements.length > 0) {
        this.allStatements = [...this.statements];
      }
    }
  }

  getResponseDistributionValues(rDistribution: any) {
    rDistribution.dates = this.pollDatesForResponseDistribution;
    rDistribution.onInsight = true;
    this.responseDistribution = new Observable(subcriber => {
      subcriber.next(rDistribution);
    });
  }

  private getMemberActivity() {
    this.api.getMemberActivity(this.currentTeamUuid).subscribe(
      res => {
        if (res.teamMembersReport) {
          this.members = res.teamMembersReport;
        }
        // this.ngAfterViewInit();
      }, err => {
        console.log(err);
      }
    );
  }

  getProgressbarColorClass(color: any) {
    switch (color) {
      case 'green':
        return 'green';
      case 'yellow':
        return 'yellow';
      case 'orange':
        return 'orange';
      case 'red':
        return 'red';
      default:
        return '';
    }
  }

  sortBest(best: boolean) {
    this.actedUpon = false;
    if ((best && this.bestButton) && !this.matrixSelected) {
      this.bestButton = false;
      this.statements = [...this.allStatements];
      return;
    } else if ((!best && this.worstButton) && !this.matrixSelected) {
      this.worstButton = false;
      this.statements = [...this.allStatements];
      return;
    } else if ((best && this.bestButton) && this.matrixSelected) {
      this.bestButton = false;
      this.statements = [...this.allStatements];
      return;
    } else if ((!best && this.worstButton) && this.matrixSelected) {
      this.worstButton = false;
      this.statements = [...this.allStatements];
      return;
    }

    if (best) {
      this.bestButton = true;
      this.worstButton = false;
      this.greenStatements.sort((a: any, b:any) => b.question.questionScore.score - a.question.questionScore.score);
      this.yellowStatements.sort((a: any, b:any) => b.question.questionScore.score - a.question.questionScore.score);
      this.orangeStatements.sort((a: any, b:any) => b.question.questionScore.score - a.question.questionScore.score);
      this.redStatements.sort((a: any, b:any) => b.question.questionScore.score - a.question.questionScore.score);
      this.statements = this.greenStatements.concat(this.yellowStatements, this.orangeStatements, this.redStatements);
    } else {
      this.bestButton = false;
      this.worstButton = true;
      this.greenStatements.sort((a: any, b:any) => a.question.questionScore.score - b.question.questionScore.score);
      this.yellowStatements.sort((a: any, b:any) => a.question.questionScore.score - b.question.questionScore.score);
      this.orangeStatements.sort((a: any, b:any) => a.question.questionScore.score - b.question.questionScore.score);
      this.redStatements.sort((a: any, b:any) => a.question.questionScore.score - b.question.questionScore.score);
      this.statements = this.redStatements.concat(this.orangeStatements, this.yellowStatements, this.greenStatements);
    }
  }

  sortMatrix(category: any, indicator: any) {
    this.matrixSelected = false;
    this.lastCategory = category;
    this.lastIndicator = indicator;
    this.matrix.categories[this.lastMatrix.category] = false;
    this.matrix.indicators[this.lastMatrix.indicator] = false;
    if (this.lastMatrix.category !== category || this.lastMatrix.indicator !== indicator) {
      this.matrixSelected = true;
      this.matrix.categories[category] = true;
      this.matrix.indicators[indicator] = true;
      // this.sortStatements();
      this.getMatrixStatements();
      this.lastMatrix = {
        category: category,
        indicator: indicator
      };
    } else if (this.lastMatrix.category === category && this.lastMatrix.indicator === indicator) {
      this.matrixSelected = false;
      this.lastCategory = '';
      this.lastIndicator = '';
      this.statements = [...this.allStatements];
      this.getStatement();
      this.lastMatrix = {
        category: '',
        indicator: ''
      };
    }
  }

  private sortStatements() {
    let newStatements: any = [];
    this.allStatements.forEach((el: any) => {
      if (this.matrix.categories[el.categories[0].defaultTitle] && this.matrix.indicators[el.indicators[0].defaultTitle]) {
        newStatements.push(el);
      }
    })
    this.statements = [...newStatements];
    this.processStatements({questions: this.statements});
  }

  assignAction(task: any, statement: any, member: any) {
    this.data.showLoading();
    let body =
      {
        assigneeUuid: member.user.uuid,
        questionUuid: statement.uuid,
        taskUuid: task.uuid
      }

    this.api.postAssignAction(body, this.currentTeamUuid).subscribe(
      (res: any) => {
        this.statements.forEach((el: any) => {
          if (el.question.uuid === statement.uuid) {
            // this is the statement, now we need the action to make it like it is assigned
            el.question.tasks.forEach((x: any) => {
              if (x.task.uuid === task.uuid) {
                x.frequency = x.frequency + 1;
                x.task.assignedTasks[0] = res;
              }
            })
            el.hasAssignee = el.hasAssignee + 1;
          }
        });
        this.data.hideLoading();
        // add the task to the statement tasks
      }, error => {
        console.log(error);
        this.data.hideLoading();
      }
    );
  }

  createAction(statement: any, member: any) {
    const action = this.challengesService.newStatementsActions[statement];
    const form = this.challengesService.createActionForm;
    form.controls['actionTitle'].setValue(action.actionTitle);
    form.controls['actionDescription'].setValue(action.actionDescription);

    if (form.status.toLowerCase() === 'valid') {
    } else {
      this.challengesService.newStatementsActions[statement].titleError = this.challengesService.newStatementsActions[statement].actionTitle.length === 0;
      this.challengesService.newStatementsActions[statement].descriptionError = this.challengesService.newStatementsActions[statement].actionDescription.length === 0;
      return;
    }

    let body =
      {
        title: action.actionTitle,
        questionUuid: statement,
        description: action.actionDescription
      }
    this.data.showLoading();
    this.api.postCreateAction(body, this.currentTeamUuid).subscribe(
      (res: any) => {
        // add the task to the statement tasks
        this.statements.forEach((el: any, index: number) => {
          if (el.question.uuid === statement) {
            let body = {
              task: res,
              frequency: 0
            }
            if (member !== null) {
              // get the index of the statement
              this.assignAction(res, el.question, member);
              body.frequency = 1;
            }
            el.question.tasks.unshift(body);
            // remove input data
            this.challengesService.newStatementsActions[statement].actionTitle = '';
            this.challengesService.newStatementsActions[statement].actionDescription = '';
            this.data.hideLoading();
          }
        })
      }, error => {
        console.log(error)
        this.data.hideLoading();
      }
    );
  }

  notRelevant(task: any, statement: any) {
    this.data.showLoading();
    this.api.patchTask(task.task.uuid, 'useless', false, this.currentTeamUuid, statement.question.uuid).subscribe(
      (res: any) => {
        this.data.hideLoading();
        this.statements.forEach((el: any) => {
          if (el === statement) {
            el.question.tasks.forEach((x: any, index: number) => {
              if (x === task) {
                el.question.tasks.splice(index, 1);
              }
            })
          }
        })
      }, () => {
        this.data.hideLoading();
      }
    );
  }

  getStatementsSelector(select: object) {
    // check if matrix is selected
    // @ts-ignore
    this.dateSelected = select.value
    if (this.lastCategory === '' && this.lastIndicator === ''){
      this.getStatement();
    } else {
      this.getMatrixStatements()
    }
  }

  actedUponSort() {
    if(!this.actedUpon) {
      this.actedUpon = true;
      this.bestButton = false;
      this.worstButton = false;
      
    } else if (this.actedUpon) {
      this.actedUpon = false;
    }
    this.statements.sort(function (x: any, y: any) {
      return x.hasAssignee > 0 ? -1 : y.hasAssignee === 0 ? 1 : 0;
    });
  }

  clickHeader(index: number) {
    if (!this.statements[index].question.gotTasks || this.statements[index].question.tasks.length === 0) {
      this.api.getStatementActions(this.currentTeamUuid, this.statements[index].question.uuid).subscribe(
        res => {
          this.statements[index].question.tasks = [];
          res.forEach((el: any) => {
            this.statements[index].question.tasks.push(
              {
                frequency: 0,
                task: el
              }
            );
          });
          if (res.length > 0) {
            this.statements[index].question.gotTasks = true;
            this.statements[index].question.noTasks = false;
          }
        }, error => {
          console.log(error);
        }
      )
    }
  }

  makeOwnToggle(x: number) {
    const makeOwn = document.getElementById("makeOwn" + x);
    makeOwn?.classList.toggle('toggle-solution');
    setTimeout(()=>{
      document.getElementById('ownActionTitle')?.focus()
    },200)
  }

   checkWizard() {
    if (this.data.wizardOn ){
      if (this.activeRoute.snapshot.queryParams['start_tour'] === undefined) {
        this.router.navigate([this.router.url], {queryParams: {start_tour: environment.teamInsightWizard}});
        setTimeout(()=>{
          location.reload();
        }, 100)
      }
    }
  }

  getStatementResponseDistribution(index: number): Observable<any>{
    let statementsDate;
    if (this.dateSelected !== 0) {
      statementsDate = this.data.getLastDays(this.dateSelected);
    } else {
      statementsDate = {next: '', today: ''}
    }
    this.pollDatesForResponseDistribution = {startDate: this.statements[index].poll.created.date, endDate: this.statements[index].poll.expireDate.date};
    return this.api.getStatementResponseDistributionByPollUuid(this.currentTeamUuid, this.statements[index].question.uuid, this.statements[index].poll.uuid, statementsDate);
  }

  onOpenAccordion(event: any, index: number) {
    this.getStatementResponseDistribution(index).subscribe((res:any) => {
      if (res) {
        this.getResponseDistributionValues(res);
        this.openedAccordionIndex = index;
        if(!this.statements[index].question.gotTasks && this.statements[index].colorName !== 'green') {
          this.makeOwnToggle(index);
        }
        if(!this.previousShowSelectedTabIndex && this.previousShowSelectedTabIndex != 0) {
          this.previousShowSelectedTabIndex = index;
          this.showSelectedTab[index] = true;
        } else {
          this.showSelectedTab[this.previousShowSelectedTabIndex] = false;
          this.previousShowSelectedTabIndex = index;
          this.showSelectedTab[index] = true;
        }
        this.worstStatementsAccordionIndex = index;
      }
    });
    // this.responseDistribution = this.statements[index]['question']['responseDistribution'];
    // this.responseDistribution.peoplesCount = this.members.length;
    // this.responseDistribution.onInsight = true;
  }

  onCloseAccordion() {
    this.openedAccordionIndex = '';
  }
}
