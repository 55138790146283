import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MustMatch} from "../../helpers/must-match.validator";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {SearchCountryField, CountryISO, PhoneNumberFormat} from 'ngx-intl-tel-input';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-member-info',
  templateUrl: './member-info.component.html',
  styleUrls: ['./member-info.component.scss']
})
export class MemberInfoComponent implements OnInit {

  userDetailsForm: FormGroup =  this.formBuilder.group({
    firstname: ['', [
      Validators.required,
      Validators.minLength(1)]],
    lastname: ['', [
      Validators.required,
      Validators.minLength(1)]],
    displayName: ['', [
      Validators.minLength(1)]],
    phone: new FormControl(''),
    password: ['', [
      Validators.minLength(6)]],
    confirmPassword: ['', []],
  }, {
    validator: MustMatch('password', 'confirmPassword')
  });
  showUserDetails = false;
  submitted = {accountSubmitted: false, workspaceSubmitted: false};
  phoneError = false;
  userDetails: any;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  PhoneNumberFormat = PhoneNumberFormat;
  agreeCheck: boolean = false;

  selectedCountryCode = 'dk';
  countryCodes = ['dk', 'gb'];
  apiLanguages = [];
  languageDisplayed = 'Danish';
  userLang :any = {};
  constructor(public api: ApiwrapperService, public formBuilder: FormBuilder, public data: DataManipulationService, public translate: TranslateService) {
    this.getUserDetails()

    // @ts-ignore
    this.userLang =JSON.parse(localStorage.getItem('userDetails')).detail.preferredLanguage;

  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.data.showLoading();
    if (this.userDetailsForm.invalid) {
      this.data.hideLoading();
      this.userDetailsForm.markAsDirty();
      if (this.userDetailsForm.value['phone'] === null || this.userDetailsForm.value['phone'].nationalNumber.length === 0) {
        this.phoneError = true;
      }
      this.userDetailsForm.markAllAsTouched();
      this.data.showAlertError('Please complete the form')
      return;
    } else {
      this.updateUserAccount();
    }
  }

  updateUserAccount() {
    this.data.showLoading();
    this.submitted.accountSubmitted = true;
    if ((this.userDetailsForm.invalid && !this.phoneError) && this.userDetailsForm.value.phone === null)  {
      if (this.userDetailsForm.value.firstname === null || this.userDetailsForm.value.firstname === '') {
        // @ts-ignore
        this.userDetailsForm.controls['firstname'].markAsTouched(true);
      }
      if (this.userDetailsForm.value.lastname === null || this.userDetailsForm.value.lastname === '') {
        // @ts-ignore
        this.userDetailsForm.controls['lastname'].markAsTouched(true);
      }
      // show input that have errors
      this.data.hideLoading();
      return;
    } else {
      let data;
      if (this.userDetailsForm.value.password === '') {
        data = {
          firstname: this.userDetailsForm.value.firstname,
          lastname: this.userDetailsForm.value.lastname,
          displayName: this.userDetailsForm.value.displayName,
        };
        if (this.userDetailsForm.value.phone !== null) {
          // @ts-ignore
          data.phone = this.userDetailsForm.value.phone.internationalNumber;
        }
      } else {
        data = {
          firstname: this.userDetailsForm.value.firstname,
          lastname: this.userDetailsForm.value.lastname,
          displayName: this.userDetailsForm.value.displayName,
          // phone: this.userDetailsForm.value.phone.internationalNumber,
          password: this.userDetailsForm.value.password,
          passwordConfirm: this.userDetailsForm.value.confirmPassword
        };

        if (this.userDetailsForm.value.phone !== null) {
          // @ts-ignore
          data.phone = this.userDetailsForm.value.phone.internationalNumber;
        }
      }
      this.api.patchUser(this.userDetails.uuid, data).subscribe(res => {
        // this.data.showAlert('Your account was updated successfully.\n');
        // this.submitted.accountSubmitted = false;
        this.data.setChangedUserData(true);
        // @ts-ignore
        this.api.getUserDetails(localStorage.getItem('WORKSPACE_UUID')).subscribe(res=>{
          this.data.hideLoading();
          location.reload();
        })
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('Could not update account!');
        this.data.showAlertError(typeof err.error.error.messages === 'object' ?
          this.data.parseError(err.error.error.messages) : err.error.error.messages);
      });
    }
  }

  uploadImage(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.data.showLoading();
            this.api.uploadAvatar(file).subscribe(
              (res: any) => {
                this.userDetails.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.data.hideLoading();
              },
              (error) => {
                this.data.hideLoading();
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  getUserDetails() {
    this.data.showLoading();
    // @ts-ignore
    this.api.getUserDetails(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.userDetails = res;
        // this.getMyTeamsFromWorkspace();
        this.userDetailsForm.setValue({
          firstname: res.detail.firstname,
          lastname: res.detail.lastname,
          displayName: res.detail.displayName,
          phone: res.detail.phone,
          password: '',
          confirmPassword: ''
        });
        this.showUserDetails = true;
        // @ts-ignore
        document.getElementById('memberInfoModal').click();
        this.data.hideLoading();
        this.apiLanguages = this.data.apiLanguages;
        this.getDisplayLanguage(res.detail.preferredLanguage);
      }, error => {
        console.log(error);
      });
  }

  checkPhone() {
    if (this.userDetailsForm.value['phone'] !== null && (this.userDetailsForm.value['phone'].nationalNumber.length > 0 && this.userDetailsForm.controls['phone']?.invalid)) {
      this.phoneError = false;
      return true;
    } else {
      this.phoneError = true;
      return false;
    }
  }

  getDisplayLanguage(language: any) {
    // @ts-ignore
      if (language.language === 'English') {
        this.languageDisplayed = 'English'
      } else if (language.language === 'Danish') {
        this.languageDisplayed = 'Dansk'
    }

    if (language.language === 'English') {
      this.selectedCountryCode = 'gb'
    } else if (language.language === 'Danish') {
      this.selectedCountryCode = 'dk'
    }
    this.translate.use(language.tag.split("_")[0]);
  }

  changeLanguage(data: any) {
    if (data === 'gb') {
      data = 'en';
    }
    this.apiLanguages.forEach((el: any) => {
      if (data === el.tag.split("_")[0].toLowerCase() || data === el.tag.split("_")[1].toLowerCase()) {
        this.data.language.language = el.tag.split("_")[0];
        this.getDisplayLanguage(el);
        this.api.patchUser(this.userDetails.uuid, {preferredLanguageUuid: el.uuid}).subscribe();
      }
    });
  }
}
