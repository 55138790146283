import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {Subscription, concat} from "rxjs";
import {Router} from "@angular/router";
import {Options, LabelType} from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-collector',
  templateUrl: './collector.component.html',
  styleUrls: ['./collector.component.scss']
})
export class CollectorComponent implements OnInit, OnDestroy {

  frequency: any;
  frequencyKeys: any = [];
  frequencySelected: any;
  startCollectorDate = 'TODAY';
  pollingFrequency: any = [];

  todayDate: Date;
  pikedDate: Date;
  minDateValue: Date;
  teams: [] = []
  private slide = 0;
  // teamsSubs: Subscription;
  collectorShow: Subscription;
  plan: any;
  private paidFeatureModalOpen: boolean | undefined = false;
  showFeedbackDemo: boolean = false;
  selectedFrequency: string = 'weekly';
  recomandedStatements: number = 8;
  sliderValue: number = 8;
  sliderTickTitle: string = '';
  tsFrequencySelected: any = 'weekly';
  tsSelectedFrequency: string = 'weekly';

  options: Options = {
    floor: 3,
    ceil: 15,
    showTicks: false,
    showTicksValues: false,
    // translate: (value: number): string => {
    //   if(value === 8) {
    //     return this.sliderTickTitle.concat(value + ' statements' + '<p>(recomanded)</p>');
    //   } else {
    //     return value +' statements';
    //   }
    // }
  };
  public reportFrequency= {
    weekly:false,
    '14_days':false,
    monthly:false
  };
  private pollingSuggestedFrequency =[];

  constructor(public api: ApiwrapperService, public data: DataManipulationService, private router: Router) {
    this.getFrequency();

    this.plan = data.getWorkspacePlan()
    this.todayDate = new Date();
    this.pikedDate = new Date();
    this.minDateValue = new Date();

    this.collectorShow = this.data.collectorShowSub.subscribe((show: any) => {
      this.teams = this.data.collectorTeams;
      if (show) {
        this.selectedFrequency = this.data.editTeamCollector.pollingFrequency.type;
        this.frequencySelected = this.data.editTeamCollector.pollingResponseFrequency.type;
        this.tsFrequencySelected = this.data.editTeamCollector.pollingFrequency.type;
        this.sliderValue = this.data.editTeamCollector.questionNumber;
        this.pollingFrequency[this.data.editTeamCollector.pollingFrequency.type].forEach((el: any, i: number) => {
          if (el.uuid === this.data.editTeamCollector.pollingFrequency.uuid) {
            if (i > 0) {
              let iterations = 0;
              let interv = setInterval(() => {
                const nextSlide = document.getElementById('nextSlide');
                nextSlide?.click();
                iterations = iterations + 1;
                if (iterations === i) {
                  clearInterval(interv);
                }
              }, 800);
            }
          }
        })
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    // this.teamsSubs.unsubscribe();
    this.collectorShow.unsubscribe();
  }

  changeSlides() {
    this.selectedFrequency = this.frequencySelected;
    this.slide = 0;
    this.setStatementsRange();
  }

  changeTsFrequency() {
    this.tsSelectedFrequency = this.tsFrequencySelected;
  }

  setStatementsRange() {
    switch(this.frequencySelected) {
      case 'weekly':
        this.recomandedStatements = 8;
        this.sliderValue = 8;
        this.options = {
          floor: 3,
          ceil: 15
        }
        break;
      case '14_days':
        this.recomandedStatements = 15;
        this.sliderValue = 15;
        this.options = {
          floor: 8,
          ceil: 20
        }
        break;
      case 'monthly':
        this.recomandedStatements = 18;
        this.sliderValue = 18;
        this.options = {
          floor: 12,
          ceil: 25
        }
        break;
    }
  }

  pickDate() {
    this.startCollectorDate = 'PIKED';
  }

  private getFrequency() {
    this.frequencyKeys = [];
    this.api.getPollingFrequencies().subscribe(
      res => {
        this.frequencyKeys = [];
        Object.entries(res).forEach((el: any, index) => {
          el[1].forEach((elFrequency : any) => {
            if (index === 0) {
              this.frequencySelected = elFrequency.type;
            }
            // @ts-ignore
            this.frequencyKeys.push({title: elFrequency.name.toLowerCase(), type: elFrequency.type ,disabled: false});
          })
        });
        this.pollingFrequency = res;
        this.getSuggestions();
      }, err => {
        console.log(err);
      })
  }

  statementSlide(b: boolean) {
    if (b) {
      // @ts-ignore
      if (this.slide === this.pollingFrequency[this.frequencySelected].length - 1) {
        this.slide = 0;
      } else {
        this.slide = this.slide + 1;
      }
    } else {
      if (this.slide === 0) {
        this.slide = this.pollingFrequency[this.frequencySelected].length - 1;
      } else {
        this.slide = this.slide - 1;
      }
    }
  }

  doneSettingLoop() {
    // change to new api requirement
    let startDate;
    let endDate
    if (this.startCollectorDate === 'TODAY') {
      startDate = this.todayDate.getFullYear() + '-' + (this.todayDate.getMonth() + 1) + '-' + this.todayDate.getDate();
      endDate = this.todayDate.getFullYear() + 1 + '-' + (this.todayDate.getMonth() + 1) + '-' + this.todayDate.getDate();
    } else {
      startDate = this.pikedDate.getFullYear() + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate();
      endDate = this.pikedDate.getFullYear() + 1 + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate();
    }
    let loop = {
      startDate,
      endDate,
      frequency: this.pollingFrequency[this.frequencySelected][this.slide].uuid,
      frequencyType: this.frequencySelected,
      // multipleTeam: this.hasMultipleTeams,
      error: false
    }
    localStorage.setItem('loop', JSON.stringify(loop));
    if (this.frequencySelected === 'weekly') {
      this.startFeedbackLoops();
      // this.onboardingDone = true;

    } else {
      //show the payment details page
      // paymentDetails
      // check if user has payment added
      this.startFeedbackLoops()
      this.paidFeatureModalOpen = true;
    }
  };

  startFeedbackLoops() {
    // get the teams that are ready for collector
    // get the date (now / pick)
    //     "pollingResponseFrequencyUuid": "06f7138e-4ce6-11ea-aba4-f2d473d6d142",
    //     "pollingReportFrequencyUuid": "06f7138e-4ce6-11ea-aba4-f2d473d6d142",
    //     "startDate": "23.08.2023",
    //     "questionNumber": "12"

    // @ts-ignore
    let loop = JSON.parse(localStorage.getItem('loop'));

    let pollingReportFrequencyUuid = '';
    this.pollingSuggestedFrequency.forEach((el: any) =>{
      if (el.type === this.tsFrequencySelected){
        pollingReportFrequencyUuid = el.uuid;
      }
    })

    //for on the teams that are valid for feedback loop
    // api call to start the collectors for the teams
    let count = 0;
    this.data.showLoading();
    let workspaceUuid = localStorage.getItem('WORKSPACE_UUID');
    this.teams.forEach(el => {
        // @ts-ignore
        this.api.postTeamPolling(el.uuid,
          {
            pollingResponseFrequencyUuid: loop.frequency,
            pollingReportFrequencyUuid,
            startDate: loop.startDate,
            endDate: loop.endDate,
            questionNumber: this.sliderValue
          }).subscribe(
          res => {
            ++count;
            if (count === this.teams.length) {
              this.api.patchWorkspace(workspaceUuid, {teamStartGuide: true}).subscribe(
                resolve => {
                  // set the localstorage workspace to the new workspace
                  localStorage.setItem('WORKSPACE', JSON.stringify(resolve));
                }
              );
              this.data.hideLoading();
              localStorage.removeItem('loop');
              // @ts-ignore
              document.getElementById('closeFeedbackLoopModal').click();
              this.data.getAllTeams = false;
              // if start from team get poll in team
              if (this.data.openFromTeam) {
                this.data.addedMembersToTeam(true);
              }

              if (this.data.openFromDashboard) {
                this.data.dashboardCollector(true);
                this.data.openFromDashboard = false;
              }

              this.data.getWorkspaceTeams();
              if (this.router.url.includes('workspace-member-leader')){
                // if in workspace member leader
                this.data.setLeaderCollector(true);
              }
            }
          }, error => {
            ++count;
            if (count === this.teams.length) {
              this.data.hideLoading();
              loop.error = true;
              this.router.url
              localStorage.setItem('loop', JSON.stringify(loop));

              if (this.router.url.includes('workspace-member-leader')){
                // if in workspace member leader
                this.data.setLeaderCollector(true);
              }

              // @ts-ignore
              document.getElementById('closeFeedbackLoopModal').click();
              this.data.showAlertError(error.error.message[0])
              this.data.getAllTeams = false;
              this.data.getWorkspaceTeams();
            }
          });
      }
    )
  }

  // patchCollector(){
  //   console.log('team collector :: ', this.pollingFrequency[this.frequencySelected][this.slide].uuid);
  // }

  patchCollector() {
    //todo change 'pollingFrequencyUuid' to 'pollingResponseFrequencyUuid'
    // add  'pollingReportFrequencyUuid'

    let pollingReportFrequencyUuid = '';
    this.pollingSuggestedFrequency.forEach((el: any) =>{
      if (el.type === this.tsFrequencySelected){
        pollingReportFrequencyUuid = el.uuid;
      }
    })
    this.data.showLoading();
    this.api.patchActivePolling(this.data.editTeamCollector.uuid, this.data.currentTeam.uuid, {
      status: 'active',
      pollingReportFrequencyUuid,
      pollingResponseFrequencyUuid: this.pollingFrequency[this.frequencySelected][this.slide].uuid,
      questionNumber: this.sliderValue
    }).subscribe(
      res => {
        this.data.editTeamCollector = res;
        this.data.setShowCollector(true);
        this.data.showAlert('Collector updated')
        // @ts-ignore
        document.getElementById('closeFeedbackLoopModal').click();
        // if in team (single team update) get collector for refreshing in page

        this.data.hideLoading()
      }, err => {
        console.log(err)
        this.data.showAlertError('Failed to updated collector')
        this.data.hideLoading()
      }
    )
  }

  goToBilling() {
    document.getElementById('closeFeedbackLoopModal')?.click();
    this.data.lastRouteBeforeBilling = this.router.url;
    this.router.navigate(['/settings/billing']);
  }

  resumeCollector() {
    let body: any = {status: 'active'};

    // if the date is set for later add date to the body
    if (this.startCollectorDate === 'TODAY') {
      body.startDate = this.pikedDate.getFullYear() + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate();
    } else {
      body.startDate = this.pikedDate.getFullYear() + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate()
    }
    // if the frequency is modified add it to the body
    if (this.pollingFrequency[this.frequencySelected][this.slide].uuid !== this.data.editTeamCollector.pollingFrequency.uuid){
      body.pollingFrequencyUuid = this.pollingFrequency[this.frequencySelected][this.slide].uuid
    }
    this.data.showLoading();
    this.api.patchActivePolling(this.data.editTeamCollector.uuid, this.data.currentTeam.uuid, body).subscribe(
      res => {
        this.data.editTeamCollector = res;
        this.data.showAlert('Collector updated')
        // @ts-ignore
        document.getElementById('closeFeedbackLoopModal').click();
        this.data.addedMembersToTeam(true);
        this.data.hideLoading()
      }, err => {
        console.log(err)
        this.data.showAlertError('Failed to updated collector')
        this.data.hideLoading()
      }
    )
  }

  collectorEditChanged() {
    if (this.data.pauseCollector) {
      // possible to check for second box [this.data.editTeamCollector.createDate.date  - this.data.editTeamCollector.startDate.date ]
      if ((this.pollingFrequency[this.frequencySelected][this.slide].uuid !== this.data.editTeamCollector.pollingFrequency.uuid)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getSuggestions() {
    this.api.getPollingSuggestedFrequency(this.pollingFrequency[this.frequencySelected][this.slide].uuid, this.sliderValue).subscribe(
        res => {
          res.sort((a:any,b:any) => a.min - b.min)
          this.pollingSuggestedFrequency = res;
          this.reportFrequency= {
            weekly:false,
            '14_days':false,
            monthly:false
          }
          this.tsFrequencySelected=res[0].type;
          res.forEach((el: any)=>{
            if (el.type === 'weekly') {
              this.reportFrequency.weekly = true;
            }   if (el.type === '14_days'){
                this.reportFrequency["14_days"] =true;
            }   if (el.type === 'monthly'){
                this.reportFrequency.monthly =true;
            }
          })
        }
    )
  }

  checkDisable(title: string) {
    // @ts-ignore
    return !this.reportFrequency[""+title];
  }
}
