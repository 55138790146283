import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {ApiwrapperService} from '../../services/apiwrapper.service';
import {DataManipulationService} from '../../services/data-manipulation.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  title = 'successTeam';
  activated = false;
  errorActivation = false;
  emailAgain = false;
  email: any;
  emailSent = false;

  constructor(private router: Router, private data: DataManipulationService,
              private activeRoute: ActivatedRoute,
              private apiwrapper: ApiwrapperService, private translate: TranslateService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    if (this.activeRoute.snapshot.paramMap.get('hash') != null) {
      this.activateUser(this.activeRoute.snapshot.paramMap.get('hash'));
    }
    // document.getElementById('loginForm').click();
  }

  activateUser(hash: string | null) {
    this.authenticationService.activateUser(hash).subscribe(() => {
      this.data.showAlert('Your account was verified successfully. Log in to continue the onboarding process.');
      this.activated = true;
      // this.router.navigate(['login']);
      this.router.navigate(['login']);
    }, () => {
      this.router.navigate(['login']);
      this.errorActivation = true;
      this.data.showAlertError('Your account could not be activated. Try again or contact our support team.');
    });
  }

  resendActivation() {
    if (this.email === undefined) {
      this.apiwrapper.postResendInvitation(this.email).subscribe(
        res => {
          this.emailSent = true;
          this.emailAgain = false;
        }, error => {
          this.data.showAlertError(error.error.error.messages[0]);
        }
      );
    } else {
      this.data.showAlertError('Please insert email!');
    }
  }
}
