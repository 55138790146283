import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Options, LabelType} from '@angular-slider/ngx-slider';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {Router} from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-feedback-questions-demo',
  templateUrl: './feedback-questions-demo.component.html',
  styleUrls: ['./feedback-questions-demo.component.scss']
})
export class FeedbackQuestionsDemoComponent implements OnInit {

  @Output() closeFeedbackModal = new EventEmitter<boolean>();
  teamName: string = 'Operation';
  disableButton: boolean = false;
  previousQuestionButton: boolean = true;
  value: any = 4;
  options: Options = {
    step: 1,
    showTicksValues: false,
    showTicks: true,
    stepsArray: [],
    translate: (value: number, label: LabelType) => {
      const lastElement: number = this.feedbackQuestions ? 6 : 0;
      if (this.answers) {
        switch (label) {
          case LabelType.Floor:
            return this.answers[0].title;
          case LabelType.Ceil:
            return this.answers[lastElement].title;
          default:
            return this.answers[value].title;
        }
      }
    }
  };
  feedbackQuestions: { title: string , responses: { title: string, translation: string|null, uuid: string, weight: number }[] }[] = [
    {
      title: 'We motivate each other in the team',
      responses: [
        {title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0},
        {title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6},
        {title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2},
        {title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8},
        {title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4},
        {title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83},
        {title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100}
      ]
    },
    {
      title: 'If a team member fails, we collectively support that person',
      responses: [
        {title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0},
        {title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6},
        {title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2},
        {title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8},
        {title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4},
        {title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83},
        {title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100}
      ]
    },
    {
      title: 'My workload is consistent with what I feel I can overcome',
      responses: [
        {title: "Totally disagree",
          translation: null,
          uuid: "3552ccb4-0cc9-11ed-9948-0e7fbfa73d13",
          weight: 0},
        {title: "Mostly disagree",
          translation: null,
          uuid: "3552cfca-0cc9-11ed-95d3-0e7fbfa73d13",
          weight: 16.6},
        {title: "Slightly disagree",
          translation: null,
          uuid: "3552d290-0cc9-11ed-8350-0e7fbfa73d13",
          weight: 33.2},
        {title: "Neither agree or disagree",
          translation: null,
          uuid: "3552d54c-0cc9-11ed-b175-0e7fbfa73d13",
          weight: 49.8},
        {title: "Slightly agree",
          translation: null,
          uuid: "3552d812-0cc9-11ed-8a38-0e7fbfa73d13",
          weight: 66.4},
        {title: "Mostly agree",
          translation: null,
          uuid: "3552dac4-0cc9-11ed-b762-0e7fbfa73d13",
          weight: 83},
        {title: "Totally agree",
          translation: null,
          uuid: "3552dd80-0cc9-11ed-bbd6-0e7fbfa73d13",
          weight: 100}
      ]
    }
  ];
  answers: any;
  feedbackForm = new FormGroup({
    answer: new FormControl('', [Validators.required, Validators.minLength(1)])
  });
  questionAnswer: any;
  currentQuestion: any;
  questionNumber: number = 0;
  currentPoll = [];
  private collector: any = {teamUuid: '', teamCollector: ''};
  public currentPollDetails: {
    poll: { uuid: any };
    maxStatements: any;
    currentPage: any;
    uuid: any;
    maxPage: any
  } | undefined;
  showCurrentQuestion = false;
  public onboarding: boolean = false;
  feedbackQuestionProgress: any;
  lastQuestion: any = {};
  fromPreviousQuestion: boolean = false;

  constructor(public data: DataManipulationService, public api: ApiwrapperService, private router: Router, private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.translate.get('feedback_demo_questions.question').subscribe((res) => {
      this.feedbackQuestions.forEach((question: any, index: number) => {
        question.title = res['titles']['title'+index];
        question.responses.forEach((response: any, index: number) => {
          response.title = res['responses']['response'+index];
        });
      });
    });
    this.currentPollDetails = {
      poll: {uuid:''},
      uuid: '',
      maxStatements: this.feedbackQuestions.length,
      currentPage: 1,
      maxPage: 1,
    };
    this.showModalDialog();
    this.currentQuestion = this.feedbackQuestions[this.questionNumber];
    this.getAnswerLabels([]);
    this.getFeedbackProgress();
  }

  showModalDialog() {
    const openModal = document.getElementById('questionModal');
    openModal?.click();
  }

  editPreviousAnswer() {
    const titleAnswer = this.answers[this.feedbackForm.controls['answer'].value].title;
      let answerUuid = '';
      //@ts-ignore
      this.lastQuestion.responses.forEach((el) => {
        if (el.title === titleAnswer) {
          answerUuid = el.uuid;
        }
      });
      const body = {
        //@ts-ignore
        pollResponseUuid: answerUuid,
      };
      this.api.patchUserPollResopnse(body, this.lastQuestion.pollUuid, this.collector.teamUuid).subscribe(
        (res: any) => {
          this.feedbackForm.controls['answer'].setValue(3);
          this.questionNumber = this.questionNumber + 1;
          this.lastQuestion = {};
          this.previousQuestionButton = true;
          this.fromPreviousQuestion = false;
          return;
        }, (err: { error: { error: { messages: string[]; }; }; }) => {
          if (err.error.error.messages[0]) {
            this.previousQuestionButton = true;
            this.fromPreviousQuestion = false;
            // @ts-ignore
            const closeModal = document.getElementById('questionModalClose');
            closeModal?.click();
            this.data.setTeamCollectorSubs(false);
            this.data.showAlertError(err.error.error.messages[0]);
          }
        }
      );
  }

  goToPreviousQuestion() {
    this.fromPreviousQuestion = true;
    this.previousQuestionButton = true;
    this.feedbackForm.controls['answer'].setValue(this.lastQuestion.responseIndex);
    this.questionNumber = this.questionNumber - 1;
  }

  getAnswerLabels(answers: any) {
    const labels = [];
    let ans: { title: string; }[] = [];
    if (answers.length === 0) {
      for (let i = 0; i < this.feedbackQuestions.length; i++) {
        ans = this.feedbackQuestions[i].responses;
      }
    } else {
      ans = answers;
    }


    for (let i = 0; i < ans.length; i++) {
      if (i === 3) {
        this.questionAnswer = i;
        this.feedbackForm.controls['answer'].setValue(i);
      }
      labels.push({value: i, title: ans[i].title});
    }
    this.options.stepsArray = labels;
    this.answers = labels;
  }

  nextQuestion() {
    if (this.currentPollDetails?.maxStatements - this.questionNumber > 1) {
      this.questionNumber = this.questionNumber + 1;
      this.currentQuestion = this.feedbackQuestions[this.questionNumber];
      this.getFeedbackProgress();
      // @ts-ignore
      this.feedbackForm.controls.answer.setValue(3);
    } else {
      const closeModal = document.getElementById('questionModalClose');
      closeModal?.click();
    }
  }

  getFeedbackProgress() {
    this.feedbackQuestionProgress = (this.questionNumber / this.currentPollDetails?.maxStatements * 100) + '%';
  }

  closeModal() {
    const closeModal = document.getElementById('questionModalClose');
    closeModal?.click();
    this.closeFeedbackModal.emit(false);
  }
}
