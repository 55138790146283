<div class="main-container">
    <div class="checkins-header">
        <div class="title-container">
            <h2 class="checkins-header-title">{{'custom_poll_onboarding_temp_checkins_title' | translate}}</h2>
        </div>
        <div class="checkins-main-body">
            <div class="checkins-category-container">
                <form class="category-form" [formGroup]="checkinsForm">
                    <div (click)="getCheckinStatements(checkin.uuid, i)" [ngClass]="selectedCategoryToShowStatements[i] ? 'selected':''" class="category-container" *ngFor="let checkin of checkinsCategories, let i = index">
                        <div class="category-header">
                            <input class="category-check" type="checkbox"
                                [ngClass]="checkin.slug === 'month-1-check-in' || checkin.slug === 'week-2-check-in' ? 'locked':''" 
                                [checked]="checkin.selected || (checkin.slug === 'month-1-check-in' || checkin.slug === 'week-2-check-in')" 
                                [disabled]="checkin.slug === 'month-1-check-in' || checkin.slug === 'week-2-check-in'" 
                                (change)="onChange(checkin.uuid, checkin.title, false, $event)"
                                (click)="$event.stopPropagation()">
                            <h2 class="category-check-title">{{checkin.title}}</h2>
                            <img class="lock-icon" *ngIf="checkin.slug === 'month-1-check-in' || checkin.slug === 'week-2-check-in'" src="../../../../../assets/svg/lock.svg">
                            <div class="lock-info-container">
                                <img class="info-circle-icon" src="../../../../../assets/svg/info_circle_icon.svg">
                                <span>{{'custom_poll_onboarding_temp_lock_text' | translate}}</span>
                            </div>
                            <i [ngClass]="selectedCategoryToShowStatements[i] ? 'checked':''" class="bi bi-chevron-right"></i>
                        </div>
                        <div class="category-check-description" [innerHTML]="checkin.description"></div>
                    </div>
                </form>
            </div>
            <div class="checkins-category-statements-container">
                <h2 style="font-size: 1.8rem">{{'custom_poll_gen_categ_prew' | translate}}</h2>
                <div [ngStyle]="checkinCategoryStatements.length ? {}:{'align-items':'center'}" class="checkins-category-statement-main">
                    <span *ngIf="!checkinCategoryStatements.length" class="no-statements-info">{{'custom_poll_onboarding_temp_checkin_no_statements' | translate}}</span>
                    <div class="checkins-category-statement" *ngFor="let statement of checkinCategoryStatements">
                        <div class="quote">
                            <i class="bi bi-quote"></i>
                        </div>
                        <span class="statement-title">{{statement.title}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="checkins-footer">
        <button class="checkins-back-btn" (click)="saveAndFinish()" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button class="checkins-next-btn" 
            pButton type="button" 
            label="{{'custom_poll_onboarding_temp_checkin_next_step_btn' | translate}}"
            (click)="nextStep()"></button>
    </div>
</div>