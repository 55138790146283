import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApiwrapperService} from 'src/app/services/apiwrapper.service';
import {DataManipulationService} from 'src/app/services/data-manipulation.service';
import {environment} from '../../../environments/environment';
import {ModalsComponent} from "../../components/modals/modals.component";
import {Router} from "@angular/router";
import {resolve} from "@angular/compiler-cli";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  refreshUserDataSubscription: Subscription;
  //add subscriptions for members and teams

  user: any;
  workspace: any;
  members: any = [];
  currentTime = 1;
  hideStep6 = false;
  reviewResults = false;
  chosePlan = false;

  savedSuccess = {accountSavedSuccess: false, workspaceSavedSuccess: false};
  baseUrl: any;
  public step: number = 1;
  public activeStep: number = 1;
  workspaceTeams: any = [];

  membersSubs: Subscription;
  teamsSubs: Subscription;
  teamResponseRate: number = 0;
  teamResponseDate: string = '';
  isMember: boolean = false;
  memberTeams: any = [];
  gotNextPolling: boolean = false;
  teamResponse: any;
  lastCompleted: Boolean = false;
  private step3Subscription: Subscription;
  userTeamNumber: number = 0;


  constructor(public data: DataManipulationService, public apiwrapper: ApiwrapperService,
              public modal: ModalsComponent, private router: Router) {
    // @ts-ignore
    let workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    // if the start guide is done redirect to team overview of if there is one team to that one
    this.memberTeams = [...this.data.userRole.teams];
        this.userTeamNumber = this.data.userTeams().length;
    if (workspace.startGuide) {
      // this.ngOnDestroy();
      if (this.userTeamNumber > 1) {
        // if the user is owner or admin rerout to worksapce overview
        if (this.data.userRole.role.workspacePriority <= 20){
          this.router.navigate(['/teams-overview']);
        } else {
          this.router.navigate(['/workspace-overview']);
        }
      } else if (this.userTeamNumber === 1) {
        this.memberTeams.forEach((team:any, i: number) => {
          if(team.role != null) {
            this.router.navigate(['/team/' + this.memberTeams[i].team.uuid]);
          }
        });
      }
    }

    this.baseUrl = environment.baseUrl;
    this.membersSubs = data.gotMembersSubs.subscribe((gotMembers: any) => {
      if (gotMembers && !this.isMember) {
        this.getUserWorkspaces();
      }
    })

    this.teamsSubs = this.data.gotTeamsSubs.subscribe((gotTeams: any) => {
      if (gotTeams && !this.isMember) {
        this.workspaceTeams = data.allTeams;
        if (this.workspaceTeams.length > 0) {
          //there is at least 1 team check second step
          this.setProgress(3);
        }
        let teamPollStarted = false;
        this.workspaceTeams.forEach((el: {
          membersEligibleForPoll: number;
          teamHaveActivePolling: any;
          totalMembers: number;
          pollsCount: number;
        }) => {
          if (el.membersEligibleForPoll >= 4) {
            this.setProgress(4);
          }
          if (el.teamHaveActivePolling === 'yes') {
            teamPollStarted = true;
            data.showLoading();
            setTimeout(() => {
              this.getWorkspacePollings();
              this.setProgress(5);
              data.hideLoading();
            }, 2000);
            // get team polling and show data in modal
            // // TODO - remove
            // this.getTeamResponseRate(el);
          }
          if(el.pollsCount > 0 && workspace.startGuideStep === 5) {
            setTimeout(() => {
              if(this.lastCompleted) {
                this.setProgress(6);
              }
            }, 3000);
          }
        })
        this.data.hideLoading();
      }
    })
    if (data.getAllTeams && !this.isMember) {
      this.data.hideLoading();
    }
    this.refreshUserDataSubscription = this.data.changedUserData.subscribe((refreshUserData: any) => {
      if (refreshUserData && !this.isMember) {
        // @ts-ignore
        this.apiwrapper.getUserDetails(localStorage.getItem('WORKSPACE_UUID')).subscribe(res => {
          this.getUserDetails();
        });
      }
    });

    this.step3Subscription = this.data.collectorDashboard.subscribe(refreshTeam => {
      if (refreshTeam) {
        this.setProgress(4);
      }
    });

  }

  ngOnInit(): void {
    // @ts-ignore
    this.isMember = this.data.userRole.role.workspacePriority <= 20;
    // @ts-ignore
    let workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    if (!workspace.teamStartGuide) {
      this.data.showLoading();
      this.apiwrapper.getWorkspacesPollings().subscribe(
        res => {
          if (res.lastCompletedPoll !== null && res.nextCompletedPoll !== null) {
            // if there are collectors started in the workspace patch the workspace teamStartGuide to true
            // and hide the team leader start guide
            this.apiwrapper.patchWorkspace(workspace.uuid, {teamStartGuide: true}).subscribe(
              resolve => {
                // set the localstorage workspace to the new workspace
                localStorage.setItem('WORKSPACE', JSON.stringify(resolve));
                location.reload();
              }
            );
          } else {
            this.data.hideLoading();
          }
        }
      )
    }

    // check all teams of member - if the user has no teams redirect to workspace-member page
    // if the user has at least 1 team check the team role
    //  - if the user has the role of team-leader and the team has report - 0 redirect to workspace-member-leader page
    //  - if the user has the role of team-member and [no team-leader + team report > 0] redirect to workspace-member
    if (this.isMember) {
      let isTeamLeader = false;
      let teamMember: boolean = false;
      let teamsNumber: number = 0
      this.data.userRole.teams.forEach((team: any, index: number) => {
        if (team.role !== null) {
          teamsNumber = +1;
          if (team.role.teamPriority > 10 && team.team.pollsCount < 2) {
            // show home-leader button
            isTeamLeader = true;
          } else if (team.role.teamPriority === 10) {
            // show home-member button
            teamMember = true;
          }
          // if the user is team-leader and the team
        }
        // console.log('XX: ', this.data.userLink);
        // redirect the member to the stuff
        if (index === this.data.userRole.teams.length - 1) {
          if (isTeamLeader) {
            if(!workspace.startGuide) {
              this.data.hideLoading();
              this.data.showHomeMenu = 'member-leader';
              localStorage.setItem('menuHome', 'member-leader');
              this.router.navigate(['/workspace-member-leader']);
            }
            // show the home leader button
          } else if (teamMember) {
            // check if the user has responded to any collectors
            // if the user has answerd collectors redirect to team/teams
            if(!workspace.startGuide) {
              this.data.hideLoading();
              this.data.showHomeMenu = 'member';
              localStorage.setItem('menuHome', 'member');
              this.router.navigate(['/workspace-member']);
            }
            // show the home workspace member button
          } else {
            this.data.showHomeMenu = '';
            this.data.hideLoading();
            if (teamsNumber > 1) {
              if (this.data.userRole.role.workspacePriority <= 20){
                this.router.navigate(['/teams-overview']);
              } else {
                this.router.navigate(['/workspace-overview']);
              }
            } else if (teamsNumber === 1) {
              this.data.userRole.teams.forEach((team:any, i: number) => {
                if(team.role != null) {
                  this.router.navigate(['/team/' + this.data.userRole.teams[i].team.uuid]);
                }
              });
            } else if (teamsNumber === 0) {
              localStorage.setItem('menuHome', 'member');
              this.router.navigate(['/workspace-member']);
            }
          }
        }
      })
    } else {
      // if the workspace has the startGuide = true (the start guide is done) hide the home button from menu
      //
      this.getWorkspace();
      this.getUserDetails();
      // TODO show loading and hide it after reentering dashboard
      // this.data.showLoading();
      this.checkSubscription()
      this.getWorkspacePollings()
    }
  }

  getWorkspace() {
    // @ts-ignore
    this.apiwrapper.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.workspace = res;
        this.getTime();
      }, error => {
      });
  }

  getTime() {
    this.currentTime = new Date().getTime();
  }

  getUserWorkspaces() {
    // get all users from data.allUsers
    this.members = this.data.allMembers;
    if (this.members.length > 4) {
      this.setProgress(2);
    }
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('userDetails'));
    this.members.forEach((el: { workspace: { name: string | any[]; }; }, index: any) => {
        if (el.workspace.name.includes(user.email)) {
          this.members.splice(index, 1);
        }
      }
    );
  }

  getUserDetails() {
    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));
  }

  ngOnDestroy() {
    // @ts-ignore
    this.refreshUserDataSubscription.unsubscribe();
    this.step3Subscription.unsubscribe();
    this.membersSubs.unsubscribe();
    this.teamsSubs.unsubscribe();
  }

  setProgress(step: number) {
    this.data.startGuideStep = step;
    if (this.step < step) {
      this.step = step;
    }
    if (this.members.length === 1 && step === 2) {
      this.activeStep = 1;
    } else if (this.activeStep < step) {
      this.activeStep = step;
    }
  }

  setupCollectors() {
    //setup collectors for all teams
    this.data.collectorTeams = this.data.allTeams;
    this.data.openFromTeam = false;
    this.data.setShowCollector(false);
    this.modal.openCollectorModal()
  }

  seeResults() {
    // if there is lastCompletedPole go to team after updating step
    // + if there is step 6 completed then hide dashboard
    // else open modal
    if (this.lastCompleted) {
      // go direct to team
      this.goToTeam();
    } else {
      document.getElementById('reviewResultsModal')?.click();
    }
  }

  // @ts-ignore
  showSaved(block) {
    if (block === 'account') {
      this.savedSuccess.accountSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.accountSavedSuccess = false;
      }, 3001);
    }
    if (block === 'workspace') {
      this.savedSuccess.workspaceSavedSuccess = true;
      setTimeout(() => {
        this.savedSuccess.workspaceSavedSuccess = false;
      }, 3000);
    }
  }

  uploadImage(event: Event) {
    // @ts-ignore
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            return;
          } else {
            this.apiwrapper.uploadAvatar(file).subscribe(
              (res: any) => {
                this.user.avatar = res.avatar;
                this.data.setChangedUserData(true);
                this.showSaved('account');
              },
              (error) => {
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }


  organizeTeams() {
    // setup organize teams
    this.data.organizeTeams = this.data.allTeams;
    this.data.openFromTeam = false;
    this.data.setOrganizeTeams(true);
  }

  private checkSubscription() {
    const plan = this.data.getWorkspacePlan()
    if (plan.requirePayment === 'yes') {
      this.hideStep6 = true;
    }
  }

  private getWorkspacePollings() {
    let lastPoolings: any;
    this.lastCompleted = false;
    this.apiwrapper.getWorkspacesPollings().subscribe(
      (res: any) => {
        if (res.lastCompletedPoll === null && res.nextCompletedPoll === null) {
          return
        }
        if (res.lastCompletedPoll !== null) {
          this.lastCompleted = true;
          lastPoolings = res.lastCompletedPoll;
        } else {
          lastPoolings = res.nextCompletedPoll;
          // get the graph to show response rate  || p l m
          this.apiwrapper.getTeamStatistics(res.nextCompletedPoll.team.uuid).subscribe(
            res => {
              this.teamResponseRate = res.percentage;
            }, err => {
              console.log(err);
            }
          );
        }
        this.gotNextPolling = true;
        this.teamResponseRate = lastPoolings.team.report.responseRate;
        this.teamResponseDate = lastPoolings.expireDate.date.slice(0, 10);
        this.teamResponse = lastPoolings.team;
      }, error => {

      }
    )
  }

  goToTeam() {
    // if the step 6 is hidden (paid) patch the workspace and make startGuide true (and hide the home page)
    // if the step 6 is still available update the W.startGuide to true when step 6 is clicked
    // this.updateWorkspaceStartGuideStep(6);
    if (this.hideStep6 && this.lastCompleted) {
      // patch workspace startGuide and hide it in menu
      this.data.showHomeMenu = '';
      localStorage.setItem('menuHome', '');
      this.updateWorkspaceStartGuide(true);
    } else if(!this.hideStep6 && this.lastCompleted) {
      this.updateWorkspaceStartGuideStep(5);
    }
    // take member to team
    this.router.navigate(['/team/' + this.teamResponse.uuid]);
  }

  goToBilling() {
    // if the next step is 6 then hide the
    this.data.lastRouteBeforeBilling = this.router.url;
    this.updateWorkspaceStartGuide(true);
    this.router.navigate(['/settings/billing']);
  }

  updateWorkspaceStartGuideStep(startGuideStep: number) {
    this.apiwrapper.patchWorkspace(this.workspace.uuid, {startGuideStep}).subscribe(
      resolve => {
        // set the localstorage workspace to the new workspace
        localStorage.setItem('WORKSPACE', JSON.stringify(resolve));
        // this.data.showHomeMenu = '';
        // localStorage.setItem('menuHome', '');
      }
    );
  }

  updateWorkspaceStartGuide(startGuide: boolean) {
    this.apiwrapper.patchWorkspace(this.workspace.uuid, {startGuide}).subscribe(
      resolve => {
        // set the localstorage workspace to the new workspace
        // todo check if .workspace is needed
        localStorage.setItem('WORKSPACE', JSON.stringify(resolve));
        // this.data.showHomeMenu = '';
        // localStorage.setItem('menuHome', '');
      }
    );
  }

  click() {
    this.updateWorkspaceStartGuide(false);
    // document.getElementById('workspaceUpdrageModalButton')?.click();
  }
}
