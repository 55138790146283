import {Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {DataManipulationService} from "../../services/data-manipulation.service";
import {moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {Router} from "@angular/router";
import {debounceTime, distinctUntilChanged, Subject, Subscription} from "rxjs";
import {BillingService} from "../../services/billing.service";
import {ENTER, SPACE} from "@angular/cdk/keycodes";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatChipSelectionChange} from "@angular/material/chips";
import {Options} from "@angular-slider/ngx-slider";
import {TranslateService} from "@ngx-translate/core";

interface TeamTypes {
  name: string,
  icon: string,
  type: string,
  uuid: string
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({
        transform: 'none'
      })),
      state('flipped', style({
        transform: 'rotateY(180deg)'
      })),
      transition('default => flipped', [
        animate('400ms')
      ]),
      transition('flipped => default', [
        animate('400ms')
      ])
    ])
  ]
})
export class OnboardingComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.innerHeight = window.innerHeight;
    if(window.innerHeight > 1000) {
      this.isInnerHeight = true;
    }
  }
  public isInnerHeight: boolean = false;
  innerHeight: any;
  public onboardingDone: boolean = false;
  public showOnboarding: boolean = false;
  public tryFeedbackQuestions: boolean = false;

  // @ts-ignore
  @ViewChild("card1") card1: TemplateRef<any>;
  // @ts-ignore
  @ViewChild("card2") card2: TemplateRef<any>;

  // @ts-ignore
  firstCard: TemplateRef<any>;
  // @ts-ignore
  secondCard: TemplateRef<any>;

  public workspace: any;
  user: any;
  needPayment: boolean = true;

  onboardingStep: number = 1;
  steps = [
    {
      value: 25,
      animation: false,
      striped: false
    },
    {
      value: 0,
      animation: false,
      striped: false
    },
    {
      value: 0,
      animation: false,
      striped: false
    },
    {
      value: 0,
      animation: false,
      striped: false
    },
    {
      value: 0,
      animation: false,
      striped: false
    },
    {
      value: 0,
      animation: false,
      striped: false
    }
  ];
  priority: number = 40;
  workspaceTeams: {
    members: number;
    error: boolean;
    created: boolean;
    name: string, type: string, color: string
  }[] = [];
  selectedTeam: any;
  feedbackFrequency: { name: string }[] = [];

  createWorkspace = new FormGroup({
    workspaceName: new FormControl('', [
      Validators.required,
      Validators.minLength(1)]),
  });

  checkedAllMembers: boolean = false;
  teamsNotCompleted: boolean = false;
  searchValue: any = '';
  emptyResult: boolean = false;
  private searchResult: any = [];
  private invitedMember: any;

  selectedCountryCode = 'dk';
  countryCodes = ['dk', 'gb'];
  apiLanguages = [];
  languageDisplayed = 'Danish';

  private userDetails: any;
  public theWorkspaceName: string = '';
  public hasMultipleTeams: boolean = false;
  public workspaceName: string = '';
  private billingDone: Subscription | undefined;
  inviteEmails: any = [];
  // emailCheck = new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]);
  emailCheck = new FormControl('', [Validators.required, Validators.email]);
  singleTeamType: any;


  inviteViaEmail = new FormGroup({});
  inviteViaLink = new FormGroup({
    invitationLink: new FormControl(this.data.workspaceInvitation),
    invitationLinkWithMessage: new FormControl('')
  });

  teamName = new FormControl('');
  teamEmails = new FormControl('');
  responsiveOptions = [
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  members: any = [];
  workspaceMembers: any = [];
  membersInvited: any = [];
  workspaceMemberPage: number = 0
  workspaceMemberMaxPage: number = 0
  loadMembers: boolean = false;
  searching = new Subject<string>();
  searchingApi: boolean = false;
  teamLeaders: any = [];
  teamMembers: any = [];
  workspaceMemberList: any;
  files: File[] = [];
  workspaceIndustry = '';
  workspaceIndustries = [];
  searchIndustry = 'name';
  public showBilling: boolean = false;
  public onboarding: any;
  onboardingCreator: boolean = false;
  gotCreator: boolean = false;
  workspaceSizes: any;

  readonly separatorKeysCodes = [ENTER, SPACE] as const;
  addOnBlur: boolean = true;
  emailPermissionCheck: Boolean = false;
  noPermission: Boolean = false;
  workspaceSize: string | undefined;
  showWorkspaceSizeSelector: boolean = false;
  private roleMember: any;
  teamType: TeamTypes[] = [];
  public teamTypes: any = [];
  public teamsCreated: any = [];
  private roleTeamLeader: any;
  private roleTeamMember: any;
  state: 'default' | 'flipped' | 'matched' = 'default';
  displayModal = false;
  teamsValid: any[] = [];

  frequency: any;
  frequencyKeys: any = [];
  todayDate: Date;
  pikedDate: Date;
  minDateValue: Date;

  pollingFrequency: any;
  private slide = 0;

  frequencySelected: any;
  startCollectorDate = 'TODAY';
  paidFeatureModalOpen: boolean = false;
  noMembersAdded: boolean = false;
  previewMessageButton = false;
  workspaceInvitationBody: any = {};
  showTeamTypeDropdown: boolean[] = [false];
  showSingleTeamDropdown: boolean = false;
  selectedFrequency: string = 'weekly';
  recomandedStatements: number = 8;
  sliderValue: number = 8;
  pollingSuggestedFrequency: any;
  public reportFrequency= {
    weekly:false,
    '14_days':false,
    monthly:false
  };
  tsFrequencySelected: any = 'weekly';
  tsSelectedFrequency: string = 'weekly';
  showFeedbackDemo: boolean = false;
  options: Options = {
    floor: 3,
    ceil: 15,
    showTicks: false,
    showTicksValues: false,
  };
  gotFrequency: boolean = false;
  nextTeamActive: boolean = false;

  constructor(public data: DataManipulationService, public api: ApiwrapperService, private router: Router,
              private billing: BillingService, public translate: TranslateService) {

    this.todayDate = new Date();
    this.pikedDate = new Date();
    this.minDateValue = new Date();

    this.api.getAllRoles('workspace').subscribe(
      res => {
        res.forEach((el: { name: string; uuid: any; }) => {
          if (el.name === 'workspace-member') {
            this.roleMember = el.uuid;
          }
        });
      }, err => {
        console.log(err);
      });
    this.api.getAllRoles('team').subscribe(
      res => {
        res.forEach((el: { name: string; uuid: any; }) => {
          if (el.name === 'team-admin') {
            this.roleTeamLeader = el;
          } else if (el.name === 'team-member') {
            this.roleTeamMember = el;
          }
        });
      }, err => {
        console.log(err);
      });

    if (router.url.includes('success')) {
      this.billingSuccess();
    } else if (router.url.includes('cancel')) {
      this.billingCancel();
    }

    if (localStorage.getItem('onboardingValidCoupon')) {
      localStorage.removeItem('onboardingValidCoupon');
      this.data.showAlert('Coupon applied successfully')
    }

    this.searching.pipe(
        debounceTime(1000),
        distinctUntilChanged())
        .subscribe(value => {
          this.emptyResult = false;
          if (value.length > 0 ) {
            this.searchingApi = true;
            api.getSearchWorkspaceMember(value).subscribe(
                res => {
                  this.emptyResult = false;
                  res.forEach((el: any, index: number)=>{
                    el.teams = [];
                    this.teamLeaders.forEach((leader:any) => {
                      if (leader.uuid === el.uuid){
                        res.splice(index, 1);
                      }
                    })
                    this.teamMembers.forEach((member:any) => {
                      if (member.uuid === el.uuid){
                        res.splice(index, 1);
                      }
                    })
                  })
                  this.searchingApi = false;
                  if (res.length > 0) {
                    // check if member is team member or team leader and remove from team
                    this.members = res;
                  } else if (res.length === 0){
                    let l = false;
                    let m = false;
                    // check if search is in team
                    this.teamLeaders.forEach((leader:any) => {
                      if (leader.user.email === value){
                        l =true;
                      }
                    })
                    this.teamMembers.forEach((member:any) => {
                      if (member.user.email === value){
                        m = true;
                      }
                    })
                    if (!l && !m){
                      this.emptyResult = true;
                    }
                  }
                }
            )
          } else {
            this.members = [...this.workspaceMembers]
          }
        });
  }


  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    if(window.innerHeight > 1000) {
      this.isInnerHeight = true;
    }
    // @ts-ignore
    if (!JSON.parse(localStorage.getItem('ONBOARDING'))) {
      this.router.navigate(['/login']);
      return;
    }
    this.getWorkspace();
    // after the user selects a plan
    this.billingDone = this.billing.billingDone.subscribe(billing => {
      if (billing) {
        localStorage.removeItem('workspace_payment');
        this.showBilling = false;
        this.data.showAlert('Payment added successfully!');
      }
    });
  }

  onScroll(event: any) {
    if (((event.target.offsetHeight + Math.ceil(event.target.scrollTop) >= event.target.scrollHeight - 50))&& (this.workspaceMemberPage !==this.workspaceMemberMaxPage)) {
      // get the next user page
      this.loadMembers = true;
      this.getWorkspaceMembers(this.workspaceMemberPage + 1);
    }
  }

  getWorkspace() {
    this.data.showLoading();
    this.gotCreator = false;
    // @ts-ignore
    this.api.getWorkspaces(localStorage.getItem('WORKSPACE_UUID'))
      .subscribe(res => {
        this.theWorkspaceName = res.name;
        const invitationKeys = Object.keys(res.activeInvitationBody);
        invitationKeys.forEach((el) => {
          if (res.activeInvitationBody[el].uuid) {
            res.activeInvitationBody = res.activeInvitationBody[el];
          }
        });
        this.workspace = res;
        this.workspaceName = res.name;
        this.workspaceInvitationBody = this.workspace.activeInvitationBody;
        this.workspaceInvitationBody.body = this.workspace.activeInvitationBody.body.replace(/&#10;&#13;/g, '\n');
        this.needPayment = res.workspaceSubscription.workspacePlan.requirePayment !== 'yes';
        // @ts-ignore
        const member = JSON.parse(localStorage.getItem('userDetails'));
        this.user = member;
        this.getDisplayLanguage(member.detail.preferredLanguage);

        // @ts-ignore
        this.onboarding = JSON.parse(localStorage.getItem('ONBOARDING'));
        if (this.onboarding) {
          this.workspaceName = this.onboarding.workspaceName;
          this.hasMultipleTeams = this.onboarding.hasMultipleTeams;
        }
        // this.setStep(res.onboardingStep.split("_")[1]);
        this.setStep(parseInt(res.onboardingStep.split("_")[1]));
        // if the user is not the owner and has datails dont show onboarding
        this.onboardingCreator = this.onboarding.workspaceRole === 'workspace-owner';
        this.gotCreator = true;
        this.api.getWorkspaceSize().subscribe(
          resSize => {
            this.workspaceSizes = resSize;
            this.showWorkspaceSizeSelector = true;
          }
        );
        this.api.getWorkspaceIndustries().subscribe(
          resInd => {
            this.workspaceIndustries = resInd;
          }
        );
        this.api.getUserDetails(member.uuid).subscribe(
          user => {
            if (user.detail.firstname === null) {
              // this.getUserDetails();
            } else if (!localStorage.getItem('ONBOARDING')) {
              this.router.navigate(['/login']);
            }
            this.data.hideLoading();
          }, error => {
            this.data.hideLoading();
          }
        );

        this.data.getWorkspaceInv();
        this.getTeamTypes();

        this.apiLanguages = this.data.apiLanguages;
        // if the user language is EN and the workspace is DK make the workspace EN
        if (this.languageDisplayed === 'English' && this.workspace.language.language !== 'English'){
          this.changeLanguage('en');
        }
      }, error => {
      });
  }

  getTeamTypes() {
    this.teamTypes = [];
    this.api.getTeamType().subscribe(
      types => {
        types.forEach((type: any) => {
          if(type.title != 'General') {
            this.teamTypes.push({
              name: type.title,
              icon: type.slug === 'physical-workplace' ? 'office' : type.slug,
              type: type.title.toLowerCase(),
              uuid: type.uuid
            });
          }
          if(this.onboardingStep === 2) {
            this.teamTypes.sort((a: any, b: any) => {
              return a.name.length < b.name.length ? 1 : -1
            });
          }
        });
      }
    );
  }

  nextStep() {
    this.onboardingStep++;
    this.setStep(this.onboardingStep)
  }

  previousStep() {
    this.onboardingStep--;
    this.setStep(this.onboardingStep)
  }

  updateWorkspace() {
    if (this.createWorkspace.valid) {
      this.data.showLoading();
      const data = {
        name: undefined
      };
      // @ts-ignore
      data.name = '' + this.createWorkspace.controls['workspaceName'].value
      // @ts-ignore
      if (this.workspaceIndustry !== '' && this.workspaceIndustry.uuid) {
        // @ts-ignore
        data.workspaceIndustryUuid = this.workspaceIndustry.uuid;
      } else {
        this.data.showAlertError('Select industry');
        this.data.hideLoading();
        return;
      }

      if (this.workspaceSize !== '' && this.workspaceSize !== undefined) {
        // @ts-ignore
        data.workspaceSizeUuid = this.workspaceSize;
      }
      
      this.api.patchWorkspace(this.workspace.uuid, data)
        .subscribe(res => {
          this.data.hideLoading();
          this.theWorkspaceName = this.createWorkspace.controls['workspaceName'].value;
          this.workspace.name = this.createWorkspace.controls['workspaceName'].value;
          // localStorage.setItem('workspace_payment', 'true');
          localStorage.setItem('setWorkspaceDetails', 'true');
          this.workspaceIndustry = '';
          localStorage.removeItem('setWorkspaceDetails');
          localStorage.setItem('WORKSPACE', JSON.stringify(res));
          this.setStep(2);
        }, err => {
          this.data.hideLoading();
          this.data.showAlertError(err.error.error.messages[0]);
        });
    }
  }

  setStep(step: number) {
    // hide BIG DIV
    this.showOnboarding = false
    if (step === 1) {
      this.onboardingStep = step;
      setTimeout(() => {
        // @ts-ignore
        document.getElementById(`workspaceName`).focus();
      }, 500);
    }
    if (step === 2) {
      this.onboardingStep = step;
      this.showOnboarding = true;
      setTimeout(() => {
        // @ts-ignore
        this.setCards();
      }, 100);
    }
    if (step === 3 || step === 6) {
      this.onboardingStep = step;
      this.getWorkspaceMembers(1);
      setTimeout(() => {
        // @ts-ignore
        document.getElementById(`inviteEmail`).focus();
      }, 500)
    } else if (step === 4) {
      this.onboardingStep = step;
      this.getWorkspaceTeams();
      this.getWorkspaceMembers(1);
    } else if (step === 5 || step === 7) {
      this.onboardingStep = step;
      this.getWorkspaceTeams();
      this.getWorkspaceMembers(1);
    } else if (step === 8) {
      //TODO verify all teams and if there are any valid for collector enable step 9
      setTimeout(() => {
        // if there are no teams
        if (this.teamsNotCompleted){
          document.getElementById('closeMultipleTeamSetupButton')?.click();
          // show the modified congratulation
          localStorage.setItem('loop', JSON.stringify(''));
          this.onboardingDone = true;
          this.finishOnboarding();
        }
        this.data.hideLoading();
        this.onboardingStep = step;
      }, 1000);
      this.getWorkspaceTeams();
    } else if (step === 9) {
      this.onboardingStep = step;
      this.getWorkspaceTeams();
    }
    window.scroll(0, 0);
    // set in API onboarding step
    setTimeout(() => {
      this.showOnboarding = true;
    }, 200)

    this.updateWorkspaceOnboarding(step);
  }

  // @ts-ignore
  getStep(step) {
    if (step !== 'done') {
      // tslint:disable-next-line:radix
      return parseInt(step.match(/\d+/)[0]);
    } else {
      // remove onboarding
      this.finishOnboarding();
    }
  }

  getWorkspaceMembers(page : number) {
    if (page ===1){
      this.members = [];
    }
    // @ts-ignore
    let me = JSON.parse(localStorage.getItem('WORKSPACE')).creator;
    this.api.getWorkspacesUserWithPagination(page).subscribe(
      res => {
        this.workspaceMemberPage = page
        this.workspaceMemberMaxPage = res._page_count;
        this.data.hideLoading();
        this.membersInvited = [];
        this.teamLeaders = [];
        this.teamMembers = [];
        res._embedded.users.forEach((el: {
          checked: boolean;
          teams: never[];
        }) => {
          el.teams = [];
          el.checked = false;
          // if single team populate team list
          if (this.onboardingStep === 7) {
            // @ts-ignore
            if (me.email === el.user.email) {
              this.teamLeaders.push(el);
            } else {
              this.teamMembers.push(el);
            }
          }
          // @ts-ignore
          if (el.user.email !== this.user.email) {
            this.membersInvited.push(el);
          }
        });
        if(page !== 1) {
          this.members = this.members.concat(res._embedded.users);
        } else {
          this.members = res._embedded.users;
        }        
        this.workspaceMembers = [...this.members];
        this.loadMembers = false;
      }
    );
  }

  getTeamMembers() {
    this.data.getEditTeamCurrentMembers().then(res => {
      this.data.workspaceMembersForTeam.forEach((el) => {
        // @ts-ignore
        el.buttonType = false;
      });
    });
  }

  finishOnboarding() {
    this.updateWorkspaceOnboarding('done');
  }

  onImageAdd(event: { addedFiles: any; }) {
    this.data.showLoading();
    this.files = [];
    this.files.push(...event.addedFiles);
    this.uploadWorkspaceImage();
  }

  updateWorkspaceOnboarding(step: string | number) {
    let onboardingStep = '';
    if (step === '1' || step === 1 || step === '0' || step === 0) {
      return;
    } else if (step !== 'done') {
      onboardingStep = 'step_' + step;
    } else if (step === 'done') {
      onboardingStep = 'done';
    }

    this.data.showLoading();
    this.api.patchWorkspace(this.workspace.uuid, {onboardingStep})
      .subscribe(res => {
        // store in local step changed
        // @ts-ignore
        const onbording = JSON.parse(localStorage.getItem('ONBOARDING'));
        onbording.onboardingStep = onboardingStep;
        localStorage.setItem('ONBOARDING', JSON.stringify(onbording));
        if (onboardingStep === 'done') {
          localStorage.setItem('ONBOARDING_DONE', 'DONE');
          localStorage.removeItem('ONBOARDING');
          // as required in text  => feedback congratulation bottom
          setTimeout(() => {
            this.data.hideLoading();
            location.reload();
          }, 5000)
        } else {
          this.data.hideLoading();
        }
      }, err => {
        this.data.hideLoading();
        if (this.onboardingCreator) {
          this.data.showAlertError(err.error.error.messages[0]);
        }
      });
  }

  // SELECT TEAM
  openTeamTab(team: any) {
    if (team === this.selectedTeam) {
      return;
    }
    for (let i = 0; i < this.teamLeaders.length; i++) {
      this.members.push(this.teamLeaders[i]);
    }
    this.teamLeaders = [];

    for (let i = 0; i < this.teamMembers.length; i++) {
      this.members.push(this.teamMembers[i]);
    }
    this.teamMembers = [];

    this.selectedTeam = team;
    for (let i = 0; i < this.members.length; i++) {
      let el = this.members[i];
      // @ts-ignore
      if (el.teams.length > 0) {
        // @ts-ignore
        el.teams.forEach((memberTeam) => {
          if (memberTeam.uuid === team.uuid) {
            // check the team role and add it to the correct array
            if (memberTeam.role.teamPriority === 20) {
              this.teamLeaders.push(el);
            } else if (memberTeam.role.teamPriority === 10) {
              this.teamMembers.push(el);
            }
            this.members.splice(i, 1);
            --i;
          }
        })
      }
    }
  }

  openInviteModal() {
    // @ts-ignore
    document.getElementById('modalEditInvite').click()
  }

  previewMessageOnEmailInvitation() {
    if (this.previewMessageButton) {
      // update email
      this.data.showLoading();
      this.api.patchWorkspaceInvitation(this.workspaceInvitationBody.body, this.workspaceInvitationBody.uuid).subscribe(
        res => {
          this.data.showAlert('Your invitation was successfully updated!');
          this.workspace.workspaceInvitationBody = res;
          localStorage.setItem('WORKSPACE', JSON.stringify(this.workspace));
          this.data.inviteMessage = res.body;
          this.previewMessageButton = !this.previewMessageButton;
          this.data.hideLoading();
        }, err => {
          this.data.showAlertError('Unable to update invitation!');
          this.data.hideLoading();
          return;
        }
      );
    } else {
      this.previewMessageButton = !this.previewMessageButton;
    }
  }

  goToPaymentMethods() {

  }

  addTeam() {
    let teamExists = false;
    if (this.workspaceTeams.length > 0) {
      this.workspaceTeams.forEach((el: any) => {
        if (el.name === this.teamName.value) {
          this.data.showAlertError('Team already exist');
          teamExists = true;
        }
      })
    }
    if (!teamExists) {
      this.data.showLoading();
      this.api.checkTeamName(this.teamName.value).subscribe(res => {
        if (this.teamName.value.length > 0) {
          this.workspaceTeams.push({
            name: this.teamName.value,
            members: 0,
            created: false,
            error: false,
            type: '',
            color: 'red'
          });
          this.data.hideLoading();
          this.teamName.setValue('');
        } else {
          this.data.hideLoading();
          this.data.showAlertError('Please insert a team name')
        }
      }, () => {
        this.data.hideLoading();
        this.data.showAlertError('Team already exist');
      })
    }
  }

  deleteTeam(index: number) {
    this.workspaceTeams.splice(index, 1);
  }

  drop(event: any) {
    // TODO
    let checkedMembers = false;
    this.members.forEach((member: any) => {
      if (member.checked) {
        checkedMembers = true;
      }
    })
    // if members are checked and containers are not the same move all the checked members
    if ((event.previousContainer.id !== event.container.id) && checkedMembers) {
      // this.members.forEach((el: any, index: any, object:any) => {
      for (let i = 0; i < this.members.length; i++) {
        let el = this.members[i];
        if ((event.container.id === 'leaders') && el.checked) {
          el.checked = false;
          this.teamLeaders.push(el);
          this.members.splice(i, 1);
          --i;
        } else if ((event.container.id === 'members') && el.checked) {
          el.checked = false;
          this.teamMembers.push(el);
          this.members.splice(i, 1);
          --i;
        }
        this.selectedTeam.leaders = this.teamLeaders.length;
        this.selectedTeam.members = this.teamMembers.length;
      }

    } else if (event.previousContainer === event.container) {
      // if there is the same container move element to index
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // if not same container move item
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.selectedTeam.leaders = this.teamLeaders.length;
      this.selectedTeam.members = this.teamMembers.length;
    }


    for (let i = 0; i < this.teamLeaders.length; i++) {
      let el = this.teamLeaders[i];
      // set leader role
      // check if the team is already in member
      let num = 0;
      el.teams.forEach((team: any) => {
        if (team.name === this.selectedTeam.name) {
          return;
        }
        ++num;
      })

      if (num === el.teams.length) {
        const userTeam = {
          uuid: this.selectedTeam.uuid,
          name: this.selectedTeam.name,
          role: {},
        };
        userTeam.role = this.roleTeamLeader;
        el.teams.push(userTeam);
      }

    }

    for (let i = 0; i < this.teamMembers.length; i++) {
      let el = this.teamMembers[i];
      // set member role
      let num = 0
      el.teams.forEach((team: any) => {
        if (team.name === this.selectedTeam.name) {
          return
        }
        ++num;
      })


      if (num === el.teams.length) {
        const userTeam = {
          uuid: this.selectedTeam.uuid,
          name: this.selectedTeam.name,
          role: {},
        };
        userTeam.role = this.roleTeamMember;
        el.teams.push(userTeam);
      }
    }
  }

  uploadWorkspaceImage() {
    // @ts-ignore
    const file = this.files[0];
    const reader = new FileReader();
    if (file !== undefined) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          if ((width < 50 || width > 3000) || (height < 50 || height > 3000)) {
            // document.getElementById('close').click();
            this.data.showAlertError(`Your profile picture's width and height must be between 50 and 3000 pixels.`);
            this.files = [];
            this.data.hideLoading();
            return;
          } else {
            this.data.showLoading();
            this.api.uploadWorkspaceAvatar(file).subscribe(
              (res: any) => {
                this.data.setWorkspaceAdded(true);
                this.workspace.avatar = res.avatar;
                this.data.setChangeImage(true);
                this.data.hideLoading();
              },
              (error) => {
                this.files = [];
                this.data.hideLoading();
                // document.getElementById('close').click();
                this.data.showAlertError(`There was an error uploading the image. Try again.`);
              });
          }
        };
      };
    }
  }

  updateWorkspaceName() {
    if (this.createWorkspace.valid) {
      this.data.showLoading();
      this.api.patchWorkspace(this.workspace.uuid, {name: '' + this.createWorkspace.controls['workspaceName'].value})
        .subscribe(res => {
          this.data.hideLoading();
          this.theWorkspaceName = this.createWorkspace.controls['workspaceName'].value;
          this.workspace.name = this.createWorkspace.controls['workspaceName'].value;
          localStorage.setItem('setWorkspaceDetails', 'true');
        }, err => {
          this.data.hideLoading();
          this.data.showAlertError(err.error.error.messages[0]);
        });
    }
  }

  demoTeam() {
    // this.onboardingStep = 2;
    // this.setStep(2);
    // // this.nextStep()
    this.data.showTeamAnalyse = false;
    this.data.showMyActions = false;
    this.data.showDemo = !this.data.showDemo;
  }

  teamsSelect(multiple: boolean) {
    this.hasMultipleTeams = multiple;
    // @ts-ignore
    let onboarding = JSON.parse(localStorage.getItem('ONBOARDING'));
    onboarding.hasMultipleTeams = multiple;
    localStorage.setItem('ONBOARDING', JSON.stringify(onboarding));

    if (multiple) {
      //if multiple teams go to create teams page
      this.updateWorkspaceOnboarding(3);
      this.setStep(3);
    } else {
      // create a team with workspace name after user selects type
      if (this.singleTeamType === undefined) {
        this.data.showAlertError('Select team type');
      } else {
        this.data.showLoading();
        // get workspace name
        // @ts-ignore
        let name = JSON.parse(localStorage.getItem('WORKSPACE')).name;
        const body = {
          name: name,
          workspaceUuid: localStorage.getItem('WORKSPACE_UUID'),
          questionCollections: this.singleTeamType.uuid
        };
        //create team
        this.api.createWorkspaceTeam(body).subscribe(res => {
          // go to next step
          this.data.hideLoading();
          this.setStep(6);
        }, err => {
          this.data.hideLoading();
          this.data.showAlertError('We were unable to create the team. Try again.');
        });
      }
      // this.setStep(6)
    }
  }

  addEmail(event: any): void {
    const value = (event.value || '').trim();
    let mails = [];
    if (value === '') {
      return;
    }
    if(value.indexOf(' ') > -1 ) {
      mails = value.split(' ');
    } else {
      mails = value.split(',').map((item: string) => item.trim()).filter((el: string) => el != '');
    }
    // check if multiple email addresses and add all that are valid
    mails.forEach((mail: string) => {
      mail = mail.replace(/\,/g, '');
      this.emailCheck.setValue(mail);
      if (this.emailCheck.valid) {
        if (!this.inviteEmails.includes(mail)) {
          this.inviteEmails.push(mail);
          event.chipInput!.clear();
        } else {
          this.data.showAlertWarning('"' + mail + '" was already added in the email list.');
        }
        // this.inviteEmails.push({name: mail});
        // Clear the input value
        event.chipInput!.clear();
      } else {
        this.data.showAlertError('"' + mail + '" not a valid email');
      }
    });
  }

  selectedEmail(event: MatChipSelectionChange, email: any): void {
    if (event.selected) {
      this.teamEmails.setValue(email);
      this.removeEmail(email);
    }
    document.getElementById('inviteEmail')?.focus();
  }

  removeEmail(email: any): void {
    const index = this.inviteEmails.indexOf(email);
    if (index >= 0) {
      this.inviteEmails.splice(index, 1);
    }
  }

  inviteByEmail() {
    // check if there are no email and let the user go strait to the video page
    if (this.inviteEmails.length === 0 && this.membersInvited.length === 0) {
      // set the step to number 8
      this.noMembersAdded = true;
      this.setStep(8);
      return;
    }

    if (!this.emailPermissionCheck) {
      document.getElementById('permissionInv')?.scrollIntoView({ behavior: "smooth"});
      this.noPermission = true;
      return;
    }
    this.data.showLoading();
    if (this.inviteEmails.length > 0) {
      this.api.inviteUserToWorkspace({emails: this.inviteEmails, roleUuid: this.roleMember}).subscribe(res => {
        this.teamEmails.setValue('');
        this.inviteEmails = [];
        if (this.onboardingStep === 3) {
          this.setStep(4);
        } else {
          this.setStep(7);
        }
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error sending the invitation. Try again a bit later.');
      });
    } else if (this.members.length > 0) {
      if (this.onboardingStep === 3) {
        this.setStep(4);
      } else {
        this.setStep(7);
      }
      this.data.hideLoading();
      return;
    }
  }

  setTeamType(teamType: any, team: any) {
    this.workspaceTeams.forEach((el: any) => {
      if (el.name === team.name) {
        team.type = teamType.uuid;
      }
    })
  }

  createTeams() {
    this.data.showLoading();
    let body = {teams: [{}]}
    let teamsNumber = 0;
    body = {teams: []};
    this.workspaceTeams.forEach((team) => {
      if (team.type === '') {
        team.error = true;
        this.data.hideLoading();
        this.data.showAlertError('Please select the work setting for team: ' + team.name);
        throw new Error('Enter team type');
      } else if (!team.created) {
        team.error = false;
        body.teams.push({
          name: team.name,
          questionCollections: [team.type]
        });

        // this.createTeamForm.controls.teamName.disable();
        // this.showCreateTeamMembers = true;

        if (team.created) {
          ++teamsNumber;
        } else {
          // create a team
          // this.api.createWorkspaceTeam(body).subscribe(res => {
          //   this.teamsCreated.push(res);
          //   team.created = true;
          //   ++teamsNumber;
          //   if (this.workspaceTeams.length === teamsNumber) {
          //     this.data.hideLoading();
          //     this.getWorkspaceMembers();
          //     this.setStep(5);
          //   }
          // }, err => {
          //   this.data.hideLoading();
          //   this.data.showAlertError('We were unable to create team: ' + team.name + '. Try again.');
          // });
        }
      } else if (team.created) {
        ++teamsNumber;
        if (this.workspaceTeams.length === teamsNumber) {
          this.data.hideLoading();
          this.getWorkspaceMembers(1);
          this.setStep(5);
        }
      }
    });
    this.api.createWorkspaceMultipleTeams(body).subscribe(
      res => {
        this.teamsCreated = res;
        if (this.workspaceTeams.length === res.length) {
          this.data.hideLoading();
          this.getWorkspaceMembers(1);
          this.setStep(5);
        }
      },
      error => {
        this.data.hideLoading();
        console.log(error)
      }
    )
  }

  selectedWorkspaceIndustry($event: any) {
    this.workspaceIndustry = $event;
  }

  private getWorkspaceTeams() {
    // todo HERE
    this.getFrequency();
    this.gotFrequency = false;
    // this.data.tea
    this.api.getTeamsFromWorkspace().subscribe(
      res => {
        res.forEach((el: any) => {
          if (el.questionCollections.length > 0){
            if (el.questionCollections[el.questionCollections.length-1].title === 'Physical workplace'){
              el.icon = "office"
            } else {
              el.icon = el.questionCollections[el.questionCollections.length-1].title.toLowerCase();
            }
          }
          el.leaders = 0;
          el.members = 0;
        })
        this.selectedTeam = res[0];
        this.workspaceTeams = res;
        this.teamsValid = [];
        this.workspaceTeams.forEach((el: any) => {
            if ((el.members >= 4 && el.leaders > 0) || el.totalMembers > 4) {
              this.teamsValid.push(el);
            }
          }
        )
        this.gotFrequency = true;
      }, err => {
        console.log(err);
      }
    )
  }

  deleteTeamLeader(i: number) {
    // move team leader back to workspace members
    let member = this.teamLeaders[i];
    if (member.teams.length > 0) {
      // remove the team
      for (let i = 0; i < member.teams.length; i++) {
        if (member.teams[i].uuid === this.selectedTeam.uuid) {
          member.teams.splice(i, 1);
        }
      }
    }
    this.selectedTeam.leaders--;
    this.members.push(this.teamLeaders[i]);
    this.teamLeaders.splice(i, 1);
  }

  deleteTeamMember(i: number) {
    let member = this.teamMembers[i];
    if (member.teams.length > 0) {
      // remove the team
      for (let i = 0; i < member.teams.length; i++) {
        if (member.teams[i].uuid === this.selectedTeam.uuid) {
          member.teams.splice(i, 1);
        }
      }
    }

    // move team member back to workspace members
    this.selectedTeam.members--;
    this.members.push(this.teamMembers[i]);
    this.teamMembers.splice(i, 1);
  }

  addMembers(nextTeam: boolean) {
    // if the
    if (nextTeam) {
      if (this.workspaceTeams.length > 1) {
        this.openTeamTab(this.workspaceTeams[0]);
      }
      // invite members
      this.data.showLoading();
      let count = 0;
      let teamMembersAdded = 0;
      // get the number of calls
      this.workspaceTeams.forEach(el => {
        // @ts-ignore
        count = count + el.leaders + el.members
      });

      // show modal to show user how many teams are empty
      this.workspaceMembers.forEach((el: any) => {
        if (el.teams.length > 0) {
          // el.uuid - user uuid
          el.teams.forEach((team: any) => {
              // teamUuid = team.uuid
              // roleUuid = team.role.uuid
              this.api.postAddMemberToTeam({userUuid: el.user.uuid, roleUuid: team.role.uuid},
                team.uuid).subscribe(
                res => {
                  ++teamMembersAdded;
                  if (teamMembersAdded === count) {
                    this.data.hideLoading();
                    this.setStep(8);
                  }
                }, err => {
                  //todo - remove after
                  ++teamMembersAdded;
                  if (teamMembersAdded === count) {
                    this.data.hideLoading();
                    this.setStep(8);
                  }
                })
            }
          )
        } else if (el.teams.length === 0) {
          this.finishOnboarding();
        }
      })
      // after invite members to the teams, after the invitation process is completed go to next screen
    } else {
      this.nextTeamActive = true;
      this.selectTeam(true);
    }
  }

  selectTeam(next: boolean) {
    if (next) {
      // this.selectedTeam = this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) + 1];
      this.openTeamTab(this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) + 1]);
    } else {
      // this.selectedTeam = this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) - 1];
      this.openTeamTab(this.workspaceTeams[this.workspaceTeams.indexOf(this.selectedTeam) - 1]);
      this.nextTeamActive = false;
    }
  }

  cardClicked() {
    if (this.state === "default") {
      this.state = "flipped";
    } else {
      this.state = "default";
    }
  }

  doneMembers() {
    this.teamsValid = [];
    this.workspaceTeams.forEach((el: any) => {
        if ((el.members >= 4 && el.leaders > 0) || el.totalMembers > 4) {
          this.teamsValid.push(el);
        }
      }
    )
    // show modal with the teams that are ready for feedback collector
    if (this.teamsValid.length < this.workspaceTeams.length) {
      // open modal
      document.getElementById('modalMultipleTeamSetupButton')?.click();
    } else if (this.teamsValid.length === this.workspaceTeams.length) {
      this.doneAssigningMembers();
    }
    // give option to proceed and finish onboarding

    if (this.router.url.includes('success')) {
      this.startFeedbackLoops();
    }
  }

  private getFrequency() {
    this.frequencyKeys = [];
    this.api.getPollingFrequencies().subscribe(
      res => {
        this.frequencyKeys = [];
        Object.keys(res).forEach((el, index) => {
          if (index === 0) {
            this.frequencySelected = el;
          }
          // @ts-ignore
          this.frequencyKeys.push({title: el, disabled: false});
        });
        this.pollingFrequency = res;
        this.getSuggestions();
      }, err => {
        console.log(err);
      })
  }

  doneAssigningMembers() {
    if (this.teamsValid.length === 0){
      this.teamsNotCompleted = true;
    }
    // // this.doneMembers();
    this.addMembers(true);
    document.getElementById('closeModalMultipleTeamSetup')?.click();
    // if (this.teamsValid.length === this.workspaceTeams.length) {
    //   // all teams are valid for the collector
    //   this.addMembers(true);
    //   // this.setStep(8);
    // } else if (this.teamsValid.length > 0) {
    //   // // go to step 8 (done page)
    //   // // show modal with all the valid teams
    //   // // @ts-ignore
    //   // document.getElementById('modalTeamSetupButton').click(); } else if (this.teamsValid.length === 0) {
    //   // //  show congratulation screen
    //   // this.onboardingDone = true;
    //   // // TODO set onboarding finish
    //   // this.finishOnboarding();
    // }
  }

  acceptTeams() {
    // @ts-ignore
    document.getElementById('closeTeamSetupModal').click();
    // move all members
    this.addMembers(true);
  }


  doneSettingLoop() {
    this.paidFeatureModalOpen = false;
    this.needPayment = true;
    if (this.frequencySelected === 'weekly') {
      this.needPayment = false;
    }

    let startDate;
    let endDate
    if (this.startCollectorDate === 'TODAY') {
      startDate = this.todayDate.getFullYear() + '-' + (this.todayDate.getMonth() + 1) + '-' + this.todayDate.getDate();
      endDate = this.todayDate.getFullYear() + 1 + '-' + (this.todayDate.getMonth() + 1) + '-' + this.todayDate.getDate();
    } else {
      startDate = this.pikedDate.getFullYear() + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate();
      endDate = this.pikedDate.getFullYear() + 1 + '-' + (this.pikedDate.getMonth() + 1) + '-' + this.pikedDate.getDate();
    }
    let loop = {
      startDate,
      endDate,
      frequency: this.pollingFrequency[this.frequencySelected][this.slide].uuid,
      frequencyType: this.frequencySelected,
      multipleTeam: this.hasMultipleTeams,
      error: false
    }
    localStorage.setItem('loop', JSON.stringify(loop));
      this.startFeedbackLoops();

    // if (!this.needPayment) {
    //   this.startFeedbackLoops();
    //   // this.onboardingDone = true;
    // } else {
    //   //show the payment details page
    //   // paymentDetails
    //   this.paidFeatureModalOpen = true;
    // }
  };

  startFeedbackLoops() {
    // get the teams that are ready for collector
    // get the date (now / pick)

    //"pollingResponseFrequencyUuid": "06f7138e-4ce6-11ea-aba4-f2d473d6d142",
    //"pollingReportFrequencyUuid": "06f7138e-4ce6-11ea-aba4-f2d473d6d142",
    //"startDate": "23.08.2023",
    //"questionNumber": "12"

    // @ts-ignore
    let loop = JSON.parse(localStorage.getItem('loop'));
    let pollingReportFrequencyUuid = '';
    this.pollingSuggestedFrequency.forEach((el: any) =>{
      if (el.type === this.tsFrequencySelected){
        pollingReportFrequencyUuid = el.uuid;
      }
    })
    //for on the teams that are valid for feedback loop
    // api call to start the collectors for the teams
    let count = 0;
    this.data.showLoading();
    this.teamsValid.forEach(el => {
        this.api.postTeamPolling(el.uuid,
          {
            pollingResponseFrequencyUuid: loop.frequency,
            pollingReportFrequencyUuid,
            startDate: loop.startDate,
            endDate: loop.endDate,
            questionNumber: this.sliderValue
          }).subscribe(
          res => {
            ++count;
            if (count === this.teamsValid.length) {
              this.data.hideLoading();
              localStorage.removeItem('loop');
              this.updateWorkspaceOnboarding('done');
              this.onboardingDone = true;
            }
          }, error => {
            // TODO if team has insufficient members - because teamMembers count has 3 members and >2 leaders
            //  do something
            ++count;
            if (count === this.teamsValid.length) {
              this.data.hideLoading();
              loop.error = true;
              localStorage.setItem('loop', JSON.stringify(loop));
              // this.updateWorkspaceOnboarding('done');
              this.onboardingDone = true;
            }
          });
      }
    )
  }

  statementSlideOnb(b: boolean) {
    if (b) {
      // @ts-ignore
      if (this.slide === this.pollingFrequency[this.frequencySelected].length - 1) {
        this.slide = 0;
      } else {
        this.slide = this.slide + 1;
      }
    } else {
      if (this.slide === 0) {
        this.slide = this.pollingFrequency[this.frequencySelected].length - 1;
      } else {
        this.slide = this.slide - 1;
      }
    }
  }

  changeSlides() {
    //TODO
    this.selectedFrequency = this.frequencySelected;
    this.slide = 0;
      switch(this.frequencySelected) {
        case 'weekly':
          this.recomandedStatements = 8;
          this.sliderValue = 8;
          this.options = {
            floor: 3,
            ceil: 15
          }
          break;
        case '14_days':
          this.recomandedStatements = 15;
          this.sliderValue = 15;
          this.options = {
            floor: 8,
            ceil: 20
          }
          break;
        case 'monthly':
          this.recomandedStatements = 18;
          this.sliderValue = 18;
          this.options = {
            floor: 12,
            ceil: 25
          }
          break;
      }
  }

  pickDate() {
    this.startCollectorDate = 'PIKED';
  }

  setCards() {
    // @ts-ignore
    let onboarding = JSON.parse(localStorage.getItem('WORKSPACE')).workspaceSize;
    if (onboarding.min > 50) {
      this.firstCard = this.card1;
      this.secondCard = this.card2;
    } else {
      this.firstCard = this.card2;
      this.secondCard = this.card1;
    }
  }

  openFeedbackQuestionsModal() {
    this.tryFeedbackQuestions = true;
  }

  confirmSingleTeam() {
    // @ts-ignore
    if (this.teamMembers.length < 4) {
      // @ts-ignore
      document.getElementById('modalSingleTeamSetupButton').click();
    } else {
      this.finishSingleTeam();
    }
  }

  finishSingleTeam() {
    this.data.showLoading();
    let teamUsers: {
      user: any;
      role: any;
    }[] = [];
    this.teamLeaders.forEach((el: { role: any; }) => {
      el.role = this.roleTeamLeader;
      ++this.selectedTeam.leaders;
      // @ts-ignore
      teamUsers.push(el);
    })
    this.teamMembers.forEach((el: { role: any; }) => {
      el.role = this.roleTeamMember;
      ++this.selectedTeam.members;
      // @ts-ignore
      teamUsers.push(el);
    })
    let count = 0;

    this.data.hideLoading();

    teamUsers.forEach(el => {
      this.api.postAddMemberToTeam({userUuid: el.user.uuid, roleUuid: el.role.uuid},
        this.selectedTeam.uuid).subscribe(res => {
        ++count;
        if (teamUsers.length === count) {
          this.data.hideLoading();
          if (this.teamMembers.length > 3) {
            // go to step 8
            this.setStep(8);
          } else {
            this.onboardingDone = true;
            // TODO set onboarding finish
            this.finishOnboarding();
          }
        }
      }, err => {
        this.data.hideLoading();
        ++count;
        this.data.showAlertError('Error in adding member: ' + el.user.email)
      })
    })
  }

  feedbackQuestionModalClose(event: boolean) {
    this.tryFeedbackQuestions = event;
  }

  paidFeatureModalClose(event: boolean) {
    this.paidFeatureModalOpen = event;
  }

  private billingSuccess() {
    this.data.showAlert('Payment method added successfully!');
    //show loading screen and after start collectors for valid teams
    if (localStorage.getItem('onboardingValidCoupon')) {
      // @ts-ignore
      let coupon = JSON.parse(localStorage.getItem('onboardingValidCoupon'))
      //@ts-ignore
      this.api.postCoupon(coupon.hash).subscribe(
        response => {
          this.data.hideLoading();
          localStorage.setItem('ONBOARDING_PLAN_SELECTED', JSON.stringify(response.workspacePlan));
          localStorage.setItem('workspacePlan', JSON.stringify(response.workspacePlan));
          // document.getElementById('contentCoupon').click();
          // if coupon applied change estimated and blue banner
          this.data.showAlert('Coupon applied successfully')
          localStorage.removeItem('onboardingValidCoupon');
          this.startFeedbackLoops();
        }, error => {
          this.data.hideLoading();
          this.data.showAlertError('Coupon couldn\'t be applied');
          console.log(error);
        }
      );
    } else {
      // set a paid plan and then start loop
      this.api.getBillingPlans().subscribe(
        res => {
          // get the plans and if plan has payment required set workspace plan
          res.forEach((el: { uuid: string; requirePayment: string; }) => {
            if (el.requirePayment === 'yes') {
              this.api.patchBillingPlan(el.uuid).subscribe(
                res => {
                  this.startFeedbackLoops();
                }
              )
            }
          })
        }
      )
    }

  }

  private billingCancel() {
    //show modal and show error on adding payment method
    this.data.paymentFail = true;
    setTimeout(() => {
      this.data.showAlertError('Error in adding the payment method! Please try again!');
      // @ts-ignore
      this.paidFeatureModalOpen = true;
    }, 100)
  }


  checkAllMembers() {
    this.members.forEach((el: { checked: boolean; }) => {
        el.checked = this.checkedAllMembers;
      }
    )
  }

  inviteEmail() {
    if ((/\s/g).test(this.searchValue)) {
      this.searchValue = this.searchValue.replace(/\s/g, "");
    }
    // invite if the email is not in members
    // check if valid email
    this.emailCheck.setValue(this.searchValue);
    if (this.emailCheck.valid) {
      this.data.showLoading();
      // invite member to workspace and add it to workspace members :)
      this.api.inviteUserToWorkspace({
        emails: [this.searchValue],
        roleUuid: this.roleMember
      }).subscribe(res => {
        // TODO after invitation add user
        // after invite push user to members and workspace-members as first
        this.data.isInvited = true;
        this.invitedMember = this.searchValue;
        this.searchValue = '';
        // get all members and trigger subs
        this.api.getSearchWorkspaceMember(this.invitedMember).subscribe(
          res => {
            res[0].teams= [];
            this.members = this.workspaceMembers;
            this.members.unshift(res[0]);
            this.data.hideLoading();
          }, error => {
            this.data.hideLoading();
          })
        // @ts-ignore
        this.data.hideLoading();
      }, err => {
        this.data.hideLoading();
        this.data.showAlertError('There was an error sending the invitation. Try again a bit later.');
      });
    } else {
      this.data.showAlertError('"' + this.searchValue + '" not a valid email');
    }
  }


  searchMember() {
    if (this.searchValue !== '') {
      let result: { email: string | any[]; }[] = []
      this.searchResult.forEach((el: {
        user: any;
        email: string | any[];
      }) => {
        if (el.user.email.includes(this.searchValue) ||
          (el.user.detail.displayName !== undefined && el.user.detail.displayName !== null && el.user.detail.displayName.includes(this.searchValue)) ||
          (el.user.detail.firstname !== undefined && el.user.detail.firstname !== null && el.user.detail.firstname.includes(this.searchValue)) ||
          (el.user.detail.lastname !== undefined && el.user.detail.lastname !== null && el.user.detail.lastname.includes(this.searchValue))) {
          result.push(el);
        }
      });
      this.emptyResult = result.length === 0;
      this.members = [...result];
    } else {
      this.emptyResult = false;
      this.emptyResult = false;
      this.members = [...this.searchResult];
    }
  }

  getValidTeams() {
    let teams = ''
    this.teamsValid.forEach((el) => {
      teams = teams + ', ' + el.name;
    })
    return teams.slice(1);
  }

  goToInvite() {
    if (this.onboardingStep === 7){
      this.setStep(6)
    } else if (this.onboardingStep === 5){
     this.setStep(3)
    }
  }

  click(){
    localStorage.setItem('loop', JSON.stringify(''));
    this.onboardingDone = true;
  }

  getSuggestions() {
    this.api.getPollingSuggestedFrequency(this.pollingFrequency[this.frequencySelected][this.slide].uuid, this.sliderValue).subscribe(
        res => {
          res.sort((a:any,b:any) => a.min - b.min)
          this.pollingSuggestedFrequency = res;
          this.reportFrequency= {
            weekly:false,
            '14_days':false,
            monthly:false
          }
          this.tsFrequencySelected=res[0].type;
          res.forEach((el: any)=>{
            if (el.type === 'weekly') {
              this.reportFrequency.weekly = true;
            }   if (el.type === '14_days'){
              this.reportFrequency["14_days"] =true;
            }   if (el.type === 'monthly'){
              this.reportFrequency.monthly =true;
            }
          })
        }
    )
  }

  checkDisable(title: string) {
    // @ts-ignore
    return !this.reportFrequency[""+title];
  }

  changeTsFrequency() {
    this.tsSelectedFrequency = this.tsFrequencySelected;
  }

  getDisplayLanguage(language: any) {
    this.data.showLoading();
    // @ts-ignore
    const userLang = JSON.parse(localStorage.getItem('language'));
    if (userLang.language === 'English') {
      this.languageDisplayed = language.language
    } else if (userLang.language === 'Danish'){
      if (language.language === 'English') {
        this.languageDisplayed = 'Engelsk'
      } else if (language.language === 'Danish') {
        this.languageDisplayed = 'Dansk'
      }
    }

    if (language.language === 'English') {
      this.selectedCountryCode = 'gb'
    } else if (language.language === 'Danish') {
      this.selectedCountryCode = 'dk'
    }
    this.translate.use(language.tag.split("_")[0]);
    // get the industries
    this.api.getWorkspaceIndustries().subscribe(
        resInd => {
          this.data.hideLoading();
          this.workspaceIndustries = resInd;
        }, err => {
          this.data.hideLoading();
        }
    );
  }

  changeLanguage(data: any) {
    if (data === 'gb') {
      data = 'en';
    }
    this.apiLanguages.forEach((el: any) => {
      if (data === el.tag.split("_")[0].toLowerCase() || data === el.tag.split("_")[1].toLowerCase()) {
        this.data.language.language = el.tag.split("_")[0];
        this.data.setTeamCollectorSubs(true);
        this.workspace.language = el;
        localStorage.setItem('language', JSON.stringify(el));
        // patch WORKSPACE LANGUAGE
        this.api.patchWorkspace(this.workspace.uuid, {languageUuid: el.uuid}).subscribe(
          res => {
            this.workspace = res;
            this.getDisplayLanguage(el);
            this.workspaceInvitationBody= res.activeInvitationBody;
            this.workspaceInvitationBody.body = this.workspace.activeInvitationBody.body.replace(/&#10;&#13;/g, '\n');
            localStorage.setItem('WORKSPACE', JSON.stringify(res));
            this.getTeamTypes();
          }
        );
        this.api.patchUser(this.user.uuid, {preferredLanguageUuid: el.uuid}).subscribe(
            res =>{
              localStorage.setItem('userDetails', JSON.stringify(res));
            }
        );
      }
    });
  }
}
