import {Component, OnInit} from '@angular/core';
import {ApiwrapperService} from '../../../services/apiwrapper.service';
import {DataManipulationService} from '../../../services/data-manipulation.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit {
  public indicators = [];
  gotIndicators = false;
  indicatorTitle = '';
  indicatorSelected: any;
  selected = [];
  showSelect = false;
  categoryImage: string | undefined;
  indicatorImage: string | undefined;
  constructor(private apiwrapper: ApiwrapperService, private data: DataManipulationService) {
    if (localStorage.getItem('upgrading')){
      localStorage.removeItem('upgrading');
    }
  }

  ngOnInit() {
    this.getIndicators(true);
  }

  getIndicators(bool: boolean) {
    this.gotIndicators = false;
    this.apiwrapper.getQuestionsSuperCategories().subscribe(
      res => {
        this.indicators = res;
        this.gotIndicators = true;
        if (bool) {
          this.sortSelect();
        }
      }, err => {
        console.log(err);
      }
    );
  }

  updateIndicators() {
    this.apiwrapper.patchIndicators(this.indicatorSelected).subscribe(
      res => {
        this.getIndicators(false);
        this.data.showAlert('Indicator details updated!');
      }, err => {
        this.data.showAlertError('There was an error updating the indicator details.');
      });
  }

  private sortIndicators(x: number, y: number) {
    this.indicatorSelected = this.indicators[x];
    // @ts-ignore
    this.indicatorSelected.indicator = this.indicators[x].indicators[y];
    this.indicatorTitle = this.indicatorSelected.indicator.title + ' in ' + this.indicatorSelected.title;
    this.categoryImage = this.indicatorSelected.title;
    this.indicatorImage = this.indicatorSelected.indicator.title;
  }

  private sortSelect() {
    let i = 0;
    this.indicators.forEach((category) => {
      // @ts-ignore
      category.indicators.forEach((indicator) => {
        i++;
        // @ts-ignore
        this.selected.push({selected: indicator.title + ' in ' + category.title, value: i});
      });
    });
    this.showSelect = true;
    this.sortIndicators(0, 0);
  }

  itemSelected(event: any[] | any) {
    switch (event) {
      case 1:
        this.sortIndicators(0, 0);
        break;
      case 2:
        this.sortIndicators(0, 1);
        break;
      case 3:
        this.sortIndicators(0, 2);
        break;
      case 4:
        this.sortIndicators(0, 3);
        break;
      case 5:
        this.sortIndicators(1, 0);
        break;
      case 6:
        this.sortIndicators(1, 1);
        break;
      case 7:
        this.sortIndicators(1, 2);
        break;
      case 8:
        this.sortIndicators(1, 3);
        break;
      case 9:
        this.sortIndicators(2, 0);
        break;
      case 10:
        this.sortIndicators(2, 1);
        break;
      case 11:
        this.sortIndicators(2, 2);
        break;
      case 12:
        this.sortIndicators(2, 3);
        break;
    }
  }
  isMVP() {
    return true;
    // return localStorage.getItem('WORKSPACE_UUID') === 'a57c2fa0-5c07-11ea-bbef-391a5da26eac';
  }
}
