<header [ngClass]="!data.showMobileWarning ? 'warning' : ''" class="header">
  <div class="header__container">
    <div class="header__toggle">
      <img src="../../../assets/images/logo.png" id="header-toggle" style="width: auto;" alt="">
    </div>
  </div>
</header>
<!--========== NAV ==========-->
<div class="nav" id="navbar">
  <nav class="nav__container" style="padding-left: 1rem">
    <div>
      <button (click)="closeMenu()" class="p-button-text nav-close-button-mobile" pButton type="button"
              icon="bi bi-chevron-bar-left" iconPos="left"></button>
      <a href="#" class="nav__link nav__logo">
        <!--        <picture>-->
        <!--          <source media="(min-width: 767px)" srcset="../../../assets/images/successteam-logo-white.svg">-->
        <!--          <img src="../../../assets/images/logo.png" style="width: auto;">-->
        <!--        </picture>-->
        <img class="nav__logoLogo" style="width: auto;" alt="">
      </a>

      <div class="nav__list" style="padding-top: 5rem;" (mouseleave)="retrieveMenuItems()">
        <div class="nav__items">
          <a routerLink="/" class="nav__link active" *ngIf="data.showHomeMenu === 'home'">
            <i class='bi bi-house-door nav__icon'></i>
            <span class="nav__name">{{'nav_home'|translate}}</span>
          </a>
          <a [routerLink]="memberPrio?.workspace.startGuide ? '/workspace-overview' : '/'" class="nav__link active" *ngIf="data.showHomeMenu === 'workspace' && teams.length > 1">
            <i class='bi bi-house-door nav__icon'></i>
            <span class="nav__name">{{'nav_home'|translate}}</span>
          </a>
          <a routerLink="/workspace-member" class="nav__link active" *ngIf="data.showHomeMenu === 'member'">
            <i class='bi bi-house-door nav__icon'></i>
            <span class="nav__name">{{'nav_home'|translate}}</span>
          </a>
          <a routerLink="/workspace-member-leader" class="nav__link active" *ngIf="data.showHomeMenu === 'member-leader'">
            <i class='bi bi-house-door nav__icon'></i>
            <span class="nav__name">{{'nav_home'|translate}}</span>
          </a>
          <div class="nav__dropdown" [ngClass]="isTeamDropdownOpen ? 'opened':''"
               (click)="isTeamDropdownOpen = !isTeamDropdownOpen">
            <a class="nav__link">
              <i class="bi bi-people nav__icon"></i>
              <span class="nav__name">{{'left_menu_1'| translate}}</span>
              <i *ngIf="teams.length > 0"
                 [ngClass]="isTeamDropdownOpen ? 'rotate':''"
                 class='bi bi-chevron-down nav__icon nav__dropdown-icon'></i>
            </a>

            <div class="nav__dropdown-collapse" *ngIf="teams.length > 0">
              <div class="nav__dropdown-content menu-teams">
                <a routerLink="/teams-overview" class="nav__dropdown-item" *ngIf="teams.length > 1" (click)="currentTeamUuid = ''">
                  <i class="bi bi-search nav__icon"></i>
                  <span class="nav__name">{{'teams_overview'| translate}}</span>
                </a>
                <a class="nav__dropdown-item" *ngFor="let team of teams" (click)="setCurrentTeam(team.uuid)"
                   routerLink="/team/{{team.uuid}}" [ngStyle]="currentTeamUuid === team.uuid && onCurrentTeamPage ? {'color':'white'}:{}">
                  <i class="bi bi-bar-chart-line nav__icon"></i>
                  <span class="nav__name">{{team.name}}</span>
                </a>
              </div>
            </div>
            <a (click)="this.memberPrio?.role.workspacePriority > 10 ? modal.openTeamModal() : modal.openAdminRequestModal()" class="nav__dropdown-item" style="margin-left: 2rem"
               pTooltip="{{'left_menu_11_tooltip' | translate}}" tooltipPosition="right" tooltipStyleClass="tooltip-style"
               [tooltipDisabled]="this.memberPrio?.role.workspacePriority > 10">
              <i class='bi bi-plus-circle nav__icon'></i>
              <span class="nav__name">{{'left_menu_11'| translate}}</span>
            </a>
          </div>

<!--          <a routerLink="/team-analyse" class="nav__link">-->
<!--            <i class='bi bi-clipboard2-data nav__icon'></i>-->
<!--            <span class="nav__name">Team analyse</span>-->
<!--          </a>-->

          <a routerLink="/team-actions" class="nav__link" *ngIf="showActionButton">
            <i class='bi bi-list-task nav__icon'></i>
            <span class="nav__name">{{'left_menu_2'|translate}}</span>
          </a>
          <a routerLink="/feedback-templates" class="nav__link" *ngIf="memberPrio?.role.workspacePriority >= 20 || showFeedbackTemplatesButton">
            <i class='bi bi bi-book nav__icon'></i>
            <span class="nav__name">{{'custom_poll_menu_item'|translate}}</span>
          </a>


          <!--          <a href="#" class="nav__link">-->
          <!--            <i class='bi bi-plus-circle nav__icon'></i>-->
          <!--            <span class="nav__name">Add</span>-->
          <!--          </a>-->

          <div class="nav__dropdown" [ngClass]="isAddDropdownOpen ? 'opened':''"
               (click)="isAddDropdownOpen = !isAddDropdownOpen">
            <a class="nav__link">
              <i class='bi bi-building nav__icon'></i>
              <span class="nav__name">{{'left_menu_3'|translate}}</span>
              <i [ngClass]="isAddDropdownOpen ? 'rotate':''"
                 class='bi bi-chevron-down nav__icon nav__dropdown-icon'></i>
            </a>

            <div class="nav__dropdown-collapse">
              <div class="nav__dropdown-content">
                <a (click)="this.memberPrio?.role.workspacePriority > 10 ? modal.openMemberModal() : modal.openAdminRequestModal()"
                   class="nav__dropdown-item" pTooltip="{{'left_menu_31_tooltip' | translate}}" tooltipPosition="right" tooltipStyleClass="tooltip-style"
                   [tooltipDisabled]="this.memberPrio?.role.workspacePriority > 10">
                  <i class='bi bi-person-plus nav__icon'></i>
                  <span class="nav__name">{{'left_menu_31'|translate}}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="nav__dropdown" [ngClass]="isSettingsDropdownOpen ? 'opened':''"
               (click)="isSettingsDropdownOpen = !isSettingsDropdownOpen">
            <a class="nav__link">
              <i class='bi bi-gear nav__icon'></i>
              <span class="nav__name">{{'left_menu_4'|translate}}</span>
              <i [ngClass]="isSettingsDropdownOpen ? 'rotate':''"
                 class='bi bi-chevron-down nav__icon nav__dropdown-icon'></i>
            </a>

            <div class="nav__dropdown-collapse">
              <div class="nav__dropdown-content">
                <a routerLink="/settings/account" class="nav__dropdown-item">
                  <i class='bi bi-person nav__icon'></i>
                  <span class="nav__name">{{'left_menu_41'|translate}}</span>
                </a>
                <a routerLink="/settings/workspace" class="nav__dropdown-item">
                  <i class='bi bi-globe nav__icon'></i>
                  <span class="nav__name">{{'left_menu_42'| translate}}</span>
                </a>
                <a (click)="data.lastRouteBeforeBilling = 'menu'" routerLink="/settings/billing" class="nav__dropdown-item"
                   *ngIf="this.memberPrio?.role.workspacePriority === 40">
                  <i class='bi bi-cart nav__icon'></i>
                  <span class="nav__name">{{'left_menu_43'| translate}}</span>
                </a>
                <!--                <a routerLink="/settings/actions" class="nav__dropdown-item">-->
                <!--                  <i class='bi bi-grid-3x2-gap nav__icon'></i>-->
                <!--                  <span class="nav__name">Actions</span>-->
                <!--                </a>-->
                <!--                <a routerLink="/settings/statements" class="nav__dropdown-item">-->
                <!--                  <i class='bi bi-journal-text nav__icon'></i>-->
                <!--                  <span class="nav__name">Statements</span>-->
                <!--                </a>-->
              </div>
            </div>
          </div>

        </div>

<!--        <a class="nav__link nav__logout">-->
<!--          <i class='bi bi-power nav__icon'></i>-->
<!--          <span class="nav__name" (click)="goToBilling()">URL</span>-->
<!--        </a>-->

        <a class="nav__link nav__logout">
          <i class='bi bi-power nav__icon'></i>
          <span class="nav__name" (click)="logout()">{{'left_menu_5'|translate}}</span>
        </a>
      </div>
    </div>

    <div *ngIf="workspacePlanFree && this.memberPrio?.role.workspacePriority > 10" routerLink="/settings/billing" (click)="goToBilling()">
      <img class="menu-bottom-image" style="width: auto; cursor: pointer" alt="">
    </div>

  </nav>
</div>
