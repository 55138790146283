import { Component, OnInit } from '@angular/core';
import {ApiwrapperService} from "../../services/apiwrapper.service";
import {DataManipulationService} from "../../services/data-manipulation.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {

  constructor(private api: ApiwrapperService, public data:DataManipulationService,
              private authService: AuthenticationService) { }

  ngOnInit(): void {
  }

  resendForgotMail() {
    this.authService.sendResetPasswordMail(this.data.forgetEmail).subscribe((res) => {
      this.data.mailSent = true;
      this.data.hideLoading();
      // show the mail sent page
      this.data.showAlert('A new reset password email was sent to you.');
    }, () => {
      this.data.showAlertError('There was an error sending you the reset password email. Try again later.');
    });
  }

  resendMail() {
    this.api.postResendInvitation(this.data.registeredMember).subscribe(
      res => {
        this.data.showAlert('A new activation email was sent to you.');
      },
      err => {
        this.data.showAlertError('There was an error sending you the activation email.');
      }
    );
  }
}
