<div class="main-container">
    <div class="review-custom-poll-header" *ngIf="this.teamSelected && teamsAndCategories.length">
        <h2 class="rcp-header-title">{{'custom_poll_review_title' | translate}}</h2>
        <div class="review-custom-poll-body">
            <div class="teams-container">
                <h2 style="font-size: 1.8rem;">{{'workspace_tab2' | translate}}</h2>
                <div class="team" [ngClass]="defaultTeam === index ? 'active-team':''"
                     *ngFor="let team of teamsAndCategories, let index = index"
                     (click)="selectTeam(index)">
                    <span class="team-name">{{team.team.name}}</span>
                    <span *ngIf="teamsWithSpecialCategories[index]" class="special-tag">{{'custom_poll_review_special' | translate}}</span>
                    <i [ngStyle]="!teamsWithSpecialCategories[index] ? {'margin-left':'auto'}:{}" class="bi bi-chevron-right"></i>
                </div>
            </div>
            <div class="categories-container">
                <h2 style="font-size: 1.8rem;">{{'custom_poll_review_general_categ' | translate}}</h2>
                <div class="line"></div>
                <div class="general-categories-container">
                    <div class="general-category" [ngStyle]="category.category.type !=='general' ? {'display':'none'}:{}" *ngFor="let category of selectedCategories">
                        <div *ngIf="category.category.type === 'general'" class="category">
                            <input class="category-check" type="checkbox" [checked]="true" [disabled]="true">
                            <span>{{category.category.title}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="teamsWithSpecialCategories[defaultTeam]">
                    <h2 style="font-size: 1.8rem;">{{'custom_poll_review_special_categ' | translate}}</h2>
                    <div class="line"></div>
                    <div class="general-categories-container">
                        <div class="general-category" [ngStyle]="category.category.type !=='special' ? {'display':'none'}:{}" *ngFor="let category of selectedCategories">
                            <div *ngIf="category.category.type === 'special'" class="category">
                                <input class="category-check" type="checkbox" [checked]="true" [disabled]="true">
                                <span>{{category.category.title}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="review-custom-poll-footer" *ngIf="teamSelected && teamsAndCategories.length">
        <button class="review-custom-poll-back-btn" pButton type="button" label="{{'custom_poll_gen_categ_back_btn' | translate}}" (click)="back()"></button>
        <span class="save-and-finish-later" (click)="saveAndFinish()">{{'custom_poll_gen_categ_save_and_finish' | translate}}</span>
        <button class="review-custom-poll-next"
            pButton type="button"             
            label="{{'next_button' | translate}}"
            (click)="endReviewPage()"></button>
    </div>
</div>