<div class="notifications-header">
  <h2 class="notifications-header-title">{{'notification_title'|translate}}</h2>
</div>
<div class="notifications-body" *ngIf="gotNotifications">
  <div *ngIf="notifications.unread.length === 0 && notifications.read.length === 0">
    <div class="date-notifications-container" style="padding: 0">
      <div class="date-notifications-inner-container">
        <div class="statements-value">
          <div class="notification-container">
            <p-accordion class="'blue-shadow'">
              <p-accordionTab [disabled]="true">
                <ng-template pTemplate="header">
                  <img [src]="'../../assets/svg/notification-info.svg'"
                       alt="icon">
                  <h3 [ngStyle]="{'font-size':'1.6rem', 'margin':'0 0 0 1.7rem'}">{{'notification_no_notification'|translate}}</h3>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="notifications.unread.length > 0">
    <div *ngFor="let date of unreadNotificationsDates" class="date-container">
      <div class="date">
        <div style="margin-left:0" class="team-polling-time">
          <span>{{date | date:'dd.MM.yyyy'}}</span>
        </div>
      </div>
      <div class="date-notifications-container">
        <div class="date-notifications-inner-container">
          <div *ngFor="let unread of notifications.unread, let first = first" class="statements-value">
            <div *ngIf="(date | date:'dd.MM.yyyy') === (unread['created']['date'] | date:'dd.MM.yyyy')">
              <div class="notification-container">
                <p-accordion [ngClass]="unread['resourceType'] === 'poll_new' ? 'green-shadow' :
                                    unread['resourceType'] === 'workspace_new' ? 'green-shadow' :
                                    unread['resourceType'] === 'member_new' ? 'blue-shadow' :
                                    unread['resourceType'] === 'task_new' ? 'orange-shadow' : ''">
                  <p-accordionTab [selected]="first">
                    <ng-template pTemplate="header">
                      <img [src]="unread['resourceType'] === 'poll_new' ? 'assets/svg/notification-checkmark.svg' :
                                            unread['resourceType'] === 'workspace_new' ? 'assets/svg/notification-checkmark.svg' :
                                            unread['resourceType'] === 'member_new' ? 'assets/svg/notification-info.svg' :
                                            unread['resourceType'] === 'task_new' ? 'assets/svg/notification-warning.svg' : ''"
                           alt="icon">
                      <h3 [ngStyle]="{'font-size':'1.6rem', 'margin':'0 0 0 1.7rem'}">{{unread['title']}}</h3>
                      <div *ngIf="unread['resourceType'] === 'poll_new'" class="notification-polling">
                        <i [ngStyle]="{'background-color':'#48DF99'}" class="bi bi-caret-right ongoing-icon"></i>
                        <span class="team-polling-status">{{'team_feedback_status2'|translate}}</span>
                        <span class="feedback-loop">{{'team_feedback_title'|translate}}</span>
                      </div>
                      <button *ngIf="unread['resourceType'] === 'task_new' || unread['resourceType'] === 'member_new'"
                              pButton type="button" label="Team dashboard" icon="bi bi-chevron-right" iconPos="right"
                              class="team-dashboard"></button>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <h3
                        [ngStyle]="{'font-size':'1.6rem','color':'#000000', 'margin':'1rem 0 .8rem 0'}">{{unread['body']}}</h3>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="notifications.read.length > 0">
    <div *ngFor="let date of readNotificationsDates" class="date-container">
      <div class="date">
        <div style="margin-left:0" class="team-polling-time">
          <span>{{date | date:'dd.MM.yyyy'}}</span>
        </div>
      </div>
      <div class="date-notifications-container">
        <div class="date-notifications-inner-container">
          <div *ngFor="let read of notifications.read, let first = first" class="statements-value">
            <div *ngIf="(date | date:'dd.MM.yyyy') === (read['created']['date'] | date:'dd.MM.yyyy')">
              <div class="notification-container">
                <p-accordion [ngClass]="read['resourceType'] === 'poll_new' ? 'green-shadow' :
                                        read['resourceType'] === 'workspace_new' ? 'green-shadow' :
                                        read['resourceType'] === 'member_new' ? 'blue-shadow' :
                                        read['resourceType'] === 'task_new' ? 'orange-shadow' : ''">
                  <p-accordionTab [selected]="first">
                    <ng-template pTemplate="header">
                      <img [src]="read['resourceType'] === 'poll_new' ? 'assets/svg/notification-checkmark.svg' :
                                                read['resourceType'] === 'workspace_new' ? 'assets/svg/notification-checkmark.svg' :
                                                read['resourceType'] === 'member_new' ? 'assets/svg/notification-info.svg' :
                                                read['resourceType'] === 'task_new' ? 'assets/svg/notification-warning.svg' : ''"
                           alt="icon">
                      <h3 [ngStyle]="{'font-size':'1.6rem', 'margin':'0 0 0 1.7rem'}">{{read['title']}}</h3>
                      <div *ngIf="read['resourceType'] === 'poll_new'" class="notification-polling">
                        <i [ngStyle]="{'background-color':'#48DF99'}" class="bi bi-caret-right ongoing-icon"></i>
                        <span class="team-polling-status">{{'team_feedback_status2'|translate}}</span>
                        <span class="feedback-loop">{{'team_feedback_title'|translate}}</span>
                      </div>
                      <button *ngIf="read['resourceType'] === 'task_new' || read['resourceType'] === 'member_new'"
                              pButton type="button" label="Team dashboard" icon="bi bi-chevron-right" iconPos="right"
                              class="team-dashboard"></button>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <h3
                        [ngStyle]="{'font-size':'1.6rem','color':'#000000', 'margin':'1rem 0 .8rem 0'}">{{read['body']}}</h3>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
