import { Component, OnChanges, OnInit, Input, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strenght-metter',
  templateUrl: './password-strenght-metter.component.html',
  styleUrls: ['./password-strenght-metter.component.scss']
})
export class PasswordStrenghtMetterComponent implements OnInit, OnChanges {

  @Input()
  passwordToCheck!: string;   
  step0!: string;  
  step1!: string;  
  step2!: string;  
  step3!: string;  
  
  private colors = ['#FF3C3C', '#FFC828', '#FFFF00', '#00BD82'];
  
  constructor() { }

  private static measureStrength(pass: string) {  
      let score = 0;    
      let letters: any = {};  
      for (let i = 0; i< pass.length; i++) {  
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;  
      score += 5.0 / letters[pass[i]];  
      }    
      let variations: any = {  
      digits: /\d/.test(pass),  
      lower: /[a-z]/.test(pass),  
      upper: /[A-Z]/.test(pass),  
      nonWords: /\W/.test(pass),  
      };  
      let variationCount = 0;  
      for (let check in variations) {  
      variationCount += (variations[check]) ? 1 : 0;  
      }  
      score += (variationCount - 1) * 10;  
      return Math.trunc(score);  
  }
  private getColor(score: number) {  
    let idx = 0;  
    if (score > 70) {  
      idx = 3;  
    } else if (score > 40) {  
      idx = 2;  
    } else if (score >= 30 || score >= 20) {  
      idx = 1;  
    }  
    return {  
      idx: idx + 1,  
      col: this.colors[idx]  
    };  
  }   
 
  
  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {  
      var password = changes['passwordToCheck'].currentValue;  
      this.setBarColors(5, '#DDD');  
      if (password) {  
          let c = this.getColor(PasswordStrenghtMetterComponent.measureStrength(password));  
          this.setBarColors(c.idx, c.col);  
      }  
  }  
  private setBarColors(count: number, col: string) {
      for (let i = 0; i < count; i++) {  
          (this as any)['step' + i] = col;  
      }  
  }
  ngOnInit(): void {
    
  }

}
