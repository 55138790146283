<div class="main-container">
  <div class="auth-container">
    <div class="left-side">
      <img class="logo" src="../../../assets/images/successteam-logo-white.svg" alt="succesteam_logo">
      <!--      <h1 class="">Startups</h1>-->
      <!--      <p class="">Learn about creating the best and most effective teams as a startup</p>-->
      <!--      <img class="startup-logo" src="../../../assets/images/startup.png">-->
    </div>
    <div class="right-side">
      <div class="" *ngIf="!gotWorkspaces">
        <h1 class="">{{'login_title'| translate}}</h1>
        <p>{{'login_no_account' | translate}} <a class="blue-link"
                                                 [routerLink]="invitation ? '/register/' + invitation : '/register'"> {{'login_no_account_create' | translate}}</a>
        </p>
        <form class="mt-12" [formGroup]="loginForm" (ngSubmit)="login()">
          <div class="input-container">
            <label class="">{{'login_email' | translate}}</label>
            <input class="form-control register-input" id="email" formControlName="email" type="email">
            <div
              *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
              class="text-danger">
              <div *ngIf="loginForm.controls['email']?.errors?.['required']">{{'login_email_error' |translate}}</div>
            </div>
          </div>
          <div class="input-container">
            <label class="">{{'login_password' | translate}}</label>
            <input class="form-control register-input" id="password" formControlName="password" type="password">
          </div>

          <div class="">
            <input class="form-check-input" type="checkbox" value="" id="checkbox">
            <label class="form-check-label ms-2 p-top">{{'login_remember_me' | translate}}</label>
          </div>
          <a class="link" routerLink="/reset-password">{{'login_forgot_pass' | translate}}</a>
          <button class="btn register-submit-button" type="submit">
            {{'login_button'|translate}}
            <i class="bi bi-arrow-right ms-2"></i>
          </button>
        </form>
      </div>

      <div class="select-workspace" *ngIf="gotWorkspaces">
        <h1 class="">{{'login_select_workspace' | translate}}</h1>
        <div *ngIf="gotWorkspaces && workspaces.length > 0" class="select-workspace-dropdown-container">
          <p-dropdown
            [options]="workspaces"
            dropdownIcon="bi bi-chevron-down"
            optionLabel="workspace.name"
            [(ngModel)]="workspacesSelected"
            (onChange)="workspaceSelected(workspacesSelected)"
            placeholder="{{'Select the workspace you want to enter' | translate}}">
            <!-- <option [value]="workspace" *ngFor="let workspace of workspaces">{{workspace.workspace.name}}</option> -->
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>
